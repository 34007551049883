import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ChevronDownIcon } from '../../../../../images/icons/ChevronDownIcon';
import { CredentialType, getStatusLicenseColor } from '../../../../../utils/CredentialUtils';
import StateCredential from '../../credentialComponents/StateCredential';
import { deepCopy } from '../../../../../utils';


export const LicenseSectionItem = ({
  index,
  license: licenseProp,
  licenses: licensesProp,
  setLicenses: setLicensesProp,
  stateCredentials,
  allProfessions,
  setDeleteId,
  setShowDeletePopup,

  // new table item variant
  isTableItem,
  isExpanded,
  handleToggle,
}) => {
  const [licenses, setLicensesState] = useState(deepCopy(licensesProp));

  useEffect(() => {
    setLicensesState(deepCopy(licensesProp))
  }, [licensesProp]);

  const license = licenses?.[index] || {};

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.users.licenseDetailLoadingId === license?.id);

  if (!license?.id) {
    return null;
  }

  const stateLicense = license.credentials.find(
    (credential) => credential && credential.credential_type === CredentialType.StateLicense,
  );
  const daCertificate = license.credentials.find(
    (credential) => credential && credential.credential_type === CredentialType.DACertificate,
  );
  const cprBls = license.credentials.find(
    (credential) => credential && credential.credential_type === CredentialType.CprBls,
  );
  const radiology = license.credentials.find(
    (credential) => credential && credential.credential_type === CredentialType.Radiology,
  );
  const reference = license.credentials.find(
    (credential) => credential && credential.credential_type === CredentialType.Reference,
  );

  const professionCredential = stateCredentials?.[license.state]?.[license.profession];

  const hasStateLicense = professionCredential?.state_license;
  const hasDACertificate = professionCredential?.da_certificate;
  const hasCPRBLS = professionCredential?.[CredentialType.CprBls];
  const hasRadiology = professionCredential?.radiology;
  const hasReference = professionCredential?.reference;

  const hasOnlyOneCredential =
    license?.state &&
    license?.profession &&
    stateCredentials &&
    stateCredentials[license.state] &&
    professionCredential &&
    Object.keys(professionCredential).length === 1;


  const licenseContainerStyle = isTableItem
    ? {
        borderTop: 0,
        paddingBottom: isExpanded ? 70 : 0,
      }
    : {
        marginBottom: 70,
      };

  const renderStateCredential = () => {
    if (isLoading) {
      return (
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="info"
            style={{
              height: 30,
              width: 30,
            }}
          />
        </div>
      );
    }
    return (
      <StateCredential
        license={license}
        oldLicense={licensesProp?.[index]}
        stateLicense={stateLicense}
        reference={reference}
        daCertificate={daCertificate}
        cprBls={cprBls}
        radiology={radiology}
        hasStateLicense={hasStateLicense}
        hasDACertificate={hasDACertificate}
        hasCPRBLS={hasCPRBLS}
        hasReference={hasReference}
        hasRadiology={hasRadiology}
        hasOnlyOneCredential={hasOnlyOneCredential}
        stateCredentials={stateCredentials}
        setLicenses={isTableItem ? setLicensesState : setLicensesProp}
        allProfessions={allProfessions}
        licenses={licenses}
        setDeleteId={setDeleteId}
        setShowDeletePopup={setShowDeletePopup}
        dispatch={dispatch}
      />
    );
  };
  
  const expirationDate = licenseProp?.credentials?.[0]?.entry_field?.expiration_date;
  const expirationDateFormat = expirationDate ? moment(expirationDate).format('MM/DD/YYYY') : '-';

  return (
    <div
      style={{
        border: '1px solid #b7b7b7',
        ...licenseContainerStyle,
      }}
    >
      {isTableItem && (
        <div
          style={{
            padding: '16px 60px',
            borderBottom: isExpanded ? '1px solid #b7b7b7' : 0,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
          }}
          onClick={() => handleToggle(licenseProp.id)}
        >
          <div style={{ flex: 1 }}>
            {allProfessions.find((key) => key.code === licenseProp?.profession)?.name}
          </div>
          <div style={{ flex: 1 }}>{licenseProp?.state}</div>
          <div style={{ flex: 1, color: getStatusLicenseColor(licenseProp?.status) }}>
            {licenseProp.status.charAt(0).toUpperCase() + licenseProp.status.slice(1)}
          </div>
          <div style={{ flex: 1 }}>{expirationDateFormat}</div>
          {isExpanded ? (
            <div
              style={{
                transform: 'rotate(180deg)',
              }}
            >
              <ChevronDownIcon />
            </div>
          ) : (
            <ChevronDownIcon />
          )}
        </div>
      )}
      {(isExpanded || !isTableItem) && renderStateCredential()}
    </div>
  );
};
