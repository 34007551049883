export const TABS = {
  ACCOUNT: 'Account',
  NOTIFICATIONS: 'Notifications',
};

export const ENTITY_TYPES = {
  DSO: 'dso',
  REGION: 'region',
  GROUP: 'group',
  OFFICE: 'office',
};
