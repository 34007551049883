export const GIFT_CARD_REDEEM_POINT_STATUS = {
  SENT: 'sent',
  FAILED: 'failed',
  PENDING: 'pending',
};

export const GIFT_CARD_REDEEM_POINT_STATUS_TEXT = {
  sent: 'Sent',
  failed: 'Failed',
  pending: 'Pending',
};
