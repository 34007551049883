import { Colors } from '../../../themes/colors';

export const InputSearch = ({ style, value, onChange, placeholder }) => (
  <input
    className="form-control"
    style={{
      paddingLeft: '8px',
      width: 220,
      height: 32,
      boxShadow: 'none',
      backgroundColor: Colors.white,
      border: `1px solid ${Colors.border}`,
      ...style,
    }}
    value={value}
    type="search"
    onChange={onChange}
    placeholder={placeholder}
  />
);
