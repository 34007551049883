import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobs, reloadJobs } from '../../../actions/jobsAction';
import { fetchRequestedEducations } from '../../../actions/licensesAction';
import { getAdminRamUser } from '../../../actions/userActions';
import { createLoadingSelector } from '../../../apis/selectors';
import SynchronizeIcon from '../../../images/synchronize.png';
import Styles from '../../../themes/style.module.scss';
import { Table } from '../../commonComponents/Table/Table';
import { jobListColumn, jobListConditionalRowStyles } from '../../commonComponents/jobs-column';
import { CustomHeader } from './CustomHeader';
import { handleFilterChange } from '../../commonComponents/Table/utils';

const ROWS_PER_PAGE = 15;

const initialActions = ['GET_ADMIN_RAM', 'GET_DSO_NAMES'];
const initialLoadingSelector = createLoadingSelector(initialActions);

const actions = ['FETCH_JOBS', 'RELOAD_JOBS'];
const loadingSelector = createLoadingSelector(actions);

export const JobList = ({ payOutOnly }) => {
  const dispatch = useDispatch();

  const initialLoading = useSelector((state) => initialLoadingSelector(state));
  const isLoading = useSelector((state) => loadingSelector(state));
  const jobs = useSelector((state) => state.jobs.jobs);
  const searchParams = useSelector((state) => state.jobs.searchParams);
  const totalFiltered = useSelector((state) => state.jobs.totalFiltered);

  const [filter, setFilter] = useState({
    profession: searchParams?.profession || '',
    subProfession: searchParams?.subProfession || '',
    specialtyRdh: searchParams?.specialtyRdh || '',
    specialtyDa: searchParams?.specialtyDa || '',
    workerClassification: searchParams?.workerClassification || '',
    ram: searchParams?.ram || '',
    dsoName: searchParams?.dsoName || '',
    dsoManager: searchParams?.dsoManager || '',
    searchText: searchParams?.searchText || '',
    state: searchParams?.state || '',
    jobStatus: searchParams?.jobStatus || 'all',
    dateRange: {
      startDate: searchParams?.startDate,
      endDate: searchParams?.endDate,
      key: 'selection',
    },
    sizePerPage: ROWS_PER_PAGE,
    page: searchParams?.page || 1,
    sortOrder: searchParams?.sortOrder || 'desc',
    sortField: searchParams?.sortField || 'createdAt',
    dsoRamIds: searchParams?.dsoRamIds || '',
    dsoId: searchParams?.dsoId || '',
  });

  const totalPages = Math.ceil(totalFiltered / ROWS_PER_PAGE);

  const handleOnSort = (selectedColumn, sortDirection) => {
    const sortObj = {
      page: filter.page,
      sortField: selectedColumn.sortField,
      sortOrder: sortDirection,
    };

    setFilter((prevState) => ({
      ...prevState,
      ...sortObj,
    }));
  };

  useEffect(() => {
    dispatch(getAdminRamUser());
    dispatch(fetchRequestedEducations());
  }, [dispatch]);

  const handleTableChange = (page) => {
    setFilter((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    dispatch(
      fetchJobs({
        ...filter,
        ...filter.dateRange,
      }),
    );
  }, [filter, dispatch]);

  const getJobUrl = (job) => `/job/${job.id}`;

  const getUserUrl = (userId, userType) => `/user/${userType.toLowerCase()}/${userId}`;

  const getColumn = () =>
    jobListColumn({
      getUserUrl,
      getJobUrl,
      showCounterExpiration: ['pending', 'counter', 'pending-counter'].includes(filter?.jobStatus),
      cancellationTime: true,
    });

  if (initialLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          overflowY: 'auto',
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginTop: 10,
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 20,
          paddingTop: 8,
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          position: 'absolute',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: '50%',
          }}
        >
          <h3 className={Styles.h3} style={{ fontSize: '24px', textAlign: 'center' }}>
            {payOutOnly ? 'Pay Out Jobs' : 'Jobs'}
          </h3>
          {isLoading ? (
            <div>
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            </div>
          ) : (
            <div>
              <img
                src={SynchronizeIcon}
                width="38px"
                height="38px"
                className={Styles.dashboardUserIcon}
                onClick={() => dispatch(reloadJobs())}
                alt="reload action"
              />
            </div>
          )}
        </div>
      </div>
      <div style={{ width: '90%', paddingTop: 60 }}>
        <CustomHeader
          filter={filter}
          handleFilterChange={(type, value) => handleFilterChange(type, value, setFilter)}
          payOutOnly={payOutOnly}
        />
        <Table
          keyField="jobList.id"
          initialPage={filter.page}
          columns={getColumn()}
          data={jobs}
          noDataComponent="There are no Jobs"
          isLoading={isLoading}
          totalPages={totalPages}
          showTotal={false}
          onSort={handleOnSort}
          paginationPerPage={ROWS_PER_PAGE}
          paginationTotalRows={totalFiltered}
          onPageChange={handleTableChange}
          conditionalRowStyles={jobListConditionalRowStyles}
        />
      </div>
    </div>
  );
};
