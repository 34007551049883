import { useDispatch, useSelector } from 'react-redux';
import {
  createErrorSelector,
  createLoadingSelector,
} from '../../../../../../../../../../../apis/selectors';
import { deleteRegionById } from '../../../../../../../../../../../actions/dsosActions';
import { clearError } from '../../../../../../../../../../../actions/errorActions';

const isRemoveRegionLoadingSelector = createLoadingSelector(['REMOVE_REGION_FROM_DSO']);
const isRemoveRegionErrorSelector = createErrorSelector(['REMOVE_REGION_FROM_DSO']);

export const useRemoveRegion = ({ dsoId, regionId }) => {
  const dispatch = useDispatch();

  const isRemoveRegionLoading = useSelector((state) => isRemoveRegionLoadingSelector(state));
  const isRemoveRegionError = useSelector((state) => isRemoveRegionErrorSelector(state));

  const handleRemoveRegion = () => {
    dispatch(deleteRegionById({ dsoId, regionId }));
  };

  const handleClearRegionError = () => {
    dispatch(clearError('REMOVE_REGION_FROM_DSO'));
  };

  return {
    isRemoveRegionError,
    isRemoveRegionLoading,
    handleClearRegionError,
    handleRemoveRegion,
  };
};
