import { InputSearch } from '../../../../../../../../../../../commonComponents/InputSearch';

export const SearchOffice = ({ value, onChange }) => (
  <div
    style={{
      width: 320,
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      marginInline: 'auto',
      marginTop: 24,
      marginBottom: 20,
    }}
  >
    <b
      style={{
        fontSize: 14,
        textWrap: 'nowrap',
      }}
    >
      Search:
    </b>
    <InputSearch placeholder="Type Office Name" value={value} onChange={onChange} />
  </div>
);
