import React from 'react';
import Portal from './Portal';
import CustomButton from './buttons';

export default ({
  title,
  text,
  textStyle,
  submitPressed,
  cancelPressed,
  cancelText,
  submitText,
  Icon,
  children,
  rightButtonDisabled = false,
  containerStyle = {},
  leftButtonProps = {},
  rightButtonProps = {},
  hideLeftButton = false,
  hideRightButton = false,
}) => (
  <Portal>
    <div className="modal" onMouseDownCapture={(e) => e.stopPropagation()}>
      <div
        className="modal_content"
        style={{ minHeight: 0, padding: 20, margin: 25, ...containerStyle }}
      >
        {Icon && (
          <img src={Icon} style={{ objectFit: 'contain', marginTop: 20 }} alt="modal_icon" />
        )}
        <h1
          className="blue_font"
          style={{ fontSize: 18, marginBottom: 10, color: '#435963', textAlign: 'center' }}
        >
          {title || ''}
        </h1>

        {text && (
          <p
            className="global_font f-dark"
            style={{
              margin: '10px 10px',
              color: '#697A82',
              marginTop: 20,
              fontSize: 16,
              ...textStyle,
            }}
          >
            {text || ''}
          </p>
        )}

        {children}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
            marginTop: 20,
            marginBottom: 40,
          }}
        >
          {!hideLeftButton && (
            <CustomButton
              width={200}
              name={cancelText}
              class_name="button"
              color="#4FC0CF"
              click={() => {
                cancelPressed();
              }}
              {...leftButtonProps}
            />
          )}
          {!hideRightButton && (
            <CustomButton
              width={200}
              name={submitText}
              class_name="button"
              color="#307F92"
              btnDisabled={rightButtonDisabled}
              click={() => {
                submitPressed();
              }}
              {...rightButtonProps}
            />
          )}
        </div>
      </div>
    </div>
  </Portal>
);
