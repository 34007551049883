import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  AccessPermission,
  AccountInfo,
  AssignDso,
  RateLimit,
  RateLimitHistory,
} from './components';
import { useAssignToDso } from './hooks/useAssignToDso';

import './styles.scss';

export const GeneralTab = () => {
  const params = useParams();
  const staffId = params?.id;

  const dso = useSelector((state) => state.staff.dso.details);
  const { handleAssignDso } = useAssignToDso();

  return (
    <div className="general-tab">
      <aside>
        <AccountInfo />
        <RateLimit staffId={staffId} />
        <RateLimitHistory />
      </aside>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <AssignDso
          value={{
            id: dso?.id,
            label: dso?.name,
          }}
          onSave={handleAssignDso}
        />
        <AccessPermission />
      </div>
    </div>
  );
};
