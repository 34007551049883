import { useEffect, useState } from 'react';
import { TrashIcon } from '../../../../../../../../../../images/icons';
import { MenuItem } from '../../../../../../../../../commonComponents/DotsMenu';
import { ConfirmRemoveRegionPopup } from './ConfirmRemoveRegionPopup';
import { useRemoveRegion } from './hooks';
import { RemoveRegionErrorPopup } from './RemoveRegionErrorPopup';

export const RemoveRegionMenuItem = ({ dsoId, item }) => {
  const [isShowConfirmModal, setShowConfirmModal] = useState(false);

  const { handleClearRegionError, handleRemoveRegion, isRemoveRegionError, isRemoveRegionLoading } =
    useRemoveRegion({
      dsoId,
      regionId: item.id,
    });

  useEffect(() => {
    if (!isRemoveRegionLoading || isRemoveRegionError) {
      setShowConfirmModal(false);
    }
  }, [isRemoveRegionLoading, isRemoveRegionError]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MenuItem
        Icon={() => <TrashIcon />}
        text="Remove Region"
        onClick={() => setShowConfirmModal(true)}
      />
      {isShowConfirmModal && (
        <ConfirmRemoveRegionPopup
          region={item}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={handleRemoveRegion}
        />
      )}
      {isRemoveRegionError && <RemoveRegionErrorPopup onClose={handleClearRegionError} />}
    </div>
  );
};
