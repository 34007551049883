import { DSOS_ACTIONS } from '../actions/actionTypes';
import { clearQueryCache } from '../apis/cache';
import { OrderBy } from '../enums/Sorting';

const defaultListParams = {
  dsoId: null,
  data: [],
  total: 0,
  isActiveRequest: false,
  isFinalPage: false,
  filters: {
    page: 1,
    limit: 15,
    orderBy: OrderBy.ASC,
  },
};

const regionListDefaultState = {
  ...defaultListParams,
  filters: {
    page: 1,
    limit: 100,
    groupID: undefined,
    orderBy: OrderBy.ASC,
  },
};

const officeListDefaultState = {
  ...defaultListParams,
  filters: {
    regionIDs: [],
    groupIDs: [],
    emptyRegion: false,
    officeName: null,
    page: 1,
    limit: 15,
    orderBy: OrderBy.ASC,
  },
};

export const defaultState = {
  dsosList: {
    data: [],
    total: 0,
    isActiveRequest: false,
    isFinalPage: false,
    filters: {
      dsoName: '',
      page: 1,
      limit: 15,
    },
  },
  dsoGroupDetails: {
    id: null,
    name: null,
    code: null,
    createdAt: null,
  },
  dsoGroupList: {
    dsoId: null,
    data: [],
    total: 0,
    isActiveRequest: false,
    isFinalPage: false,
    filters: {
      page: 1,
      limit: 15,
    },
  },
  dsoRegionList: {
    dsoId: null,
    data: [],
    total: 0,
    isActiveRequest: false,
    isFinalPage: false,
    filters: {
      page: 1,
      limit: 15,
    },
  },
  dsoOfficeAdminsList: {
    dsoId: null,
    data: [],
    total: 0,
    isActiveRequest: false,
    isFinalPage: false,
    filters: {
      dsoId: undefined,
      officeId: undefined,
      limit: 15,
      page: 1,
      staffEmail: undefined,
      staffName: undefined,
      staffPhone: undefined,
      regionId: undefined,
      groupId: undefined,
    },
  },
  dsoOfficeList: {
    dsoId: null,
    data: [],
    total: 0,
    isActiveRequest: false,
    isFinalPage: false,
    filters: {
      entity: 'dso',
      page: 1,
      limit: 15,
    },
  },
  dsoRegionOfficeList: {
    data: [],
    filters: {
      limit: 15,
      page: 1,
      regionId: null,
    },
    isActiveRequest: false,
    isFinalPage: false,
    total: 0,
  },
  dsoGroupOfficeList: {
    data: [],
    filters: {
      limit: 15,
      page: 1,
      regionId: null,
    },
    isActiveRequest: false,
    isFinalPage: false,
    total: 0,
  },
  office: {
    createdAt: null,
    dso: null,
    groups: null,
    id: null,
    name: null,
    region: null,
  },
  orgStructure: {
    selectedGroupId: null,
    selectedRegionsIds: [],
    selectedOffices: [],
    isBulkOfficeSelectionActive: false,
    groupList: defaultListParams,
    regionList: regionListDefaultState,
    officeList: officeListDefaultState,
  },
  dsoJobs: {
    data: [],
    dsoId: null,
    filters: {
      limit: 15,
      page: 1,
    },
    isFinalPage: false,
    total: 0,
  },
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  switch (action.type) {
    case DSOS_ACTIONS.FETCH_DSOS_LIST_REQUEST: {
      return {
        ...state,
        dsosList: {
          ...state.dsosList,
          isActiveRequest: true,
          filters: {
            ...state.dsosList.filters,
            ...action.payload,
          },
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSOS_LIST_SUCCESS: {
      const isFirstPage = state.dsosList.filters.page === 1;

      return {
        ...state,
        dsosList: {
          ...state.dsosList,
          isActiveRequest: false,
          total: action.payload.total,
          data:
            isFirstPage || !action.payload.isInfiniteScroll
              ? action.payload.data
              : [...state.dsosList.data, ...action.payload.data],
          isFinalPage: action.payload.data.length < state.dsosList.filters.limit,
          filters: {
            ...state.dsosList.filters,
            page: action.payload.page,
          },
        },
      };
    }
    case DSOS_ACTIONS.ADD_DSO_SUCCESS: {
      const {
        total,
        data,
        filters: { limit },
      } = state.dsosList;

      return {
        ...state,
        dsosList: {
          ...state.dsosList,
          data: limit - data.length > 0 ? [...data, action.payload] : data,
          total: total + 1,
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_REQUEST: {
      const isSameDsoId = state.dsoGroupList.dsoId === action.payload.dsoId;

      const { groupList } = state.orgStructure;

      return {
        ...state,
        dsoGroupList: {
          ...state.dsoGroupList,
          dsoId: action.payload.dsoId,
          filters: {
            ...state.dsoGroupList.filters,
            page: isSameDsoId ? action.payload.page || 1 : 1,
          },
          data: isSameDsoId ? state.dsoGroupList.data : [],
          total: isSameDsoId ? state.dsoGroupList.total : 0,
          isActiveRequest: true,
          isFinalPage: isSameDsoId ? state.dsoGroupList.isFinalPage : false,
        },
        orgStructure: {
          ...state.orgStructure,
          groupList: {
            ...groupList,
            dsoId: action.payload.dsoId,
            filters: {
              ...groupList.filters,
              page: isSameDsoId ? action.payload.page || 1 : 1,
              orderBy: action.payload.orderBy || OrderBy.ASC,
            },
            data: isSameDsoId ? groupList.data : [],
            total: isSameDsoId ? groupList.total : 0,
            isActiveRequest: true,
            isFinalPage: isSameDsoId ? groupList.isFinalPage : false,
          },
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_SUCCESS: {
      const { groupList } = state.orgStructure;

      return {
        ...state,
        dsoGroupList: {
          ...state.dsoGroupList,
          isActiveRequest: false,
          isFinalPage: action.payload.data.length < state.dsoGroupList.filters.limit,
          total: action.payload.total,
          data:
            state.dsoGroupList.filters.page === 1
              ? action.payload.data
              : [...state.dsoGroupList.data, ...action.payload.data],
        },
        orgStructure: {
          ...state.orgStructure,
          groupList: {
            ...groupList,
            isActiveRequest: false,
            isFinalPage: action.payload.data.length < groupList.filters.limit,
            total: action.payload.total,
            data:
              groupList.filters.page === 1
                ? action.payload.data
                : [...groupList.data, ...action.payload.data],
          },
        },
      };
    }
    case DSOS_ACTIONS.ADD_GROUP_TO_DSO_SUCCESS: {
      const { total, data } = state.dsoGroupList;

      const { total: groupListTotal, data: groupListData } = state.orgStructure.groupList;

      const dataGroup = {
        ...action.payload,
        numOfRegions: action.payload.numOfRegions || 0,
      };

      return {
        ...state,
        dsoGroupList: {
          ...state.dsoGroupList,
          data: data.length === total ? [...data, dataGroup] : data,
          total: total + 1,
        },
        orgStructure: {
          ...state.orgStructure,
          groupList: {
            ...state.orgStructure.groupList,
            data:
              groupListData.length === groupListTotal
                ? [...groupListData, dataGroup]
                : groupListData,
            total: groupListTotal + 1,
          },
        },
      };
    }
    case DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_SUCCESS: {
      return {
        ...state,
        dsoGroupList: {
          ...state.dsoGroupList,
          data: state.dsoGroupList.data.map((group) => {
            if (group.id === action.payload.id) {
              return {
                ...group,
                name: action.payload.name,
              };
            }
            return group;
          }),
        },
        orgStructure: {
          ...state.orgStructure,
          groupList: {
            ...state.orgStructure.groupList,
            data: state.orgStructure.groupList.data.map((group) => {
              if (group.id === action.payload.id) {
                return {
                  ...group,
                  name: action.payload.name,
                };
              }
              return group;
            }),
          },
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_REGION_LIST_REQUEST: {
      const isSameDsoId = state.dsoRegionList.dsoId === action.payload.dsoId;

      const { regionList } = state.orgStructure;

      return {
        ...state,
        dsoRegionList: {
          ...state.dsoRegionList,
          dsoId: action.payload.dsoId,
          filters: {
            ...state.dsoRegionList.filters,
            page: isSameDsoId ? action.payload.page || 1 : 1,
          },
          data: isSameDsoId ? state.dsoRegionList.data : [],
          total: isSameDsoId ? state.dsoRegionList.total : 0,
          isActiveRequest: true,
          isFinalPage: isSameDsoId ? state.dsoRegionList.isFinalPage : false,
        },
        orgStructure: {
          ...state.orgStructure,
          regionList: {
            ...regionList,
            dsoId: action.payload.dsoId,
            filters: {
              ...regionList.filters,
              page: isSameDsoId ? action.payload.page || 1 : 1,
              limit: action.payload.limit,
              groupID: action.payload.groupID,
              orderBy: action.payload.orderBy || OrderBy.ASC,
            },
            data: isSameDsoId ? regionList.data : [],
            total: isSameDsoId ? regionList.total : 0,
            isActiveRequest: true,
            isFinalPage: isSameDsoId ? regionList.isFinalPage : false,
          },
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_REGION_LIST_SUCCESS: {
      const { regionList } = state.orgStructure;

      return {
        ...state,
        dsoRegionList: {
          ...state.dsoRegionList,
          isActiveRequest: false,
          isFinalPage: action.payload.data.length < state.dsoRegionList.filters.limit,
          total: action.payload.total,
          data:
            state.dsoRegionList.filters.page === 1
              ? action.payload.data
              : [...state.dsoRegionList.data, ...action.payload.data],
        },
        orgStructure: {
          ...state.orgStructure,
          regionList: {
            ...regionList,
            isActiveRequest: false,
            isFinalPage: action.payload.data.length < regionList.filters.limit,
            total: action.payload.total,
            data:
              regionList.filters.page === 1
                ? action.payload.data
                : [...regionList.data, ...action.payload.data],
          },
        },
      };
    }
    case DSOS_ACTIONS.ADD_REGION_TO_DSO_SUCCESS: {
      const { total, data } = state.dsoRegionList;

      const { total: regionListTotal, data: regionListData } = state.orgStructure.regionList;

      const dataRegion = {
        ...action.payload,
        numOfOffices: action.payload.numOfOffices || 0,
      };

      return {
        ...state,
        dsoRegionList: {
          ...state.dsoRegionList,
          data: data.length === total ? [...data, dataRegion] : data,
          total: total + 1,
        },
        orgStructure: {
          ...state.orgStructure,
          regionList: {
            ...state.orgStructure.regionList,
            data:
              regionListData.length === regionListTotal
                ? [...regionListData, dataRegion]
                : regionListData,
            total: regionListTotal + 1,
          },
        },
      };
    }
    case DSOS_ACTIONS.EDIT_DSO_REGION_NAME_SUCCESS: {
      return {
        ...state,
        dsoRegionList: {
          ...state.dsoRegionList,
          data: state.dsoRegionList.data.map((region) => {
            if (region.id === action.payload.id) {
              return {
                ...region,
                name: action.payload.name,
              };
            }
            return region;
          }),
        },
        orgStructure: {
          ...state.orgStructure,
          regionList: {
            ...state.orgStructure.regionList,
            data: state.orgStructure.regionList.data.map((group) => {
              if (group.id === action.payload.id) {
                return {
                  ...group,
                  name: action.payload.name,
                };
              }
              return group;
            }),
          },
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_BY_ID_SUCCESS: {
      return {
        ...state,
        dsoGroupDetails: action.payload,
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_OFFICE_ADMINS_LIST_REQUEST: {
      const isSameDsoId = state.dsoOfficeAdminsList.dsoId === action.payload.dsoId;
      return {
        ...state,
        dsoOfficeAdminsList: {
          ...state.dsoOfficeAdminsList,
          dsoId: action.payload.dsoId,
          filters: {
            ...state.dsoOfficeAdminsList.filters,
            dsoId: action.payload.dsoId,
            officeId: action.payload.officeId,
            limit: action.payload.limit || 15,
            page: isSameDsoId ? action.payload.page || 1 : 1,
            staffEmail: action.payload.staffEmail,
            staffName: action.payload.staffName,
            staffPhone: action.payload.staffPhone,
            regionId: action.payload.regionId,
            groupId: action.payload.groupId,
          },
          data: isSameDsoId ? state.dsoOfficeAdminsList.data : [],
          total: isSameDsoId ? state.dsoOfficeAdminsList.total : 0,
          isActiveRequest: true,
          isFinalPage: isSameDsoId ? state.dsoOfficeAdminsList.isFinalPage : false,
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_OFFICE_ADMINS_LIST_SUCCESS: {
      const {
        data: { data, total },
        isInfiniteScroll,
      } = action.payload;
      const isFirstPage = state.dsoOfficeAdminsList.filters.page === 1;

      return {
        ...state,
        dsoOfficeAdminsList: {
          ...state.dsoOfficeAdminsList,
          isActiveRequest: false,
          isFinalPage: data.length < state.dsoOfficeAdminsList.filters.limit,
          total,
          data:
            isFirstPage || !isInfiniteScroll ? data : [...state.dsoOfficeAdminsList.data, ...data],
        },
      };
    }
    case DSOS_ACTIONS.CLEAR_DSO_REGION_OFFICE_LIST: {
      return {
        ...state,
        dsoRegionOfficeList: defaultState.dsoRegionOfficeList,
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_REGION_OFFICE_LIST_REQUEST: {
      return {
        ...state,
        dsoRegionOfficeList: {
          ...state.dsoRegionOfficeList,
          filters: {
            ...state.dsoRegionOfficeList.filters,
            ...action.payload,
          },
          isActiveRequest: true,
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_REGION_OFFICE_LIST_SUCCESS: {
      const { payload } = action;
      const { data, total } = payload;

      const isFirstPage = state.dsoRegionOfficeList.filters.page === 1;
      const isFinalPage =
        data.length === 0 || data.length < state.dsoRegionOfficeList.filters.limit;

      return {
        ...state,
        dsoRegionOfficeList: {
          ...state.dsoRegionOfficeList,
          data: isFirstPage ? data : [...state.dsoRegionOfficeList.data, ...data],
          isActiveRequest: false,
          isFinalPage,
          total,
        },
      };
    }
    case DSOS_ACTIONS.CLEAR_DSO_GROUP_OFFICE_LIST: {
      return {
        ...state,
        dsoGroupOfficeList: defaultState.dsoGroupOfficeList,
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_GROUP_OFFICE_LIST_REQUEST: {
      return {
        ...state,
        dsoGroupOfficeList: {
          ...state.dsoGroupOfficeList,
          filters: {
            ...state.dsoGroupOfficeList.filters,
            ...action.payload,
          },
          isActiveRequest: true,
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_GROUP_OFFICE_LIST_SUCCESS: {
      const { payload } = action;
      const { data, total } = payload;

      const isFirstPage = state.dsoGroupOfficeList.filters.page === 1;
      const isFinalPage = data.length === 0 || data.length < state.dsoGroupOfficeList.filters.limit;

      return {
        ...state,
        dsoGroupOfficeList: {
          ...state.dsoGroupOfficeList,
          data: isFirstPage ? data : [...state.dsoGroupOfficeList.data, ...data],
          isActiveRequest: false,
          isFinalPage,
          total,
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_OFFICE_LIST_REQUEST: {
      const isSameDsoId = state.dsoOfficeList.dsoId === action.payload.dsoId;

      const { officeList } = state.orgStructure;

      return {
        ...state,
        dsoOfficeList: {
          ...state.dsoOfficeList,
          dsoId: action.payload.dsoId,
          filters: {
            ...state.dsoOfficeList.filters,
            entity: action.payload.entity || 'dso',
            limit: action.payload.limit || 15,
            page: isSameDsoId ? action.payload.page || 1 : 1,
          },
          data: isSameDsoId ? state.dsoOfficeList.data : [],
          total: isSameDsoId ? state.dsoOfficeList.total : 0,
          isActiveRequest: true,
          isFinalPage: isSameDsoId ? state.dsoOfficeList.isFinalPage : false,
        },
        orgStructure: {
          ...state.orgStructure,
          officeList: {
            ...officeList,
            dsoId: action.payload.dsoId,
            filters: {
              ...officeList.filters,
              page: isSameDsoId ? action.payload.page || 1 : 1,
              limit: action.payload.limit,
              groupIDs: action.payload.groupIDs || [],
              regionIDs: action.payload.regionIDs || [],
              officeName: action.payload.officeName || null,
              orderBy: action.payload.orderBy || OrderBy.ASC,
              emptyRegion: action.payload.emptyRegion || false,
            },
            data: isSameDsoId ? officeList.data : [],
            total: isSameDsoId ? officeList.total : 0,
            isActiveRequest: true,
            isFinalPage: isSameDsoId ? officeList.isFinalPage : false,
          },
        },
      };
    }
    case DSOS_ACTIONS.CLEAR_DSO_OFFICE_LIST: {
      return {
        ...state,
        dsoOfficeList: defaultState.dsoOfficeList,
        orgStructure: {
          ...state.orgStructure,
          officeList: defaultState.orgStructure.defaultListParams,
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_OFFICE_LIST_SUCCESS: {
      const { officeList } = state.orgStructure;

      return {
        ...state,
        dsoOfficeList: {
          ...state.dsoOfficeList,
          isActiveRequest: false,
          isFinalPage: action.payload.data.length < state.dsoOfficeList.filters.limit,
          total: action.payload.total,
          data:
            state.dsoOfficeList.filters.page === 1
              ? action.payload.data
              : [...state.dsoOfficeList.data, ...action.payload.data],
        },
        orgStructure: {
          ...state.orgStructure,
          officeList: {
            ...officeList,
            isActiveRequest: false,
            isFinalPage: action.payload.data.length < officeList.filters.limit,
            total: action.payload.total,
            data:
              officeList.filters.page === 1
                ? action.payload.data
                : [...officeList.data, ...action.payload.data],
          },
        },
      };
    }
    case DSOS_ACTIONS.GET_OFFICE_BY_ID_SUCCESS: {
      return {
        ...state,
        office: {
          ...state.office,
          ...action.payload,
        },
      };
    }
    case DSOS_ACTIONS.ASSIGN_OFFICES_TO_DSO_SUCCESS: {
      if (action.payload.officesIds.includes(state.office.id)) {
        return {
          ...state,
          office: {
            ...state.office,
            dso: state.dsosList.data.find((dso) => dso.id === action.payload.dsoId),
          },
        };
      }
      return {
        ...state,
        office: {
          ...state.office,
          ...action.payload,
        },
      };
    }
    case DSOS_ACTIONS.REMOVE_OFFICE_FROM_DSO_SUCCESS: {
      return {
        ...state,
        office: {
          ...state.office,
          dso: null,
          region: null,
          groups: null,
        },
      };
    }
    case DSOS_ACTIONS.ADD_OFFICES_TO_REGION_SUCCESS: {
      const newOffices = action.payload.officesIds.map((officeId) =>
        state.dsoOfficeList.data.find((office) => office.id === officeId),
      );

      const mergedOffices = [...state.dsoRegionOfficeList.data, ...newOffices];

      const uniqueOffices = Array.from(
        new Map(mergedOffices.map((office) => [office.id, office])).values(),
      );

      return {
        ...state,
        dsoRegionOfficeList: {
          ...state.dsoRegionOfficeList,
          data: uniqueOffices,
        },
      };
    }
    case DSOS_ACTIONS.SELECT_DSO_GROUP_ID: {
      return {
        ...state,
        orgStructure: {
          ...state.orgStructure,
          selectedGroupId: action.payload,
        },
      };
    }
    case DSOS_ACTIONS.GET_GROUP_REGIONS_AND_OFFICES_REQUEST: {
      return {
        ...state,
        orgStructure: {
          ...state.orgStructure,
          regionList: regionListDefaultState,
          officeList: officeListDefaultState,
        },
      };
    }
    case DSOS_ACTIONS.GET_GROUP_REGIONS_AND_OFFICES_SUCCESS: {
      return {
        ...state,
        orgStructure: {
          ...state.orgStructure,
          selectedRegionsIds: state.orgStructure.regionList.data.map((region) => region.id),
        },
      };
    }
    case DSOS_ACTIONS.SELECT_DSO_REGION_ID: {
      const { selectedRegionsIds, selectedGroupId } = state.orgStructure;

      const newSelectedRegionsIds = selectedRegionsIds.includes(action.payload)
        ? selectedRegionsIds.filter((regionId) => regionId !== action.payload)
        : [...selectedRegionsIds, action.payload];

      const showEmptyOfficeList = selectedGroupId && !newSelectedRegionsIds.length;
      if (showEmptyOfficeList) {
        clearQueryCache('dsoOfficeList');
      }

      return {
        ...state,
        orgStructure: {
          ...state.orgStructure,
          selectedRegionsIds: newSelectedRegionsIds,
          officeList: showEmptyOfficeList ? officeListDefaultState : state.orgStructure.officeList,
        },
      };
    }

    case DSOS_ACTIONS.SELECT_MULTIPLE_REGIONS: {
      return {
        ...state,
        orgStructure: {
          ...state.orgStructure,
          selectedRegionsIds: action.payload,
          officeList: !action.payload.length
            ? officeListDefaultState
            : state.orgStructure.officeList,
        },
      };
    }

    case DSOS_ACTIONS.CLEAR_ORG_STRUCTURE: {
      return {
        ...state,
        orgStructure: defaultState.orgStructure,
      };
    }

    case DSOS_ACTIONS.ADD_REGION_TO_GROUP_SUCCESS: {
      const { region, groupId } = action.payload;
      const { selectedGroupId, regionList } = state.orgStructure;

      if (regionList.data.find((r) => r.id === region.id)) {
        return state;
      }

      return {
        ...state,
        orgStructure: {
          ...state.orgStructure,
          regionList: {
            ...state.orgStructure.regionList,
            ...(selectedGroupId === groupId && {
              data: [...state.orgStructure.regionList.data, region],
              total: state.orgStructure.regionList.total + 1,
            }),
          },
        },
      };
    }

    case DSOS_ACTIONS.ASSIGN_OFFICES_TO_REGION_SUCCESS: {
      const { regionId, offices } = action.payload;
      const { selectedRegionsIds, officeList } = state.orgStructure;

      const selectedRegionsSet = new Set(selectedRegionsIds);
      const officeIdsSet = new Set(offices.map((office) => office.id));

      // Temporary set to track removed offices
      const removedOfficeIds = new Set();

      const newOfficeList = officeList.data
        .map((office) => {
          if (selectedRegionsSet.has(regionId)) {
            // If the region is selected and the office is in the list, update its new regionID
            if (officeIdsSet.has(office.id)) {
              return { ...office, regionID: regionId };
            }
            // If the region is selected and the office isn't in the list, do nothing
            return office;
          }

          if (!selectedRegionsSet.has(regionId)) {
            if (selectedRegionsSet.size > 0) {
              // If the region isn't selected, but there are selected regions, and the office is in the list, remove it
              if (officeIdsSet.has(office.id) && selectedRegionsSet.has(office.regionID)) {
                removedOfficeIds.add(office.id); // Track removed office
                return null; // Mark for removal
              }
              // If the region isn't selected, but there are selected regions, and the office isn't in the list, do nothing
              return office;
            }
            // If the region isn't selected, but there aren't selected regions, update the office with new regionID
            if (officeIdsSet.has(office.id)) {
              return { ...office, regionID: regionId };
            }
          }

          return office;
        })
        .filter(Boolean); // Remove null entries (offices that should be removed)

      let addedOffices = [];
      if (selectedRegionsSet.has(regionId)) {
        // If the region is selected and office isn't in the list, add it to the list with new regionID
        const existingOfficeIds = new Set(newOfficeList.map((o) => o.id));
        addedOffices = offices
          .filter(
            (office) => !existingOfficeIds.has(office.id) && !removedOfficeIds.has(office.id), // Prevent re-adding removed offices
          )
          .map((office) => ({
            ...office,
            regionID: regionId,
          }));
      }

      return {
        ...state,
        orgStructure: {
          ...state.orgStructure,
          selectedOffices: [],
          isBulkOfficeSelectionActive: false,
          officeList: {
            ...officeList,
            data: [...newOfficeList, ...addedOffices],
          },
        },
      };
    }

    case DSOS_ACTIONS.GET_JOBS_REQUEST: {
      return {
        ...state,
        dsoJobs: {
          ...state.dsoJobs,
          dsoId: action.payload.dsoId,
          filters: {
            ...state.dsoJobs.filters,
            ...action.payload,
          },
        },
      };
    }

    case DSOS_ACTIONS.GET_JOBS_SUCCESS: {
      const { data } = action.payload;
      const isFirstPage = state.dsoJobs.filters.page === 1;

      const jobsList = isFirstPage ? data.data : [...state.dsoJobs.data, ...data.data];

      const isFinalPage =
        data.data.length < state.dsoJobs.filters.limit || jobsList.length === data.total;

      return {
        ...state,
        dsoJobs: {
          ...state.dsoJobs,
          data: jobsList,
          isFinalPage,
          total: data.total,
        },
      };
    }

    case DSOS_ACTIONS.TOGGLE_BULK_OFFICE_SELECTION: {
      return {
        ...state,
        orgStructure: {
          ...state.orgStructure,
          isBulkOfficeSelectionActive: !state.orgStructure.isBulkOfficeSelectionActive,
          selectedOffices: [],
        },
      };
    }

    case DSOS_ACTIONS.SELECT_DSO_OFFICE: {
      const { selectedOffices } = state.orgStructure;
      const office = action.payload;

      const selectedOfficeIds = selectedOffices.map((office) => office.id);

      const newSelectedOffices = selectedOfficeIds.includes(office.id)
        ? selectedOffices.filter((selectedOffice) => selectedOffice.id !== office.id)
        : [...selectedOffices, office];

      return {
        ...state,
        orgStructure: {
          ...state.orgStructure,
          selectedOffices: newSelectedOffices,
        },
      };
    }

    case DSOS_ACTIONS.FETCH_DSO_OFFICE_LIST_BY_IDS_SUCCESS: {
      return {
        ...state,
        dsoOfficeList: {
          ...state.dsoOfficeList,
          data: action.payload.map((office) => ({
            id: office.id,
            name: office.name,
          })),
        },
      };
    }
    case DSOS_ACTIONS.UNLINK_OFFICE_FROM_REGION_SUCCESS: {
      const { officeId, regionId } = action.payload;
      const { selectedRegionsIds, officeList } = state.orgStructure;

      const isOfficeInSelectedRegions = selectedRegionsIds.includes(regionId);

      const newOfficeList = officeList.data
        .map((office) => {
          if (office.id === officeId && office.regionID === regionId) {
            // If the office is in one of the selected regions, remove it
            if (isOfficeInSelectedRegions) {
              return null;
            }

            return { ...office, regionID: null };
          }

          return office;
        })
        .filter(Boolean);

      return {
        ...state,
        orgStructure: {
          ...state.orgStructure,
          officeList: {
            ...officeList,
            total: isOfficeInSelectedRegions ? officeList.total - 1 : officeList.total,
            data: newOfficeList,
          },
        },
      };
    }

    default:
      return state;
  }
};
