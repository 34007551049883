import React, { useState } from 'react';
import moment from 'moment';
import { CustomSelect } from '../../../mainComponents/UsersList/components/CustomSelect';
import { PROFESSION_FILTER, STATES_FILTER } from '../../../../utils/filters';
import InputNumber from '../../InputNumber';
import MobileAppModal from '../../MobileAppModal';

const MIN_RADIUS = 1;
const MAX_RADIUS = 50;

export const Filter = ({ allProfessions, filter, handleFilterChange }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',
        gap: '30px',
      }}
    >
      {[STATES_FILTER, PROFESSION_FILTER(allProfessions)].map(({ key, label, options }) => (
        <CustomSelect
          key={key}
          id={key}
          label={label}
          options={options}
          value={filter[key]}
          onChange={handleFilterChange}
        />
      ))}

      <div>
        <p style={{ fontSize: '14px' }}>Radius: </p>
        <InputNumber
          style={{ width: '150px' }}
          value={filter.radius}
          onChange={(value) => handleFilterChange('radius', value)}
          min={MIN_RADIUS}
          max={MAX_RADIUS}
          step={5}
          debounceTime={1000}
        />
      </div>

      <div
        style={{
          marginBlockStart: '25px',
          paddingInlineStart: '25px',
          borderInlineStart: '2px solid #E2E2E2',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <span style={{ fontSize: '14px' }}>
          Date : <strong>{filter.date || moment().format('YYYY-MM-DD')}</strong>
        </span>
        <button
          type="button"
          className="btn btn-info mr-1 ml-1"
          style={{ width: '150px', borderRadius: '10px' }}
          onClick={() => setModalIsOpen(true)}
        >
          Change Date
        </button>
      </div>

      {modalIsOpen && (
        <MobileAppModal
          show={modalIsOpen}
          selectedDay={moment(filter.date).toDate()}
          maxDate={moment()
            .add(99 - 1, 'days') // total 99 days, minus 1 day as today is included
            .toDate()}
          closeModal={() => setModalIsOpen(false)}
          content="calendar"
          result={(selectedDay) => {
            handleFilterChange('date', moment(selectedDay).format('YYYY-MM-DD'));
          }}
        />
      )}
    </div>
  );
};
