import React from 'react';
import '../../../themes/LoyaltyCardGroup.scss';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../../utils/NumberUtils';

export const LoyaltyCardGroup = () => {
  const { level, point } = useSelector((state) => state.metrics?.loyalty?.[0] || {});

  return (
    <div className="loyalty-card-group">
      <div className="card border-0">
        <h3 className="card-title">Loyalty Program Level</h3>

        <div className="row-block">
          <p className="card-text row-block__label">Level</p>
          <p className="card-text">{level ?? '-'}</p>
        </div>
        <div className="row-block">
          <p className="card-text row-block__label">ProPoints</p>
          <p className="card-text">{point ?  formatNumber(point) : '-'}</p>
        </div>
      </div>
    </div>
  );
};
