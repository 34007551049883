import { useState } from 'react';
import { LinkIcon } from '../../../../../../../../images/icons';
import { MenuItem } from '../../../../../../../commonComponents/DotsMenu';
import { LinkEntityPopup } from './LinkEntityPopup';

export const LinkEntityMenuItem = ({ dsoId, item, entityName, linkTo, fetchHook, action }) => {
  const [showModal, setShowModal] = useState(false);
  const title = `Link ${entityName} to ${linkTo}`;

  // Check if "entityName" starts with a vowel for proper grammar
  const article = /^[aeiouAEIOU]/.test(entityName) ? 'an' : 'a';

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MenuItem Icon={() => <LinkIcon />} text={title} onClick={() => setShowModal(true)} />

      {showModal && (
        <LinkEntityPopup
          Description={() => (
            <>
              <div
                style={{
                  fontSize: 16,
                  marginTop: 12,
                  marginBottom: 32,
                  textAlign: 'center',
                }}
              >
                Select {article} {entityName.toLowerCase()} to add to {item.name}
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 16,
                  fontSize: 16,
                }}
              >
                <b>{linkTo} Name:</b>
                <span>{item.name}</span>
              </div>
            </>
          )}
          inputSelectLabel={`${entityName} Name:`}
          submitText={`Link ${entityName}`}
          dsoId={dsoId}
          items={[item]}
          title={title}
          linkTo={linkTo}
          fetchHook={fetchHook}
          action={action}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
