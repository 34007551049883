import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from '../../../../../../../../../../../apis/selectors';
import { ENTITY_TYPES } from '../../../../../../../../enums';
import { getStaffDSORegions } from '../../../../../../../../../../../actions/staffActions';

const loadingSelector = createLoadingSelector(['FETCH_DSO_REGIONS']);

export const useRegionTabPanel = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => loadingSelector(state));

  const staff = useSelector((state) => state.staff.staffDetails);
  const { data: accesses } = useSelector((state) => state.staff.officeAccesses);
  const { data: regions, total: totalRegions } = useSelector((state) => state.staff.dso.regions);

  const totalAccess = useMemo(
    () => accesses.filter((access) => access.entityType === ENTITY_TYPES.REGION)?.length || 0,
    [accesses],
  );

  const onSearchRegion = (terms) => {
    dispatch(
      getStaffDSORegions({
        dsoId: staff.dsoID,
        filters: {
          name: terms,
          limit: 50,
        },
      }),
    );
  };

  const isChecked = useCallback(
    (id) => accesses?.some((access) => access.entityID === id),
    [accesses],
  );

  return {
    isLoading,
    regions,
    staff,
    totalAccess,
    totalRegions,
    isChecked,
    onSearchRegion,
  };
};
