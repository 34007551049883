import { AVAILABILITY_ACTIONS } from '../actions/actionTypes';

const initialState = {
  availability: {
    data: [],
    total: 0,
    searchParams: {
      state: null,
      profession: null,
      radius: null,
      date: null,
    },
  },
  professionalAvailability: {
    onCallAvailability: null,
    lastUpdated: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AVAILABILITY_ACTIONS.FETCH_AVAILABILITY_REQUEST:
      return {
        ...state,
        availability: {
          ...state.availability,
          searchParams: action.payload,
        },
      };
    case AVAILABILITY_ACTIONS.FETCH_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: {
          ...state.availability,
          data: action.payload.data,
          total: action.payload.data.length,
        },
      };
    case AVAILABILITY_ACTIONS.FETCH_PROFESSIONAL_AVAILABILITY_SUCCESS: {
      const { availability, onCall, lastUpdated } = action.payload;

      const onCallAvailability = [...new Set([...onCall, ...availability])].reduce((acc, date) => {
        acc[date] = {
          isOnCall: onCall.includes(date),
          isAvailability: availability.includes(date),
        };
        return acc;
      }, {});

      return {
        ...state,
        professionalAvailability: {
          onCallAvailability,
          lastUpdated,
        },
      };
    }
    default:
      return state;
  }
};
