import { TabPanel, Tabs } from '../../../../../../../commonComponents/TabV2';
import { TabViewContainer } from '../../../../../../../commonComponents/TabViewContainer';
import {
  GroupTabPanel,
  LinkOffice,
  OfficeTabPanel,
  OverviewTabPanel,
  RegionTabPanel,
} from './components';
import { useAccessPermission } from './hooks';

export const AccessPermission = () => {
  const { hasDSOAssigned, hasGroups, hasRegions } = useAccessPermission();

  return (
    <TabViewContainer
      title="Access"
      variant="card"
      style={{
        margin: 0,
        minWidth: 'auto',
        paddingInline: 24,
      }}
    >
      <Tabs
        TrailingComponent={() => <LinkOffice />}
        defaultValue={hasDSOAssigned ? 'overview' : 'offices'}
        tabs={[
          hasDSOAssigned ? { title: 'Overview', value: 'overview' } : null,
          { title: 'Offices', value: 'offices' },
          hasDSOAssigned && hasRegions ? { title: 'Regions', value: 'regions' } : null,
          hasDSOAssigned && hasGroups ? { title: 'Groups', value: 'groups' } : null,
        ].filter(Boolean)}
      >
        <TabPanel value="overview">
          <OverviewTabPanel />
        </TabPanel>
        <TabPanel value="offices">
          <OfficeTabPanel />
        </TabPanel>
        <TabPanel value="regions">
          <RegionTabPanel />
        </TabPanel>
        <TabPanel value="groups">
          <GroupTabPanel />
        </TabPanel>
      </Tabs>
    </TabViewContainer>
  );
};
