import { getAuthHeaders } from '../actions/authActions';
import tempMeeApi from '../apis/tempMee';
import { removeFalsyValues } from '../utils';

/**
 * Delete the region by its unique identifier.
 *
 * @param {Object} payload - The parameters object.
 * @param {string} payload.dsoId - The unique identifier of the DSO.
 * @param {string} payload.regionId - The unique identifier of the region.
 * @returns {Promise<void>} A promise that resolves when the region is deleted.
 * @throws {Error} If the request fails.
 */
export const deleteRegionById = async ({ dsoId, regionId }) => {
  const headers = await getAuthHeaders();

  await tempMeeApi.delete(`office-service/admin/dsos/${dsoId}/regions/${regionId}`, {
    headers,
  });
};

/**
 * Fetch the DSO information by its unique identifier.
 *
 * @param {Object} payload - The parameters object.
 * @param {string} payload.dsoId - The unique identifier of the DSO.
 * @returns {Promise<{code: string, createdAt: string, id: string, name: string}>} A promise that resolves to an object containing:
 *  - `code` (string): The unique code of the DSO.
 *  - `createdAt` (string): The date when the DSO was created.
 *  - `id` (string): The unique identifier of the DSO.
 *  - `name` (string): The name of the DSO.
 * @throws {Error} If the request fails.
 */
export const fetchDSOById = async ({ dsoId }) => {
  const headers = await getAuthHeaders();

  const response = await tempMeeApi.get(`/office-service/admin/dsos/${dsoId}`, {
    headers,
  });

  return response.data;
};

/**
 * Get a paginated list of groups for a particular DSO
 *
 * @param {Object} payload - The parameters object.
 * @param {string} payload.dsoId - The unique identifier of the DSO.
 * @param {Object} [payload.filters] - The filters object.
 * @param {number} [payload.filters.limit] - The number of items per page.
 * @param {number} [payload.filters.page] - The page number.
 * @param {string} [payload.filters.name] - The name of dso groups.
 * @param {string} [payload.filters.orderBy] - The field to order.
 * @param {string} [payload.filters.sortBy] - The field to sort.
 * @returns {Promise<{data: Array<{createdAt: string, id: string, name: string}>, total: number}>} A promise that resolves to an object containing:
 * - `data` (Array): An array of objects containing:
 *  - `createdAt` (string): The date when the group was created.
 *  - `id` (string): The unique identifier of the group.
 *  - `name` (string): The name of the group.
 * - `total` (number): The total number of groups.
 * @throws {Error} If the request fails.
 */
export const fetchGroupsByDsoId = async ({ dsoId, filters }) => {
  const headers = await getAuthHeaders();

  const payload = {
    ...filters,
    limit: filters.limit || 15,
    name: filters.name || undefined,
    orderBy: filters.orderBy || undefined,
    page: filters.page || 1,
  };

  const response = await tempMeeApi.get(`/office-service/admin/dsos/${dsoId}/groups`, {
    params: removeFalsyValues(payload),
    headers,
  });

  return response.data;
};

/**
 * Get office list by DSO, Group, and/or Region IDs. All filter will be treated as AND condition, except for emptyRegion.
 *
 * @param {Object} payload - The parameters object.
 * @param {Array<string>} [payload.dsoIds] - The list of DSO IDs.
 * @param {Array<string>} [payload.groupIds] - The list of Group IDs.
 * @param {boolean} [payload.isEmptyDSO] - The flag to indicate if the DSO is empty.
 * @param {boolean} [payload.isEmptyRegion] - The flag to indicate if the region is empty.
 * @param {number} [payload.limit] - The number of items per page.
 * @param {string} [payload.officeIds] - The list of Office IDs.
 * @param {string} [payload.officeName] - The name of the office.
 * @param {string} [payload.orderBy] - The field to order.
 * @param {number} [payload.page] - The page number.
 * @param {Array<string>} [payload.regionIds] - The list of Region IDs.
 * @param {string} [payload.sortBy] - The field to sort.
 * @returns {Promise<{data: Array<{createdAt: string, dsoID: string, id: string, name: string, regionID: string}>, total: number}>} A promise that resolves to an object containing:
 * - `data` (Array): An array of objects containing:
 *  - `createdAt` (string): The date when the office was created.
 *  - `dsoID` (string): The unique identifier of the DSO.
 *  - `id` (string): The unique identifier of the office.
 *  - `name` (string): The name of the office.
 *  - `regionID` (string): The unique identifier of the region.
 * - `total` (number): The total number of offices.
 * @throws {Error} If the request fails.
 */
export const fetchOffices = async ({
  dsoIds,
  groupIds,
  isEmptyDSO,
  isEmptyRegion,
  limit,
  officeIds,
  officeName,
  orderBy,
  page,
  regionIds,
  sortBy,
}) => {
  const headers = await getAuthHeaders();

  const payload = {
    dsoIDs: (dsoIds || []).join(','),
    groupIDs: (groupIds || []).join(','),
    emptyDSO: isEmptyDSO,
    emptyRegion: isEmptyRegion,
    limit: limit || 15,
    officeIDs: (officeIds || []).join(','),
    officeName,
    orderBy,
    page: page || 1,
    regionIDs: (regionIds || []).join(','),
    sortBy,
  };

  const response = await tempMeeApi.get(`/office-service/admin/offices`, {
    params: removeFalsyValues(payload),
    headers,
  });

  return response.data;
};

export const fetchRegionsByDsoId = async ({ dsoId, filters }) => {
  const headers = await getAuthHeaders();
  const response = await tempMeeApi.get(`/office-service/admin/dsos/${dsoId}/regions`, {
    params: {
      ...filters,
      page: filters.page || 1,
      limit: filters.limit || 15,
      name: filters.name || undefined,
    },
    headers,
  });
  return response.data;
};

export const fetchOfficesByDsoId = async ({ dsoId, filters }) => {
  const headers = await getAuthHeaders();
  const response = await tempMeeApi.get(`/office-service/admin/offices`, {
    params: {
      dsoIDs: dsoId,
      limit: filters.limit || 15,
      page: filters.page || 1,
      regionIDs: filters.regionIDs || undefined,
      groupIDs: filters.groupIDs || undefined,
      officeName: filters.officeName || undefined,
    },
    headers,
  });
  return response.data;
};

export const fetchOfficeById = async ({ officeId }) => {
  const headers = await getAuthHeaders();

  const response = await tempMeeApi.get(`/office-service/admin/offices/${officeId}`, {
    headers,
  });

  return response.data;
};

export const fetchGroupsLinkedToRegion = async (regionId) => {
  const headers = await getAuthHeaders();

  const response = await tempMeeApi.get(`/office-service/admin/regions/${regionId}/groups`, {
    headers,
  });

  return response.data.data;
};

/**
 * Delete the region by its unique identifier.
 *
 * @param {Object} payload - The parameters object.
 * @param {number} [payload.page] - The page number.
 * @param {number} [payload.limit] - The number of items per page.
 * @returns {Promise<void>} A promise that resolves when the region is deleted.
 * @throws {Error} If the request fails.
 */
export const fetchDSOList = async ({ page, limit }) => {
  const headers = await getAuthHeaders();

  const response = await tempMeeApi.get('/office-service/admin/dsos', {
    params: {
      page: page || 1,
      limit: limit || 15,
    },
    headers,
  });

  return response.data;
};
