const tabMappings = {
  regions: 'Regions',
  groups: 'Groups',
  'office-staffs': 'Office Staffs',
  offices: 'Offices',
  orgStructure: 'Org Structure',
  jobs: 'Jobs',
};

export const getTabByName = (currentTab) => tabMappings[currentTab] || tabMappings.orgStructure;

export const getUrlByTab = (dsoId, tab) => {
  const normalizedTab =
    Object.keys(tabMappings).find((key) => tabMappings[key] === tab) || 'orgStructure';
  return `/dsos/${dsoId}/${normalizedTab}`;
};
