import { Colors } from '../../../../../../../../../../../../themes/colors';
import CustomPortal from '../../../../../../../../../../../commonComponents/CustomPortal';
import { InputSelect } from '../../../../../../../../../../../commonComponents/InputSelect/InputSelect';
import { useLinkOfficeForm } from './hooks/useLinkOfficeForm';

export const LinkOfficeForm = ({ onClosePopup }) => {
  const {
    formValues,
    handleFormSubmit,
    handleFormValueChanged,
    isFetchingFirstPage,
    isFetchingNextPage,
    onScroll,
    onSearch,
    results,
    staff,
  } = useLinkOfficeForm();

  const onSubmit = () => {
    handleFormSubmit();
    onClosePopup();
  };

  return (
    <CustomPortal
      title="Link Office to Staff Member"
      text={`Select an office to add to ${staff.firstName} ${staff.lastName}’s access`}
      cancelText="Close"
      submitText="Link Office"
      cancelPressed={onClosePopup}
      submitPressed={onSubmit}
    >
      <form
        style={{
          marginTop: 20,
        }}
        onSubmit={(e) => e.preventDefault()}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            gap: 12,
            maxWidth: 400,
            width: '100%',
          }}
        >
          <b
            style={{
              color: Colors.neutral_600,
              fontSize: 16,
              textWrap: 'nowrap',
            }}
          >
            Office Name:
          </b>
          <InputSelect
            autoComplete
            value={formValues.officeId}
            setValue={(value) => handleFormValueChanged('officeId', value)}
            options={results.map((item) => ({
              value: item.id,
              title: item.name,
            }))}
            isSearchVisible
            onSearch={onSearch}
            debounceTime={750}
            placeholder="Name"
            onScroll={onScroll}
            isFetchingFirstPage={isFetchingFirstPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </div>
      </form>
    </CustomPortal>
  );
};
