import * as React from 'react';

export const FireIcon = ({ width = 35, height = 39 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6097 7.15132C24.6097 7.15132 19.9314 4.66747 18.3523 1.78783L17.8586 0L16.7318 1.14459C13.1032 4.78602 11.3871 10.3786 10.6434 13.7334C10.0727 12.8497 9.64915 11.8793 9.38933 10.8599L8.70193 8.22461L6.71701 10.0872C3.24139 13.3519 0.921629 16.5893 0.921629 21.7729C0.888211 25.5134 2.10575 29.1578 4.38081 32.127C6.65587 35.0963 9.85794 37.2201 13.4783 38.161C14.38 38.3894 15.2981 38.5474 16.2242 38.6336C16.833 38.7413 17.4599 38.7974 18.1002 38.7974C19.0479 38.7974 19.9664 38.6743 20.8401 38.4435C24.5137 37.7896 27.8823 35.936 30.4062 33.1537C33.2322 30.0385 34.7965 25.9822 34.794 21.7761C34.794 14.8507 24.6097 7.15132 24.6097 7.15132Z"
      fill="url(#paint0_linear_26858_15709)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_26858_15709"
        x1={17.8575}
        y1={0}
        x2={17.8575}
        y2={38.7974}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9115" />
        <stop offset={1} stopColor="#FFBF78" />
      </linearGradient>
    </defs>
  </svg>
);
