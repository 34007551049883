import history from '../../../../../../../../../../history';
import { SearchIcon } from '../../../../../../../../../../images/icons';
import { MenuItem } from '../../../../../../../../../commonComponents/DotsMenu';

export const ViewOfficeMenuItem = ({ item }) => {
  const handleViewOfficeClick = () => {
    history.push(`/user/dentist/${item.id}`);
  };

  return (
    <MenuItem Icon={() => <SearchIcon />} text="View Office" onClick={handleViewOfficeClick} />
  );
};
