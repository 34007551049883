import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import {
  deleteW9Form,
  importToTrinet,
  sendW9,
  terminateUser,
  updateCurrentUser,
  updateI9Status,
  updateResume,
  updateW9Form,
} from '../../../actions/userActions';
import { createLoadingSelector } from '../../../apis/selectors';
import { useW9 } from '../../../hooks/useW9';

export default function TaxDocument() {
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.users.currentUser);

  const [showModal, setShowModal] = useState(false);
  const [W9File, setW9File] = useState(null);
  const [, setW9FileUrl] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const [, setResumeFileUrl] = useState(null);

  const actions = ['UPDATE_W9', 'RESUME_UPLOAD'];
  const loadingSelector = createLoadingSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));

  const i9Status = userProfile?.professional_info?.employee?.i9?.status;
  const trinetImportDisabled =
    (i9Status !== 'not started' && i9Status !== 'failed trinet import');

  const { showStripeButton, stripeUrl } = useW9();

  const handleW9FileChange = (event) => {
    setW9File(event.target.files[0]);
    setW9FileUrl(URL.createObjectURL(event.target.files[0]));
  };

  const handleResumeChange = (event) => {
    setResumeFile(event.target.files[0]);
    setResumeFileUrl(URL.createObjectURL(event.target.files[0]));
  };

  const uploadW9Form = () => {
    if (!W9File) {
      return;
    }

    dispatch(updateW9Form(W9File));
  };

  const uploadResume = () => {
    if (!resumeFile) {
      return;
    }

    dispatch(updateResume(resumeFile));
  };

  const deleteW9 = () => {
    dispatch(deleteW9Form());

    setShowModal(false);
  };

  const showContractorOptions = () => {
    const contractorInfo = userProfile?.professional_info?.contractor;

    return (
      <select
        className="custom-select custom-select-lg mb-3"
        style={{ marginTop: '5px', position: 'relative' }}
        value={contractorInfo?.w9?.pendingNewW9 ? 'pending' : contractorInfo?.status}
        onChange={(e) =>
          dispatch(
            updateCurrentUser({
              professional_info: {
                contractor: { status: e.target.value },
              },
            }),
          )
        }
        disabled
      >
        <option className="dropdown-item" value="new">
          New
        </option>
        <option className="dropdown-item" value="pending">
          Verified - Pending
        </option>
        <option className="dropdown-item" value="verified">
          Verified
        </option>
        <option className="dropdown-item" value="restricted">
          Restricted
        </option>
      </select>
    );
  };

  const showEmplopyeeOptions = () => (
    <select
      className="custom-select custom-select-lg mb-3"
      style={{ marginTop: '5px', position: 'relative' }}
      value={userProfile?.professional_info?.employee?.status}
      onChange={(e) =>
        dispatch(
          updateCurrentUser({
            professional_info: {
              employee: { status: e.target.value },
            },
          }),
        )
      }
      disabled
    >
      <option className="dropdown-item" value="new">
        New
      </option>
      <option className="dropdown-item" value="verified">
        Verified
      </option>
      <option className="dropdown-item" value="restricted">
        Restricted
      </option>
    </select>
  );

  const showI9FormOnboardingOptions = () => (
    <select
      className="custom-select custom-select-lg mb-3"
      style={{ marginTop: '5px', position: 'relative' }}
      value={userProfile?.professional_info?.employee?.i9?.status}
      onChange={(e) =>
        dispatch(
          updateI9Status({
            i9: { status: e.target.value },
          }),
        )
      }
    >
      <option className="dropdown-item" value="not started">
        Not started
      </option>
      <option className="dropdown-item" value="pending">
        Pending
      </option>
      <option className="dropdown-item" value="completed">
        Completed
      </option>
      <option className="dropdown-item" value="restricted">
        Restricted
      </option>
    </select>
  );

  const renderDeleteW9Confirmation = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h2 className="h3" style={{ fontSize: '24px' }}>
        Delete W9?
      </h2>
      <div style={{ width: '80%' }}>
        <h3 className="h3" style={{ fontSize: '20px', textAlign: 'center' }}>
          Are you sure you want to delete this W9?
        </h3>
      </div>
      <div
        style={{
          display: 'flex',
          width: '60%',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <button
          type="button"
          className="btn btn-info"
          style={{
            backgroundColor: 'gray',
            width: '45%',
            height: '34px',
            margin: '0',
          }}
          onClick={() => setShowModal(false)}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-info"
          style={{
            backgroundColor: 'red',
            width: '45%',
            height: '34px',
            margin: '0',
          }}
          onClick={() => deleteW9()}
        >
          Delete
        </button>
      </div>
    </div>
  );

  const renderLoader = () =>
    isLoading ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
            alignSelf: 'center',
          }}
        />
      </div>
    ) : (
      <></>
    );

  return (
    <>
      <div
        style={{
          width: '85%',
          padding: 20,
          border: '1px solid #e5e5e5',
          marginTop: 15,
          backgroundColor: 'white',
        }}
      >
        {renderLoader()}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h3 className="card-title" style={{ flex: 1 }}>
            Tax Documents
          </h3>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 180,
                marginLeft: 25,
              }}
            >
              <h4 className="card-title">Contractor: </h4>
              {showContractorOptions()}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 180,
                marginLeft: 25,
              }}
            >
              <h4 className="card-title">Employee: </h4>
              {showEmplopyeeOptions()}
            </div>
          </div>

          <button
            disabled={trinetImportDisabled}
            className="btn btn-info"
            style={{
              width: '130px',
              marginTop: 15,
              marginLeft: 60,
            }}
            onClick={() => dispatch(importToTrinet(userProfile?.id))}
            type="button"
          >
            Import to TriNet
          </button>

          <button
            className="btn btn-danger"
            style={{
              width: '130px',
              marginTop: 15,
              marginLeft: 60,
            }}
            onClick={() => {
              dispatch(terminateUser(userProfile?.id));
            }}
            type="button"
          >
            Terminate
          </button>

          <div style={{ flex: 1 }} />
        </div>

        <div className="card-deck mb-4 mt-2">
          <div
            className="card"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h3 className="card-title pl-4" style={{ alignSelf: 'flex-start' }}>
              W9 Form Upload
            </h3>

            <div className="row gap-8">
              <button
                className="btn btn-info"
                style={{
                  width: '100px',
                }}
                onClick={() => dispatch(sendW9())}
                type="button"
              >
                Send W9
              </button>

              {showStripeButton && (
                <a
                  href={stripeUrl}
                  className="card-text btn btn-info"
                  target="_blank"
                  rel="noreferrer"
                >
                  Open Stripe
                </a>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 30,
              }}
            >
              <h3
                className="card-text"
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  margin: '20px 0px 20px 50px',
                }}
              >
                Upload W9 form
              </h3>
              {userProfile?.has_w9 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={require('../../../images/green-check.png')}
                    alt="check"
                    width="30px"
                    style={{ margin: '5px' }}
                  />
                  <a href={userProfile?.professional_info?.contractor?.w9?.file_url} download>
                    Download
                  </a>

                  <button
                    onClick={() => setShowModal(true)}
                    type="button"
                    className="btn btn-danger"
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <img
                  src={require('../../../images/close-cross.png')}
                  style={{
                    width: '20px',
                    height: '20px',
                    marginLeft: '10px',
                    marginBottom: '5px',
                  }}
                />
              )}
            </div>
            <input style={{ width: '90%' }} type="file" onChange={(e) => handleW9FileChange(e)} />

            <button
              className="btn btn-info"
              onClick={() => uploadW9Form()}
              style={{
                marginTop: '6px',
                marginBottom: 10,
              }}
              disabled={!W9File}
              type="submit"
            >
              Submit
            </button>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 180,
                marginLeft: 25,
                marginBlock: 30,
              }}
            >
              <h4 className="card-title">I9 Form/Onboarding: </h4>
              {showI9FormOnboardingOptions()}
            </div>
          </div>
        </div>

        <div className="card-deck mb-4 mt-2">
          <div
            className="card"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h3 className="card-title pl-4" style={{ alignSelf: 'flex-start' }}>
              Resume Upload
            </h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <h3
                className="card-title"
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  margin: '20px 0px 20px 50px',
                }}
              >
                Upload Resume
              </h3>
              {userProfile?.resume ? (
                <div>
                  <img
                    src={require('../../../images/green-check.png')}
                    alt="check"
                    width="30px"
                    style={{ margin: '5px' }}
                  />
                  <a href={userProfile.resume} download>
                    Download
                  </a>
                </div>
              ) : (
                <img
                  src={require('../../../images/close-cross.png')}
                  style={{
                    width: '20px',
                    height: '20px',
                    marginLeft: '10px',
                    marginBottom: '5px',
                  }}
                />
              )}
            </div>
            <input style={{ width: '90%' }} type="file" onChange={(e) => handleResumeChange(e)} />
            <button
              className="btn btn-info"
              onClick={() => uploadResume()}
              style={{
                marginTop: '6px',
                marginBottom: 10,
              }}
              disabled={!resumeFile}
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            height: 200,
          },
        }}
        onRequestClose={() => setShowModal(false)}
      >
        {renderDeleteW9Confirmation()}
      </Modal>
    </>
  );
}
