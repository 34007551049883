import { DragAndDropV2 } from './DragDropV2';
import { SliderV2 } from './SliderV2';

export const StateCredentialFileV2 = ({
  editMode,
  credential,
  license,
  setLicenses,
  credentialType,
}) => (
  <div
    style={{
      border: '1px dashed #cecece',
      borderRadius: '5px',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    {credential && credential?.files?.length > 0 ? (
      <SliderV2
        editMode={editMode}
        credential={credential}
        license={license}
        setLicenses={setLicenses}
        credentialType={credentialType}
      />
    ) : (
      <DragAndDropV2
        editMode={editMode}
        credential={credential}
        license={license}
        setLicenses={setLicenses}
        credentialType={credentialType}
      />
    )}
  </div>
);
