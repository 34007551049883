import WarningAlertIcon from '../../../../../../../../../../images/warning_alert_outline_yellow.svg';
import { Colors } from '../../../../../../../../../../themes/colors';
import CustomPortal from '../../../../../../../../../commonComponents/CustomPortal';

export const UpdatePermissionConfirmationPopupMode = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

export const UpdatePermissionConfirmationPopup = ({
  data,
  mode = UpdatePermissionConfirmationPopupMode.ADD,
  onConfirm,
  onClose,
}) => {
  const isRemoveAccess = mode === UpdatePermissionConfirmationPopupMode.REMOVE;

  const fmtActions = isRemoveAccess ? 'removing' : 'adding';

  return (
    <CustomPortal
      Icon={isRemoveAccess ? WarningAlertIcon : null}
      title="Staff Member Access Update "
      // eslint-disable-next-line max-len
      textStyle={{ textAlign: 'center' }}
      cancelText="Go Back"
      submitText="Confirm"
      submitPressed={onConfirm}
      cancelPressed={onClose}
    >
      <p
        style={{
          color: Colors.neutral_500,
          fontSize: 16,
          textAlign: 'center',
        }}
      >
        Are you sure you would like to update permissions
        <br />
        for {`${data.staffFirstName} ${data.staffLastName}`} by {fmtActions}{' '}
        <strong>{data.access}</strong>
        <br />
        to their access?
      </p>
    </CustomPortal>
  );
};
