export const Header = ({ title, children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '32px 32px 20px 32px',
    }}
  >
    <span
      style={{
        fontSize: 24,
      }}
    >
      {title}
    </span>
    {children}
  </div>
);
