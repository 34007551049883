import React from 'react';

import { useDispatch } from 'react-redux';

import imageCompression from 'browser-image-compression';
import { uploadCredential } from '../../../../actions/licensesAction';

const DragAndDrop = ({
  credential,
  license,
  setLicenses,
  credentialType,
  stateIdField,
  handleFileChange,
}) => {
  const dispatch = useDispatch();

  const uploadFile = async (file) => {
    const hasCredentialRecord = license.credentials.find(
      (c) => c && c.credential_type === credentialType,
    );

    const options = {
      maxSizeMB: 1,
    };
    try {
      file = await imageCompression(file, options);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    if (!hasCredentialRecord) {
      setLicenses((prevState) => {  
        const licenseIndex = prevState.findIndex((data) => data.id === license.id);
        const credentialIndex = prevState[licenseIndex].credentials.findIndex(
          (c) => c && c?.credential_type === credentialType,
        );
        if (credentialIndex === -1) {
          prevState[licenseIndex].credentials.push({
            credential_type: credentialType,
            credential_input_type: 'upload',
            files: [],
          });
        }
        return [...prevState];
      });
    }

    const credentialIndex = license.credentials.findIndex(
      (c) => c && c.credential_type === credentialType,
    );

    dispatch(
      uploadCredential(
        file,
        !hasCredentialRecord && {
          credential_type: credentialType,
          credential_input_type: 'upload',
        },
        license.id,
        license?.credentials?.[credentialIndex]?.id,
      ),
    ).then((res) => {
      if (!res || res?.error) return;
      setLicenses((prevState) => {
        const licenseIndex = prevState.findIndex((data) => data.id === license.id);
        const cIndex = prevState[licenseIndex].credentials.findIndex(
          (c) => c && c?.credential_type === credentialType,
        );

        prevState[licenseIndex].credentials[cIndex] = res;
        return [...prevState];
      });
    });
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 50,
        position: 'relative',
        cursor: 'pointer',
      }}
    >
      <h4
        className="card-text"
        style={{
          textAlign: 'center',
          fontSize: '16px',
          marginTop: 20,
        }}
      >
        <p style={{ color: '#899197' }}>Drag and drop files here or</p>
      </h4>

      <div>
        <label htmlFor={`${license.id}`} className="btn btn-default">
          Browse
        </label>
        <input
          onChange={(event) => {
            uploadFile(event.target.files[0]);
          }}
          type="file"
          id={`${license.id}`}
          style={{
            opacity: 0,
            padding: 20,
            position: 'absolute',
            bottom: 20,
            right: 0,
            padding: 70,
            cursor: 'pointer',
          }}
        />
      </div>
    </div>
  );
};

export default DragAndDrop;
