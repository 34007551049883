export const formatNumber = (num) => {
  const numStr = num.toString();
  const reversed = numStr.split('').reverse();
  const parts = [];

  for (let i = 0; i < reversed.length; i += 3) {
    parts.push(reversed.slice(i, i + 3).join(''));
  }

  return parts.join(',').split('').reverse().join('').replace(/-,/g, '-');
}
