import { Colors } from '../../../themes/colors';

import './styles.scss';

export const ListItem = ({
  text,
  LeadingComponent,
  TrailingComponent,
  itemStyle = {},
  textStyle = {},
  children,
}) => (
  <div className="list-item" style={itemStyle}>
    {LeadingComponent && <LeadingComponent />}
    {children || (
      <span
        style={{
          position: 'relative',
          fontSize: 16,
          color: Colors.neutral_600,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...textStyle,
        }}
      >
        {text}
      </span>
    )}
    {TrailingComponent && <TrailingComponent />}
  </div>
);
