import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import {
  fetchCurrentUserLicensesDetailV2,
} from '../../../../../actions/userActions';
import { getShouldUsingCredentialServiceEndpointForLicense } from '../../../../../growthbook';
import { LicenseSectionItem } from './LicenseSectionItem';
import { createLoadingSelector } from '../../../../../apis/selectors';

export const LicenseSection = ({
  licenses,
  setLicenses,
  stateCredentials,
  allProfessions,
  setDeleteId,
  setShowDeletePopup,
}) => {
  const dispatch = useDispatch();
  const shouldUsingCredentialServiceEndpointForLicense =
    getShouldUsingCredentialServiceEndpointForLicense();

  const loadingSelector = createLoadingSelector(['GET_LICENSES']);
  const isLoading = useSelector((state) => loadingSelector(state));

  const [expandedIds, setExpandedIds] = useState([]);

  const handleToggle = (id) => {
    const isExpanded = expandedIds.includes(id);
    if (isExpanded) {
      setExpandedIds((prev) => prev.filter((item) => item !== id));
    } else {
      setExpandedIds((prev) => [...prev, id]);
      dispatch(fetchCurrentUserLicensesDetailV2(id));
    }
  };

  const renderEmpty = () => {
    if (!shouldUsingCredentialServiceEndpointForLicense) {
      return null;
    }

    return (
      <div
        style={{
          flex: 1,
          border: '1px solid #b7b7b7',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 60,
        }}
      >
        <p style={{ textAlign: 'center' }}>Licenses is Empty</p>
      </div>
    );
  };

  const renderLoading = () => {
    if (!shouldUsingCredentialServiceEndpointForLicense) {
      return null;
    }

    return (
      <div
        style={{
          flex: 1,
          border: '1px solid #b7b7b7',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 60,
        }}
      >
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
          }}
        />
      </div>
    );
  };

  const renderItem = () => {
    if (isLoading) {
      return renderLoading();
    }
    if (licenses.length === 0) {
      return renderEmpty();
    }
    return licenses.map((license, index) => (
      <LicenseSectionItem
        key={license.id}
        index={index}
        license={license}
        licenses={licenses}
        setLicenses={setLicenses}
        stateCredentials={stateCredentials}
        allProfessions={allProfessions}
        setDeleteId={setDeleteId}
        setShowDeletePopup={setShowDeletePopup}
        isTableItem={shouldUsingCredentialServiceEndpointForLicense}
        isExpanded={expandedIds.includes(license.id)}
        handleToggle={handleToggle}
      />
    ));
  };

  return (
    <>
      <h3>Professional Credentials</h3>

      {shouldUsingCredentialServiceEndpointForLicense && (
        <div
          style={{
            border: '1px solid #b7b7b7',
            padding: '16px 60px',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#ADD8E6',
          }}
        >
          <div style={{ flex: 1 }}>Profession</div>
          <div style={{ flex: 1 }}>Issued State</div>
          <div style={{ flex: 1 }}>Status</div>
          <div style={{ flex: 1 }}>Expired At</div>
          <div style={{ width: 24 }} />
        </div>
      )}

      {renderItem()}
    </>
  );
};
