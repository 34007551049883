import { fetchOfficesByDsoId } from '../../../../../../../../../../../services/dsos';
import { usePaginatedQuery } from '../../../../../hooks/usePaginatedQuery';

export const useLinkOfficeToRegion = ({ dsoId }) =>
  usePaginatedQuery({
    queryKey: 'linkOfficeToRegion',
    fetchFunction: fetchOfficesByDsoId,
    dsoId,
    searchParam: 'officeName',
  });
