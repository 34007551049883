import { Colors } from '../../../../../../../../../../themes/colors';

export const AccessHeader = ({ linkedText, title, total }) => (
  <header
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    }}
  >
    <span
      style={{
        color: Colors.neutral_800,
        fontSize: 16,
        fontWeight: 700,
      }}
    >
      {title}
    </span>
    <span
      style={{
        color: Colors.neutral_500,
        fontSize: 16,
        fontWeight: 600,
      }}
    >
      {linkedText}
    </span>
    <article
      style={{
        color: Colors.neutral_800,
        display: 'flex',
        fontSize: 20,
        fontWeight: 600,
        gap: 4,
        marginLeft: 'auto',
        textAlign: 'right',
      }}
    >
      <span>Total</span>
      <span>|</span>
      <span>{total}</span>
    </article>
  </header>
);
