import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDsoOffices, selectDsoRegion } from '../../../../../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../../../../../apis/selectors';

const actions = ['FETCH_DSO_OFFICE_LIST'];
const loadingSelector = createLoadingSelector(actions);

export const useRegionItem = (dsoId, item) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const { selectedRegionsIds, selectedGroupId } = useSelector((state) => state.dsos.orgStructure);
  const isSelected = selectedRegionsIds.includes(item.id);
  const showEmptyOfficeList = selectedGroupId && !selectedRegionsIds.length;

  const timerRef = useRef(null);

  const onCheckboxClick = () => {
    dispatch(selectDsoRegion(item.id));
  };

  useEffect(() => {
    // Start a timer
    if (!showEmptyOfficeList) {
      timerRef.current = setTimeout(() => {
        dispatch(
          fetchDsoOffices(dsoId, {
            regionIDs: selectedRegionsIds.length ? selectedRegionsIds.join(',') : [],
            page: 1,
            limit: 15,
          }),
        );
      }, 750);
    }

    return () => {
      // Cleanup timeout on unmount
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [
    isSelected,
    dispatch,
    dsoId,
    item.id,
    selectedGroupId,
    selectedRegionsIds,
    showEmptyOfficeList,
  ]);

  return {
    isSelected,
    onCheckboxClick,
    isLoading,
  };
};
