/* eslint-disable camelcase */
// eslint-disable-next-line import/no-cycle
import moment from 'moment';
import tempMee from '../apis/tempMee';
import {
  GET_ALL_UNVERIFIED_LICENSES_SUCCESS,
  GET_ALL_UNVERIFIED_LICENSES_FAILURE,
  GET_ALL_UNVERIFIED_LICENSES_REQUEST,
  CREDENTIAL_ACTION,
  SHOW_MESSAGE,
  EDUCATION_ACTION,
} from './actionTypes';

import { getAuthHeaders } from './authActions';
import { UserType } from '../enums/UserType';
import { UserProfession, UserSubProfession } from '../enums/UserProfession';
import {
  getShouldUsingCredentialServiceEndpoint,
  getShouldUsingCredentialServiceEndpointForLicense,
} from '../growthbook';
import {
  addProfLicenseReferenceV2,
  addProfLicenseStateLicenseV2,
  deleteProfLicenseFileV2,
  deleteProfLicenseReferenceV2,
  deleteProfLicenseStateLicenseV2,
  getCredentialsV2,
  patchCredentials,
  patchCredentialsV2,
  updateProfLicenseReferenceV2,
  updateProfLicenseStateLicenseV2,
  uploadProfLicenseFileV2,
} from '../services/credentials';
import { patchUser } from '../services/users';
import { CredentialType, ICredentialV2FileType } from '../utils/CredentialUtils';

export const fetchLicenses = (state) => async (dispatch) => {
  let params = {};

  if (state) {
    params = {
      page: state.page || 1,
      sortBy: state.sortField?.split('.')?.[1] || '',
      sortDirection: state.sortOrder?.toUpperCase() || '',
      limit: 15,
      status: state.status || '',
      state: state.state || '',
      profession: state.profession || '',
      subProfession: state.subProfession || '',
      credential_type: state.credentialType || '',
      account_status: state.accountStatus || '',
      gov_uploaded: state.govIdUploaded || '',
      gov_status: state.govIdUploaded || '',
      expiresSoon: state.expiresSoon || '',
      contractor_status: state.contractorStatus || '',
      employee_status: state.employeeStatus || '',
    };
  } else {
    params = {
      page: 1,
    };
  }

  dispatch({
    type: GET_ALL_UNVERIFIED_LICENSES_REQUEST,
    payload: params,
  });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/licenses', {
      params,
      headers,
    });
    dispatch({ type: GET_ALL_UNVERIFIED_LICENSES_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_ALL_UNVERIFIED_LICENSES_FAILURE, payload: err });
  }
};

/**
 * To get educations of professions
 *
 * @param {string[] | UserProfession[]} professions
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchRequestedEducations =
  (professions = [UserProfession.RDH, UserProfession.DA]) =>
    async (dispatch) => {
      dispatch({
        type: EDUCATION_ACTION.FETCH_REQUESTED_EDUCATIONS_REQUEST,
      });

      try {
        const headers = await getAuthHeaders();

        const responses = await Promise.all(
          professions.map((profession) =>
            tempMee.get('credential-service/credentials', {
              headers,
              params: {
                profession,
                userType: UserType.HYG,
              },
            }),
          ),
        );

        const educations = responses.reduce((acc, response) => ({
          ...acc.data,
          ...response.data,
        }));

        // eslint-disable-next-line max-len
        dispatch({ type: EDUCATION_ACTION.FETCH_REQUESTED_EDUCATIONS_SUCCESS, payload: educations });
      } catch (err) {
        dispatch({ type: EDUCATION_ACTION.FETCH_REQUESTED_EDUCATIONS_FAILURE, payload: err });
      }
    };

export const getUserEducation =
  ({ userType, profession } = {}) =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: EDUCATION_ACTION.GET_USER_EDUCATION_REQUEST });

        const user = getState().users.currentUser;
        const type = userType || user?.user_type;
        const userProfession = profession || user?.licenseTypes?.[0] || 'RDH';

        const headers = await getAuthHeaders();
        const response = await tempMee.get(`credential-service/credentials`, {
          headers,
          params: {
            profession: userProfession,
            userType: type,
          },
        });

        dispatch({
          type: EDUCATION_ACTION.GET_USER_EDUCATION_SUCCESS,
          payload: response.data[type === 'DNT' ? 'DNT' : userProfession],
        });
      } catch (err) {
        dispatch({ type: EDUCATION_ACTION.GET_USER_EDUCATION_FAILURE, payload: err });
      }
    };

  export const updateUserEducation =
    ({ years_of_experience, school, graduationDate, education, insurances }) =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: EDUCATION_ACTION.UPDATE_USER_EDUCATION_REQUEST });
  
        const { users } = getState();
        const currentUser = users?.currentUser || {};
        const { id } = currentUser;
    

        if (getShouldUsingCredentialServiceEndpoint()) {
  
          await Promise.all([
            // patch yoe, school, graduation date
            patchUser(id, { years_of_experience, school, graduationDate }),
            // patch specialties & insurance via credentials service
            patchCredentialsV2(
              id,
              currentUser?.user_type,
              {
                ...currentUser?.professional_info?.education,
                ...education,
              },
              insurances,
            ),
          ]);
  
          dispatch({
            type: EDUCATION_ACTION.UPDATE_USER_EDUCATION_SUCCESS,
            payload: {
              ...currentUser,
              professional_info: {
                ...currentUser?.professional_info,
                insurances: insurances || [],
                education,
              },
            },
          });
        } else {
          await patchUser(id, { years_of_experience, school, graduationDate });
          const response = await patchCredentials(id, education, insurances);
  
          dispatch({
            type: EDUCATION_ACTION.UPDATE_USER_EDUCATION_SUCCESS,
            payload: response.data,
          });
        }
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            message: 'User education have been updated successfully.',
          },
        });
      } catch (err) {
        dispatch({ type: EDUCATION_ACTION.UPDATE_USER_EDUCATION_FAILURE, payload: err });
      }
    };

export const fetchStateCredentials = () => async (dispatch) => {
  dispatch({
    type: CREDENTIAL_ACTION.GET_ALL_STATE_CREDENTIALS_REQUEST,
  });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('users/state-credentials', {
      headers,
    });

    dispatch({ type: CREDENTIAL_ACTION.GET_ALL_STATE_CREDENTIALS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: CREDENTIAL_ACTION.GET_ALL_STATE_CREDENTIALS_FAILURE, payload: err });
  }
};

// eslint-disable-next-line consistent-return
export const updateLicenseCredential = (licenseId, license, oldCredentials) => (dispatch) => {
  if (getShouldUsingCredentialServiceEndpointForLicense()) {
    return dispatch(updateLicenseCredentialV2(licenseId, license, oldCredentials));
  }

  const validCredentials = license?.credentials?.map?.((credential) => {
    const { credential_type: credentialType, entry_field: entryField } =
      credential || {};

    if (credentialType === 'reference') {
      return {
        ...credential,
        entry_field: {
          // only send references key, anything else is not needed
          references: (entryField.references ?? []).filter(
            ({ officeName, contactEmail, employmentStart, employmentEnd }) =>
              officeName.trim().length > 0 &&
              contactEmail.trim().length > 0 &&
              !!employmentStart &&
              !!employmentEnd,
          ),
        },
      };
    }

    return credential;
  }) || [];

  // since field of Front Desk credential is different from other professions,
  // license profession/sub-profession need to be updated first before updating credentials
  // to avoid wrong input type fields error
  if (validCredentials) {
    // eslint-disable-next-line no-restricted-syntax
    for (const c of validCredentials) {
      const {
        credential_input_type: credentialInputType,
        credential_type: credentialType,
        entry_field: entryField,
        id,
      } = c || {};

      if (c) {
        const isReferenceCredentialValid =
          credentialType === 'reference' &&
          credentialInputType === 'entry_field' &&
          entryField?.references?.length > 0;
        const isLicenseCredentialValid =
          credentialType !== 'reference' &&
          credentialInputType === 'entry_field' &&
          entryField?.license;

        if (isReferenceCredentialValid || isLicenseCredentialValid) {
          if (!id) {
            dispatch(createCredentialEntryType(c, licenseId));
          } else {
            dispatch(updateCredentialEntryType(c, licenseId, id));
          }
        }
      }
    }
  }
}

export const createCredentialEntryType = (data, licId) => async (dispatch, getState) => {
  dispatch({
    type: CREDENTIAL_ACTION.POST_ENTRY_STATE_TYPE_CREDENTIALS_REQUEST,
  });
  try {
    const headers = await getAuthHeaders();
    const currUserId = getState().users.currentUser.id;

    await tempMee.post(`/admin/users/${currUserId}/licenses/${licId}/credentials`, data, {
      headers,
    });
  } catch (err) {
    dispatch({ type: CREDENTIAL_ACTION.POST_ENTRY_STATE_TYPE_CREDENTIALS_FAILURE, payload: err });
  }
};

export const updateCredentialEntryType =
  (data, licId, credentialId) => async (dispatch, getState) => {
    dispatch({
      type: CREDENTIAL_ACTION.PATCH_ENTRY_STATE_TYPE_CREDENTIALS_REQUEST,
    });
    try {
      const headers = await getAuthHeaders();
      const currUserId = getState().users.currentUser.id;

      await tempMee.patch(
        `/admin/users/${currUserId}/licenses/${licId}/credentials/${credentialId}`,
        data,
        {
          headers,
        },
      );
    } catch (err) {
      dispatch({
        type: CREDENTIAL_ACTION.PATCH_ENTRY_STATE_TYPE_CREDENTIALS_FAILURE,
        payload: err,
      });
    }
  };

export const uploadCredential =
  // eslint-disable-next-line consistent-return
  (image, data, licId, credentialId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREDENTIAL_ACTION.UPLOAD_STATE_CREDENTIALS_REQUEST });

      const currUserId = getState().users.currentUser.id;
      const form = new FormData();
      form.append('image', image);
      const headers = await getAuthHeaders();

      if (data) {
        const credentialUploadData = await tempMee.post(
          `/admin/users/${currUserId}/licenses/${licId}/credentials-upload`,
          data,
          {
            headers,
          },
        );

        // eslint-disable-next-line no-param-reassign
        credentialId = credentialUploadData.data.id;
      }

      const response = await tempMee.patch(
        `/admin/users/${currUserId}/licenses/${licId}/credentials-upload/${credentialId}`,
        form,
        {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: 'Credential is uploaded',
        },
      });

      return response.data;
    } catch (error) {
      dispatch({ type: CREDENTIAL_ACTION.UPLOAD_STATE_CREDENTIALS_FAILURE, payload: error });
    }
  };

export const deleteCredentialUpload = (licId, credentialId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREDENTIAL_ACTION.DELETE_CREDENTIALS_UPLOAD_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();

    await tempMee.delete(
      `/admin/users/${currUserId}/licenses/${licId}/credentials-upload/${credentialId}`,
      {
        headers: {
          ...headers,
        },
      },
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Credential is deleted',
      },
    });
  } catch (error) {
    dispatch({ type: CREDENTIAL_ACTION.DELETE_CREDENTIALS_UPLOAD_FAILURE, payload: error });
  }
};

export const deleteCredentialFile = (licId, credentialId, fileId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREDENTIAL_ACTION.DELETE_CREDENTIALS_UPLOAD_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();

    await tempMee.delete(
      `/admin/users/${currUserId}/licenses/${licId}/credentials-upload/${credentialId}/${fileId}`,
      {
        headers: {
          ...headers,
        },
      },
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Image is deleted',
      },
    });
  } catch (error) {
    dispatch({ type: CREDENTIAL_ACTION.DELETE_CREDENTIALS_UPLOAD_FAILURE, payload: error });
  }
};

export const fetchUserCredentials = () => async (dispatch, getState) => {
  if (!getShouldUsingCredentialServiceEndpoint()) {
    return
  }

  try {
    const { users } = getState();
    const currentUser = users?.currentUser || {};

    dispatch({ type: CREDENTIAL_ACTION.GET_USER_CREDENTIALS_REQUEST });

    const response = await getCredentialsV2(currentUser?.id, currentUser?.user_type);

    const professional_info = {
      ...currentUser.professional_info,
      insurances: response.data?.insurances || [],
      softwares: response.data?.softwares || [],
      education: response?.data?.education,
    };

    dispatch({
      type: CREDENTIAL_ACTION.GET_USER_CREDENTIALS_SUCCESS,
      payload: {
        // eslint-disable-next-line camelcase
        professional_info,
      },
    });
  } catch (err) {
    dispatch({
      type: CREDENTIAL_ACTION.GET_USER_CREDENTIALS_FAILURE,
      payload: err,
    });
  }
};

/** start of cred-service license credentials v2 */

/**
 * Handles uploading license files for a given license ID and credentials.
 * @returns Returns an array of upload promises for the files.
 */
// eslint-disable-next-line no-underscore-dangle
export const handleUploadLicenseFilesV2 = (userId, licenseId, credentials, subProfession) => {
  const uploadPromises = credentials?.flatMap?.(
    (item) =>
      item.files
        ?.filter((itemFile) => itemFile.path)
        .map((itemFile) => {
          const form = new FormData();

          const fileType =
            item.credential_type === CredentialType.DACertificate &&
            subProfession === UserSubProfession.EFDA
              ? ICredentialV2FileType.efdaCertificate
              : item.credential_type;

          form.append('file', itemFile?.path);
          form.append('command', JSON.stringify({ type: fileType }));

          // Return the promise from uploadLicenseFileV2
          return uploadProfLicenseFileV2(userId, licenseId, form);
        }) || [], // Handle cases where item.files is undefined
  );

  return uploadPromises;
};

/**
 * Handles deleting license files for a given license ID and credentials.
 * @returns Returns an array of delete files promises.
 */
// eslint-disable-next-line no-underscore-dangle
const _handleDeleteLicenseFilesV2 = (userId, licenseId, credentials, oldCredentials) => {
  const deletePromises = oldCredentials?.flatMap?.((item) => {
    const newCredential = credentials?.find?.(
      (itemCred) => itemCred.credential_type === item.credential_type,
    );

    if (!newCredential) {
      return [];
    }

    const findDeletedFiles =
      item.files?.filter(
        (itemFile) =>
          !newCredential.files?.find((itemFileNew) => itemFileNew.file_id === itemFile.file_id),
      ) || [];

    return (
      findDeletedFiles.map((itemFile) =>
        deleteProfLicenseFileV2(userId, licenseId, itemFile.file_id),
      ) || []
    );
  });

  return deletePromises;
};

/**
 * Handles updating state license information for a given license ID and credentials.
 * @returns Returns an array of update state license promises.
 */
// eslint-disable-next-line no-underscore-dangle
const _handleUpdateStateLicense = (userId, licenseId, credentials, user) => {
  const updatePromises = credentials?.flatMap?.((item) => {
    if (item.credential_type !== CredentialType.StateLicense) {
      return [];
    }

    const stateLicense = item?.entry_field;
    if (!stateLicense) {
      return [];
    }

    const isStateExist = stateLicense.license;

    if (isStateExist && item?.id) {
      return updateProfLicenseStateLicenseV2(userId, licenseId, item?.id, {
        expiryDate: moment(stateLicense?.expiration_date).format('YYYY-MM-DD'),
        issuedDate: moment(stateLicense?.issue_date).format('YYYY-MM-DD'),
        number: stateLicense?.license,
        firstName: user?.first_name,
        lastName: user?.last_name,
      });
    }

    if (isStateExist) {
      return addProfLicenseStateLicenseV2(userId, licenseId, {
        expiryDate: moment(stateLicense?.expiration_date).format('YYYY-MM-DD'),
        issuedDate: moment(stateLicense?.issue_date).format('YYYY-MM-DD'),
        number: stateLicense?.license,
        firstName: user?.first_name,
        lastName: user?.last_name,
      });
    }

    if (item?.id) {
      return deleteProfLicenseStateLicenseV2(userId, licenseId, item?.id);
    }

    return null;
  });

  return updatePromises;
};

/**
 * Handles updating reference information for a given license ID and credentials.
 * @returns Returns an array of update reference promises.
 */
// eslint-disable-next-line no-underscore-dangle
const _handleUpdateReference = (userId, licenseId, credentials) => {
  const updatePromises = credentials?.flatMap?.((item) => {
    if (item.credential_type !== CredentialType.Reference) {
      return [];
    }

    return (
      item.entry_field?.references?.map((itemReference) => {
        const isContactExist = itemReference?.officeName && itemReference?.contactEmail;

        if (isContactExist && itemReference?._id) {
          return updateProfLicenseReferenceV2(userId, licenseId, itemReference?._id, {
            contactEmail: itemReference?.contactEmail || '',
            contactName: itemReference?.officeName || '',
            employmentStart: moment(itemReference.employmentStart).format('YYYY-MM-DD'),
            employmentEnd: moment(itemReference.employmentEnd).format('YYYY-MM-DD'),
          });
        }

        if (isContactExist) {
          return addProfLicenseReferenceV2(userId, licenseId, {
            contactEmail: itemReference?.contactEmail || '',
            contactName: itemReference?.officeName || '',
            employmentStart: moment(itemReference.employmentStart).format('YYYY-MM-DD'),
            employmentEnd: moment(itemReference.employmentEnd).format('YYYY-MM-DD'),
          });
        }

        if (itemReference?._id) {
          return deleteProfLicenseReferenceV2(userId, licenseId, itemReference?._id);
        }

        return null;
      }) || []
    ); // Handle cases where item.files is undefined
  });

  return updatePromises;
};

export const updateLicenseCredentialV2 =
  (licenseId, license, oldCredentials) => async (dispatch, getState) => {
    dispatch({
      type: CREDENTIAL_ACTION.PATCH_ENTRY_STATE_TYPE_CREDENTIALS_REQUEST,
    });
    const { credentials, subProfession } = license || {};

    try {
      const user = getState().users.currentUser;

      await Promise.all([
        // files
        ...handleUploadLicenseFilesV2(user.id, licenseId, credentials, subProfession) || [],
        ..._handleDeleteLicenseFilesV2(user.id, licenseId, credentials, oldCredentials) || [],
        // references
        ..._handleUpdateReference(user.id, licenseId, credentials) || [],
        // state license
        ..._handleUpdateStateLicense(user.id, licenseId, credentials, user) || [],
      ]);
    } catch (error) {
      dispatch({
        type: CREDENTIAL_ACTION.PATCH_ENTRY_STATE_TYPE_CREDENTIALS_FAILURE,
        payload: error,
      });
    }
  };

/** end of cred-service license credentials v2 */
