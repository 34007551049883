import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Colors } from '../../../../themes/colors';
import Styles from '../../../../themes/style.module.scss';
import { Pagination } from '../../../commonComponents/Table/Pagination';
import { TabViewContainer } from '../../../commonComponents/TabViewContainer';
import { AddNewDso } from './components/AddNewDso';
import { Filters } from './components/Filters';
import { useDsosListTable } from './hooks/useDsosListTable';

import '../../../../themes/List.scss';

export const DsosList = () => {
  const {
    filters,
    handleTableChange,
    onRowClicked,
    handleFilterChange,
    total,
    data,
    totalPages,
    isLoading,
  } = useDsosListTable();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 8,
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h3 className={Styles.h3} style={{ fontSize: '24px', textAlign: 'center' }}>
            DSO Groups
          </h3>
        </div>
      </div>
      <div style={{ marginTop: 48, width: 740 }}>
        <Filters filters={filters} handleFilterChange={handleFilterChange} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 12,
            justifyContent: 'flex-end',
            marginTop: 40,
          }}
        >
          <AddNewDso />
          <span
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              color: '#435963',
            }}
          >
            Total | {total !== undefined ? Intl.NumberFormat().format(total) : ''}
          </span>
        </div>
      </div>
      {isLoading ? (
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      ) : (
        <TabViewContainer
          className="list"
          style={{
            alignItems: 'center',
            paddingTop: 40,
            width: 740,
            minWidth: 740,
            marginBottom: 140,
          }}
          variant="card"
        >
          <header
            className="list-header"
            style={{
              justifyContent: 'space-between',
              paddingInline: 12,
            }}
          >
            <span>Name</span>
            <span># Offices</span>
          </header>
          {data.map((item) => (
            <div
              key={item.id}
              style={{
                borderBottom: `1px solid ${Colors.neutral_70}`,
                paddingBlock: 4,
                width: '100%',
              }}
            >
              <div
                className="list-item"
                style={{
                  color: Colors.neutral_600,
                  cursor: 'pointer',
                  fontWeight: 700,
                  height: 40,
                  justifyContent: 'space-between',
                  paddingRight: 24,
                  borderBottom: 'none',
                }}
                onClick={() => onRowClicked(item)}
              >
                <span
                  style={{
                    overflow: 'hidden',
                    textDecoration: 'underline',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {item.name}
                </span>
                <span>{item.numOfOffices}</span>
              </div>
            </div>
          ))}
          <footer style={{ paddingTop: 40 }}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              style={{
                margin: 0,
              }}
              onPageChange={(value) => {
                setCurrentPage(value);
                handleTableChange(value);
              }}
            />
          </footer>
        </TabViewContainer>
      )}
    </div>
  );
};
