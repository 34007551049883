/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
import moment from 'moment';
import { UserProfession, UserSubProfession } from '../enums/UserProfession';

export const getStatusLicenseColor = (status) => {
  if (status === 'new') {
    return 'black';
  }
  if (status === 'verified') {
    return 'green';
  }
  return 'red';
};

export const CredentialType = {
  StateLicense: 'state_license',
  DACertificate: 'da_certificate',
  Radiology: 'radiology',
  CprBls: 'cpr/bls',
  Reference: 'reference',
  Upload: 'upload',
  Npi: 'npi',
};

export const CredentialInputType = {
  Upload: 'upload',
  EntryField: 'entry_field',
  SingleField: 'single_field',
};

export const ICredentialV2FileType = {
  daCertificate: 'da_certificate',
  efdaCertificate: 'efda_certificate',
  radiology: 'radiology',
  cprBls: 'cpr/bls',
};

/**
 * converting response license v2 (from credentials service) to v1 (monolith) response
 *
 * @param data
 * @returns
 */
export function convertLicensesV2toV1(data) {
  return data
    .map((entry) => ({
      status: entry.status,
      license: '',
      state: entry.state,
      profession: entry.profession,
      school: '',
      subProfession: entry.subProfession,
      addedAt: new Date(entry?.createdAt),
      credentials: [
        {
          entry_field: {
            license: '',
            expiration_date: entry.expiredAt ? new Date(entry.expiredAt) : undefined,
            normalizedLicense: '',
          },
          credential_type: CredentialType.StateLicense,
          credential_input_type: CredentialInputType.EntryField,
          files: [],
          addedAt: entry.createdAt,
        },
      ],
      id: entry.id,
      professionName: UserProfession.getName(entry.profession),
    }))
    .sort((a, b) => b.addedAt.getTime() - a.addedAt.getTime());
}

/**
 * converting response license detail v2 (from credentials service) to v1 (monolith) response
 *
 * @param entry
 * @returns
 */
export function convertLicenseDetailV2toV1(entry) {
  const radiologyFiles = [];
  const daCertificateFiles = [];
  const efdaCertificateFiles = [];
  const cprBlsFiles = [];

  entry?.files?.forEach((item) => {
    const file = {
      file_id: item.id,
      s3_url: item.url,
    };
    switch (item.type) {
      case ICredentialV2FileType.radiology:
        radiologyFiles.push(file);
        break;
      case ICredentialV2FileType.daCertificate:
        daCertificateFiles.push(file);
        break;
      case ICredentialV2FileType.efdaCertificate:
        efdaCertificateFiles.push(file);
        break;
      case ICredentialV2FileType.cprBls:
        cprBlsFiles.push(file);
        break;

      default:
        break;
    }
  });

  return {
    status: entry.status,
    license: '',
    state: entry.state,
    profession: entry.profession,
    school: '',
    subProfession: entry.subProfession,
    addedAt: entry.createdAt,
    credentials: [
      ...(entry.stateLicense
        ? [
            {
              entry_field: {
                license: entry.stateLicense.number,
                issue_date: entry?.stateLicense?.issuedAt
                  ? new Date(entry.stateLicense.issuedAt)
                  : undefined,
                expiration_date: entry?.stateLicense?.expiredAt
                  ? new Date(entry?.stateLicense?.expiredAt)
                  : undefined,
                normalizedLicense: entry.stateLicense.normalizedNumber,
              },
              credential_type: CredentialType.StateLicense,
              credential_input_type: CredentialInputType.EntryField,
              addedAt: entry?.stateLicense?.createdAt,
              id: entry.stateLicense.id,
              files: [],
            },
          ]
        : []),
      ...(entry.references
        ? [
            {
              entry_field: {
                references: entry.references.map((item) => ({
                  _id: item.id,
                  contactEmail: item.contactEmail,
                  officeName: item.contactName,
                  employmentStart: item.employmentStart ? new Date(item.employmentStart) : null,
                  employmentEnd: item.employmentEnd ? new Date(item.employmentEnd) : null,
                })),
                normalizedLicense: '',
              },
              credential_type: CredentialType.Reference,
              credential_input_type: CredentialInputType.EntryField,
              addedAt: entry?.stateLicense?.createdAt,
              files: [],
            },
          ]
        : []),
      ...(radiologyFiles.length
        ? [
            {
              credential_type: CredentialType.Radiology,
              credential_input_type: CredentialInputType.Upload,
              files: radiologyFiles,
            },
          ]
        : []),
      ...(daCertificateFiles.length
        ? [
            {
              credential_type: CredentialType.DACertificate,
              credential_input_type: CredentialInputType.Upload,
              files: daCertificateFiles,
            },
          ]
        : []),
      ...(efdaCertificateFiles.length
        ? [
            {
              credential_type: CredentialType.DACertificate,
              credential_input_type: CredentialInputType.Upload,
              files: efdaCertificateFiles,
            },
          ]
        : []),
      ...(cprBlsFiles.length
        ? [
            {
              credential_type: CredentialType.CprBls,
              credential_input_type: CredentialInputType.Upload,
              files: cprBlsFiles,
            },
          ]
        : []),
    ],

    id: entry.id,
    professionName: UserProfession.getName(entry.profession),
  };
}

/**
 * get payload for post license credentials
 *
 * @param licenseData
 * @param credentials
 * @param user
 * @param subProfession
 * @returns PayloadLicense
 */
export function getLicensesV2Payload(licenseData, credentials, user, subProfession) {
  const stateLicenses = credentials.filter(
    (item) => item.credential_type === CredentialType.StateLicense,
  );
  const files = credentials.filter(
    (item) => item.credential_input_type === CredentialInputType.Upload,
  );

  const { expiration_date, issue_date, license } = stateLicenses?.[0]?.entry_field || {};

  const stateLicense = stateLicenses?.[0]?.entry_field?.license
    ? {
        expiryDate: expiration_date ? moment(expiration_date).format('YYYY-MM-DD') : null,
        issuedDate: issue_date ? moment(issue_date).format('YYYY-MM-DD') : null,
        number: license,
        firstName: user?.first_name,
        lastName: user?.last_name,
      }
    : undefined;

  // IF LICENSE DA
  if (licenseData?.profession === UserProfession.DA) {
    const fileIndex = files?.findIndex((item) => {
      const credentialType = item.credential_type;
      if (credentialType === ICredentialV2FileType.daCertificate) {
        return true;
      }

      return false;
    });

    const fileType =
      subProfession === UserSubProfession.EFDA
        ? ICredentialV2FileType.efdaCertificate
        : ICredentialV2FileType.daCertificate;

    const uri = files?.[fileIndex]?.files?.[0]?.path || '';

    const newFiles = [];
    files.forEach((item, index) => {
      if (index === fileIndex) {
        newFiles.push({
          ...item,
          files: item.files?.filter((_, idx) => idx !== 0),
        });
      } else {
        newFiles.push(item);
      }
    });

    return {
      data: {
        state: licenseData.state,
        status: licenseData.status,
        stateLicense,
        file: {
          uri,
          type: fileType,
        },
      },
      files: newFiles,
    };
  }

  // ELSE LICENSE RDH, DN, FD
  const references = credentials.filter(
    (item) => item.credential_type === CredentialType.Reference,
  );

  return {
    files,
    data: {
      state: licenseData.state,
      stateLicense,
      status: licenseData.status,
      command:
        references.length > 0
          ? {
              state: licenseData.state,
              references:
                references?.[0]?.entry_field?.references?.map?.((item) => ({
                  contactEmail: item?.contactEmail || '',
                  contactName: item?.officeName || '',
                  employmentStart: item.employmentStart
                    ? moment(item.employmentStart).format('YYYY-MM-DD')
                    : null,
                  employmentEnd: item.employmentEnd
                    ? moment(item.employmentEnd).format('YYYY-MM-DD')
                    : null,
                })) || [],
            }
          : undefined,
    },
  };
}