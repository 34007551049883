import { useSelector } from 'react-redux';
import { Colors } from '../../../../../../../../../../themes/colors';
import CustomPortal from '../../../../../../../../../commonComponents/CustomPortal';

export const ConfirmRemoveRegionPopup = ({ region, onClose, onConfirm }) => {
  const { name } = useSelector((state) => state.dsos.dsoGroupDetails);

  return (
    <CustomPortal
      title="Remove Region from DSO"
      cancelPressed={onClose}
      cancelText="Go Back"
      submitPressed={onConfirm}
      submitText="Remove Region"
    >
      <div
        style={{
          padding: '0 40px',
          width: '100%',
          marginTop: 12,
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
        }}
      >
        <span
          style={{
            display: 'flex',
            fontSize: 16,
            color: Colors.neutral_500,
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          Are you sure you would like to remove {region.name} from {name}’s organization structure?
        </span>
        <span
          style={{
            display: 'flex',
            fontSize: 16,
            color: Colors.neutral_500,
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          Please ensure that all region-level office staff are unlinked from the region (navigate to
          DSO page &gt; Office Staff tab and filter by region).
        </span>
      </div>
    </CustomPortal>
  );
};
