export const Pressable = ({ onClick, children, style, disabled = false }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    style={{
      all: 'unset',
      display: 'flex',
      cursor: disabled ? 'not-allowed' : 'pointer',
      ...style,
    }}
  >
    {children}
  </button>
);
