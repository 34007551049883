import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import {
  fetchUserCredentials,
  getUserEducation,
  updateUserEducation,
} from '../../../actions/licensesAction';
import skillsCheckedIcon from '../../../images/skillsCheckedIcon.png';
import skillsUncheckedIcon from '../../../images/skillsUncheckedIcon.png';
import { createLoadingSelector } from '../../../apis/selectors';
import { filterObjectKeys } from '../../../utils';
import { UserProfession } from '../../../enums/UserProfession';

export default () => {
  const dispatch = useDispatch();

  const loadingSelector = createLoadingSelector(['UPDATE_USER_EDUCATION', 'FETCH_CURRENT_USER']);
  const isLoading = useSelector((state) => loadingSelector(state));

  const loadingGetSelector = createLoadingSelector(['GET_USER_CREDENTIALS']);
  const isLoadingGet = useSelector((state) => loadingGetSelector(state));

  const user = useSelector((state) => state.users.currentUser);
  const userEducation = useSelector((state) => state.users.userEducation);

  const userProfession = user?.licenseTypes?.[0];
  const educationInfo = user?.professional_info?.education;

  const [yearsExp, setYearsExp] = useState(user?.years_of_experience || '');
  const [graduationDate, setGraduationDate] = useState(user.graduationDate || '');

  const [school, setSchool] = useState(user?.school || '');

  const [experiencedList, setExperiencedList] = useState({});

  useEffect(() => {
    if (educationInfo?.[userProfession]) {
      setExperiencedList(
        {
          ...educationInfo?.[userProfession],
          insurances: user?.professional_info?.insurances,
        } || {},
      );
    }
  }, [
    educationInfo,
    educationInfo.userProfession,
    user?.professional_info?.insurances,
    userProfession,
  ]);

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getUserEducation());
    dispatch(fetchUserCredentials());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveEditSkills = () => {
    dispatch(
      updateUserEducation({
        years_of_experience: yearsExp,
        graduationDate,
        school,
        education: { [userProfession]: experiencedList },
        insurances: experiencedList.insurances,
      }),
    );

    setIsEdit(false);
  };

  const renderEducation = () => (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 20,
        }}
      >
        <p
          style={{
            marginRight: 20,
            fontFamily: 'Nunito',
            fontWeight: 'bolder',
            fontSize: 16,
          }}
        >
          Years of Experience
        </p>
        <p>{user?.years_of_experience || '-'}</p>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 20,
        }}
      >
        <p
          style={{
            marginRight: 20,
            fontFamily: 'Nunito',
            fontWeight: 'bolder',
            fontSize: 16,
          }}
        >
          School
        </p>
        <p>{user?.school || '-'}</p>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 20,
        }}
      >
        <p
          style={{
            marginRight: 20,
            fontFamily: 'Nunito',
            fontWeight: 'bolder',
            fontSize: 16,
          }}
        >
          Graduation Date
        </p>
        <p>
          {user?.graduationDate
            ? moment(user?.graduationDate).add(8, 'hour').format('YYYY-MM-DD')
            : '-'}
        </p>
      </div>
    </>
  );

  const renderEducationInput = () => (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 20,
          justifyContent: 'space-between',
        }}
      >
        <p
          style={{
            marginRight: 20,
            fontFamily: 'Nunito',
            fontWeight: 'bolder',
            fontSize: 16,
          }}
        >
          Years of Experience
        </p>
        <select
          value={yearsExp || 'Less than 1 Year'}
          className="form-control"
          onChange={(val) => setYearsExp(val.target.value)}
          style={{ width: 150, marginBottom: 5 }}
        >
          <option value="Less than 1 Year">Less than 1 Year</option>
          <option value="1 - 3 Years"> 1 - 3 Years</option>
          <option value="4- 9 Years">4- 9 Years</option>
          <option value="10 - 19 Years">10 - 19 Years</option>
          <option value="20+ Years">20+ Years</option>
        </select>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 10,
          marginBottom: 10,
          justifyContent: 'space-between',
        }}
      >
        <p
          style={{
            marginRight: 20,
            fontFamily: 'Nunito',
            fontWeight: 'bolder',
            fontSize: 16,
          }}
        >
          School
        </p>
        <input
          style={{ height: 40, width: 350 }}
          className="form-controll"
          value={school}
          type="text"
          onChange={(e) => setSchool(e.target.value)}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 20,
          justifyContent: 'space-between',
        }}
      >
        <p
          style={{
            marginRight: 20,
            fontFamily: 'Nunito',
            fontWeight: 'bolder',
            fontSize: 16,
          }}
        >
          Graduation Date
        </p>
        <input
          style={{ height: 40, width: 200 }}
          className="form-controll"
          value={moment(graduationDate).add(8, 'hour').format('YYYY-MM-DD')}
          type="date"
          onChange={(e) => {
            setGraduationDate(e.target.value);
          }}
        />
      </div>
    </>
  );

  const renderRDHEducation = () => (
    <div style={{ fontSize: 16 }}>
      <p style={{ marginTop: 20 }}>
        <strong>Specialties (RDH)</strong>
      </p>
      <div style={{ height: 1, backgroundColor: '#EFEFEF', width: '100%', marginBottom: 10 }} />

      <div>
        {Object.keys(filterObjectKeys(userEducation?.specialtiesAndProcedures, 'specialty'))?.map(
          (key) => (
            <div
              aria-hidden
              onClick={() => {
                if (!isEdit) return;

                if (experiencedList?.specialtiesAndProcedures?.[key]) {
                  const newList = { ...experiencedList };

                  delete newList?.specialtiesAndProcedures?.[key];
                  setExperiencedList(newList);
                } else {
                  const newList = { ...experiencedList };

                  newList.specialtiesAndProcedures[key] = [];
                  setExperiencedList(newList);
                }
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 5,
                cursor: isEdit ? 'pointer' : 'default',
              }}
            >
              <img
                alt="skills_check_icon"
                src={
                  experiencedList?.specialtiesAndProcedures?.[key]
                    ? skillsCheckedIcon
                    : skillsUncheckedIcon
                }
                style={{ height: 20, width: 20, marginRight: 10 }}
              />
              <p>{key?.split('_')?.[1]}</p>
            </div>
          ),
        )}
      </div>

      <p style={{ marginTop: 20 }}>
        <strong>Procedures (RDH)</strong>
      </p>
      <div style={{ height: 1, backgroundColor: '#EFEFEF', width: '100%', marginBottom: 10 }} />

      <div>
        {Object.keys(filterObjectKeys(userEducation?.specialtiesAndProcedures, 'procedure'))?.map(
          (key) => (
            <div
              aria-hidden
              onClick={() => {
                if (!isEdit) return;

                if (experiencedList?.specialtiesAndProcedures?.[key]) {
                  const newList = { ...experiencedList };

                  delete newList?.specialtiesAndProcedures?.[key];
                  setExperiencedList(newList);
                } else {
                  const newList = { ...experiencedList };

                  newList.specialtiesAndProcedures[key] = [];
                  setExperiencedList(newList);
                }
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 5,
                cursor: isEdit ? 'pointer' : 'default',
              }}
            >
              <img
                alt="skills_check_icon"
                src={
                  experiencedList?.specialtiesAndProcedures?.[key]
                    ? skillsCheckedIcon
                    : skillsUncheckedIcon
                }
                style={{ height: 20, width: 20, marginRight: 10 }}
              />
              <p>{key?.split('_')?.[1]}</p>
            </div>
          ),
        )}
      </div>
    </div>
  );

  const renderProfessionEducation = (profession) => (
    <div style={{ fontSize: 16 }}>
      <p style={{ marginTop: 20 }}>
        <strong>Specialties & Procedures ({profession})</strong>
      </p>
      <hr style={{ height: 1, backgroundColor: '#EFEFEF', margin: '0 0 10px' }} />

      {Object.keys(userEducation?.specialtiesAndProcedures || {})?.map((key) => (
        <>
          <p style={{ marginTop: 20 }}>
            <strong>{key?.split('_')?.[1]}</strong>
          </p>

          {!userEducation?.specialtiesAndProcedures[key]?.length && (
            <div
              aria-hidden
              onClick={() => {
                if (!isEdit) return;

                if (experiencedList?.specialtiesAndProcedures?.[key]) {
                  const newList = { ...experiencedList };

                  delete newList?.specialtiesAndProcedures?.[key];
                  setExperiencedList(newList);
                } else {
                  const newList = { ...experiencedList };

                  newList.specialtiesAndProcedures[key] = [];
                  setExperiencedList(newList);
                }
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 5,
                cursor: isEdit ? 'pointer' : 'default',
              }}
            >
              <img
                alt="skills_check_icon"
                src={
                  experiencedList?.specialtiesAndProcedures?.[key]
                    ? skillsCheckedIcon
                    : skillsUncheckedIcon
                }
                style={{ height: 20, width: 20, marginRight: 10 }}
              />
              <p>{key?.split('_')?.[1]}</p>
            </div>
          )}

          {userEducation?.specialtiesAndProcedures[key]?.map((name) => (
            <div
              aria-hidden
              onClick={() => {
                if (!isEdit) return;

                if (experiencedList?.specialtiesAndProcedures?.[key]?.includes(name)) {
                  setExperiencedList((prevState) => {
                    if (prevState?.specialtiesAndProcedures?.[key].length === 1) {
                      const newList = { ...experiencedList };

                      delete newList?.specialtiesAndProcedures?.[key];

                      return newList;
                    }

                    return {
                      ...experiencedList,
                      specialtiesAndProcedures: {
                        ...experiencedList.specialtiesAndProcedures,
                        [key]: experiencedList?.specialtiesAndProcedures?.[key].filter(
                          (item) => item !== name,
                        ),
                      },
                    };
                  });
                } else {
                  setExperiencedList({
                    ...experiencedList,
                    specialtiesAndProcedures: {
                      ...experiencedList.specialtiesAndProcedures,
                      [key]: [...(experiencedList?.specialtiesAndProcedures?.[key] || []), name],
                    },
                  });
                }
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 5,
                cursor: isEdit ? 'pointer' : 'default',
              }}
            >
              <img
                alt="skills_check_icon"
                src={
                  experiencedList?.specialtiesAndProcedures?.[key]?.includes(name)
                    ? skillsCheckedIcon
                    : skillsUncheckedIcon
                }
                style={{ height: 20, width: 20, marginRight: 10 }}
              />
              <p>{name?.split('_')?.[1]}</p>
            </div>
          ))}
        </>
      ))}
    </div>
  );

  const renderView = () => (
    <div style={{ width: '100%' }}>
      {isEdit ? null : renderEducation()}
      <div style={{ height: 1, backgroundColor: '#EFEFEF', width: '100%' }} />

      {userProfession === 'RDH' && renderRDHEducation()}

      {[UserProfession.DA, UserProfession.DN, UserProfession.FD].includes(userProfession) &&
        renderProfessionEducation(userProfession)}

      <p style={{ fontFamily: 'Nunito', fontWeight: 'bolder', fontSize: 16, marginTop: 20 }}>
        Softwares
      </p>
      <div style={{ height: 1, backgroundColor: '#EFEFEF', width: '100%', marginBottom: 10 }} />

      <div>
        {userEducation?.softwares?.map((key) => (
          <div
            aria-hidden
            onClick={() => {
              if (!isEdit) return;

              if (experiencedList?.softwares?.includes(key)) {
                setExperiencedList({
                  ...experiencedList,
                  softwares: experiencedList?.softwares?.filter((name) => name !== key),
                });
              } else {
                setExperiencedList({
                  ...experiencedList,
                  softwares: [...(experiencedList?.softwares || []), key],
                });
              }
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 5,
              cursor: isEdit ? 'pointer' : 'default',
            }}
          >
            <img
              alt="skills_check_icon"
              src={
                experiencedList?.softwares?.includes(key) ? skillsCheckedIcon : skillsUncheckedIcon
              }
              style={{ height: 20, width: 20, marginRight: 10 }}
            />
            <p>{key}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const renderEdit = () => (
    <div style={{ width: '100%' }}>
      {renderEducationInput()}

      {renderView()}
    </div>
  );

  const renderEditButtons = () => (
    <div>
      <button
        className="btn btn-success"
        style={{
          width: '100px',
          marginRight: 20,
        }}
        onClick={() => saveEditSkills()}
        type="button"
      >
        Save
      </button>
      <button
        className="btn btn-secondary"
        style={{
          width: '100px',
        }}
        onClick={() => setIsEdit(false)}
        type="button"
      >
        Cancel
      </button>
    </div>
  );

  const renderViewButtons = () => (
    <button
      className="btn btn-info"
      style={{
        width: '100px',
      }}
      onClick={() => setIsEdit(true)}
      type="button"
    >
      Edit
    </button>
  );
  return (
    <div className="card mt-5 mb-5" style={{ width: '45vw' }}>
      <div
        className="card-body"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingLeft: 30,
          paddingRight: 30,
        }}
      >
        <h3> Skills</h3>
        {isLoadingGet ? (
          <div
            style={{
              width: '100%',
              height: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 30,
                width: 30,
                marginLeft: 20,
                marginTop: 10,
              }}
            />
          </div>
        ) : (
          <>
            {isEdit ? renderEdit() : renderView()}
            {isLoading ? (
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            ) : (
              <div style={{ marginTop: 30 }}>
                {isEdit ? renderEditButtons() : renderViewButtons()}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
