import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Combobox } from '../../../../commonComponents/Combobox/Combobox';
import { fetchDsoGroupList } from '../../../../../actions/dsosActions';

export const GroupFilter = ({ dsoId, filters, onChange }) => {
  const dispatch = useDispatch();

  const {
    data,
    filters: groupOptionsFilters,
    total,
  } = useSelector((state) => state.dsos.dsoGroupList);

  const options = useMemo(
    () =>
      data.map((it) => ({
        label: it.name,
        value: it.id,
      })),
    [data],
  );

  useEffect(() => {
    if (data.length === 0) {
      dispatch(fetchDsoGroupList(dsoId, { page: 1 }));
    }
  }, [data, dispatch, dsoId]);

  return (
    <Combobox
      hasReachMax={total === data.length}
      isAutoApply
      isPaged
      label="Group:"
      options={options}
      placeholder="All"
      values={options?.find((it) => it.value === filters.groupId) || []}
      onChange={(values) => {
        onChange('groupId', values?.value);
      }}
      onNextPage={() => {
        dispatch(fetchDsoGroupList(dsoId, { page: groupOptionsFilters.page + 1 }));
      }}
    />
  );
};
