import React, { Component } from 'react';
import { connect } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Styles from '../../../themes/style.module.scss';
import { getUsers, clearUserList } from '../../../actions/userActions';
import { createLoadingSelector, createErrorMessageSelector } from '../../../apis/selectors';

class UserSerchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hygName: '',
    };

    this.handleSearchChangeDebounce = AwesomeDebouncePromise(this.search, 1000);
  }

  search = () => {
    const { user_type } = this.props;
    const { hygName } = this.state;
    const except = this.props.except || [];

    this.props.getUsers({ searchText: hygName, user_type });
  };

  renderUserAddress(address) {
    if (address.length === 0) {
      return (
        <div lassName="card-text" style={{ fontFamily: 'Nunito', color: '#435963', margin: 2 }}>
          No address
        </div>
      );
    }

    const city = address[0].city || '';
    const state = address[0].state || '';
    return (
      <div className="card-text" style={{ fontFamily: 'Nunito', color: '#435963', margin: 2 }}>
        {city},{state}
      </div>
    );
  }

  renderUserName(user) {
    if (this.props.user_type === 'DNT') {
      return (
        <div className="card-title" style={{ fontFamily: 'Nunito-Bold', color: '#3EB0CA' }}>
          {user.office_name}
        </div>
      );
    }

    return (
      <div className="card-title" style={{ fontFamily: 'Nunito-Bold', color: '#3EB0CA' }}>
        {user.first_name} {user.last_name}
      </div>
    );
  }

  renderUsersList() {
    const { userList } = this.props;

    if (!userList?.length) return <></>;

    return (
      <div
        className="card"
        style={{
          display: 'flex',
          width: 200,
          marginTop: 45,
          background: '#ffffff',
          position: 'absolute',
          zIndex: 5,
        }}
      >
        {userList.slice(0, 5).map((user) => (
          <button
            key={user.id}
            style={{ width: '100%' }}
            onClick={(e) => {
              this.props.onSelectUser(user);
              this.props.clearUserList();
              this.setState({ hygName: '' });
            }}
            className={Styles.create_job_pick_user_btn}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: '4px',
                marginBottom: '4px',
                marginLeft: '7px',
                marginRight: '7px',
              }}
            >
              {this.renderUserName(user)}
              {this.renderUserAddress(user.address)}
            </div>
          </button>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center',
          width: '90%',
          height: this.props.heightEnabled ? 150 : null,
          ...this.props.style,
        }}
      >
        <input
          type="text"
          placeholder={this.props.placeholder}
          value={this.state.hygName}
          onChange={(e) => {
            this.setState({ hygName: e.target.value });
            this.handleSearchChangeDebounce();
          }}
          className="form-control"
          style={{ width: '100%', marginBottom: '20px', height: 45 }}
        />

        {this.renderUsersList()}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['GET_CURRENT_USER']);
const errorSelector = createErrorMessageSelector(['GET_CURRENT_USER']);

const mapStateToProps = (state) => ({
  isLoading: loadingSelector(state),
  error: errorSelector(state),
  userList: state.users.userList,
});

export default connect(mapStateToProps, { getUsers, clearUserList })(UserSerchField);
