import { OfficeDSOInformation, OfficeStaffMemberList } from './components';
import { useOfficeAccess } from './hooks/useOfficeAccess';

export const OfficeAccess = () => {
  const {
    data,
    groups,
    hasDso,
    isOfficeAdminListInitialLoading,
    isOfficeAdminListLoading,
    region,
    handleOnScroll,
  } = useOfficeAccess();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '70%',
        gap: 16,
      }}
    >
      <OfficeStaffMemberList
        data={data}
        isInitialLoading={isOfficeAdminListInitialLoading}
        isLoading={isOfficeAdminListLoading}
        onScroll={handleOnScroll}
      />
      {hasDso && (
        <OfficeDSOInformation
          groups={groups}
          isLoading={isOfficeAdminListInitialLoading}
          region={region}
        />
      )}
    </div>
  );
};
