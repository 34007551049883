import { getShouldUsingCredentialServiceEndpointForLicense } from '../../../../growthbook';
import DragAndDrop from './DragDrop';
import Slider from './Slider';
import { StateCredentialFileV2 } from './StateCredentialFileV2';

export const StateCredentialFile = ({
  editMode,
  credential,
  license,
  setLicenses,
  credentialType,
}) => {
  if (getShouldUsingCredentialServiceEndpointForLicense()) {
    return (
      <StateCredentialFileV2
        editMode={editMode}
        credential={credential}
        license={license}
        setLicenses={setLicenses}
        credentialType={credentialType}
      />
    );
  }

  return (
    <div
      style={{
        border: '1px dashed #cecece',
        borderRadius: '5px',
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {credential && credential?.files?.length > 0 ? (
        <Slider
          credential={credential}
          license={license}
          setLicenses={setLicenses}
          credentialType={credentialType}
        />
      ) : (
        <DragAndDrop
          credential={credential}
          license={license}
          setLicenses={setLicenses}
          credentialType={credentialType}
        />
      )}
    </div>
  );
};
