import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDsoGroupList } from '../../../../../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../../../../../apis/selectors';

const actions = ['FETCH_DSO_GROUP_LIST'];
const loadingSelector = createLoadingSelector(actions);

export const useGroupsCard = (dsoId) => {
  const dispatch = useDispatch();
  const {
    filters,
    data,
    total,
    isActiveRequest,
    isFinalPage,
    dsoId: stateDsoId,
  } = useSelector((state) => state.dsos.orgStructure.groupList);
  const isLoading = useSelector((state) => loadingSelector(state));

  const handleScrollList = useCallback(
    (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      const tableScrollHeight = scrollHeight - scrollTop - 20;
      if (tableScrollHeight <= clientHeight && !isActiveRequest && !isFinalPage) {
        dispatch(fetchDsoGroupList(dsoId, { page: filters.page + 1, orderBy: filters.orderBy }));
      }
    },
    [dispatch, filters.page, isActiveRequest, isFinalPage, dsoId, filters.orderBy],
  );

  useEffect(() => {
    dispatch(fetchDsoGroupList(dsoId, { page: 1, limit: 15 }));
  }, [dispatch, dsoId]);

  return {
    data: stateDsoId === dsoId ? data : [],
    total,
    limit: filters.limit,
    isInitialLoading: !data.length && isLoading,
    isLoading: isActiveRequest,
    handleScrollList,
  };
};
