import { assignOfficesToRegion } from '../../../../../../../../../../actions/dsosActions';
import { LinkEntityMenuItem } from '../../../LinkEntityTo';
import { useLinkOfficeToRegion } from './hooks';

export const LinkOfficeToRegionMenuItem = ({ dsoId, item }) => (
  <LinkEntityMenuItem
    dsoId={dsoId}
    item={item}
    entityName="Office"
    linkTo="Region"
    fetchHook={useLinkOfficeToRegion}
    action={({ items, entity }) =>
      assignOfficesToRegion({ regionId: items[0].id, offices: [entity] })
    }
  />
);
