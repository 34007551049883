import Alert from 'react-s-alert';

export const showErrorMessage = ({ message, position = 'bottom-right' }) => {
  Alert.error(message, {
    position,
    effect: 'slide',
    beep: false,
    timeout: 5000,
    offset: 100,
  });
};

export function isValidTokenKey() {
  const validTokenUuid = process.env.REACT_APP_VALID_TOKEN_UUID;
  const storedUuid = localStorage.getItem('validTokenUuid');

  return !(validTokenUuid && storedUuid !== validTokenUuid);
}

export function reverseHex(hexString) {
  // Step 1: Convert hexadecimal string to byte array
  const byteArray = hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16));

  // Step 2: Convert byte array to string
  const jsonString = String.fromCharCode.apply(null, byteArray);

  // Step 3: Parse string back to JSON object
  return JSON.parse(jsonString);
}

export function filterObjectKeys(obj, string) {
  const filteredEntries = Object.entries(obj || {}).filter(([key]) => key.startsWith(string));

  return Object.fromEntries(filteredEntries);
}

export function capitalizeEveryFirstLetter(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

export const isBoolean = (variable) => typeof variable === 'boolean' || variable instanceof Boolean;

export const getQueryParam = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const setQueryParams = (params, keys) => {
  if (!params) {
    return;
  }

  const url = new URL(window.location);
  const urlParams = new URLSearchParams();

  Object.keys(params)
    .filter((key) => !keys || keys.includes(key))
    .forEach((key) => {
      if (params[key] === null || params[key] === undefined || params[key] === '') {
        urlParams.delete(key);
      } else {
        urlParams.set(key, params[key]);
      }
    });

  // Update the URL without reloading the page
  const newUrl = urlParams.toString() ? `${url.pathname}?${urlParams.toString()}` : url.pathname;
  window.history.replaceState(null, '', newUrl);
};

export const updateQueryParam = (key, value) =>
  new Promise((resolve) => {
    const url = new URL(window.location);
    const urlParams = new URLSearchParams(url.search);

    if (value === null || value === undefined || value === '') {
      urlParams.delete(key);
    } else {
      urlParams.set(key, value);
    }

    // Update the URL without reloading the page
    const newUrl = urlParams.toString() ? `${url.pathname}?${urlParams.toString()}` : url.pathname;
    window.history.replaceState(null, '', newUrl);

    resolve();
  });

export const removeFalsyValues = (obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      acc[key] = value;
    }
    return acc;
  }, {});

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj))