import { useState } from 'react';
import { Colors } from '../../../../../../../../themes/colors';
import { DotsMenu } from '../../../../../../../commonComponents/DotsMenu';

export const DefaultItem = ({
  text,
  MenuOptions,
  LeadingComponent,
  itemStyles = {},
  textStyle = {},
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        backgroundColor: isHovered ? Colors.neutral_100 : 'transparent',
        height: 40,
        borderRadius: 6,
        paddingLeft: 12,
        marginLeft: 4,
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${Colors.neutral_70}`,
        ...itemStyles,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 12,
        }}
      >
        {LeadingComponent && <LeadingComponent />}
        <span
          style={{
            position: 'relative',
            fontSize: 16,
            color: Colors.neutral_600,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...textStyle,
          }}
        >
          {text}
        </span>
      </div>

      <DotsMenu>
        <MenuOptions />
      </DotsMenu>
    </div>
  );
};
