import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { fetchProfessionalPoints } from '../../../../actions/metricsAction';
import { createLoadingSelector } from '../../../../apis/selectors';

const actions = ['GET_PROFESSIONAL_POINTS'];
const loadingSelector = createLoadingSelector(actions);
export const ROWS_PER_PAGE = 20;

export const useGiftCardRedemption = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const userProfile = useSelector((state) => state.users.currentUser);
  const { response: data, totalElements: total } = useSelector(
    (state) => state.metrics.properksRedeemPoints,
  );
  const totalPages = Math.ceil(total / ROWS_PER_PAGE);

  const isLoading = useSelector((state) => loadingSelector(state));

  const fetchGiftCardRedemtion = useCallback(() => {
    dispatch(
      fetchProfessionalPoints({
        userId: userProfile?.id,
        event: 'point_redeemed',
        limit: ROWS_PER_PAGE,
        page: 1
      }),
    );
  }, [dispatch, userProfile?.id]);

  const handleTableChange = (pageTarget) => {
    setPage(pageTarget);
    dispatch(
      fetchProfessionalPoints({
        userId: userProfile?.id,
        event: 'point_redeemed',
        limit: ROWS_PER_PAGE,
        page: pageTarget
      }),
    );
  };

  return {
    data,
    page,
    total,
    totalPages,
    isLoading,
    fetchGiftCardRedemtion,
    handleTableChange,
  };
};
