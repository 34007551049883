import WarningAlertIcon from '../../../../../../../../../../images/warning_alert_outline_yellow.svg';
import { Colors } from '../../../../../../../../../../themes/colors';
import CustomPortal from '../../../../../../../../../commonComponents/CustomPortal';

export const RemoveRegionErrorPopup = ({ onClose }) => (
  <CustomPortal
    Icon={WarningAlertIcon}
    title="Unable to Remove Region from DSO"
    submitPressed={onClose}
    submitText="Close"
    hideLeftButton
  >
    <div
      style={{
        padding: '0 40px',
        width: '100%',
        marginTop: 12,
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
      }}
    >
      <span
        style={{
          display: 'flex',
          fontSize: 16,
          color: Colors.neutral_500,
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        This region cannot be removed until:
      </span>
      <ul
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
          marginTop: 8,
          color: Colors.neutral_500,
          fontSize: 16,
          marginBottom: 32,
        }}
      >
        <li>All offices are unlinked from the region</li>
        <li>
          All region-level office staff are unlinked from the region (navigate to DSO page &gt;
          Office Staff tab and filter by region)
        </li>
      </ul>
    </div>
  </CustomPortal>
);
