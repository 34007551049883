import { useMemo } from 'react';
import history from '../../../../../history';
import { Table } from '../../../../commonComponents/Table/Table';
import { style } from '../../../../commonComponents/user-column';
import { useDSOList } from './TableHeader/DsoFilter/hooks/useDSOList';

export const StaffList = ({ data, filters, isLoading, totalPages, total, handlePageChanged }) => {
  const { data: dsoList } = useDSOList();

  const dsoListMap = useMemo(
    () =>
      dsoList.reduce((acc, it) => {
        acc[it.id] = it;
        return acc;
      }, {}),
    [dsoList],
  );

  return (
    <Table
      filter={filters}
      columns={[
        {
          id: 'firstName',
          selector: ({ firstName }) => firstName,
          name: 'First Name',
          style: style.tableText,
        },
        {
          id: 'lastName',
          selector: ({ lastName }) => lastName,
          name: 'Last Name',
          style: style.tableText,
        },
        {
          id: 'email',
          selector: ({ email }) => email,
          name: 'Email',
          style: style.tableText,
        },
        {
          id: 'phoneNumber',
          selector: ({ phoneNumber }) => phoneNumber,
          name: 'Phone Number',
          style: style.tableText,
        },
        {
          id: 'dsoName',
          selector: ({ dsoID }) => dsoListMap[dsoID]?.name || '-',
          name: 'DSO Name',
          style: style.tableText,
        },
      ]}
      initialPage={filters.page}
      data={data}
      forceLoading={isLoading}
      noDataComponent="There are no Office Admins"
      isLoading={isLoading}
      totalPages={totalPages}
      paginationPerPage={filters.limit}
      paginationTotalRows={total}
      onPageChange={handlePageChanged}
      onRowClicked={(it) => history.push(`/staff/${it.id}`)}
      showColumnsToHide={false}
      showTotal={false}
    />
  );
};
