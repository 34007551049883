import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { createLoadingSelector } from '../../../../../../../../../apis/selectors';
import usePhoneHook from '../../../../../../../../../hooks/phoneHook';
import { updateUserInfo } from '../../../../../../../../../actions/userActions';

const loadingSelector = createLoadingSelector([
  'FETCH_STAFF_BY_ID',
  'FETCH_STAFF_GOTU_TERMS',
  'UPDATE_USER_INFO',
]);

export const useAccountInfo = () => {
  const dispatch = useDispatch();

  const [isEditMode, setIsEditMode] = useState(false);

  const isLoading = useSelector((state) => loadingSelector(state));
  const staff = useSelector((state) => state.staff.staffDetails);

  const [formPhoneNumber, setFormPhoneNumber, resetFormPhoneNumber] = usePhoneHook('');
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const isFormValid =
    !!formValues.firstName && !!formValues.lastName && !!formValues.email && !!formPhoneNumber;

  const handleResetFormValues = () => {
    resetFormPhoneNumber(staff.phoneNumber);
    setFormValues({
      firstName: staff.firstName,
      lastName: staff.lastName,
      email: staff.email,
    });
  };

  const handleCancelButton = () => {
    handleResetFormValues();
    setIsEditMode(false);
  };

  const handleEditButton = () => {
    setIsEditMode(true);
  };

  const handleFormValuesChanged = (key, value) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    dispatch(updateUserInfo(staff.userID, staff.id, { ...formValues, phone: formPhoneNumber }));
    setIsEditMode(false);
  };

  useEffect(() => {
    if (staff) {
      handleResetFormValues();
    }
  }, [staff]);

  return {
    formPhoneNumber,
    formValues,
    isEditMode,
    isFormValid,
    isLoading,
    staff,
    handleCancelButton,
    handleEditButton,
    handleFormPhoneNumberChanged: setFormPhoneNumber,
    handleFormValuesChanged,
    handleSubmit,
  };
};
