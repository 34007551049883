import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomPortal from '../../../../commonComponents/CustomPortal';
import { FormControl } from '../FormControl';
import { addNewAdminToDso } from '../../../../../actions/dsosActions';
import usePhoneHook from '../../../../../hooks/phoneHook';
import { Colors } from '../../../../../themes/colors';

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
};

export const CreateNewButton = ({ className, dsoId, buttonText, modalTitle }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const [formValues, setFormValues] = useState(initialState);
  const [inputContactNumber, setInputContactNumber, resetInputContactNumber] = usePhoneHook('');

  const isFormValid =
    formValues.email && formValues.firstName && formValues.lastName && inputContactNumber !== '';

  const handleFormValueChanged = (key, value) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
  };

  const handleResetForm = () => {
    resetInputContactNumber('');
    setFormValues(initialState);
  };

  const handleSubmit = () => {
    dispatch(
      addNewAdminToDso(dsoId, {
        ...formValues,
        phoneNumber: inputContactNumber,
      }),
    );
    handleResetForm();
    setShowModal(false);
  };

  return (
    <>
      <button
        className={className || `btn btn-info`}
        onClick={() => setShowModal(true)}
        type="button"
      >
        {buttonText}
      </button>

      {showModal && (
        <CustomPortal
          title={modalTitle}
          textStyle={{ textAlign: 'center' }}
          cancelText="Close"
          submitText="Create Staff Member"
          submitPressed={handleSubmit}
          cancelPressed={() => {
            setShowModal(false);
            handleResetForm();
          }}
          rightButtonDisabled={!isFormValid}
          containerStyle={{
            width: 590,
          }}
          rightButtonProps={{
            style: {
              backgroundColor: isFormValid ? Colors.primary_500 : Colors.primary_200,
              width: 230,
            },
          }}
          leftButtonProps={{
            width: 230,
          }}
        >
          <FormControl
            label="First Name:"
            value={formValues.firstName}
            onChange={(e) => handleFormValueChanged('firstName', e.target.value)}
            style={{
              justifyContent: 'center',
              maxWidth: 'auto',
              gap: 8,
            }}
            labelStyle={{
              width: 120,
              textAlign: 'right',
            }}
            inputStyle={{
              width: 340,
              height: 40,
            }}
          />
          <FormControl
            label="Last Name:"
            value={formValues.lastName}
            onChange={(e) => handleFormValueChanged('lastName', e.target.value)}
            style={{
              justifyContent: 'center',
              maxWidth: 'auto',
              gap: 8,
            }}
            labelStyle={{
              width: 120,
              textAlign: 'right',
            }}
            inputStyle={{
              width: 340,
              height: 40,
            }}
          />
          <FormControl
            label="Email:"
            value={formValues.email}
            onChange={(e) => handleFormValueChanged('email', e.target.value)}
            style={{
              justifyContent: 'center',
              maxWidth: 'auto',
              gap: 8,
            }}
            labelStyle={{
              width: 120,
              textAlign: 'right',
            }}
            inputStyle={{
              width: 340,
              height: 40,
            }}
          />
          <FormControl
            label="Phone Number:"
            value={inputContactNumber}
            onChange={(e) => setInputContactNumber(e.target.value)}
            style={{
              justifyContent: 'center',
              maxWidth: 'auto',
              gap: 8,
            }}
            labelStyle={{
              width: 120,
              textAlign: 'right',
            }}
            inputStyle={{
              width: 340,
              height: 40,
            }}
          />
        </CustomPortal>
      )}
    </>
  );
};
