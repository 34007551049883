import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createLoadingSelector } from '../../../../../../../../../../../apis/selectors';
import { ENTITY_TYPES } from '../../../../../../../../enums';
import {
  fetchOfficeAccessesByStaffId,
  getStaffDSOOffices,
} from '../../../../../../../../../../../actions/staffActions';

const loadingSelector = createLoadingSelector(['FETCH_DSO_OFFICES', 'FETCH_STAFF_OFFICE_ACCESSES']);

export const useOfficeTabPanel = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => loadingSelector(state));
  const staff = useSelector((state) => state.staff.staffDetails);
  const { data: accesses } = useSelector((state) => state.staff.officeAccesses);
  const { data: offices, total: totalOffices } = useSelector((state) => state.staff.dso.offices);

  const [formOfficeName, setFormOfficeName] = useState('');

  const accessesMap = useMemo(
    () =>
      accesses
        .filter((it) => it.entityType === ENTITY_TYPES.OFFICE)
        .reduce((acc, it) => {
          acc[it.entityID] = true;
          return acc;
        }, {}),
    [accesses],
  );

  const totalAccess = Object.keys(accessesMap).length || 0;

  const isChecked = useCallback((id) => accessesMap[id], [accessesMap]);

  useEffect(() => {
    if (staff.id) {
      dispatch(fetchOfficeAccessesByStaffId(staff.id));
    }
  }, [dispatch, staff]);

  useEffect(() => {
    const officeIds = Object.keys(accessesMap);

    if (officeIds.length > 0) {
      dispatch(getStaffDSOOffices({ officeIds }));
    }
  }, [accessesMap]);

  const officesFiltered = useMemo(() => {
    if (formOfficeName?.length === 0) {
      return offices.filter((it) => isChecked(it.id));
    }

    return offices.filter(
      (it) => isChecked(it.id) && it.name?.toLowerCase()?.includes(formOfficeName.toLowerCase()),
    );
  }, [formOfficeName, isChecked, offices]);

  return {
    formOfficeName,
    handleSearchOffice: (e) => setFormOfficeName(e.target.value),
    isChecked,
    isLoading,
    offices: officesFiltered,
    staff,
    totalAccess,
    totalOffices,
  };
};
