import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { unlinkRegionFromGroup } from '../../../../../../../../../../actions/dsosActions';
import { Colors } from '../../../../../../../../../../themes/colors';
import CustomPortal from '../../../../../../../../../commonComponents/CustomPortal';
import { RadioOption } from '../../../../../../../../../commonComponents/RadioOption';
import { useGroupsLinkedToRegion } from './hooks';

export const GroupsLinkedToRegionPopup = ({ region, onClose }) => {
  const { groups, isLoading } = useGroupsLinkedToRegion(region.id);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleOnClose = () => {
    setSelectedGroup(null);
    onClose();
  };

  const handleConfirmSelection = () => {
    setShowConfirmation(true);
  };

  return !showConfirmation ? (
    <CustomPortal
      title="Groups Linked to Region"
      textStyle={{ textAlign: 'center' }}
      cancelText="Go Back"
      submitText="Select"
      submitPressed={handleConfirmSelection}
      cancelPressed={handleOnClose}
      rightButtonDisabled={!selectedGroup}
    >
      <div
        style={{
          padding: '0 40px',
          width: '100%',
          marginTop: 12,
        }}
      >
        <span
          style={{
            display: 'flex',
            fontSize: 16,
            color: Colors.neutral_600,
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          Below there’s a list of groups linked to this region.
          <br />
          Select a group to unlink.
        </span>

        {isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 24,
                width: 24,
              }}
            />
          </div>
        )}

        {!isLoading && groups.length === 0 && (
          <div
            style={{
              fontSize: 16,
              color: Colors.neutral_500,
              textAlign: 'center',
              marginTop: 20,
              marginBottom: 24,
            }}
          >
            There are no groups linked to this region.
          </div>
        )}

        {!isLoading && groups.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
              padding: '0 32px',
              minHeight: 50,
              maxHeight: 200,
              marginTop: 24,
              marginBottom: 32,
              overflowY: 'auto',
            }}
          >
            {groups.map((group) => (
              <RadioOption
                key={group.id}
                text={group.name}
                checked={selectedGroup?.id === group.id}
                onClick={() => setSelectedGroup(group)}
              />
            ))}
          </div>
        )}
      </div>
    </CustomPortal>
  ) : (
    <ConfirmationPopup
      region={region}
      group={selectedGroup}
      onClose={() => setShowConfirmation(false)}
      onConfirm={handleOnClose}
    />
  );
};

const ConfirmationPopup = ({ region, group, onClose, onConfirm }) => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(unlinkRegionFromGroup({ regionId: region.id, groupId: group.id }));
    onConfirm();
  };

  return (
    <CustomPortal
      title="Unlink Region from a Group"
      textStyle={{ textAlign: 'center' }}
      cancelText="Go Back"
      submitText="Unlink Region"
      submitPressed={handleSubmit}
      cancelPressed={onClose}
    >
      <div
        style={{
          padding: '0 40px',
          width: '100%',
          marginTop: 12,
        }}
      >
        <span
          style={{
            display: 'flex',
            fontSize: 16,
            color: Colors.neutral_600,
            textAlign: 'center',
          }}
        >
          Are you sure you would like to unlink {region.name} from {group.name}? By completing this
          action:
        </span>

        <ul
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            marginTop: 24,
            color: Colors.neutral_600,
            fontSize: 16,
            marginBottom: 32,
          }}
        >
          <li>This region will be removed from this group.</li>
          <li>
            Any group-level office staff will lose access to this region and any associated offices.
          </li>
        </ul>
      </div>
    </CustomPortal>
  );
};
