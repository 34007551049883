import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabViewContainer } from '../TabViewContainer';
import { useAvailabilityList } from './hooks/useAvailabilityList';
import history from '../../../history';
import { Spinner } from '../Spinner';
import { Filter } from './components/Filters';
import { Table } from '../Table/Table';
import { availabilityColumns, availabilityConditionalRowStyles } from './utils';
import { getAllProfessions } from '../../../actions/jobsAction';
import { CustomHeader } from './components/CustomHeader';
import { createLoadingSelector } from '../../../apis/selectors';

const actions = ['GET_ALL_PROFESSIONS'];
const loadingSelector = createLoadingSelector(actions);

export const AvailabilityList = () => {
  const dispatch = useDispatch();
  const initRef = useRef(false);
  const allProfessions = useSelector((state) => state.jobs.allProfessions);
  const isInitFetching = useSelector((state) => loadingSelector(state));

  const { data, total, isLoading, filter, fetchAvailabilities, handleFilterChange, handleOnSort } =
    useAvailabilityList();

  const getColumn = () => availabilityColumns();

  useEffect(() => {
    if (!allProfessions) {
      dispatch(getAllProfessions());
    }
  }, [dispatch, allProfessions]);

  useEffect(() => {
    if (!initRef.current) {
      fetchAvailabilities();
      initRef.current = true;
    }
  }, []);

  if (!initRef.current || isInitFetching) {
    return <Spinner />;
  }

  return (
    <TabViewContainer title="Availability" variant="card" style={{ width: '90%' }}>
      <div style={{ alignSelf: 'flex-start', marginBottom: 20 }}>
        <Filter
          allProfessions={allProfessions}
          filter={filter}
          handleFilterChange={handleFilterChange}
        />
      </div>
      <Table
        CustomHeaderComponent={CustomHeader}
        keyField="availabilityList.professionalId"
        columns={getColumn()}
        data={data}
        noDataComponent="There are no availabilities"
        isLoading={isLoading}
        onRowClicked={(row) => history.push(`/user/hygienist/${row.professionalId}`)}
        onSort={handleOnSort}
        paginationTotalRows={!isLoading ? total : undefined}
        forceLoading
        conditionalRowStyles={availabilityConditionalRowStyles}
      />
    </TabViewContainer>
  );
};
