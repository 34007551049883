import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelReferral, triggerPayoutReferral } from '../../../actions/referralAction';
import Styles from '../../../themes/style.module.scss';
import CustomPortal from '../../commonComponents/CustomPortal';
import removeIcon from '../../../images/close-cross.png';
import { UserType } from '../../../enums/UserType';
import { getShouldEnableReferralPayoutButton } from '../../../growthbook';

export const ActionComponent = ({ row }) => {
  const dispatch = useDispatch();
  const isPayoutUser = useSelector((state) => state.users.isPayoutUser);
  const [payoutId, setPayoutId] = useState(null);
  const [showPayoutPopup, setShowPayoutPopup] = useState(false);
  const [cancelTarget, setCancelTarget] = useState(null);
  const [showCancelReferralPopup, setShowCancelReferralPopup] = useState(false);
  const referrals = useSelector((state) => state.referral.referrals);

  const shouldDisplayPayoutButton =
    getShouldEnableReferralPayoutButton() && !!isPayoutUser && row?.status !== 'finished';

  const shouldDisplayCancelButton =
    row?.sender?.user_type === UserType.DNT &&
    row?.receiver?.user_type === UserType.DNT &&
    !row?.isCancelled;

  const isEmptyAction = !shouldDisplayPayoutButton && !shouldDisplayCancelButton;

  const handlePayoutClick = (id) => {
    dispatch(triggerPayoutReferral(id));
  };

  const prepareText = () => {
    const referral = referrals.find((r) => r.id === payoutId);
    return (
      <div>
        <table>
          <tr>
            <th style={{ minWidth: 30, padding: 20, borderBottom: '1px solid' }}>Name</th>
            <th style={{ minWidth: 30, padding: 20, borderBottom: '1px solid' }}>Amount</th>
          </tr>
          <tr>
            <td style={{ minWidth: 30, padding: '10px 20px 0px 20px' }}>
              {row?.sender?.user_type === 'HYG' ? row?.sender?.full_name : row.sender?.office_name}
            </td>
            <td style={{ minWidth: 30, padding: '10px 20px 0px 20px' }}>
              ${referral.sender.amount}
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: 30, padding: '5px 20px 0px 20px' }}>
              {row?.receiver?.user_type === 'HYG'
                ? row?.receiver?.full_name
                : row.receiver?.office_name}
            </td>
            <td style={{ minWidth: 30, padding: '5px 20px 0px 20px' }}>
              ${referral.receiver.amount}
            </td>
          </tr>
        </table>
      </div>
    );
  };

  return (
    <>
      <div
        className="col"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 15,
        }}
      >
        {shouldDisplayPayoutButton && (
          <button
            type="button"
            className={Styles.create_job_btn}
            style={{ margin: 4 }}
            onClick={() => {
              setPayoutId(row.id);
              setShowPayoutPopup(true);
            }}
          >
            Payout
          </button>
        )}

        {shouldDisplayCancelButton && (
          <img
            onClick={() => {
              setShowCancelReferralPopup(true);
              setCancelTarget(row);
            }}
            src={removeIcon}
            style={{ width: 15, height: 15, cursor: 'pointer' }}
            alt="remove_icon"
          />
        )}

        {isEmptyAction && '-'}
      </div>

      {showPayoutPopup && payoutId ? (
        <CustomPortal
          title="Are you sure to payout for this referral?"
          text={prepareText(payoutId)}
          submitText="Payout"
          cancelText="Cancel"
          submitPressed={() => {
            handlePayoutClick(payoutId);
            setShowPayoutPopup(false);
            setPayoutId(null);
          }}
          cancelPressed={() => {
            setShowPayoutPopup(false);
            setPayoutId(null);
          }}
        />
      ) : null}

      {showCancelReferralPopup && !!cancelTarget && (
        <CustomPortal
          title="Cancel Referral"
          // eslint-disable-next-line max-len
          text={`Are you sure you would like to cancel this referral between ${cancelTarget?.sender?.office_name} and ${cancelTarget?.receiver?.office_name}?`}
          textStyle={{ textAlign: 'center' }}
          submitText="Cancel Referral"
          cancelText="Back"
          leftButtonProps={{ style: { backgroundColor: '#808080' } }}
          rightButtonProps={{ style: { backgroundColor: '#E74C3C' } }}
          submitPressed={() => {
            dispatch(cancelReferral(cancelTarget?.id));
            setShowCancelReferralPopup(false);
            setCancelTarget(null);
          }}
          cancelPressed={() => {
            setShowCancelReferralPopup(false);
            setCancelTarget(null);
          }}
        />
      )}
    </>
  );
};
