import { Colors } from '../../../../../../../../../../../../themes/colors';

export const OverviewHeader = ({ style, totalOffices = 0 }) => (
  <header
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      ...style,
    }}
  >
    <article
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        flexBasis: '55%',
      }}
    >
      <span
        style={{
          color: Colors.neutral_800,
          fontSize: 16,
          fontWeight: 700,
        }}
      >
        Office Names
      </span>
      <span
        style={{
          color: Colors.neutral_500,
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        Linked Offices ({totalOffices})
      </span>
    </article>
    <article
      style={{
        color: Colors.neutral_800,
        fontSize: 16,
        fontWeight: 700,
        flexBasis: '35%',
      }}
    >
      Region Name
    </article>
    <article style={{ flexBasis: '10%' }} />
  </header>
);
