import { Divider } from 'antd';
import { Colors } from '../../../../../themes/colors';
import { TabViewContainer } from '../../../../commonComponents/TabViewContainer';
import { Spinner } from '../../../../commonComponents/Spinner';

export const OfficeDSOInformation = ({ groups, isLoading, region }) => (
  <TabViewContainer
    title="Groups & Regions"
    className="col-6"
    variant="card"
    style={{
      marginInline: 0,
      minWidth: 'auto',
      paddingInline: 24,
    }}
  >
    {isLoading ? (
      <Spinner />
    ) : (
      <>
        <section>
          <h3
            style={{
              color: Colors.neutral_600,
              display: 'block',
              fontSize: 16,
              fontWeight: 700,
              marginTop: 10,
            }}
          >
            Group(s)
          </h3>
          {!groups || groups?.length === 0 ? (
            <p
              style={{
                color: Colors.neutral_500,
                fontSize: 14,
                fontWeight: 600,
                marginBlock: 20,
                textAlign: 'center',
              }}
            >
              There are no groups available.
            </p>
          ) : (
            <ul
              style={{
                listStylePosition: 'inside',
                paddingInlineStart: 8,
              }}
            >
              {groups?.map(({ id, name }) => (
                <li key={id}>{name}</li>
              ))}
            </ul>
          )}
        </section>
        <Divider />
        <section>
          <h3
            style={{
              color: Colors.neutral_600,
              display: 'block',
              fontSize: 16,
              fontWeight: 700,
              marginTop: 10,
            }}
          >
            Region(s)
          </h3>
          {!region ? (
            <p
              style={{
                color: Colors.neutral_500,
                fontSize: 14,
                fontWeight: 600,
                marginBlock: 20,
                textAlign: 'center',
              }}
            >
              There are no regions available.
            </p>
          ) : (
            <ul
              style={{
                listStylePosition: 'inside',
                paddingInlineStart: 8,
              }}
            >
              <li>{region?.name}</li>
            </ul>
          )}
        </section>
      </>
    )}
  </TabViewContainer>
);
