import { useCallback } from 'react';
import { Colors } from '../../../../../../../../../../themes/colors';
import { Card } from '../../../../../../../../../commonComponents/Card';
import { List } from '../../../../../../../../../commonComponents/CardList';
import { Checkbox } from '../../../../../../../../../commonComponents/Checkbox';
import { ListItem } from '../../../../../../../../../commonComponents/ListItem';
import { ENTITY_TYPES } from '../../../../../../../enums';
import { useManageStaffAccess } from '../../hooks';
import { AccessHeader } from '../Header';
import {
  UpdatePermissionConfirmationPopup,
  UpdatePermissionConfirmationPopupMode,
  useConfirmationPopup,
} from '../UpdatePermissionConfirmationPopup';
import { useRegionTabPanel } from './hooks/useRegionTabPanel';
import { SearchAccess } from '../SearchAccess';

export const RegionTabPanel = () => {
  const { onCloseModal, onOpenModal, state: popup } = useConfirmationPopup();
  const { isSubmitting, handleAddAccess, handleDeleteAccess } = useManageStaffAccess(
    ENTITY_TYPES.REGION,
  );
  const { isLoading, regions, staff, totalAccess, totalRegions, isChecked, onSearchRegion } =
    useRegionTabPanel();

  const handleConfirm = useCallback(() => {
    const { item, checked } = popup;

    if (checked) {
      handleDeleteAccess(item.id);
    } else {
      handleAddAccess(item.id);
    }

    onCloseModal();
  }, [popup, onCloseModal, handleDeleteAccess, handleAddAccess]);

  return (
    <div>
      <AccessHeader
        title="Region Name"
        linkedText={`Linked Regions (${totalAccess})`}
        total={totalRegions}
      />
      <SearchAccess placeholder="Type Region Name" onSearch={onSearchRegion} />
      <Card style={{ border: 'none' }}>
        <List
          emptyText="There are no regions linked to this DSO."
          data={regions}
          isLoading={isLoading}
          renderItem={({ item }) => {
            const isSelected = isChecked(item.id);

            return (
              <ListItem
                LeadingComponent={() => (
                  <Checkbox
                    checked={isSelected}
                    disabled={isSubmitting || isLoading}
                    onChanged={() => onOpenModal(item, isSelected)}
                    checkboxStyle={{
                      width: 18,
                      height: 18,
                      borderRadius: 4,
                      border: `2px solid ${Colors.secondary_500}`,
                      backgroundColor: isSelected ? Colors.secondary_500 : Colors.white,
                      margin: 0,
                    }}
                  />
                )}
                itemStyle={{ paddingInline: 24, gap: 8 }}
                textStyle={{ lineHeight: 'normal' }}
                text={item.name}
              />
            );
          }}
          onScroll={() => {}}
          style={{
            marginInline: 0,
          }}
        />
      </Card>
      {popup.show && (
        <UpdatePermissionConfirmationPopup
          data={{
            access: popup.item.name,
            staffFirstName: staff.firstName,
            staffLastName: staff.lastName,
          }}
          onClose={onCloseModal}
          onConfirm={handleConfirm}
          mode={
            popup.checked
              ? UpdatePermissionConfirmationPopupMode.REMOVE
              : UpdatePermissionConfirmationPopupMode.ADD
          }
        />
      )}
    </div>
  );
};
