import { useSelector } from 'react-redux';
import { addNewRegionToDso } from '../../../../../../../../../actions/dsosActions';
import { Header } from '../../../../../../../../commonComponents/CardList';
import { CreateNewButton } from '../../CreateNewButton';

export const RegionsHeader = () => {
  const { selectedGroupId } = useSelector((state) => state.dsos.orgStructure);

  return (
    <Header title="Regions">
      {!selectedGroupId && <CreateNewButton action={addNewRegionToDso} label="Region" />}
    </Header>
  );
};
