import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePaginatedQuery } from '../../../../../../../../../../../DsoGroupDetails/components/tabs/OrgStructureTab/hooks/usePaginatedQuery';
import { fetchOfficesByDsoId } from '../../../../../../../../../../../../../services/dsos';
import { useManageStaffAccess } from '../../../../../hooks';
import { ENTITY_TYPES } from '../../../../../../../../../../enums';

export const useLinkOfficeForm = () => {
  const [formValues, setFormValues] = useState({
    officeId: '',
  });

  const accesses = useSelector((state) =>
    state.staff.officeAccesses?.data
      ?.filter((it) => it.entityType === ENTITY_TYPES.OFFICE)
      ?.reduce((acc, it) => {
        acc[it.entityID] = true;
        return acc;
      }, {}),
  );
  const staff = useSelector((state) => state.staff.staffDetails);

  const { handleAddAccess } = useManageStaffAccess(ENTITY_TYPES.OFFICE);
  const { onScroll, onSearch, isFetchingFirstPage, isFetchingNextPage, results } =
    usePaginatedQuery({
      queryKey: 'officeList',
      fetchFunction: fetchOfficesByDsoId,
      dsoId: staff.dsoID || undefined,
      searchParam: 'officeName',
    });

  const filteredResults = useMemo(
    () => results.filter((it) => !accesses[it.id]),
    [results, accesses],
  );

  const handleFormSubmit = () => {
    handleAddAccess(formValues.officeId);
  };

  const handleFormValueChanged = (key, value) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return {
    formValues,
    isFetchingFirstPage,
    isFetchingNextPage,
    results: filteredResults,
    staff,
    handleFormSubmit,
    handleFormValueChanged,
    onScroll,
    onSearch,
  };
};
