import { useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { InputSearch } from './InputSearch';

export const InputSearchDebounce = ({ style, defaultValue, placeholder, onSearch }) => {
  const [formSearch, setFormSearch] = useState(defaultValue);

  const debounceOnSearch = useDebounce((payload) => {
    onSearch(payload);
  }, 750);

  const handleSearch = (e) => {
    const { value } = e.target;

    setFormSearch(value);
    debounceOnSearch(value);
  };

  return (
    <InputSearch
      placeholder={placeholder}
      style={style}
      value={formSearch}
      onChange={handleSearch}
    />
  );
};
