import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENTITY_TYPES } from '../../../../../../../../enums';
import { createLoadingSelector } from '../../../../../../../../../../../apis/selectors';
import { getStaffDSOGroups } from '../../../../../../../../../../../actions/staffActions';

const loadingSelector = createLoadingSelector(['FETCH_DSO_GROUPS']);

export const useGroupTabPanel = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => loadingSelector(state));
  const staff = useSelector((state) => state.staff.staffDetails);
  const { data: accesses } = useSelector((state) => state.staff.officeAccesses);
  const { data: groups, total: totalGroups } = useSelector((state) => state.staff.dso.groups);

  const totalAccess = useMemo(
    () => accesses.filter((access) => access.entityType === ENTITY_TYPES.GROUP)?.length || 0,
    [accesses],
  );

  const onSearch = (terms) => {
    dispatch(
      getStaffDSOGroups({
        dsoId: staff.dsoID,
        filters: {
          name: terms,
          limit: 50,
        },
      }),
    );
  };
  const isChecked = useCallback(
    (id) => accesses?.some((access) => access.entityID === id),
    [accesses],
  );

  return {
    groups,
    isLoading,
    staff,
    totalAccess,
    totalGroups,
    isChecked,
    onSearch,
  };
};
