import { useDispatch, useSelector } from 'react-redux';
import {
    clearOrgStructure,
    fetchDsoGroupList,
    fetchDsoOffices,
    fetchDsoRegionList,
} from '../../../../../../../../../actions/dsosActions';
import { OrderBy } from '../../../../../../../../../enums/Sorting';

export const useGroupsSubHeader = (dsoId) => {
  const dispatch = useDispatch();
  const {
    total,
    filters: { orderBy },
  } = useSelector((state) => state.dsos.orgStructure.groupList);

  const onOrderBy = () => {
    dispatch(clearOrgStructure());
    Promise.all([
      dispatch(
        fetchDsoGroupList(dsoId, {
          page: 1,
          limit: 15,
          orderBy: orderBy === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC,
        }),
      ),
      dispatch(fetchDsoRegionList(dsoId, { page: 1, limit: 100 })),
      dispatch(fetchDsoOffices(dsoId, { page: 1, limit: 15 })),
    ]);
  };

  return {
    orderBy,
    onOrderBy,
    total,
  };
};
