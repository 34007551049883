import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

export const usePaginatedQuery = ({ queryKey, fetchFunction, dsoId, searchParam = 'name' }) => {
  const [nameFilter, setNameFilter] = useState('');
  const queryClient = useQueryClient();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: [queryKey, dsoId],
    queryFn: async ({ pageParam = 1 }) => {
      const updatedFilters = {
        page: nameFilter ? 1 : pageParam, // Reset page if searching
        limit: nameFilter ? 10 : 15, // Smaller limit when searching
        [searchParam]: nameFilter || undefined, // Dynamic search param
      };
      return fetchFunction({ dsoId, filters: updatedFilters });
    },
    getNextPageParam: (lastPage, allPages) => {
      const loadedItems = allPages.flatMap((page) => page.data).length;
      return loadedItems < lastPage.total ? allPages.length + 1 : undefined;
    },
    staleTime: 1 * 60 * 1000, // Data is fresh for 1 minute
    cacheTime: 1 * 60 * 1000, // Cache lasts for 1 minute
  });

  const onScroll = useCallback(
    (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      if (
        scrollHeight - scrollTop <= clientHeight + 8 &&
        hasNextPage &&
        !isFetchingNextPage &&
        !nameFilter // Prevent pagination when searching
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage, nameFilter],
  );

  const onSearch = (searchTerm) => {
    setNameFilter(searchTerm);
  };

  // Check cache before refetching
  useEffect(() => {
    const cachedData = queryClient.getQueryData([queryKey, dsoId, nameFilter]);
    if (!cachedData) {
      refetch();
    }
  }, [dsoId, nameFilter, queryClient, refetch, queryKey]);

  const results = data?.pages.flatMap((page) => page.data) || [];

  return {
    results,
    isFetchingFirstPage: isRefetching,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    onScroll,
    onSearch,
  };
};
