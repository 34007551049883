import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomPortal from '../../../../../../../commonComponents/CustomPortal';
import { InputSelect } from '../../../../../../../commonComponents/InputSelect/InputSelect';

export const LinkEntityPopup = ({
  dsoId,
  items,
  title,
  Description,
  submitText,
  inputSelectLabel,
  fetchHook,
  action,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { onSearch, onScroll, results, isFetchingNextPage, isFetchingFirstPage } = fetchHook({
    dsoId,
  });

  const [selectedEntity, setSelectedEntity] = useState();

  const handleSubmit = () => {
    const entity = results.find((entity) => entity.id === selectedEntity);
    dispatch(action({ items, entity }));
    onClose();
  };

  return (
    <CustomPortal
      title={title}
      textStyle={{ textAlign: 'center' }}
      cancelText="Close"
      submitText={submitText}
      submitPressed={handleSubmit}
      cancelPressed={onClose}
      rightButtonDisabled={!selectedEntity}
    >
      <div>
        <Description />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              maxWidth: 400,
              display: 'flex',
              alignItems: 'center',
              marginTop: 20,
              marginBottom: 40,
              gap: 12,
            }}
          >
            <b
              style={{
                fontSize: 16,
                textWrap: 'nowrap',
              }}
            >
              {inputSelectLabel}
            </b>
            <InputSelect
              value={selectedEntity}
              setValue={setSelectedEntity}
              options={results.map((item) => ({
                value: item.id,
                title: item.name,
              }))}
              isSearchVisible
              onSearch={onSearch}
              debounceTime={750}
              placeholder="Name"
              onScroll={onScroll}
              isFetchingFirstPage={isFetchingFirstPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          </div>
        </div>
      </div>
    </CustomPortal>
  );
};
