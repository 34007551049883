import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDsoOffices } from '../../../../../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../../../../../apis/selectors';

const actions = ['FETCH_DSO_OFFICE_LIST', 'GET_GROUP_REGIONS_AND_OFFICES'];
const loadingSelector = createLoadingSelector(actions);

export const useOfficesCard = (dsoId) => {
  const dispatch = useDispatch();
  const {
    filters,
    data,
    total,
    isActiveRequest,
    isFinalPage,
    dsoId: stateDsoId,
  } = useSelector((state) => state.dsos.orgStructure.officeList);
  const isLoading = useSelector((state) => loadingSelector(state));

  const handleScrollList = useCallback(
    (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      const tableScrollHeight = scrollHeight - scrollTop - 20;
      if (tableScrollHeight <= clientHeight && !isActiveRequest && !isFinalPage) {
        dispatch(
          fetchDsoOffices(dsoId, {
            page: filters.page + 1,
            orderBy: filters.orderBy,
            officeName: filters.officeName,
          }),
        );
      }
    },
    [
      dispatch,
      filters.page,
      isActiveRequest,
      isFinalPage,
      dsoId,
      filters.orderBy,
      filters.officeName,
    ],
  );

  useEffect(() => {
    dispatch(fetchDsoOffices(dsoId, { page: 1, limit: 15 }));
  }, [dispatch, dsoId]);

  return {
    data: stateDsoId === dsoId ? data : [],
    total,
    isInitialLoading: !data.length && isLoading,
    isLoading: isActiveRequest,
    handleScrollList,
  };
};
