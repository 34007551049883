import { useState } from 'react';
import { LinkIcon } from '../../../../../../../../../../images/icons';
import { MenuItem } from '../../../../../../../../../commonComponents/DotsMenu';
import { LinkOfficesToRegionPopup } from './LinkOfficesToRegionPopup';

export const LinkOfficesToRegionMenuItem = ({ dsoId, items }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MenuItem
        Icon={() => <LinkIcon />}
        text="Link Office to Region"
        onClick={() => setShowModal(true)}
      />

      {showModal && (
        <LinkOfficesToRegionPopup
          dsoId={dsoId}
          offices={items}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
