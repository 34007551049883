import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import useDebounce from '../../../hooks/useDebounce';
import { ChevronDownIcon } from '../../../images/icons';
import { Colors } from '../../../themes/colors';

export const InputSelect = ({
  autoComplete,
  value: inputValue,
  setValue,
  options = [],
  onSearch,
  disabled = false,
  isFetchingFirstPage = false,
  isFetchingNextPage = false,
  onScroll = () => {},
  placeholder = 'Select',
  debounceTime = 300,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [isInputDirty, setInputDirty] = useState(false);
  const [searchTerm, setSearchTerm] = useState(
    inputValue ? options.find((opt) => opt.value === inputValue)?.title || '' : '',
  );
  const [hoveredItem, setHoveredItem] = useState(null);
  const inputRef = useRef(null);
  const optionsRef = useRef(null);

  const isShowPopover = useMemo(() => {
    if (autoComplete) {
      return showOptions && searchTerm?.length > 0 && isInputDirty;
    }

    return showOptions;
  }, [autoComplete, isInputDirty, searchTerm, showOptions]);

  // Handle click outside to close dropdown
  const handleClickOutside = useCallback((event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    if (showOptions && inputValue) {
      // INFO: the timeout is necessary to wait for the options to be rendered
      setTimeout(() => {
        const selectedOption = optionsRef.current?.querySelector(`[data-value="${inputValue}"]`);
        if (selectedOption && optionsRef.current) {
          const threshold = optionsRef.current.clientHeight / 2;
          optionsRef.current.scrollTo({
            top: selectedOption.offsetTop - threshold,
            behavior: 'smooth',
          });
        }
      }, 250);
    }
  }, [showOptions, inputValue]);

  // Use debounced search function
  const debouncedSearch = useDebounce((query) => {
    if (onSearch) {
      onSearch(query);
      setInputDirty(true);
    }
  }, debounceTime);

  // Handle user input and trigger debounced search
  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const getCursorLayout = () => {
    if (disabled) {
      return 'not-allowed';
    }
    if (onSearch) {
      return 'text';
    }
    return 'pointer';
  };

  return (
    <div ref={inputRef} style={{ position: 'relative', width: '100%' }}>
      <div
        className="select-title"
        style={{
          alignItems: 'center',
          width: '100%',
          height: 42,
          backgroundColor: Colors.neutral_50,
          border: 'none',
          borderRadius: 5,
          appearance: 'none',
          ...(disabled && { backgroundColor: Colors.neutral_150 }),
        }}
      >
        <input
          placeholder={placeholder}
          style={{
            fontSize: 16,
            padding: 0,
            width: '100%',
            boxShadow: 'none',
            color: Colors.neutral_500,
            background: 'transparent',
            cursor: getCursorLayout(),
          }}
          readOnly={!onSearch}
          value={searchTerm}
          onChange={handleInputChange}
          onClick={() => !onSearch && setShowOptions(true)}
          onFocus={() => setShowOptions(true)}
          disabled={disabled}
        />
        {!autoComplete && (
          <button
            type="button"
            disabled={disabled}
            style={{
              all: 'unset',
              width: 24,
              height: 24,
              cursor: disabled ? 'not-allowed' : 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => setShowOptions((prev) => !prev)}
          >
            <ChevronDownIcon />
          </button>
        )}
      </div>

      <div
        ref={optionsRef}
        className={`select-options ${isShowPopover ? 'open' : 'closed'}`}
        style={{
          display: isShowPopover ? 'flex' : 'none',
          flexDirection: 'column',
          position: 'absolute',
          overflow: 'auto',
          maxHeight: 240,
          top: 44,
          zIndex: 4,
          minWidth: '100%',
          whiteSpace: 'nowrap',
          backgroundColor: Colors.neutral_150,
          borderRadius: 10,
          boxShadow: '0px 6px 10px 2px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
          padding: '10px 0px',
          transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out',
          opacity: isShowPopover ? 1 : 0,
          visibility: isShowPopover ? 'visible' : 'hidden',
        }}
        onScroll={onScroll}
      >
        {options.length === 0 && !isFetchingFirstPage && !isFetchingNextPage && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 14,
              color: Colors.neutral_400,
            }}
          >
            No results found
          </div>
        )}
        {!isFetchingFirstPage &&
          [...options]?.map(({ title, value }) => (
            <div
              data-value={value}
              key={value}
              onMouseEnter={() => setHoveredItem(value)}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={() => {
                setShowOptions(false);
                setValue(value);
                setSearchTerm(title); // Set input to selected option
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: hoveredItem === value ? Colors.secondary_500 : null,
                borderRadius: 8,
                margin: '0px 10px',
                padding: '10px 15px',
                cursor: 'pointer',
              }}
            >
              <span
                style={{
                  fontFamily: 'Nunito',
                  fontWeight: inputValue === value || hoveredItem === value ? 'bold' : 'normal',
                  fontSize: 16,
                  color: hoveredItem === value ? Colors.white : Colors.neutral_600,
                  paddingRight: 20,
                }}
              >
                {title}
              </span>
            </div>
          ))}
        {(isFetchingFirstPage || isFetchingNextPage) && (
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 12, marginBottom: 12 }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 20,
                width: 20,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
