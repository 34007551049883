import { useSelector } from 'react-redux';
import { addNewGroupToDso } from '../../../../../../../../../actions/dsosActions';
import { Header } from '../../../../../../../../commonComponents/CardList';
import { CreateNewButton } from '../../CreateNewButton';

export const GroupsHeader = () => {
  const { selectedGroupId } = useSelector((state) => state.dsos.orgStructure);

  return (
    <Header title="Groups">
      {!selectedGroupId && <CreateNewButton action={addNewGroupToDso} label="Group" />}
    </Header>
  );
};
