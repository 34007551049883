import { assignOfficesToRegion } from '../../../../../../../../../../actions/dsosActions';
import { Colors } from '../../../../../../../../../../themes/colors';
import { useLinkRegionToGroup } from '../../../GroupsCard/components/LinkRegionToGroupMenuItem/hooks';
import { LinkEntityPopup } from '../../../LinkEntityTo';

export const LinkOfficesToRegionPopup = ({ dsoId, offices, onClose }) => {
  const isMultipleOffices = offices.length > 1;
  const officeTitle = `Office Name${isMultipleOffices ? 's' : ''}:`;
  const descriptionText = `Select a region to link ${
    isMultipleOffices ? `these ${offices.length} offices` : 'this office'
  } to.`;
  const submitText = `Link Office${isMultipleOffices ? 's' : ''}`;
  const title = `Link Office${isMultipleOffices ? 's' : ''} to Region`;

  return (
    <LinkEntityPopup
      Description={() => (
        <div style={styles.container}>
          <div style={styles.description}>{descriptionText}</div>
          <div style={styles.officeListContainer}>
            <b style={styles.officeTitle}>{officeTitle}</b>
            <div style={styles.officeList}>
              {offices.map((office) => (
                <div key={office.id} style={styles.officeItem}>
                  <div>•</div>
                  <span style={styles.officeName}>{office.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      inputSelectLabel="Select Region:"
      submitText={submitText}
      dsoId={dsoId}
      items={offices}
      title={title}
      fetchHook={useLinkRegionToGroup}
      action={({ items, entity }) => assignOfficesToRegion({ regionId: entity.id, offices: items })}
      onClose={onClose}
    />
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 360,
  },
  description: {
    fontSize: 16,
    marginTop: 12,
    marginBottom: 32,
    textAlign: 'center',
  },
  officeListContainer: {
    display: 'flex',
    gap: 16,
    fontSize: 16,
    flex: 1,
  },
  officeTitle: {
    width: 110,
  },
  officeList: {
    flex: 1,
    maxHeight: 150,
    overflowY: 'auto',
  },
  officeItem: {
    display: 'flex',
    gap: 8,
    flex: 1,
    color: Colors.neutral_600,
  },
  officeName: {
    flex: 1,
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
};
