import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeOfficeFromDSO } from '../../../../../../actions/dsosActions';
import { Colors } from '../../../../../../themes/colors';
import CustomPortal from '../../../../../commonComponents/CustomPortal';

export const UnlinkFromDsoButton = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const office = useSelector((state) => state.dsos.office);

  const onButtonClick = () => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const handleSubmit = () => {
    dispatch(removeOfficeFromDSO({ dsoId: office.dso.id, officeId: office.id }));
    onClose();
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-danger"
        style={{ width: '140px' }}
        onClick={onButtonClick}
      >
        Unlink from DSO
      </button>

      {showModal && (
        <CustomPortal
          title="Remove Office from DSO"
          textStyle={{ textAlign: 'center' }}
          cancelText="Go Back"
          submitText="Remove Office"
          submitPressed={handleSubmit}
          cancelPressed={onClose}
        >
          <div
            style={{
              padding: '0 40px',
              width: '100%',
              marginTop: 12,
            }}
          >
            <span
              style={{
                display: 'flex',
                fontSize: 16,
                color: Colors.neutral_500,
                textAlign: 'center',
              }}
            >
              Are you sure you would like to remove {office.name} from {office.dso?.name}&apos;s
              organization structure?
            </span>
            <br />
          </div>
        </CustomPortal>
      )}
    </>
  );
};
