import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeGroupFromDSO } from '../../../../../../../../../../actions/dsosActions';
import { TrashIcon } from '../../../../../../../../../../images/icons';
import { Colors } from '../../../../../../../../../../themes/colors';
import CustomPortal from '../../../../../../../../../commonComponents/CustomPortal';
import { MenuItem } from '../../../../../../../../../commonComponents/DotsMenu';

export const RemoveGroupMenuItem = ({ item }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const dsoGroupDetails = useSelector((state) => state.dsos.dsoGroupDetails);

  const onClose = () => {
    setShowModal(false);
  };

  const handleSubmit = () => {
    dispatch(removeGroupFromDSO({ dsoId: dsoGroupDetails.id, groupId: item.id }));
    setShowModal(false);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MenuItem Icon={() => <TrashIcon />} text="Remove Group" onClick={() => setShowModal(true)} />

      {showModal && (
        <CustomPortal
          title="Remove Group from DSO"
          textStyle={{ textAlign: 'center' }}
          cancelText="Go Back"
          submitText="Remove Group"
          submitPressed={handleSubmit}
          cancelPressed={onClose}
        >
          <div
            style={{
              padding: '0 40px',
              width: '100%',
              marginTop: 12,
            }}
          >
            <span
              style={{
                display: 'flex',
                fontSize: 16,
                color: Colors.neutral_500,
                textAlign: 'center',
              }}
            >
              Are you sure you would like to remove {item.name} from {dsoGroupDetails.name}’s
              organization structure?
            </span>
            <br />

            <span
              style={{
                display: 'flex',
                fontSize: 16,
                color: Colors.neutral_500,
              }}
            >
              Please ensure that all group-level office staff are unlinked from the group (navigate
              to DSO page {'>'} Office Staff tab and filter by group).
            </span>
            <br />
          </div>
        </CustomPortal>
      )}
    </div>
  );
};
