import { useCallback } from 'react';
import { AccessHeader } from '../Header';
import {
  UpdatePermissionConfirmationPopup,
  UpdatePermissionConfirmationPopupMode,
  useConfirmationPopup,
} from '../UpdatePermissionConfirmationPopup';
import { useOfficeTabPanel } from './hooks/useOfficeTabPanel';
import { Card } from '../../../../../../../../../commonComponents/Card';
import { List } from '../../../../../../../../../commonComponents/CardList';
import { ListItem } from '../../../../../../../../../commonComponents/ListItem';
import { Checkbox } from '../../../../../../../../../commonComponents/Checkbox';
import { Colors } from '../../../../../../../../../../themes/colors';
import { ChevronRightIcon } from '../../../../../../../../../../images/icons';
import history from '../../../../../../../../../../history';
import { useManageStaffAccess } from '../../hooks';
import { SearchOffice } from './components';

export const OfficeTabPanel = () => {
  const { onCloseModal, onOpenModal, state: popup } = useConfirmationPopup();
  const { handleDeleteAccess, isSubmitting } = useManageStaffAccess();
  const {
    formOfficeName,
    isLoading,
    offices,
    staff,
    totalAccess,
    totalOffices,
    handleSearchOffice,
    isChecked,
  } = useOfficeTabPanel();

  const handleConfirm = useCallback(() => {
    const { checked, item } = popup;

    if (checked) {
      handleDeleteAccess(item.id);
    }

    onCloseModal();
  }, [popup, onCloseModal]);
  return (
    <div>
      <AccessHeader
        linkedText={`Linked Offices (${totalAccess})`}
        title="Office Name"
        total={totalOffices}
      />
      <SearchOffice onChange={handleSearchOffice} value={formOfficeName} />
      <Card style={{ border: 'none' }}>
        <List
          emptyText="There are no offices available. If this staff member is part of a DSO, you must choose a DSO name first. If it’s part of a private practice, add an office to populate this list."
          data={offices}
          isLoading={isLoading}
          renderItem={({ item }) => {
            const isSelected = isChecked(item.id);

            return (
              <ListItem
                LeadingComponent={() => (
                  <Checkbox
                    checked={isSelected}
                    disabled={isSubmitting || isLoading}
                    onChanged={() => onOpenModal(item, isSelected)}
                    checkboxStyle={{
                      width: 18,
                      height: 18,
                      borderRadius: 4,
                      border: `2px solid ${Colors.secondary_500}`,
                      backgroundColor: isSelected ? Colors.secondary_500 : Colors.white,
                      margin: 0,
                    }}
                  />
                )}
                TrailingComponent={() => (
                  <button
                    type="button"
                    style={{
                      all: 'unset',
                      width: 24,
                      height: 24,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => history.push(`/user/dentist/${item.id}`)}
                  >
                    <ChevronRightIcon />
                  </button>
                )}
                itemStyle={{ paddingInline: 24, gap: 8 }}
                textStyle={{ lineHeight: 'normal', flex: 1 }}
                text={item.name || ''}
              />
            );
          }}
          onScroll={() => {}}
          style={{
            marginInline: 0,
          }}
        />
      </Card>
      {popup.show && (
        <UpdatePermissionConfirmationPopup
          data={{
            access: popup.item.name,
            staffFirstName: staff.firstName,
            staffLastName: staff.lastName,
          }}
          onClose={onCloseModal}
          onConfirm={handleConfirm}
          mode={
            popup.checked
              ? UpdatePermissionConfirmationPopupMode.REMOVE
              : UpdatePermissionConfirmationPopupMode.ADD
          }
        />
      )}
    </div>
  );
};
