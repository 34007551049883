import { useTabContext } from './Context';

export const TabPanel = ({ children, value }) => {
  const { activeTab } = useTabContext();

  if (activeTab !== value) {
    return null;
  }

  return children;
};
