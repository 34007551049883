import { useState } from 'react';
import { Colors } from '../../../themes/colors';

export const MenuItem = ({ text, Icon, onClick, listener }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOnClick = (event) => {
    event.stopPropagation();
    listener?.();
    onClick(event);
  };

  return (
    <button
      type="button"
      style={{
        all: 'unset',
        width: '100%',
        cursor: 'pointer',
      }}
      onClick={handleOnClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          flex: 1,
          height: 36,
          padding: '0 8px',
          margin: '0 4px',
          backgroundColor: isHovered ? Colors.neutral_100 : 'transparent',
          display: 'flex',
          flexDirection: 'row',
          borderRadius: 6,
          alignItems: 'center',
        }}
      >
        {Icon && (
          <div
            style={{
              width: 32,
            }}
          >
            <Icon />
          </div>
        )}
        {/* To keep the same content width when text is bold */}
        <span
          style={{
            position: 'relative',
            display: 'inline-block',
            fontFamily: 'Nunito',
            fontSize: 16,
            color: Colors.neutral_600,
            fontWeight: isHovered ? 700 : 400,
          }}
        >
          <span
            style={{
              visibility: 'hidden',
              fontWeight: 700,
            }}
          >
            {text}
          </span>
          <span
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            {text}
          </span>
        </span>
      </div>
    </button>
  );
};
