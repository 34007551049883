import { getAuthHeaders } from '../actions/authActions';
import tempMeeApi from '../apis/tempMee';

export const getCredentialsV2 = async (userId, userType) => {
  const headers = await getAuthHeaders();

  return tempMeeApi.get(`credential-service/users/${userId}/credentials`, {
    params: {
      userType,
    },
    headers: {
      ...headers,
    },
  });
};

export const patchCredentials = async (userId, education, insurances) => {
  const headers = await getAuthHeaders();

  return tempMeeApi.patch(
    `admin/users/${userId}/specialtiesAndProcedures`,
    { education, insurances },
    { headers },
  );
};

export const patchCredentialsV2 = async (userId, userType, education, insurances) => {
  const headers = await getAuthHeaders();

  return tempMeeApi.patch(
    `credential-service/users/${userId}/credentials?userType=${userType}`,
    {
      education,
      insurances,
    },
    { headers },
  );
};

// License
export const getProfLicensesV2 = async (userId) => {
  const headers = await getAuthHeaders();
  return tempMeeApi.get(`credential-service/users/${userId}/licenses`, { headers });
};

export const getProfLicenseByIdV2 = async (userId, licenseId) => {
  const headers = await getAuthHeaders();
  return tempMeeApi.get(`credential-service/users/${userId}/licenses/${licenseId}`, {
    headers,
  });
};

export const addProfLicenseV2 = async (userId, profession, data) => {
  const headers = await getAuthHeaders();
  return tempMeeApi.post(`credential-service/users/${userId}/licenses/${profession}`, data, {
    headers,
  });
};

export const addProfLicenseDaV2 = async (userId, profession, data) => {
  const headers = await getAuthHeaders();
  return tempMeeApi.post(`credential-service/users/${userId}/licenses/${profession}`, data, {
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateProfLicenseV2 = async (userId, licenseId, data) => {
  const headers = await getAuthHeaders();
  return tempMeeApi.patch(`credential-service/users/${userId}/licenses/${licenseId}`, data, {
    headers,
  });
};

export const deleteProfLicenseByIdV2 = async (userId, licenseId) => {
  const headers = await getAuthHeaders();
  return tempMeeApi.delete(`credential-service/users/${userId}/licenses/${licenseId}`, {
    headers,
  });
};

// License References
export const addProfLicenseReferenceV2 = async (userId, licenseId, data) => {
  const headers = await getAuthHeaders();
  return tempMeeApi.post(
    `credential-service/users/${userId}/licenses/${licenseId}/references`,
    [data],
    {
      headers,
    },
  );
};

export const updateProfLicenseReferenceV2 = async (userId, licenseId, referenceId, data) => {
  const headers = await getAuthHeaders();
  tempMeeApi.patch(
    `credential-service/users/${userId}/licenses/${licenseId}/references/${referenceId}`,
    data,
    { headers },
  );
};

export const deleteProfLicenseReferenceV2 = async (userId, licenseId, referenceId) => {
  const headers = await getAuthHeaders();
  tempMeeApi.delete(
    `credential-service/users/${userId}/licenses/${licenseId}/references/${referenceId}`,
    { headers },
  );
};

// License References

// License State Licence
export const addProfLicenseStateLicenseV2 = async (userId, licenseId, data) => {
  const headers = await getAuthHeaders();
  return tempMeeApi.post(
    `credential-service/users/${userId}/licenses/${licenseId}/state-licenses`,
    data,
    { headers },
  );
};

export const updateProfLicenseStateLicenseV2 = async (userId, licenseId, stateLicenseId, data) => {
  const headers = await getAuthHeaders();
  tempMeeApi.patch(
    `credential-service/users/${userId}/licenses/${licenseId}/state-licenses/${stateLicenseId}`,
    data,
    { headers },
  );
};

export const deleteProfLicenseStateLicenseV2 = async (userId, licenseId, stateLicenseId) => {
  const headers = await getAuthHeaders();
  tempMeeApi.delete(
    `credential-service/users/${userId}/licenses/${licenseId}/state-licenses/${stateLicenseId}`,
    { headers },
  );
};

// License State Licence

// License Files

export const uploadProfLicenseFileV2 = async (userId, licenseId, data) => {
  const headers = await getAuthHeaders();
  return tempMeeApi.post(`credential-service/users/${userId}/licenses/${licenseId}/files`, data, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteProfLicenseFileV2 = async (userId, licenseId, fileId) => {
  const headers = await getAuthHeaders();
  return tempMeeApi.delete(
    `credential-service/users/${userId}/licenses/${licenseId}/files/${fileId}`,
    {
      headers,
    },
  );
};

// License Files
