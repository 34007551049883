import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from '../Table/Table';
import { properksRedemptionColumn } from './utils';
import { ROWS_PER_PAGE, useGiftCardRedemption } from './hooks/useGiftCardRedemption';

export const Properks = () => {
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.users.currentUser);

  const { data, isLoading, fetchGiftCardRedemtion, total, totalPages, handleTableChange } =
    useGiftCardRedemption();

  useEffect(() => {
    fetchGiftCardRedemtion();
  }, [dispatch, fetchGiftCardRedemtion, userProfile]);

  const getColumn = () => properksRedemptionColumn();

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        padding: '0px 90px',
      }}
    >
      <h1
        className="global_font f-dark"
        style={{
          fontSize: 30,
          marginTop: 45,
          marginLeft: 30,
          textAlign: 'center',
        }}
      >
        Gift Card Redemption
      </h1>

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '0px 44px',
          marginBlockEnd: 117,
        }}
      >
        <Table
          keyField="properks.referenceId"
          columns={getColumn()}
          data={data}
          noDataComponent="No gift cards have been redeem"
          isLoading={isLoading}
          forceLoading={isLoading}
          showColumnsToHide={false}
          showTotal={false}
          customStyles={{
            headCells: {
              style: {
                fontSize: 13,
                fontWeight: 'bold',
                backgroundColor: '#ADD8E6',
                paddingLeft: 40,
                width: '100%',
              },
            },
            rows: {
              style: {
                minHeight: '44px',
                backgroundColor: '#F8F9FA',
                borderBottom: '0.5px solid #00000020',
                borderLeft: '1px solid #00000020',
                borderRight: '1px solid #00000020',
              },
            },
            cells: {
              style: {
                paddingLeft: 40,
              },
            },
          }}
          paginationPerPage={ROWS_PER_PAGE}
          paginationTotalRows={total}
          totalPages={totalPages}
          onPageChange={handleTableChange}
        />
      </div>
    </div>
  );
};
