/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * SliderAdd
 * - client side slider for add license v2
 * - sister files: Slider.js
 */
import React, { useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';
import ImageZoom from 'react-medium-image-zoom';
import { DeleteIcon, LeftIcon, PlusIcon, RightIcon } from '../../../../images/icons';

export const SliderV2 = ({ editMode, credential, license, setLicenses, credentialType }) => {
  const [files, setFiles] = useState(credential?.files || []);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setFiles(credential.files);
  }, [credential.files]);

  const thumbnail = files[thumbnailIndex];

  const handleDeleteByIndex = (index) => {
    setLicenses((prevState) => {
      const licenseIndex = prevState.findIndex((data) => data.id === license.id);
      const cIndex = prevState[licenseIndex].credentials.findIndex(
        (c) => c && c.credential_type === credentialType,
      );

      const theFiles = prevState[licenseIndex].credentials[cIndex].files;
      if (theFiles) {
        // eslint-disable-next-line no-param-reassign
        prevState[licenseIndex].credentials[cIndex].files = theFiles.filter(
          (_item, idx) => idx !== index,
        );
      }
      return [...prevState];
    });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            position: 'relative',
            width: 336,
            height: 224,
            backgroundColor: '#D9D9D9E5',
            borderRadius: 5,
            overflow: 'hidden',
          }}
        >
          <ImageZoom
            image={{
              src: thumbnail?.uri,
              alt: 'license_image',
              className: 'img',
              style: {
                objectFit: 'contain',
                width: '100%',
                height: 'auto',
              },
            }}
            zoomImage={{
              src: thumbnail?.uri,
              alt: 'license_image',
              style: {
                height: 'auto',
                maxWidth: 500,
                width: 'auto',
              },
            }}
          />

          <span
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (currentIndex - 1 < files.length - 1 && currentIndex - 1 >= 0) {
                const nextIndex = currentIndex - 1;
                setCurrentIndex(nextIndex);
                setThumbnailIndex(nextIndex);
              } else {
                setCurrentIndex(files.length - 1);
                setThumbnailIndex(files.length - 1);
              }
            }}
            style={{
              position: 'absolute',
              top: '40%',
              left: -7,
              cursor: 'pointer',
            }}
          >
            <LeftIcon />
          </span>

          <span
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (currentIndex + 1 < files.length) {
                const nextIndex = currentIndex + 1;
                setCurrentIndex(nextIndex);
                setThumbnailIndex(nextIndex);
              } else {
                setCurrentIndex(0);
                setThumbnailIndex(0);
              }
            }}
            style={{
              position: 'absolute',
              top: '40%',
              right: -7,
              cursor: 'pointer',
            }}
          >
            <RightIcon />
          </span>

          {!!editMode && (
            <label
              style={{
                position: 'absolute',
                bottom: -11,
                left: '40%',
                cursor: 'pointer',
              }}
              htmlFor={`file_${credentialType}_${license.id}`}
            >
              <PlusIcon />
            </label>
          )}

          <input
            onChange={async (event) => {
              event.stopPropagation();
              event.preventDefault();

              let file = event.target.files[0];
              const options = {
                maxSizeMB: 1,
              };
              try {
                file = await imageCompression(file, options);
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
              }

              setLicenses((prevState) => {
                const licenseIndex = prevState.findIndex((data) => data.id === license.id);
                const cIndex = prevState[licenseIndex].credentials.findIndex(
                  (c) => c && c.credential_type === credentialType,
                );

                // eslint-disable-next-line no-param-reassign
                prevState[licenseIndex].credentials[cIndex].files = [
                  ...prevState[licenseIndex].credentials[cIndex].files,
                  { path: file, uri: URL.createObjectURL(file) },
                ];
                return [...prevState];
              });
              return null;
            }}
            type="file"
            accept="image/png, image/gif, image/jpeg"
            id={`file_${credentialType}_${license.id}`}
            style={{ display: 'none' }}
          />

          {!!editMode && (
            <span
              onClick={() => {
                handleDeleteByIndex(thumbnailIndex);
              }}
              style={{
                position: 'absolute',
                bottom: -5,
                left: '54%',
                cursor: 'pointer',
              }}
            >
              <DeleteIcon />
            </span>
          )}
        </div>

        <div
          style={{
            marginTop: 10,
            height: 90,
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            margin: 20,
            width: 270,
          }}
        >
          {files.map((file, index) => (
            <div
              onClick={() => {
                setThumbnailIndex(index);
                setCurrentIndex(index);
              }}
              style={{
                width: 92,
                height: 62,
                backgroundColor: '#D9D9D9E5',
                borderRadius: 5,
                position: 'relative',
                flex: '0 0 auto',
                maxWidth: '100%',
                marginRight: '10px',
                backgroundImage: `url(${file?.uri})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                border: `1px solid ${index === currentIndex ? 'grey' : 'white'}`,
              }}
            >
              {!!editMode && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDeleteByIndex(index);
                  }}
                  style={{
                    position: 'absolute',
                    bottom: -7,
                    left: '40%',
                    cursor: 'pointer',
                  }}
                >
                  <DeleteIcon />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
