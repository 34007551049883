import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SubHeader } from '../../../../../../../../commonComponents/CardList/SubHeader';
import { useOfficesSubHeader } from '../hooks';
import { BulkSelect } from './BulkSelect';
import { ViewLinkedOrUnlinked } from './ViewLinkedOrUnlinked';

export const OfficesSubHeader = () => {
  const { id: dsoId } = useParams();
  const { total, onOrderBy, orderBy } = useOfficesSubHeader(dsoId);
  const { selectedGroupId } = useSelector((state) => state.dsos.orgStructure);

  return (
    <SubHeader total={total} onOrderBy={onOrderBy} orderBy={orderBy}>
      {total > 0 && (
        <div
          style={{
            paddingRight: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: selectedGroupId ? 'flex-end' : 'space-between',
            width: '100%',
          }}
        >
          <ViewLinkedOrUnlinked dsoId={dsoId} />
          <BulkSelect dsoId={dsoId} />
        </div>
      )}
    </SubHeader>
  );
};
