import { useParams } from 'react-router-dom';
import { List } from '../../../../../../../../commonComponents/CardList';
import { useRegionsCard } from '../hooks';
import { RegionItem } from './RegionItem';

export const RegionList = () => {
  const { id: dsoId } = useParams();
  const { data, handleScrollList, isInitialLoading, isLoading } = useRegionsCard(dsoId);

  return (
    <List
      data={data}
      onScroll={handleScrollList}
      renderItem={({ item }) => <RegionItem dsoId={dsoId} item={item} />}
      isLoading={isInitialLoading || isLoading}
    />
  );
};
