import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOfficeAccessesByStaffId,
  getStaffOverviewOffices,
} from '../../../../../../../../../../../actions/staffActions';
import { createLoadingSelector } from '../../../../../../../../../../../apis/selectors';
import { ENTITY_TYPES } from '../../../../../../../../enums';
import useDebounce from '../../../../../../../../../../../hooks/useDebounce';

const loadingSelector = createLoadingSelector([
  'FETCH_OVERVIEW_OFFICES',
  'FETCH_STAFF_OFFICE_ACCESSES',
]);

export const useOverviewTabPanel = () => {
  const dispatch = useDispatch();

  const [formOfficeName, setFormOfficeName] = useState('');

  const staff = useSelector((state) => state.staff.staffDetails);
  const { data: accesses } = useSelector((state) => state.staff.officeAccesses);
  const { offices, filters, totalOffices } = useSelector((state) => state.staff.dso.overviews);

  const isLoading = useSelector((state) => loadingSelector(state));

  const { dsoIds, groupIds, officeIds, regionIds } = useMemo(
    () =>
      accesses.reduce(
        (result, it) => {
          switch (it.entityType) {
            case ENTITY_TYPES.DSO:
              result.dsoIds.push(it.entityID);
              break;
            case ENTITY_TYPES.GROUP:
              result.groupIds.push(it.entityID);
              break;
            case ENTITY_TYPES.OFFICE:
              result.officeIds.push(it.entityID);
              break;
            case ENTITY_TYPES.REGION:
              result.regionIds.push(it.entityID);
              break;
            default:
              break;
          }
          return result;
        },
        { dsoIds: [], groupIds: [], officeIds: [], regionIds: [] },
      ),
    [accesses],
  );

  const debouncedSearchOffices = useDebounce((payload) => {
    dispatch(
      getStaffOverviewOffices({
        ...payload,
        dsoIds,
        groupIds,
        officeIds,
        regionIds,
        page: 1,
      }),
    );
  }, 750);

  const handlePageChanges = (page) => {
    dispatch(
      getStaffOverviewOffices({
        dsoIds,
        groupIds,
        officeIds,
        regionIds,
        limit: 15,
        page,
      }),
    );
  };

  const handleSearchOffices = (e) => {
    const { value } = e.target;

    setFormOfficeName(value);
    debouncedSearchOffices({ officeName: value });
  };

  useEffect(() => {
    if (staff.id && !isLoading) {
      dispatch(fetchOfficeAccessesByStaffId(staff.id));
    }
  }, [dispatch, staff.id]);

  useEffect(() => {
    if (!dsoIds.length && !groupIds.length && !officeIds.length && !regionIds.length) {
      return;
    }

    dispatch(
      getStaffOverviewOffices({
        dsoIds,
        groupIds,
        officeIds,
        regionIds,
        limit: 15,
        page: 1,
      }),
    );
  }, [dsoIds, groupIds, officeIds, regionIds]);

  return {
    filters,
    formOfficeName,
    isLoading,
    offices,
    totalOffices,
    handlePageChanges,
    handleSearchOffices,
  };
};
