import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDsoOffices } from '../../../../../../../../../actions/dsosActions';
import useDebounce from '../../../../../../../../../hooks/useDebounce';

export const SearchOfficeName = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const { filters, dsoId } = useSelector((state) => state.dsos.orgStructure.officeList);

  const handleNameChange = (e) => {
    const { value } = e.target;
    setName(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useDebounce((query) => {
    dispatch(fetchDsoOffices(dsoId, { ...filters, page: 1, officeName: query }));
  }, 750);

  return (
    <div
      style={{
        width: 320,
        display: 'flex',
        alignItems: 'center',
        gap: 12,
      }}
    >
      <b
        style={{
          fontSize: 14,
          textWrap: 'nowrap',
        }}
      >
        Search:
      </b>
      <input
        className="form-control"
        value={name}
        type="text"
        onChange={handleNameChange}
        placeholder="Type Office Name"
      />
    </div>
  );
};
