import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGroupRegionsAndOffices,
  selectDsoGroup,
} from '../../../../../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../../../../../apis/selectors';

const actions = ['FETCH_DSO_REGION_LIST', 'FETCH_DSO_OFFICE_LIST'];
const loadingSelector = createLoadingSelector(actions);

export const useGroupItem = (dsoId, item) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const { selectedGroupId } = useSelector((state) => state.dsos.orgStructure);
  const isSelected = selectedGroupId === item.id;

  const timerRef = useRef(null);

  const onRadioClick = () => {
    dispatch(selectDsoGroup(item.id));
  };

  useEffect(() => {
    if (isSelected) {
      // Start a timer
      timerRef.current = setTimeout(() => {
        dispatch(getGroupRegionsAndOffices({ dsoId, groupId: item.id }));
      }, 750);
    } else {
      // If selection changes, clear the timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }

    return () => {
      // Cleanup timeout on unmount
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isSelected, dispatch, dsoId, item.id]);

  return {
    isSelected,
    onRadioClick,
    isLoading,
  };
};
