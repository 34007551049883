import { useDispatch, useSelector } from 'react-redux';
import { selectDsoOffice } from '../../../../../../../../../actions/dsosActions';

export const useOfficeItem = (item) => {
  const dispatch = useDispatch();
  const { selectedOffices, isBulkOfficeSelectionActive } = useSelector(
    (state) => state.dsos.orgStructure,
  );
  const selectedOfficeIds = selectedOffices.map((office) => office.id);
  const isSelected = selectedOfficeIds.includes(item.id);

  const onButtonClick = () => {
    dispatch(selectDsoOffice(item));
  };

  return {
    isSelected,
    onButtonClick,
    isBulkOfficeSelectionActive,
  };
};
