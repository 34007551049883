import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAccessToStaffEntity,
  deleteAccessOfStaffEntity,
} from '../../../../../../../../../actions/staffActions';
import { createLoadingSelector } from '../../../../../../../../../apis/selectors';

const loadingSelector = createLoadingSelector([
  'ADD_ACCESS_TO_STAFF_ENTITY',
  'DELETE_ACCESS_TO_STAFF_ENTITY',
]);

export const useManageStaffAccess = (entityType) => {
  const dispatch = useDispatch();

  const { data: accesses } = useSelector((state) => state.staff.officeAccesses);
  const staff = useSelector((state) => state.staff.staffDetails);

  const isSubmitting = useSelector((state) => loadingSelector(state));

  const handleAddAccess = useCallback(
    (id) => {
      dispatch(addAccessToStaffEntity(staff.id, { id, entityType }));
    },
    [dispatch, entityType, staff.id],
  );

  const handleDeleteAccess = useCallback(
    (id) => {
      const accessId = accesses.find((access) => access.entityID === id)?.id;

      dispatch(deleteAccessOfStaffEntity(staff.id, accessId));
    },
    [accesses, dispatch, staff.id],
  );

  return {
    isSubmitting,
    handleAddAccess,
    handleDeleteAccess,
  };
};
