import { fetchRegionsByDsoId } from '../../../../../../../../../../../services/dsos';
import { usePaginatedQuery } from '../../../../../hooks/usePaginatedQuery';

export const useLinkRegionToGroup = ({ dsoId }) =>
  usePaginatedQuery({
    queryKey: 'linkRegionToGroup',
    fetchFunction: fetchRegionsByDsoId,
    dsoId,
    searchParam: 'name',
  });
