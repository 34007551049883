import { useDispatch, useSelector } from 'react-redux';
import {
  clearOrgStructure,
  fetchDsoGroupList,
  fetchDsoOffices,
  fetchDsoRegionList,
} from '../../../../../../../../../../actions/dsosActions';
import { Colors } from '../../../../../../../../../../themes/colors';

export const Reset = ({ dsoId }) => {
  const dispatch = useDispatch();
  const { selectedGroupId } = useSelector((state) => state.dsos.orgStructure);

  const handleButtonClick = () => {
    dispatch(clearOrgStructure());
    Promise.all([
      dispatch(fetchDsoGroupList(dsoId, { page: 1, limit: 15 })),
      dispatch(fetchDsoRegionList(dsoId, { page: 1, limit: 100 })),
      dispatch(fetchDsoOffices(dsoId, { page: 1, limit: 15 })),
    ]);
  };

  if (!selectedGroupId) {
    return null;
  }

  return (
    <button type="button" style={styles.button} onClick={handleButtonClick}>
      <b style={styles.linkText}>Reset</b>
    </button>
  );
};

const styles = {
  button: {
    all: 'unset',
    cursor: 'pointer',
  },
  linkText: {
    fontSize: 16,
    color: Colors.secondary_500,
    textDecoration: 'underline',
  },
};
