import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomPortal from '../../../../../../commonComponents/CustomPortal';
import { FormControl } from '../../../FormControl';

export const CreateNewButton = ({ action, label }) => {
  const dispatch = useDispatch();
  const { id: dsoId } = useParams();
  const [name, setName] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(action(dsoId, name));
    setShowModal(false);
    setName('');
  };

  return (
    <>
      <button className="btn btn-success" onClick={() => setShowModal(true)} type="button">
        Create New
      </button>

      {showModal && (
        <CustomPortal
          title={`Create New ${label}`}
          textStyle={{ textAlign: 'center' }}
          cancelText="Close"
          submitText={`Create ${label}`}
          submitPressed={handleSubmit}
          cancelPressed={() => {
            setShowModal(false);
            setName('');
          }}
          rightButtonDisabled={!name}
          leftButtonProps={{ width: 152, height: 48 }}
          rightButtonProps={{ width: 152, height: 48 }}
        >
          <FormControl
            label={`${label} Name:`}
            value={name}
            onChange={handleNameChange}
            style={{
              width: '100%',
              padding: '0 40px',
              maxWidth: '100%',
            }}
            placeholder="Name"
          />
        </CustomPortal>
      )}
    </>
  );
};
