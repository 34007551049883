import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { fetchDSOList } from '../../../../../../../../services/dsos';

const queryKey = 'filterDSOList';

export const useDSOList = () => {
  const client = useQueryClient();

  const {
    data,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isRefetching,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    cacheTime: 1 * 60 * 1000, // Cache lasts for 1 minute
    staleTime: 1 * 60 * 1000, // Data is fresh for 1 minute
    queryKey: [queryKey],
    getNextPageParam: (lastPage, allPages) => {
      const loadedItems = allPages.flatMap((page) => page.data).length;
      return loadedItems < lastPage.total ? allPages.length + 1 : undefined;
    },
    queryFn: async ({ pageParam = 1 }) => fetchDSOList({ page: pageParam, limit: 100 }),
  });

  useEffect(() => {
    const cached = client.getQueryData([queryKey]);

    if (!cached) {
      refetch();
    }
  }, [client, refetch]);

  return {
    data: data?.pages.flatMap((it) => it.data) || [],
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isRefetching,
    fetchNextPage,
  };
};
