import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchStaffById,
  getStaffDSODetails,
  getStaffTermsAndConditions,
} from '../../../../actions/staffActions';
import { TABS } from '../enums';
import { STAFF_ACTIONS } from '../../../../actions/actionTypes';

export const useStaffDetails = (staffId) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.staff.staffDetails);
  const dso = useSelector((state) => state.staff.dso.details);

  const [selectedTab, setSelectedTab] = useState(TABS.ACCOUNT);

  const handleTabSelection = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    dispatch(fetchStaffById(staffId));
    dispatch(getStaffTermsAndConditions(staffId));
  }, [dispatch, staffId]);

  useEffect(() => {
    if (data?.dsoID && dso?.id !== data?.dsoID) {
      dispatch(getStaffDSODetails({ dsoId: data?.dsoID }));
    }
  }, [data, dispatch, dso?.id]);

  useEffect(
    () => () => {
      dispatch({
        type: STAFF_ACTIONS.CLEAR_STAFF_INFORMATION,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    data,
    selectedTab,
    handleTabSelection,
    dso,
  };
};
