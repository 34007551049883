import { useCallback, useState } from 'react';

export const useConfirmationPopup = () => {
  const [state, setState] = useState({
    show: false,
    item: null,
    checked: false,
  });

  const onOpenModal = useCallback((item, checked) => {
    setState({ show: true, item, checked });
  }, []);

  const onCloseModal = useCallback(() => {
    setState({ show: false, item: null, checked: false });
  }, []);

  return {
    state,
    onOpenModal,
    onCloseModal,
  };
};
