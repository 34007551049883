import { useEffect, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import dotsVerticalIcon from '../../../images/dots-vertical.svg';
import { Colors } from '../../../themes/colors';

// Event Bus for managing communication between DotsMenu instances
const eventBus = {
  callbacks: [],
  subscribe(callback) {
    this.callbacks.push(callback);
  },
  unsubscribe(callback) {
    this.callbacks = this.callbacks.filter((cb) => cb !== callback);
  },
  notify() {
    this.callbacks.forEach((callback) => callback());
  },
};

export const DotsMenu = ({
  children,
  CustomMenuIcon,
  disabled = false,
  position = 'bottom',
  align = 'center',
  iconSrc = dotsVerticalIcon,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const menuRef = useRef(null);
  const popoverContentRef = useRef(null);

  // Toggle the tooltip visibility
  const toggleTooltip = () => {
    if (showTooltip) {
      setShowTooltip(false);
    } else {
      eventBus.notify(); // Notify all other DotsMenu instances to close
      setShowTooltip(true);
    }
  };

  // Close popover when clicking outside but allow clicks inside the popover content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        popoverContentRef.current &&
        !popoverContentRef.current.contains(event.target)
      ) {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showTooltip]);

  useEffect(() => {
    eventBus.subscribe(setShowTooltip);
    return () => eventBus.unsubscribe(setShowTooltip);
  }, []);

  return (
    <div ref={menuRef}>
      <Popover
        isOpen={showTooltip}
        positions={[position]}
        align={align}
        content={
          <div ref={popoverContentRef} style={styles.tooltip}>
            <div style={styles.tooltipContent}>{children}</div>
          </div>
        }
      >
        <button type="button" style={styles.button} disabled={disabled} onClick={toggleTooltip}>
          <div style={styles.menu}>
            {CustomMenuIcon ? (
              <CustomMenuIcon />
            ) : (
              <div style={styles.icon}>
                <img src={iconSrc} alt="menu_icon" style={{ width: 24, height: 24 }} />
              </div>
            )}
          </div>
        </button>
      </Popover>
    </div>
  );
};

const styles = {
  button: {
    all: 'unset',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  menu: {
    position: 'relative',
    display: 'inline-block',
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
    width: 32,
    height: 32,
    alignItems: 'center',
  },
  tooltip: {
    width: 'max-content',
    backgroundColor: Colors.white,
    padding: '5px',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
  },
};
