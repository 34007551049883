import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unlinkOfficeFromRegion } from '../../../../../../../../../../actions/dsosActions';
import { UnlinkIcon } from '../../../../../../../../../../images/icons';
import { Colors } from '../../../../../../../../../../themes/colors';
import CustomPortal from '../../../../../../../../../commonComponents/CustomPortal';
import { MenuItem } from '../../../../../../../../../commonComponents/DotsMenu';

export const UnlinkOfficeFromRegionMenuItem = ({ item }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.dsos.orgStructure.regionList);
  const region = data.find((region) => region.id === item.regionID);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    dispatch(unlinkOfficeFromRegion({ officeId: item.id, regionId: item.regionID }));
    onClose();
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MenuItem
        Icon={() => <UnlinkIcon />}
        text="Unlink Office from Region"
        onClick={() => setShowModal(true)}
      />

      {showModal && (
        <CustomPortal
          title="Unlink Office from a Region"
          textStyle={{ textAlign: 'center' }}
          cancelText="Go Back"
          submitText="Unlink Office"
          submitPressed={handleSubmit}
          cancelPressed={onClose}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              fontSize: 16,
              color: Colors.neutral_500,
              margin: '24px 32px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Are you sure you would like to unlink {item.name} from {region.name}? By completing
              this action:
            </div>
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
              }}
            >
              <li>This office will be removed from this region</li>
              <li>Any region-level office staff will lose access to this office</li>
            </ul>
          </div>
        </CustomPortal>
      )}
    </div>
  );
};
