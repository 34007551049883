import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from '../../../../apis/selectors';
import { getAvailabilityList } from '../../../../actions/availabilityAction';
import { getQueryParam, setQueryParams, updateQueryParam } from '../../../../utils';

const actions = ['FETCH_AVAILABILITY'];
const loadingSelector = createLoadingSelector(actions);

const filterKeys = ['state', 'profession', 'radius', 'date', 'sortBy', 'orderBy'];

export const useAvailabilityList = () => {
  const dispatch = useDispatch();
  const { data, total, searchParams } = useSelector((state) => state.availability.availability);
  const isLoading = useSelector((state) => loadingSelector(state));

  const fetchAvailabilities = () => {
    dispatch(
      getAvailabilityList({
        ...searchParams,
        ...filterKeys.reduce((acc, key) => {
          acc[key] = getQueryParam(key) || '';
          return acc;
        }, {}),
      }),
    );
  };

  const handleFilterChange = (type, value) => {
    updateQueryParam(type, value);
    dispatch(
      getAvailabilityList({
        ...searchParams,
        [type]: value,
      }),
    );
  };

  const handleOnSort = (selectedColumn, sortDirection) => {
    const sortObj = {
      ...searchParams,
      sortBy: selectedColumn.sortField,
      orderBy: sortDirection,
    };

    setQueryParams(sortObj, filterKeys);
    dispatch(
      getAvailabilityList({
        ...sortObj,
      }),
    );
  };

  return {
    data,
    total,
    isLoading,
    filter: searchParams,
    fetchAvailabilities,
    handleFilterChange,
    handleOnSort,
  };
};
