/**
 * StateCredentialAdd
 * - client side StateCredential for add license v2
 * - sister files: StateCredential.js, diffchecker https://www.diffchecker.com/gcy8qNlm/
 * - todo ( @aqigif ) - make this component smaller
 */

/* eslint-disable no-param-reassign */
import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { addUserLicenseV2 } from '../../../../actions/userActions';
import { states } from '../../../commonComponents/StatePicker';

import { showErrorMessage } from '../../../../utils';
import EntryFields from './EntryFields';

import { professionsWithSubProfessionsTable, userDaSubProfessions } from '../../../../constants';
import { UserProfession } from '../../../../enums/UserProfession';
import MagnifierIcon from '../../../../images/magnifier.svg';
import {
  CredentialInputType,
  CredentialType,
} from '../../../../utils/CredentialUtils';
import { createLoadingSelector } from '../../../../apis/selectors';
import { StateCredentialFileV2 } from './StateCredentialFileV2';

const abraviations = states.map((state) => state.abbreviation);

export const StateCredentialAdd = ({
  license,
  stateLicense,
  reference,
  daCertificate,
  cprBls,
  radiology,
  hasStateLicense,
  hasDACertificate,
  hasCPRBLS,
  hasRadiology,
  hasReference,
  hasOnlyOneCredential,
  stateCredentials,
  setLicenses,
  allProfessions,
  licenses,
  dispatch,
}) => {
  const loadingSelector = createLoadingSelector(['ADD_LICENSE']);
  const isLoading = useSelector((state) => loadingSelector(state));

  const { profession, professionName, state: licenseState } = license || {};

  const subProfession = license?.subProfession || 'none';
  const subProfessionName = userDaSubProfessions.find((item) => item.code === subProfession)?.name;

  const isCurrentProfessionHasSubProfession =
    professionsWithSubProfessionsTable[profession]?.length > 0;
  const hasSubProfession = subProfession?.length > 0;

  const currentUser = useSelector((state) => state.users.currentUser);

  const nationalProviderIdentifier =
    currentUser?.professional_info?.nationalProviderIdentifier || '';

  const statesFiltered = states.filter((state) => state.abbreviation === licenseState);
  const licVerificationUrl = statesFiltered.length > 0 ? statesFiltered[0].licVerificationUrl : '';

  const credentialOfLicense = stateCredentials?.[licenseState]?.[profession];

  const hasRadiologyEntry =
    credentialOfLicense?.radiology?.input_type === CredentialInputType.EntryField;
  const [radiologyChecked, setRadiologyChecked] = useState(false);

  const hasStateLicenseEntry =
    credentialOfLicense?.state_license?.input_type === CredentialInputType.EntryField;
  const [stateLicenseChecked, setStateLicenseChecked] = useState(false);

  const hasDaCertificateEntry =
    credentialOfLicense?.da_certificate?.input_type === CredentialInputType.EntryField;
  const [daCertificateChecked, setDaCertificateChecked] = useState(false);

  const hasCprLbsCertificateEntry =
    credentialOfLicense?.[CredentialType.CprBls]?.input_type === CredentialInputType.EntryField;
  const [cprLbsCertificateChecked, setCprLbsCertificateChecked] = useState(false);

  const hasReferenceEntry =
    credentialOfLicense?.reference?.input_type === CredentialInputType.EntryField;
  const maxReferenceAllowed = 3;

  const allowToSaveCredentials = (() => {
    const validations = [];

    if (hasRadiologyEntry) {
      validations.push(radiologyChecked);
    }

    if (hasStateLicenseEntry) {
      validations.push(stateLicenseChecked);
    }

    if (hasDaCertificateEntry) {
      validations.push(daCertificateChecked);
    }

    if (hasCprLbsCertificateEntry) {
      validations.push(cprLbsCertificateChecked);
    }

    return validations.every((validation) => validation === true);
  })();

  return (
    <div style={{ padding: 10 }}>
      <div className="row" style={{ marginBottom: 10 }}>
        <div
          className={`col-6 ${hasOnlyOneCredential && 'offset-3'}`}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflowY: 'auto',
            marginBottom: 10,
          }}
        >
          <div
            className="card"
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '5px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 10px',
                borderBottom: '1px solid #efefef ',
              }}
            >
              <div>State:</div>
              <div>
                <select
                  className="custom-select custom-select-lg"
                  onChange={(event) => {
                    const state = event?.target?.value;

                    setLicenses((prevState) => {
                      const licenseIndex = prevState.findIndex((data) => data.id === license.id);

                      prevState[licenseIndex].state = state;

                      return [...prevState];
                    });
                  }}
                  value={license.state}
                  style={{
                    height: 32,
                  }}
                >
                  {abraviations.map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 10px',
                borderBottom: '1px solid #efefef ',
              }}
            >
              <div>Profession:</div>
              <div>
                <select
                  defaultValue={professionName}
                  className="custom-select custom-select-lg"
                  value={professionName}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    setLicenses((prevState) => {
                      const licenseIndex = prevState.findIndex((data) => data.id === license.id);

                      prevState[licenseIndex].professionName = value;
                      prevState[licenseIndex].profession = allProfessions.find(
                        (key) => key.name === value,
                      ).code;

                      return [...prevState];
                    });
                  }}
                  style={{ height: 40 }}
                >
                  {allProfessions.map((key) => (
                    <option value={key.name}>{key.name}</option>
                  ))}
                </select>
              </div>
            </div>

            {(isCurrentProfessionHasSubProfession || (hasSubProfession && subProfessionName)) && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 10px',
                  borderBottom: '1px solid #efefef ',
                }}
              >
                <div>Sub-Profession:</div>
                <div>
                  <select
                    defaultValue={subProfessionName}
                    className="custom-select custom-select-lg"
                    value={subProfession}
                    onChange={(e) => {
                      const value = e?.target?.value;

                      setLicenses((prevState) => {
                        const licenseIndex = prevState.findIndex((data) => data.id === license.id);

                        prevState[licenseIndex].subProfession = value;
                        prevState[licenseIndex].subProfessionName = allProfessions.find(
                          (key) => key.code === value,
                        )?.name;

                        return [...prevState];
                      });
                    }}
                    style={{ height: 40 }}
                  >
                    <option key="none" value="">
                      None
                    </option>
                    {professionsWithSubProfessionsTable[profession].map((key) => (
                      <option value={key.code}>{key.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {license.profession === UserProfession.DN && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 10px',
                  borderBottom: '1px solid #efefef ',
                }}
              >
                <div>NPI Number:</div>
                <div>{nationalProviderIdentifier || 'N/A'}</div>
              </div>
            )}
          </div>

          {hasStateLicense && (
            <div
              className="card"
              style={{
                flex: 4,
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
                paddingBottom: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingTop: 25,
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <h4
                      className="card-title"
                      style={{ marginLeft: 40, fontFamily: 'Nunito', color: '#494963' }}
                    >
                      {stateCredentials[license.state][license.profession].state_license.title}{' '}
                      <span style={{ color: '#899197', fontWeight: '300' }}>
                        {stateCredentials[license.state][license.profession].state_license.required
                          ? 'Required(*)'
                          : '(Optional)'}{' '}
                      </span>
                    </h4>
                    <div style={{ color: '#899197' }}>Enter License Number</div>
                  </div>

                  <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <a
                      className="btn btn-secondary"
                      style={{ width: 94, height: 34 }}
                      href={licVerificationUrl}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        const el = document.createElement('textarea');
                        el.value = stateLicense?.entry_field?.license;
                        document.body.appendChild(el);
                        el.select();
                        document.execCommand('copy');
                        document.body.removeChild(el);
                      }}
                    >
                      <img
                        src={MagnifierIcon}
                        alt="magnifier icon"
                        style={{
                          marginRight: 4,
                          marginTop: -2,
                        }}
                      />
                      Lookup
                    </a>
                  </div>
                </div>
              </div>

              {hasStateLicenseEntry && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: 40,
                  }}
                >
                  <EntryFields
                    credential={stateLicense}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType="state_license"
                    editMode
                    isCredentialCheckboxChecked={stateLicenseChecked}
                    onCredentialCheckboxCheckChanged={setStateLicenseChecked}
                  />
                </div>
              )}

              {stateCredentials[license.state][license.profession].state_license.input_type ===
                'upload' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <StateCredentialFileV2
                    editMode
                    credential={stateLicense}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType={CredentialType.StateLicense}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {hasDACertificate && (
          <div className="col-6" style={{ marginBottom: 10 }}>
            <div
              className="card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 395,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingTop: 25,
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <h4
                      className="card-title"
                      style={{ marginLeft: 40, fontFamily: 'Nunito', color: '#494963' }}
                    >
                      {stateCredentials[license.state][license.profession].da_certificate.title}{' '}
                      <span style={{ color: '#899197', fontWeight: '300' }}>
                        {stateCredentials[license.state][license.profession].da_certificate.required
                          ? 'Required(*)'
                          : '(Optional)'}
                      </span>
                    </h4>
                    <div style={{ color: '#899197', fontStyle: 'italic' }}>
                      Example: DANB CDA, AMT RDA, DA Diploma, Expanded Functions, Duties
                      Certificates
                    </div>
                    <div style={{ color: '#899197', marginTop: 10 }}>Upload files</div>
                  </div>
                </div>
              </div>

              {hasDaCertificateEntry && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: 40,
                  }}
                >
                  <EntryFields
                    credential={daCertificate}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType="da_certificate"
                    editMode
                    daCertificateCheckboxStatus={daCertificateChecked}
                    setDaCertificateCheckboxStatus={setDaCertificateChecked}
                  />
                </div>
              )}

              {stateCredentials[license.state][license.profession].da_certificate.input_type ===
                'upload' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <StateCredentialFileV2
                    editMode
                    credential={daCertificate}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType={CredentialType.DACertificate}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {hasRadiology && (
          <div className="col-6" style={{ marginBottom: 10 }}>
            <div
              className="card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingTop: 25,
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <h4
                      className="card-title"
                      style={{ marginLeft: 40, fontFamily: 'Nunito', color: '#494963' }}
                    >
                      {stateCredentials[license.state][license.profession].radiology.title}{' '}
                      <span style={{ color: '#899197', fontWeight: '300' }}>
                        {stateCredentials[license.state][license.profession].radiology.required
                          ? 'Required(*)'
                          : '(Optional)'}
                      </span>
                    </h4>
                    <div style={{ color: '#899197', fontStyle: 'italic' }}>
                      Example: Radiology/X-Ray Certificate, Radiology License
                    </div>
                    <div style={{ color: '#899197', marginTop: 10 }}>Upload files</div>
                  </div>
                </div>
              </div>

              {hasRadiologyEntry && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: 40,
                  }}
                >
                  <EntryFields
                    credential={radiology}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType="radiology"
                    editMode
                    isCredentialCheckboxChecked={radiologyChecked}
                    onCredentialCheckboxCheckChanged={setRadiologyChecked}
                  />
                </div>
              )}

              {stateCredentials[license.state][license.profession].radiology.input_type ===
                'upload' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <StateCredentialFileV2
                    editMode
                    credential={radiology}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType={CredentialType.Radiology}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {hasCPRBLS && (
          <div className="col-6" style={{ marginBottom: 10 }}>
            <div
              className="card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <div
                  className="card-body"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingTop: 25,
                  }}
                >
                  <div
                    style={{
                      flex: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <h4
                      className="card-title"
                      style={{ marginLeft: 40, fontFamily: 'Nunito', color: '#494963' }}
                    >
                      {stateCredentials[license.state][license.profession]['cpr/bls'].title}
                      CPR/BLS Certificate{' '}
                      <span style={{ color: '#899197', fontWeight: '300' }}>
                        {stateCredentials[license.state][license.profession]['cpr/bls'].required
                          ? 'Required(*)'
                          : '(Optional)'}
                      </span>
                    </h4>
                    <div style={{ color: '#899197', fontStyle: 'italic' }}>
                      Example: CPR/BLS Certificate
                    </div>
                    <div style={{ color: '#899197', marginTop: 10 }}>Upload files</div>
                  </div>
                </div>
              </div>

              {hasCprLbsCertificateEntry && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: 40,
                  }}
                >
                  <EntryFields
                    credential={cprBls}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType="cpr/bls"
                    editMode
                    isCredentialCheckboxChecked={cprLbsCertificateChecked}
                    onCredentialCheckboxCheckChanged={setCprLbsCertificateChecked}
                  />
                </div>
              )}

              {stateCredentials[license.state][license.profession]['cpr/bls'].input_type ===
                'upload' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <StateCredentialFileV2
                    editMode
                    credential={cprBls}
                    license={license}
                    setLicenses={setLicenses}
                    credentialType={CredentialType.CprBls}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {hasReference && (
          <>
            {Array.from({ length: maxReferenceAllowed }).map((value, index) => {
              const referenceSettings =
                stateCredentials[license.state][license.profession]?.reference;
              const referenceCredential = reference?.entry_field?.references?.[index];
              const key = `reference-${license?.id}-${index}`;

              return (
                <div className="col-6" style={{ marginBottom: 10 }} key={key}>
                  <div
                    className="card"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingBottom: 40,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        className="card-body"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          paddingTop: 25,
                        }}
                      >
                        <div
                          style={{
                            flex: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                          }}
                        >
                          <h4 className="card-title" style={{ marginLeft: 40, color: '#494963' }}>
                            {referenceSettings?.title} {index + 1}{' '}
                            <span style={{ color: '#899197', fontWeight: '300' }}>
                              {index === 0 && referenceSettings?.required
                                ? 'Required(*)'
                                : '(Optional)'}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>

                    {hasReferenceEntry && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          paddingBottom: 40,
                        }}
                      >
                        <EntryFields
                          onReferenceEntryChanged={(reference) => {
                            const licenseIndex = licenses.findIndex(
                              (data) => data.id === license.id,
                            );

                            if (licenseIndex > -1) {
                              setLicenses((prevState) => {
                                const credentialIndex = prevState[
                                  licenseIndex
                                ].credentials.findIndex(
                                  (c) => c && c.credential_type === 'reference',
                                );

                                if (prevState[licenseIndex].credentials[credentialIndex]) {
                                  const newState = [...prevState];
                                  const field =
                                    newState[licenseIndex].credentials[credentialIndex].entry_field;

                                  if (!Object.prototype.hasOwnProperty.call(field, 'references')) {
                                    newState[licenseIndex].credentials[
                                      credentialIndex
                                    ].entry_field = {
                                      ...field,
                                      references: [],
                                    };
                                  }

                                  newState[licenseIndex].credentials[
                                    credentialIndex
                                  ].entry_field.references[index] = reference;

                                  return [...newState];
                                }

                                return [...prevState];
                              });
                            }
                          }}
                          credential={referenceCredential}
                          license={license}
                          setLicenses={setLicenses}
                          credentialType="reference"
                          editMode
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}

        <div
          className="col-12"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <button
            className="btn btn-success"
            disabled={isLoading}
            style={{ width: 94, height: 34, marginRight: 60 }}
            onClick={async () => {
              if (!allowToSaveCredentials) {
                showErrorMessage({
                  message: 'Confirm that the credentials provided belong to this professional.',
                  position: 'bottom-left',
                });

                return;
              }

              await dispatch(
                addUserLicenseV2(
                  license,
                  () => setLicenses((prev) => prev.filter((item) => item.id !== license.id)),
                ),
              );
            }}
            type="button"
          >
            Save
          </button>

          <button
            className="btn btn-secondary"
            style={{ width: 94, height: 34 }}
            onClick={() => {
              setLicenses((prev) => prev.filter((item) => item.id !== license.id));
            }}
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
