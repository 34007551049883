import { getAuthHeaders } from '../actions/authActions';
import tempMeeApi from '../apis/tempMee';

/**
 * Fetches the terms and conditions for a specific staff member.
 *
 * @param {Object} payload - The parameters object.
 * @param {string} payload.staffId - The unique identifier of the staff member.
 * @returns {Promise<{ accepted: boolean, date: string }>} A promise that resolves to an object containing:
 *  - `accepted` (boolean): Indicates whether the staff member has accepted the terms.
 *  - `date` (string): The date when the terms were accepted.
 * @throws {Error} If the request fails.
 */
export const getStaffTermAndConditions = async ({ staffId }) => {
  const headers = await getAuthHeaders();

  const response = await tempMeeApi.get(`/office-service/admin/staffs/${staffId}/gotu-terms`, {
    headers,
  });

  return response.data;
};
