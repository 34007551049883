import { queryClient } from '../queryClient';
import { isObjectEqual } from '../utils/Object';

export const shouldFetchWithNewParams = (queryKey, params) => {
  const cachedParams = queryClient.getQueryData(queryKey);

  if (cachedParams && isObjectEqual(params, cachedParams)) {
    return false;
  }

  queryClient.setQueryData(queryKey, params);
  return true;
};

export const clearQueryCache = (queryKey) => {
  queryClient.removeQueries(queryKey);
};

export const getQueryData = (queryKey) => queryClient.getQueryData(queryKey);
