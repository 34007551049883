import { useDispatch, useSelector } from 'react-redux';
import { fetchDsoOffices } from '../../../../../../../../../../actions/dsosActions';
import { Colors } from '../../../../../../../../../../themes/colors';

export const ViewLinkedOrUnlinked = ({ dsoId }) => {
  const dispatch = useDispatch();
  const {
    officeList: { data, filters },
    selectedGroupId,
  } = useSelector((state) => state.dsos.orgStructure);

  const handleButtonClick = () => {
    dispatch(
      fetchDsoOffices(dsoId, {
        ...filters,
        page: 1,
        limit: 15,
        emptyRegion: !filters.emptyRegion,
      }),
    );
  };

  if (!data.length || selectedGroupId) {
    return null;
  }

  return (
    <button type="button" style={styles.button} onClick={handleButtonClick}>
      <b style={styles.linkText}>{!filters.emptyRegion ? 'View Unlinked' : 'View All'}</b>
    </button>
  );
};

const styles = {
  button: {
    all: 'unset',
    cursor: 'pointer',
  },
  linkText: {
    fontSize: 16,
    color: Colors.secondary_500,
    textDecoration: 'underline',
  },
};
