import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOfficeAdminsList, getOfficeById } from '../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../apis/selectors';

const getOfficeAdminListLoading = createLoadingSelector(['FETCH_DSO_OFFICE_ADMINS_LIST']);

export const useOfficeAccess = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.users.currentUser);
  const { filters, data, isActiveRequest, isFinalPage } = useSelector(
    (state) => state.dsos.dsoOfficeAdminsList,
  );
  const { dso, groups, region } = useSelector((state) => state.dsos.office);

  const hasDso = dso && dso.id?.length > 0;

  const isOfficeAdminListLoading = useSelector((state) => getOfficeAdminListLoading(state));

  const handleOnScroll = (e) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const tableScrollHeight = e?.target?.scrollHeight - e?.target?.scrollTop - 20;
    const clientHeight = e?.target?.clientHeight;
    if (tableScrollHeight <= clientHeight && !isActiveRequest && !isFinalPage) {
      dispatch(
        fetchOfficeAdminsList({
          officeId: currentUser.id,
          page: filters.page + 1,
          limit: 15,
        }),
      );
    }
  };

  useEffect(() => {
    if (!currentUser?.id) return;

    dispatch(
      fetchOfficeAdminsList({
        officeId: currentUser.id,
        page: 1,
        limit: 15,
      }),
    );

    dispatch(getOfficeById(currentUser.id));
  }, [currentUser, dispatch]);

  return {
    data,
    groups,
    hasDso,
    isOfficeAdminListInitialLoading: filters.page === 1 && isOfficeAdminListLoading,
    isOfficeAdminListLoading,
    region,
    handleOnScroll,
  };
};
