import * as React from 'react';

export const UnlinkIcon = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_258_30831)">
      <path
        d="M10.93 18.7269L9.82843 19.8284C8.26633 21.3905 5.73367 21.3905 4.17157 19.8284C2.60948 18.2663 2.60948 15.7337 4.17157 14.1716L8.17157 10.1716C8.95262 9.39052 9.97631 9 11 9M13.072 5.27118L14.1716 4.17157C15.7337 2.60948 18.2663 2.60948 19.8284 4.17157C21.3905 5.73367 21.3905 8.26633 19.8284 9.82843L15.8284 13.8284C14.9199 14.7369 13.6832 15.117 12.5 14.9688"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 0.85C9.54679 0.85 9.16217 1.23462 9.16217 1.68783V3.72487C9.16217 4.17808 9.54679 4.5627 10 4.5627C10.4532 4.5627 10.8378 4.17808 10.8378 3.72487V1.68783C10.8378 1.23462 10.4532 0.85 10 0.85ZM3.72487 9.16217H1.68783C1.23462 9.16217 0.85 9.54679 0.85 10C0.85 10.4494 1.20442 10.8378 1.68783 10.8378H3.72487C4.17808 10.8378 4.5627 10.4532 4.5627 10C4.5627 9.54679 4.17808 9.16217 3.72487 9.16217ZM6.42353 5.28547L4.9685 3.83044C4.64537 3.50731 4.12712 3.50731 3.80399 3.83044C3.48086 4.15357 3.48086 4.67183 3.80399 4.99495L5.25901 6.44998C5.58214 6.77311 6.1004 6.77311 6.42353 6.44998C6.74666 6.12685 6.74666 5.6086 6.42353 5.28547Z"
        fill="#333333"
        stroke="#333333"
        strokeWidth="0.3"
      />
      <path
        d="M14 23.15C14.4532 23.15 14.8378 22.7654 14.8378 22.3122L14.8378 20.2751C14.8378 19.8219 14.4532 19.4373 14 19.4373C13.5468 19.4373 13.1622 19.8219 13.1622 20.2751L13.1622 22.3122C13.1622 22.7654 13.5468 23.15 14 23.15ZM20.2751 14.8378L22.3122 14.8378C22.7654 14.8378 23.15 14.4532 23.15 14C23.15 13.5506 22.7956 13.1622 22.3122 13.1622L20.2751 13.1622C19.8219 13.1622 19.4373 13.5468 19.4373 14C19.4373 14.4532 19.8219 14.8378 20.2751 14.8378ZM17.5765 18.7145L19.0315 20.1696C19.3546 20.4927 19.8729 20.4927 20.196 20.1696C20.5191 19.8464 20.5191 19.3282 20.196 19.005L18.741 17.55C18.4179 17.2269 17.8996 17.2269 17.5765 17.55C17.2533 17.8731 17.2533 18.3914 17.5765 18.7145Z"
        fill="#333333"
        stroke="#333333"
        strokeWidth="0.3"
      />
    </g>
    <defs>
      <clipPath id="clip0_258_30831">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
