import moment from 'moment';
import { Spinner } from '../../../../../../../commonComponents/Spinner';
import { TabViewContainer } from '../../../../../../../commonComponents/TabViewContainer';
import { LineInfo } from '../../../../LineInfo';
import { useAccountInfo } from './hooks/useAccountInfo';
import { useDsoExecutive } from '../../hooks';
import { Checkbox } from '../../../../../../../commonComponents/Checkbox';
import { Colors } from '../../../../../../../../themes/colors';

export const AccountInfo = () => {
  const {
    formPhoneNumber,
    formValues,
    isEditMode,
    isFormValid,
    isLoading,
    staff,
    handleCancelButton,
    handleEditButton,
    handleFormPhoneNumberChanged,
    handleFormValuesChanged,
    handleSubmit,
  } = useAccountInfo();
  const { isDsoExecutive, toggleDsoExecutive } = useDsoExecutive(staff.id);

  const hasDSO = !!staff?.dsoID;

  return (
    <TabViewContainer
      style={{
        margin: 0,
        minWidth: 'auto',
        paddingInline: 24,
      }}
      title="Account Info"
      variant="card"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <LineInfo title="First Name" text={staff.firstName}>
            {isEditMode && (
              <input
                type="text"
                className="input-field"
                style={{ height: 36 }}
                value={formValues.firstName}
                onChange={(e) => handleFormValuesChanged('firstName', e.target.value)}
              />
            )}
          </LineInfo>
          <LineInfo title="Last Name" text={staff.lastName}>
            {isEditMode && (
              <input
                type="text"
                className="input-field"
                style={{ height: 36 }}
                value={formValues.lastName}
                onChange={(e) => handleFormValuesChanged('lastName', e.target.value)}
              />
            )}
          </LineInfo>
          <LineInfo title="Email" text={staff.email}>
            {isEditMode && (
              <input
                type="text"
                className="input-field"
                style={{ height: 36 }}
                value={formValues.email}
                onChange={(e) => handleFormValuesChanged('email', e.target.value)}
              />
            )}
          </LineInfo>
          <LineInfo title="Phone Number" text={staff.phoneNumber || '-'}>
            {isEditMode && (
              <input
                type="text"
                className="input-field"
                style={{ height: 36 }}
                value={formPhoneNumber}
                onChange={(e) => handleFormPhoneNumberChanged(e.target.value)}
              />
            )}
          </LineInfo>
          <LineInfo title="T&C Agreed?" text={staff.tnc?.accepted ? 'Yes' : '-'} />
          <LineInfo
            title="T&C Accepted On"
            text={
              staff.tnc?.acceptedAt ? moment(staff.tnc?.acceptedAt).format('ddd, MMM DD YYYY') : '-'
            }
          />
          {hasDSO && (
            <LineInfo title="DSO Executive">
              <div style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
                <Checkbox
                  checked={isDsoExecutive}
                  textStyle={{ color: 'black', flex: '0 0 auto' }}
                  onChanged={toggleDsoExecutive}
                  style={{
                    margin: 0,
                  }}
                  checkboxStyle={{
                    borderRadius: 4,
                    border: `2px solid ${Colors.secondary_500}`,
                    backgroundColor: isDsoExecutive ? Colors.secondary_500 : Colors.white,
                    margin: 0,
                  }}
                />
              </div>
            </LineInfo>
          )}

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              gap: 20,
              justifyContent: 'center',
              marginTop: 24,
            }}
          >
            {isEditMode ? (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{
                    width: '100px',
                  }}
                  onClick={handleCancelButton}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="btn btn-success"
                  style={{
                    width: '100px',
                  }}
                  onClick={handleSubmit}
                  disabled={!isFormValid}
                >
                  Save
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn btn-info"
                style={{ width: '100px' }}
                onClick={handleEditButton}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      )}
    </TabViewContainer>
  );
};
