import * as React from 'react';

export const ViewDocumentIcon = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2505 20.129C18.3942 20.2669 18.5392 20.4019 18.6798 20.5409C19.0531 20.9111 19.415 21.2928 19.799 21.6514C20.1474 21.9767 20.0922 22.4013 19.8646 22.6257C19.6449 22.8429 19.2255 22.9065 18.8883 22.5506C18.4316 22.0684 17.951 21.6089 17.4815 21.1383C17.4434 21.0997 17.414 21.0525 17.3783 21.0068C16.5156 21.5438 15.6156 21.6844 14.6647 21.3968C13.9304 21.1744 13.3525 20.73 12.9346 20.0873C12.0771 18.7683 12.3202 17.0402 13.5066 15.9853C14.6643 14.9558 16.4294 14.93 17.6328 15.9265C18.8442 16.9298 19.1671 18.7004 18.2505 20.129ZM13.6976 18.3588C13.6944 19.4082 14.5444 20.2669 15.5913 20.2716C16.6427 20.2764 17.5046 19.4292 17.5109 18.3846C17.5173 17.3281 16.6641 16.4671 15.6096 16.4651C14.5583 16.4631 13.7008 17.3122 13.6976 18.3588Z"
      fill="#333333"
      stroke="#333333"
      strokeWidth="0.7"
      strokeMiterlimit="10"
    />
    <path
      d="M12.5398 9.51293C10.8672 9.51293 9.99322 9.51293 8.32071 9.51293C7.95769 9.51293 7.72017 9.34493 7.63875 9.03592C7.53946 8.659 7.81113 8.2797 8.19996 8.25269C8.23968 8.24991 8.2794 8.24951 8.31912 8.24951C11.6645 8.24951 12.622 8.24951 15.9674 8.24951C16.3392 8.24951 16.5858 8.43221 16.6573 8.75512C16.7367 9.11337 16.4698 9.47758 16.1028 9.50856C16.0337 9.51452 15.9638 9.51333 15.8947 9.51333C14.2468 9.51333 14.1876 9.51293 12.5398 9.51293Z"
      fill="#333333"
      stroke="#333333"
      strokeWidth="0.9"
      strokeMiterlimit="10"
    />
    <path
      d="M14.7542 13.3578C14.2994 13.3578 8.60523 13.369 8.15125 13.3602C7.83947 13.3543 7.58687 13.11 7.55191 12.8042C7.51696 12.4987 7.71078 12.2088 8.00946 12.1242C8.0611 12.1095 8.1167 12.1024 8.17072 12.1024C9.02862 12.1012 15.195 12.0936 16.0533 12.0992C16.3755 12.1012 16.6308 12.3435 16.6674 12.656C16.7031 12.9607 16.5097 13.2522 16.2094 13.3348C16.1344 13.3555 16.0529 13.3586 15.9743 13.359C15.5676 13.3614 15.1609 13.3602 14.7542 13.3602V13.3578Z"
      fill="#333333"
      stroke="#333333"
      strokeWidth="0.9"
      strokeMiterlimit="10"
    />
    <path
      d="M9.05025 15.944C9.48159 15.944 9.86257 15.9373 10.2939 15.9452C10.6069 15.9508 10.8583 16.1903 10.8968 16.4973C10.9349 16.8011 10.7431 17.0923 10.4436 17.1801C10.3968 17.194 10.3463 17.2027 10.2975 17.2027C9.42964 17.2043 8.99996 17.2079 8.13213 17.2027C7.86086 17.2011 7.63566 17.0188 7.55583 16.7658C7.4752 16.51 7.55027 16.2268 7.77547 16.0799C7.89263 16.0032 8.04793 15.9544 8.18813 15.9488C8.60398 15.9321 8.63362 15.9428 9.05025 15.9428V15.944Z"
      fill="#333333"
      stroke="#333333"
      strokeWidth="0.9"
      strokeMiterlimit="10"
    />
    <path
      d="M17.3081 2.56689C17.5114 2.61813 17.7184 2.65904 17.9181 2.72259C19.211 3.13446 20.1157 4.33433 20.1221 5.6899C20.1336 8.22429 20.1272 10.7587 20.1249 13.2935C20.1249 13.7741 19.7765 14.1275 19.3238 14.1244C18.8694 14.1212 18.5381 13.7697 18.5381 13.2812C18.5369 10.7928 18.5385 8.30492 18.5369 5.8166C18.5362 4.95353 17.9996 4.31011 17.1715 4.17388C17.0678 4.1568 16.9606 4.156 16.8549 4.156C13.6584 4.15521 10.4619 4.15481 7.26545 4.156C6.27012 4.156 5.59174 4.8316 5.59134 5.82296C5.59055 10.4286 5.59055 15.0343 5.59134 19.6404C5.59134 20.6345 6.26813 21.3121 7.25989 21.3137C8.36523 21.3153 9.47018 21.3137 10.5755 21.3141C11.0565 21.3141 11.3806 21.5564 11.4588 21.9726C11.5693 22.5596 11.0402 22.9036 10.5231 22.9036C9.40028 22.9036 8.27706 22.9036 7.15424 22.9036C5.4571 22.9036 4.007 21.4614 4.00462 19.7639C3.99826 15.0784 3.99866 10.3925 4.00462 5.70698C4.00541 4.26602 5.021 2.99982 6.41946 2.65745C6.55291 2.62488 6.68755 2.59708 6.8218 2.56689C10.3174 2.56689 13.8129 2.56689 17.3081 2.56689Z"
      fill="#333333"
      stroke="#333333"
      strokeWidth="0.44"
      strokeMiterlimit="10"
    />
  </svg>
);
