import { STAFF_ACTIONS } from '../actions/actionTypes';

const defaultState = {
  dso: {
    details: {
      id: null,
      name: null,
      code: null,
      createdAt: null,
    },
    groups: {
      data: [],
      filters: {
        limit: 15,
        page: 1,
        name: undefined,
      },
      isFinalPage: false,
      total: 0,
    },
    offices: {
      data: [],
      total: 0,
    },
    overviews: {
      offices: [],
      filters: {
        limit: 15,
        officeName: undefined,
        page: 1,
      },
      totalOffices: 0,
    },
    regions: {
      data: [],
      filters: {
        limit: 15,
        page: 1,
        name: undefined,
      },
      isFinalPage: false,
      total: 0,
    },
  },
  staffDetails: {
    dsoID: null,
    email: null,
    firstName: null,
    id: null,
    lastName: null,
    userID: null,
    tnc: {
      accepted: false,
      acceptedAt: null,
    },
  },
  officeAccesses: {
    staffId: null,
    data: [],
    filters: {
      page: 1,
      limit: 50,
    },
  },
  rateLimits: {
    data: [],
    filters: {
      limit: 10,
      page: 1,
    },
    total: 0,
  },
  staffRateLimits: {
    staffId: null,
    rateLimitDA: null,
    rateLimitDN: null,
    rateLimitFD: null,
    rateLimitRDH: null,
  },
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  switch (action.type) {
    case STAFF_ACTIONS.CLEAR_STAFF_INFORMATION: {
      return defaultState;
    }
    case STAFF_ACTIONS.FETCH_STAFF_BY_ID_SUCCESS: {
      return {
        ...state,
        staffDetails: {
          ...state.staffDetails,
          ...action.payload,
          tnc: {
            ...state.staffDetails.tnc,
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_STAFF_OFFICE_ACCESSES_SUCCESS: {
      return {
        ...state,
        officeAccesses: {
          ...state.officeAccesses,
          staffId: action.payload.staffId,
          data: action.payload.data,
        },
      };
    }
    case STAFF_ACTIONS.ADD_ACCESS_TO_STAFF_ENTITY_SUCCESS: {
      return {
        ...state,
        officeAccesses: {
          ...state.officeAccesses,
          data: [...state.officeAccesses.data, action.payload],
        },
      };
    }
    case STAFF_ACTIONS.DELETE_ACCESS_TO_STAFF_ENTITY_SUCCESS: {
      return {
        ...state,
        officeAccesses: {
          ...state.officeAccesses,
          data: state.officeAccesses.data.filter((item) => item.id !== action.payload.accessId),
        },
      };
    }
    case STAFF_ACTIONS.ADD_ACCESS_TO_STAFF_ENTITY_REQUEST: {
      const { limit, page } = action.payload || {};

      return {
        ...state,
        rateLimits: {
          ...state.rateLimits,
          filters: {
            limit: limit || 10,
            page: page || 1,
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_RATE_LIMIT_HISTORY_REQUEST: {
      const { limit, page } = action.payload;
      return {
        ...state,
        rateLimits: {
          ...state.rateLimits,
          filters: {
            limit,
            page,
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_RATE_LIMIT_HISTORY_SUCCESS: {
      const { data, total } = action.payload;

      return {
        ...state,
        rateLimits: {
          ...state.rateLimits,
          data,
          total,
        },
      };
    }
    case STAFF_ACTIONS.FETCH_RATE_LIMITS_REQUEST:
    case STAFF_ACTIONS.FETCH_RATE_LIMITS_SUCCESS: {
      return {
        ...state,
        staffRateLimits: {
          ...state.staffRateLimits,
          ...action.payload,
        },
      };
    }
    case STAFF_ACTIONS.SAVE_RATE_LIMITS_SUCCESS:
    case STAFF_ACTIONS.DELETE_RATE_LIMIT_SUCCESS: {
      return {
        ...state,
        staffRateLimits: {
          ...state.staffRateLimits,
          ...action.payload,
        },
      };
    }
    case STAFF_ACTIONS.FETCH_STAFF_GOTU_TERMS_SUCCESS: {
      const {
        data: { accepted, date },
      } = action.payload;

      return {
        ...state,
        staffDetails: {
          ...state.staffDetails,
          tnc: {
            accepted,
            acceptedAt: date,
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_DSO_BY_ID_SUCCESS: {
      const { id, name, code, createdAt } = action.payload;

      return {
        ...state,
        dso: {
          ...state.dso,
          details: {
            id,
            name,
            code,
            createdAt,
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_DSO_GROUPS_REQUEST: {
      const { limit, page, name } = action.payload;

      return {
        ...state,
        dso: {
          ...state.dso,
          groups: {
            ...state.dso.groups,
            filters: {
              limit: limit || 15,
              page: page || 1,
              name,
            },
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_DSO_GROUPS_SUCCESS: {
      const { data, total } = action.payload;

      const isFirstPage = state.dso.groups.filters.page === 1;

      return {
        ...state,
        dso: {
          ...state.dso,
          groups: {
            ...state.dso.groups,
            data: isFirstPage ? data : [...state.dso.groups.data, ...data],
            isFinalPage: data.length < state.dso.groups.filters.limit,
            total,
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_DSO_REGIONS_REQUEST: {
      const { limit, page, name } = action.payload;

      return {
        ...state,
        dso: {
          ...state.dso,
          regions: {
            ...state.dso.regions,
            filters: {
              limit: limit || 15,
              page: page || 1,
              name,
            },
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_DSO_REGIONS_SUCCESS: {
      const { data, total } = action.payload;

      const isFirstPage = state.dso.regions.filters.page === 1;

      return {
        ...state,
        dso: {
          ...state.dso,
          regions: {
            ...state.dso.regions,
            data: isFirstPage ? data : [...state.dso.regions.data, ...data],
            isFinalPage: data.length < state.dso.regions.filters.limit,
            total,
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_DSO_OFFICES_SUCCESS: {
      return {
        ...state,
        dso: {
          ...state.dso,
          offices: {
            ...state.dso.offices,
            data: action.payload.map((office) => ({
              id: office.id,
              name: office.name,
            })),
            total: action.payload.length,
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_OVERVIEW_OFFICES_REQUEST: {
      const { limit, officeName, page } = action.payload;

      return {
        ...state,
        dso: {
          ...state.dso,
          overviews: {
            ...state.dso.overviews,
            filters: {
              limit: limit || 15,
              officeName,
              page: page || 1,
            },
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_OVERVIEW_OFFICES_SUCCESS: {
      const { data, total } = action.payload;

      return {
        ...state,
        dso: {
          ...state.dso,
          overviews: {
            ...state.dso.overviews,
            offices: data,
            totalOffices: total,
          },
        },
      };
    }
    default:
      return state;
  }
};
