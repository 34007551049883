import { OrderBy as OrderByEnum } from '../../../enums/Sorting';
import { SwitchVerticalIcon } from '../../../images/icons';
import { Pressable } from '../../Pressable';

export const OrderBy = ({ onOrderBy, orderBy = OrderByEnum.ASC }) => (
  <Pressable onClick={onOrderBy}>
    <div
      style={{
        display: 'flex',
        gap: 4,
        alignItems: 'center',
      }}
    >
      <span style={{ fontSize: 14, textDecoration: 'underline' }}>
        {orderBy === OrderByEnum.ASC ? 'A-Z' : 'Z-A'}
      </span>
      <SwitchVerticalIcon />
    </div>
  </Pressable>
);
