import { Spinner } from 'react-bootstrap';
import { Colors } from '../../../themes/colors';

export const List = ({
  data,
  onScroll,
  renderItem,
  isLoading,
  emptyText = 'No results found.',
  style = {},
}) => (
  <div
    style={{
      margin: '20px 24px 0 24px',
      paddingBottom: 20,
      minHeight: 400,
      maxHeight: 500,
      overflowY: data.length ? 'scroll' : 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: `#D9D9D9 transparent`,
      overflowX: 'hidden',
      ...style,
    }}
    onScroll={onScroll}
  >
    {!isLoading && !data.length && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 20,
          fontSize: 14,
          color: Colors.neutral_500,
          textAlign: 'center',
        }}
      >
        {emptyText}
      </div>
    )}
    {data.map((item) => renderItem?.({ item }))}

    {isLoading && (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 24,
            width: 24,
          }}
        />
      </div>
    )}
  </div>
);
