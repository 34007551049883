import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { addLicense, deleteLicense, updateCurrentUser } from '../../../actions/userActions';

import { showErrorMessage } from '../../../utils';
import { createLoadingSelector } from '../../../apis/selectors';
import StripeLegalInfo from './licenses/StripeLegalInfo';
import TaxDocument from './TaxDocument';
import PlusIcon from '../../../images/plus.svg';
import { LicenseSection } from './components/LicenseSection/LicenseSection';
import CustomPortal from '../../commonComponents/CustomPortal';
import { getShouldUsingCredentialServiceEndpointForLicense } from '../../../growthbook';
import { LicenseAddItem } from './components/LicenseSection/LicenseAddItem';

const LicenseCredentialTab = ({ onSaveClicked, stateCredentials, allProfessions }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.users.currentUser);

  const actions = ['UPDATE_CURRENT_USER'];
  const loadingSelector = createLoadingSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));

  const [stateIdStatus, setStateIdStatus] = useState(
    currentUser?.professional_info?.government_id?.status || '',
  );
  const [licenses, setLicenses] = useState(currentUser.licenses);
  const [licensesAdd, setLicensesAdd] = useState([]);
  const [editProfile, setEditProfile] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deletedId, setDeleteId] = useState();

  useEffect(() => {
    setLicenses([...currentUser.licenses]);
  }, [currentUser.licenses]);

  const saveEditProfile = () => {
    if (!stateIdStatus.trim()) {
      showErrorMessage({
        message: 'Please enter all fields!',
      });
      return;
    }

    onSaveClicked({
      stateIdStatus,
    });
    setEditProfile(false);
  };

  const showStateIdStatusOptions = () => (
    <select
      className="custom-select custom-select-lg"
      style={{ height: 32, width: '60%' }}
      value={stateIdStatus}
      onChange={(e) => setStateIdStatus(e.target.value)}
    >
      <option className="dropdown-item" value="unverified">
        Unverified
      </option>
      <option className="dropdown-item" value="verified">
        Verified
      </option>
      <option className="dropdown-item" value="invalid">
        Invalid
      </option>
    </select>
  );

  const renderFields = () =>
    isLoading ? (
      <Spinner
        animation="border"
        role="status"
        variant="info"
        style={{
          height: 30,
          width: 30,
          marginLeft: 20,
          marginTop: 10,
        }}
      />
    ) : (
      <div style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600' }}>
            Status
          </p>
          {editProfile ? (
            showStateIdStatusOptions()
          ) : (
            <p className="card-text" style={{ textTransform: 'capitalize' }}>
              {currentUser?.professional_info?.government_id?.status}
            </p>
          )}
        </div>
      </div>
    );

  const showBackgroundCheckOptions = () => (
    <select
      className="custom-select custom-select-lg mb-3"
      style={{ marginTop: '5px', position: 'relative' }}
      value={currentUser?.backgroundCheck?.status}
      onChange={(e) => dispatch(updateCurrentUser({ backgroundCheck: { status: e.target.value } }))}
    >
      <option className="dropdown-item" value="not started">
        Not started
      </option>
      <option className="dropdown-item" value="pending">
        Pending
      </option>
      <option className="dropdown-item" value="consider">
        Consider
      </option>
      <option className="dropdown-item" value="clear">
        Clear
      </option>
      <option className="dropdown-item" value="failed">
        Failed
      </option>
    </select>
  );

  const cancelEditProfile = () => {
    setEditProfile(false);
  };

  return (
    <>
      <div
        style={{
          width: '85%',
          padding: 20,
          border: '1px solid #e5e5e5',
          marginTop: 15,
          backgroundColor: 'white',
        }}
      >
        {showDeletePopup && deletedId ? (
          <CustomPortal
            title="Are you sure you want to delete this license?"
            submitText="Delete"
            cancelText="Cancel"
            submitPressed={() => {
              dispatch(deleteLicense(deletedId));

              setShowDeletePopup(false);
              setDeleteId(null);
            }}
            cancelPressed={() => {
              setShowDeletePopup(false);
              setDeleteId(null);
            }}
          />
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: 180,
              marginLeft: 25,
            }}
          >
            <h4 className="card-title">Background Check: </h4>
            {showBackgroundCheckOptions()}
          </div>
        </div>
        <h3>Licenses</h3>
        <div
          style={{
            display: 'flex',
            backgroundColor: 'white',
          }}
        >
          <div
            className="card mt-5"
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              overflowY: 'auto',
              marginBottom: 50,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <div
                className="card-body"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <h4
                  className="card-title"
                  style={{ marginLeft: 40, fontFamily: 'Nunito', color: '#494963' }}
                >
                  Government I.D.{' '}
                  <span style={{ color: '#899197', fontWeight: '300' }}>Required(*)</span>
                </h4>
                <div style={{ color: '#899197', fontStyle: 'italic' }}>
                  Example: Driver’s License, Government Issued ID
                </div>
              </div>
              <div
                style={{
                  padding: 10,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {editProfile ? null : (
                  <button
                    className="btn btn-info"
                    style={{ width: 94, height: 34 }}
                    onClick={() => setEditProfile(true)}
                    type="button"
                  >
                    Edit
                  </button>
                )}
                {editProfile ? (
                  <button
                    className="btn btn-success"
                    style={{ width: 94, height: 34, marginRight: 10 }}
                    onClick={() => saveEditProfile()}
                    type="button"
                  >
                    Save
                  </button>
                ) : null}
                {editProfile ? (
                  <button
                    className="btn btn-secondary"
                    style={{ width: 94, height: 34 }}
                    onClick={() => cancelEditProfile()}
                    type="button"
                  >
                    Cancel
                  </button>
                ) : null}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid #ededed',
                gap: 10,
                flex: 1,
                marginLeft: 20,
                marginRight: 20,
                paddingBottom: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: 1,
                }}
              >
                {renderFields()}
              </div>
            </div>
          </div>

          <div style={{ width: 20 }} />

          <div
            className="card mt-5"
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              marginBottom: 50,
              paddingTop: 20,
            }}
          >
            <StripeLegalInfo />
          </div>
        </div>

        <LicenseSection
          licenses={licenses}
          setLicenses={setLicenses}
          allProfessions={allProfessions}
          stateCredentials={stateCredentials}
          setDeleteId={setDeleteId}
          setShowDeletePopup={setShowDeletePopup}
        />

        {licensesAdd.map((item) => (
          <LicenseAddItem
            key={item.id}
            license={item}
            licenses={licensesAdd}
            setLicenses={setLicensesAdd}
            dispatch={dispatch}
            stateCredentials={stateCredentials}
            allProfessions={allProfessions}
          />
        ))}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10,
          }}
        >
          <button
            className="btn btn-success"
            style={{ width: 94, height: 34 }}
            onClick={() => {
              const newObject = {
                status: 'new',
                state: 'AL',
                profession: 'DA',
                credentials: [],
                professionName: 'Dental Assistant',
              };

              if (getShouldUsingCredentialServiceEndpointForLicense()) {
                setLicensesAdd((prev) => [...prev, { ...newObject, id: licensesAdd.length }]);
              } else {
                dispatch(addLicense(newObject));
              }
            }}
            type="button"
          >
            <img style={{ marginRight: 4 }} src={PlusIcon} alt="Plus icon" />
            License
          </button>
        </div>
      </div>

      <TaxDocument />
    </>
  );
};

export default LicenseCredentialTab;
