import '../../../../../themes/AvailabilityCalendar.scss';
import moment from 'moment';
import React, { useMemo, useCallback } from 'react';
import DayPicker from 'react-day-picker';
import { useSelector } from 'react-redux';
import { DotIcon } from '../../../../../images/icons/DotIcon';
import { Colors } from '../../../../../themes/colors';
import { FireIcon } from '../../../../../images/icons/FireIcon';
import { DayIcon } from './DayIcon';
import { Spinner } from '../../../../commonComponents/Spinner';
import { createLoadingSelector } from '../../../../../apis/selectors';

const actions = ['FETCH_PROFESSIONAL_AVAILABILITY'];
const loadingSelector = createLoadingSelector(actions);

export const AvailabilityCalendar = () => {
  const isLoading = useSelector(loadingSelector);
  const professionalAvailability = useSelector(
    (state) => state.availability.professionalAvailability,
  );

  const { lastUpdated, onCallAvailability } = professionalAvailability || {};

  const isOnCall = useMemo(
    () => Object.values(onCallAvailability || {}).some((date) => date?.isOnCall),
    [onCallAvailability],
  );

  const renderDay = useCallback(
    (day) => {
      const date = moment(day).format('YYYY-MM-DD');
      const { isOnCall, isAvailability } = onCallAvailability?.[date] || {};
      const isToday = moment(date).isSame(moment(), 'day');

      const selected = isOnCall || isAvailability || isToday;

      return (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
          }}
        >
          {selected && (
            <DayIcon isOnCall={isOnCall} isAvailability={isAvailability} isToday={isToday} />
          )}
          <div style={{ zIndex: 2, color: selected ? Colors.white : 'inherit' }}>
            {day?.getDate()}
          </div>
        </div>
      );
    },
    [onCallAvailability],
  );

  return (
    <div
      className="availability-calendar"
      style={{ width: '100%', alignSelf: 'center', paddingInline: '5%' }}
    >
      <div className="card border-0" style={{ marginBottom: 30 }}>
        <h3 className="card-title" style={{ textAlign: 'center', marginBottom: 24 }}>
          Availability
        </h3>

        {isLoading ? (
          <Spinner />
        ) : (
          <div className="row-block" style={{ margin: 'auto' }}>
            <div className="legend-block" style={{ fontSize: 16, color: Colors.neutral_500 }}>
              <div className="legend-item">
                <DotIcon /> Today
              </div>
              <div className="legend-item">
                <DotIcon color="#6ACE8B" /> Available
              </div>
              <div className="legend-item">
                <FireIcon width={16} height={18} /> On-Call
              </div>
            </div>

            <div>
              <DayPicker
                renderDay={renderDay}
                disabledDays={{
                  before: new Date(),
                  after: moment()
                    .add(99 - 1, 'days') // total 99 days, minus 1 day as today is included
                    .toDate(),
                }}
              />
            </div>

            <div style={{ paddingInline: 12, display: 'flex', gap: 12, fontSize: 18 }}>
              <span>
                <strong>Last Updated:</strong>{' '}
                {lastUpdated ? moment(lastUpdated).format('MM-DD-YYYY') : '-'}
              </span>
              <span>|</span>
              <span>
                On-Call is <strong>{isOnCall ? 'ON' : 'OFF'}</strong>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
