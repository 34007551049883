/**
 * LicenseAddItem
 * - client side LicenseSectionItem for add license v2
 * - sister files: LicenseSectionItem.js
 */

import { useDispatch } from 'react-redux';
import { CredentialType } from '../../../../../utils/CredentialUtils';
import { StateCredentialAdd } from '../../credentialComponents/StateCredentialAdd';

export const LicenseAddItem = ({
  license,
  licenses,
  setLicenses,
  stateCredentials,
  allProfessions,
  setDeleteId,
  setShowDeletePopup,
}) => {

  const dispatch = useDispatch();

  const stateLicense = license.credentials.find(
    (credential) => credential && credential.credential_type === CredentialType.StateLicense,
  );
  const daCertificate = license.credentials.find(
    (credential) => credential && credential.credential_type === CredentialType.DACertificate,
  );
  const cprBls = license.credentials.find(
    (credential) => credential && credential.credential_type === CredentialType.CprBls,
  );
  const radiology = license.credentials.find(
    (credential) => credential && credential.credential_type === CredentialType.Radiology,
  );
  const reference = license.credentials.find(
    (credential) => credential && credential.credential_type === CredentialType.Reference,
  );

  const professionCredential = stateCredentials?.[license.state]?.[license.profession];

  const hasStateLicense = professionCredential?.state_license;
  const hasDACertificate = professionCredential?.da_certificate;
  const hasCPRBLS = professionCredential?.[CredentialType.CprBls];
  const hasRadiology = professionCredential?.radiology;
  const hasReference = professionCredential?.reference;

  const hasOnlyOneCredential =
    license?.state &&
    license?.profession &&
    stateCredentials &&
    stateCredentials[license.state] &&
    professionCredential &&
    Object.keys(professionCredential).length === 1;


  const licenseContainerStyle = {
        marginBottom: 70,
      };

  const renderStateCredential = () => (
    <StateCredentialAdd
        license={license}
        stateLicense={stateLicense}
        reference={reference}
        daCertificate={daCertificate}
        cprBls={cprBls}
        radiology={radiology}
        hasStateLicense={hasStateLicense}
        hasDACertificate={hasDACertificate}
        hasCPRBLS={hasCPRBLS}
        hasReference={hasReference}
        hasRadiology={hasRadiology}
        hasOnlyOneCredential={hasOnlyOneCredential}
        stateCredentials={stateCredentials}
        setLicenses={setLicenses}
        allProfessions={allProfessions}
        licenses={licenses}
        setDeleteId={setDeleteId}
        setShowDeletePopup={setShowDeletePopup}
        dispatch={dispatch}
      />
    );
  
  return (
    <div
      style={{
        border: '1px solid #b7b7b7',
        ...licenseContainerStyle,
      }}
    >
      {renderStateCredential()}
    </div>
  );
};
