import { useEffect, useState } from 'react';
import debounceHook from '../../../../commonComponents/debounceHook';
import { Colors } from '../../../../../themes/colors';

export const Filters = ({ filters = {}, handleFilterChange = () => {} }) => {
  const [searchText, setSearchText] = useState(filters?.dsoName || '');
  const debouncedSearchText = debounceHook(searchText, 1000);

  useEffect(() => {
    if (filters?.dsoName === debouncedSearchText) return;
    handleFilterChange('dsoName', debouncedSearchText);
  }, [debouncedSearchText, handleFilterChange, filters.dsoName]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        justifyContent: 'center',
      }}
    >
      <label htmlFor="inputSearch" style={{ margin: 0 }}>
        Search:
      </label>
      <input
        id="inputSearch"
        style={{
          paddingLeft: '8px',
          fontFamily: 'Nunito-Regular',
          width: 340,
          height: 32,
          boxShadow: 'none',
          backgroundColor: Colors.white,
          border: `1px solid ${Colors.border}`,
        }}
        placeholder="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
};
