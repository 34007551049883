import { InputSearchDebounce } from '../../../../../../../../../commonComponents/InputSearch';

export const SearchAccess = ({ placeholder, onSearch }) => (
  <div
    style={{
      width: 320,
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      marginInline: 'auto',
      marginTop: 24,
      marginBottom: 20,
    }}
  >
    <b
      style={{
        fontSize: 14,
        textWrap: 'nowrap',
      }}
    >
      Search:
    </b>
    <InputSearchDebounce placeholder={placeholder} onSearch={onSearch} />
  </div>
);
