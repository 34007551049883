import { useParams } from 'react-router-dom';
import { SubHeader } from '../../../../../../../../commonComponents/CardList/SubHeader';
import { useGroupsSubHeader } from '../hooks';
import { Reset } from './Reset';

export const GroupsSubHeader = () => {
  const { id: dsoId } = useParams();
  const { total, onOrderBy, orderBy } = useGroupsSubHeader(dsoId);

  return (
    <SubHeader total={total} onOrderBy={onOrderBy} orderBy={orderBy}>
      <Reset dsoId={dsoId} />
    </SubHeader>
  );
};
