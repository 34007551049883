import { useQuery } from '@tanstack/react-query';
import { fetchGroupsLinkedToRegion } from '../../../../../../../../../../../services/dsos';

export const useGroupsLinkedToRegion = (regionId) => {
  const { data, isLoading } = useQuery({
    queryKey: ['groupsLinkedToRegion', regionId],
    queryFn: () => fetchGroupsLinkedToRegion(regionId),
  });

  return { groups: data ?? [], isLoading };
};
