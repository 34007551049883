import { useDispatch, useSelector } from 'react-redux';
import {
  clearOrgStructure,
  fetchDsoGroupList,
  fetchDsoOffices,
  fetchDsoRegionList,
} from '../../../../../../../../../actions/dsosActions';
import { OrderBy } from '../../../../../../../../../enums/Sorting';

export const useOfficesSubHeader = (dsoId) => {
  const dispatch = useDispatch();
  const {
    total,
    filters: { orderBy, officeName },
  } = useSelector((state) => state.dsos.orgStructure.officeList);

  const onOrderBy = () => {
    dispatch(clearOrgStructure());
    Promise.all([
      dispatch(fetchDsoGroupList(dsoId, { page: 1, limit: 15 })),
      dispatch(fetchDsoRegionList(dsoId, { page: 1, limit: 100 })),
      dispatch(
        fetchDsoOffices(dsoId, {
          page: 1,
          limit: 15,
          orderBy: orderBy === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC,
          officeName,
        }),
      ),
    ]);
  };

  return {
    orderBy,
    onOrderBy,
    total,
  };
};
