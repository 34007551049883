import { useParams } from 'react-router-dom';
import { SubHeader } from '../../../../../../../../commonComponents/CardList/SubHeader';
import { useRegionsSubHeader } from '../hooks';
import { SelectAll } from './SelectAll';

export const RegionsSubHeader = () => {
  const { id: dsoId } = useParams();
  const { total, onOrderBy, orderBy } = useRegionsSubHeader(dsoId);

  return (
    <SubHeader total={total} onOrderBy={onOrderBy} orderBy={orderBy}>
      <SelectAll />
    </SubHeader>
  );
};
