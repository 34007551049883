/**
 * DragDrop
 * - client side DragDrop for add license v2
 * - sister files: DragDrop.js
 */


/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import imageCompression from 'browser-image-compression';

export const DragAndDropV2 = ({ editMode, license, setLicenses, credentialType }) => {

  const uploadFile = async (fileParam) => {

    const options = {
      maxSizeMB: 1,
    };
    let file = fileParam;
    try {
      file = await imageCompression(file, options);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    setLicenses((prevState) => {
      const licenseIndex = prevState.findIndex((data) => data.id === license.id);
      const credentialIndex = prevState[licenseIndex].credentials.findIndex(
        (c) => c && c?.credential_type === credentialType,
      );
      if (credentialIndex === -1) {
        prevState[licenseIndex].credentials.push({
          credential_type: credentialType,
          credential_input_type: 'upload',
          files: [
            {
              path: file,
              uri: URL.createObjectURL(file),
            },
          ],
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        prevState[licenseIndex].credentials[credentialIndex].files = [
          ...prevState[licenseIndex].credentials[credentialIndex].files,
          { path: file, uri: URL.createObjectURL(file) },
        ];
      }
      return [...prevState];
    });
  };

  if (!editMode) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 50,
        position: 'relative',
        cursor: 'pointer',
      }}
    >
      <h4
        className="card-text"
        style={{
          textAlign: 'center',
          fontSize: '16px',
          marginTop: 20,
        }}
      >
        <p style={{ color: '#899197' }}>Drag and drop files here or</p>
      </h4>

      <div>
        <label htmlFor={`${license.id}`} className="btn btn-default">
          Browse
        </label>
        <input
          onChange={(event) => {
            uploadFile(event.target.files[0]);
            return null;
          }}
          type="file"
          accept="image/png, image/gif, image/jpeg"
          id={`${license.id}`}
          style={{
            opacity: 0,
            padding: 20,
            position: 'absolute',
            bottom: 20,
            right: 0,
            cursor: 'pointer',
          }}
        />
      </div>
    </div>
  );
};

