import { Colors } from '../../../../themes/colors';
import { TabViewContainer } from '../../../commonComponents/TabViewContainer';
import { CreateNewButton } from '../../DsoGroupDetails/components/OfficeAdmin/CreateNewButton';
import { StaffList, TableHeader } from './components';
import { useOfficeAdminList } from './hooks/useOfficeAdminList';

export const OfficeAdminList = () => {
  const { data, filters, isLoading, total, totalPages, handleFilterChanged, handlePageChanged } =
    useOfficeAdminList();

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <TabViewContainer
        title="Office Staff"
        style={{
          marginInline: 'auto',
          width: '85%',
          paddingBottom: 20,
        }}
      >
        <TableHeader filters={filters} handleFilterChanged={handleFilterChanged} />
        <article
          style={{
            alignItems: 'center',
            display: 'flex',
            gap: 20,
            justifyContent: 'flex-end',
            padding: 20,
            width: '100%',
          }}
        >
          <CreateNewButton
            className="btn btn-success"
            dsoId={undefined}
            buttonText="Create New Staff Member"
            modalTitle="Create New Staff Member Account"
          />
          <span
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              color: Colors.neutral_600,
            }}
          >
            Total | {total !== undefined ? Intl.NumberFormat().format(total) : ''}
          </span>
        </article>
        <TabViewContainer variant="card" style={{ margin: 0, padding: 20 }}>
          <StaffList
            data={data}
            filters={filters}
            isLoading={isLoading}
            total={total}
            totalPages={totalPages}
            handlePageChanged={handlePageChanged}
          />
        </TabViewContainer>
      </TabViewContainer>
    </div>
  );
};
