import { CheckCircleIcon, PlusCircleIcon } from '../../../../../../../../../images/icons';
import { Colors } from '../../../../../../../../../themes/colors';
import { Pressable } from '../../../../../../../../Pressable';
import { DefaultItem } from '../../DefaultItem';
import { useOfficeItem } from '../hooks';
import { LinkOfficesToRegionMenuItem } from './LinkOfficesToRegionMenuItem';
import { ViewOfficeMenuItem } from './MenuItems';
import { UnlinkOfficeFromRegionMenuItem } from './UnlinkOfficeFromRegionMenuItem';

export const OfficeItem = ({ dsoId, item }) => {
  const { onButtonClick, isSelected, isBulkOfficeSelectionActive } = useOfficeItem(item);

  const getLeadingComponent = () => {
    if (!isBulkOfficeSelectionActive) {
      return null;
    }

    return (
      <Pressable onClick={onButtonClick}>
        {isSelected ? <CheckCircleIcon /> : <PlusCircleIcon />}
      </Pressable>
    );
  };

  return (
    <DefaultItem
      text={item.name}
      LeadingComponent={getLeadingComponent}
      MenuOptions={() => (
        <>
          <ViewOfficeMenuItem item={item} />
          <LinkOfficesToRegionMenuItem dsoId={dsoId} items={[item]} />
          {item.regionID && <UnlinkOfficeFromRegionMenuItem item={item} />}
        </>
      )}
      {...(isSelected && {
        itemStyles: {
          backgroundColor: Colors.neutral_100,
        },
      })}
      textStyle={{
        color: item.regionID ? Colors.neutral_600 : '#E26E26',
      }}
    />
  );
};
