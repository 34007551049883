import { useState } from 'react';
import { ViewDocumentIcon } from '../../../../../../../../../../images/icons';
import { MenuItem } from '../../../../../../../../../commonComponents/DotsMenu';
import { GroupsLinkedToRegionPopup } from './GroupsLinkedToRegionPopup';

export const ManageLinkedGroups = ({ item }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MenuItem
        Icon={() => <ViewDocumentIcon />}
        text="Manage Linked Groups"
        onClick={() => setShowModal(true)}
      />

      {showModal && (
        <GroupsLinkedToRegionPopup region={item} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};
