import { clearQueryCache, getQueryData, shouldFetchWithNewParams } from '../../apis/cache';
import tempMee from '../../apis/tempMee';
import history from '../../history';
import { removeFalsyValues } from '../../utils';
import { isObjectEqual } from '../../utils/Object';
import { DSOS_ACTIONS, SHOW_MESSAGE } from '../actionTypes';
import { getAuthHeaders } from '../authActions';
import { deleteRegionById as deleteRegionByIdService } from '../../services/dsos';

export const deleteRegionById =
  ({ dsoId, regionId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: DSOS_ACTIONS.REMOVE_REGION_FROM_DSO_REQUEST });

      await deleteRegionByIdService({ dsoId, regionId });

      dispatch({
        type: DSOS_ACTIONS.REMOVE_REGION_FROM_DSO_SUCCESS,
        payload: { dsoId, regionId },
      });

      const { dsoId: cacheDsoId, ...filters } = getQueryData('dsoRegionList');
      dispatch(fetchDsoRegionList(cacheDsoId, { ...filters, page: 1, filter: 15 }, true));

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: 'The region was removed from DSO successfully.',
        },
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.REMOVE_REGION_FROM_DSO_FAILURE, payload: e });
    }
  };

export const fetchDsosList =
  (filters, isInfiniteScroll = false) =>
  async (dispatch, getState) => {
    try {
      const { data, filters: stateFilters, isActiveRequest, isFinal } = getState().dsos.dsosList;
      const newFilterParams = { ...stateFilters, ...filters };

      if (
        isFinal ||
        isActiveRequest ||
        (data.length > 0 && isObjectEqual(stateFilters, newFilterParams))
      ) {
        return;
      }

      dispatch({ type: DSOS_ACTIONS.FETCH_DSOS_LIST_REQUEST, payload: newFilterParams });
      const headers = await getAuthHeaders();
      const response = await tempMee.get('/office-service/admin/dsos', {
        params: {
          page: filters.page || stateFilters.page,
          limit: filters.limit || stateFilters.limit,
          ...removeFalsyValues(newFilterParams),
        },
        headers,
      });
      dispatch({
        type: DSOS_ACTIONS.FETCH_DSOS_LIST_SUCCESS,
        payload: { ...response.data, page: filters.page, isInfiniteScroll },
      });
    } catch (err) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSOS_LIST_FAILURE, payload: err });
    }
  };

export const fetchDsoById = (dsoId) => async (dispatch) => {
  try {
    dispatch({ type: DSOS_ACTIONS.FETCH_DSO_BY_ID_REQUEST });
    const headers = await getAuthHeaders();
    const response = await tempMee.get(`/office-service/admin/dsos/${dsoId}`, {
      headers,
    });
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_BY_ID_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.FETCH_DSO_BY_ID_FAILURE, payload: err });
  }
};

export const fetchDsoGroupList =
  (dsoId, filters = { page: 1, limit: 15 }, refetch = false) =>
  async (dispatch, getState) => {
    const {
      data,
      filters: stateFilters,
      isActiveRequest,
      isFinalPage,
    } = getState().dsos.orgStructure.groupList;
    const newFilters = { dsoId, ...stateFilters, ...filters };

    if (
      isFinalPage ||
      isActiveRequest ||
      (data.length > 0 && isObjectEqual(stateFilters, newFilters))
    ) {
      return;
    }

    if (!refetch && !shouldFetchWithNewParams('dsoGroupList', newFilters)) {
      return;
    }

    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_REQUEST,
      payload: { dsoId, page: filters.page, orderBy: filters.orderBy },
    });

    try {
      const { page, limit } = getState().dsos.dsoGroupList.filters;
      const headers = await getAuthHeaders();
      const response = await tempMee.get(`/office-service/admin/dsos/${dsoId}/groups`, {
        params: {
          page: filters.page || page,
          limit: filters.limit || limit,
          orderBy: filters.orderBy || undefined,
          ...removeFalsyValues(filters),
        },
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_FAILURE, payload: err });
    }
  };

export const addNewDso = (name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.ADD_DSO_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(`/office-service/admin/dsos`, { name }, { headers });

    history.push(`/dsos/${response.data.id}/orgStructure`, {
      id: response.data.id,
      name: response.data.name,
    });

    dispatch({
      type: DSOS_ACTIONS.ADD_DSO_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The DSO Group was created successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.ADD_DSO_FAILURE, payload: err });
  }
};

export const addNewGroupToDso = (dsoID, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.ADD_GROUP_TO_DSO_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `/office-service/admin/dsos/${dsoID}/groups`,
      { name },
      {
        headers,
      },
    );
    dispatch({
      type: DSOS_ACTIONS.ADD_GROUP_TO_DSO_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The group was created successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.ADD_GROUP_TO_DSO_FAILURE, payload: err });
  }
};

export const addNewAdminToDso = (dsoId, formValues) => async (dispatch, getState) => {
  dispatch({ type: DSOS_ACTIONS.ADD_DSO_OFFICE_ADMIN_REQUEST });

  try {
    const headers = await getAuthHeaders();
    const { access_token: accessToken } = getState().auth;
    const response = await tempMee.post(
      `/auth-service/admin/office-staffs`,
      {
        dsoID: dsoId,
        email: formValues.email,
        lastName: formValues.lastName,
        firstName: formValues.firstName,
        phone: formValues.phoneNumber,
      },
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    dispatch({
      type: DSOS_ACTIONS.ADD_DSO_OFFICE_ADMIN_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The Staff was created successfully.',
      },
    });

    dispatch(fetchOfficeAdminsList({ dsoId }));
  } catch (e) {
    dispatch({ type: DSOS_ACTIONS.ADD_DSO_OFFICE_ADMIN_FAILURE, payload: e });
  }
};

export const editDsoGroupName = (dsoId, groupId, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `/office-service/admin/dsos/${dsoId}/groups/${groupId}`,
      { name },
      { headers },
    );
    dispatch({
      type: DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The group name was edited successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_FAILURE, payload: err });
  }
};

export const fetchDsoRegionList =
  (dsoId, filters = { page: 1, limit: 15 }, refetch = false) =>
  async (dispatch, getState) => {
    const {
      data,
      filters: stateFilters,
      isActiveRequest,
      isFinalPage,
    } = getState().dsos.orgStructure.regionList;
    const newFilters = { dsoId, ...stateFilters, ...filters };

    if (
      isFinalPage ||
      isActiveRequest ||
      (data.length > 0 && isObjectEqual(stateFilters, newFilters))
    ) {
      return;
    }

    if (!refetch && !shouldFetchWithNewParams('dsoRegionList', newFilters)) {
      return;
    }

    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_REGION_LIST_REQUEST,
      payload: {
        dsoId,
        limit: filters.limit || 15,
        page: filters.page || 1,
        groupID: filters.groupID,
        orderBy: filters.orderBy,
      },
    });

    try {
      const { page, limit, groupID } = getState().dsos.orgStructure.regionList.filters;
      const headers = await getAuthHeaders();
      const response = await tempMee.get(`/office-service/admin/dsos/${dsoId}/regions`, {
        params: {
          page,
          limit,
          groupID: groupID || undefined,
          orderBy: filters.orderBy || undefined,
        },
        headers,
      });
      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_REGION_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_REGION_LIST_FAILURE, payload: err });
    }
  };

export const addNewRegionToDso = (dsoID, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.ADD_REGION_TO_DSO_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `/office-service/admin/dsos/${dsoID}/regions`,
      { name },
      {
        headers,
      },
    );
    dispatch({
      type: DSOS_ACTIONS.ADD_REGION_TO_DSO_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The region was created successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.ADD_REGION_TO_DSO_FAILURE, payload: err });
  }
};

export const editDsoRegionName = (dsoId, regionId, name) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.EDIT_DSO_REGION_NAME_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `/office-service/admin/dsos/${dsoId}/regions/${regionId}`,
      { name },
      { headers },
    );
    dispatch({
      type: DSOS_ACTIONS.EDIT_DSO_REGION_NAME_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'The region name was edited successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: DSOS_ACTIONS.EDIT_DSO_REGION_NAME_FAILURE, payload: err });
  }
};

export const fetchOfficeAdminsList =
  (filters = {}, isInfiniteScroll = true) =>
  async (dispatch, getState) => {
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_OFFICE_ADMINS_LIST_REQUEST,
      payload: {
        dsoId: filters.dsoId,
        page: filters.page,
        officeId: filters.officeId,
        staffEmail: filters.staffEmail,
        staffName: filters.staffName,
        staffPhone: filters.staffPhone,
        regionId: filters.regionId,
        groupId: filters.groupId,
      },
    });
    try {
      const { page, limit } = getState().dsos.dsoOfficeAdminsList.filters;
      const headers = await getAuthHeaders();
      const { access_token: accessToken } = getState().auth;
      const response = await tempMee.get(`/auth-service/admin/office-staffs`, {
        params: {
          dsoID: filters.dsoId,
          limit: filters.limit || limit,
          officeID: filters.officeId || undefined,
          page: filters.page || page,
          staffEmail: filters.staffEmail,
          staffName: filters.staffName,
          staffPhone: filters.staffPhone,
          regionID: filters.regionId,
          groupID: filters.groupId,
          sortBy: 'createdAt',
          orderBy: 'desc',
        },
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_OFFICE_ADMINS_LIST_SUCCESS,
        payload: {
          data: response.data,
          isInfiniteScroll,
        },
      });
    } catch (err) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_OFFICE_ADMINS_LIST_FAILURE, payload: err });
    }
  };

export const fetchDsoRegionOffices =
  (entity, entityId, filter = { page: 1, limit: 15 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_REGION_OFFICE_LIST_REQUEST,
      payload: {
        entity,
        entityId,
        limit: filter.limit || 15,
        page: filter.page || 1,
      },
    });

    try {
      const { entity, entityId, limit, page } = getState().dsos.dsoRegionOfficeList.filters;

      const headers = await getAuthHeaders();

      const response = await tempMee.get(`/office-service/admin/offices`, {
        params: {
          entityID: entityId,
          entityType: entity,
          limit,
          page,
        },
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_REGION_OFFICE_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_REGION_OFFICE_LIST_FAILURE, payload: e });
    }
  };

export const fetchDsoGroupOffices =
  (entity, entityId, filter = { page: 1, limit: 15 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_GROUP_OFFICE_LIST_REQUEST,
      payload: {
        entity,
        entityId,
        limit: filter.limit || 15,
        page: filter.page || 1,
      },
    });

    try {
      const { entity, entityId, limit, page } = getState().dsos.dsoGroupOfficeList.filters;

      const headers = await getAuthHeaders();

      const response = await tempMee.get(`/office-service/admin/offices`, {
        params: {
          entityID: entityId,
          entityType: entity,
          limit,
          page,
        },
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_GROUP_OFFICE_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_GROUP_OFFICE_LIST_FAILURE, payload: e });
    }
  };

export const clearDsoRegionOffices = () => (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.CLEAR_DSO_REGION_OFFICE_LIST });
};

export const clearDsoGroupOffices = () => (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.CLEAR_DSO_GROUP_OFFICE_LIST });
};

export const clearDsoOffices = () => (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.CLEAR_DSO_OFFICE_LIST });
};

export const getOfficeById = (officeId) => async (dispatch) => {
  dispatch({
    type: DSOS_ACTIONS.GET_OFFICE_BY_ID_REQUEST,
  });

  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.get(`/office-service/admin/offices/${officeId}`, {
      headers,
    });

    dispatch({
      type: DSOS_ACTIONS.GET_OFFICE_BY_ID_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({ type: DSOS_ACTIONS.GET_OFFICE_BY_ID_FAILURE, payload: e });
  }
};

export const assignOfficesToDso = (dsoId, officesIds) => async (dispatch) => {
  dispatch({
    type: DSOS_ACTIONS.ASSIGN_OFFICES_TO_DSO_REQUEST,
  });

  try {
    const headers = await getAuthHeaders();

    await tempMee.put(
      `/office-service/admin/dsos/${dsoId}/offices`,
      {
        officeIDs: officesIds,
      },
      {
        headers,
      },
    );

    dispatch({
      type: DSOS_ACTIONS.ASSIGN_OFFICES_TO_DSO_SUCCESS,
      payload: { dsoId, officesIds },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'DSO Name was updated successfully.' },
    });
  } catch (e) {
    dispatch({ type: DSOS_ACTIONS.ASSIGN_OFFICES_TO_DSO_FAILURE, payload: e });
  }
};

export const fetchDsoOffices =
  (dsoId, filters = { page: 1, limit: 15 }) =>
  async (dispatch, getState) => {
    const { filters: stateFilters } = getState().dsos.orgStructure.officeList;
    const newFilters = { dsoId, ...stateFilters, ...filters };
    if (!shouldFetchWithNewParams('dsoOfficeList', newFilters)) {
      return;
    }

    dispatch({
      type: DSOS_ACTIONS.FETCH_DSO_OFFICE_LIST_REQUEST,
      payload: {
        dsoId,
        limit: filters.limit || 15,
        page: filters.page || 1,
        groupIDs: filters.groupIDs,
        regionIDs: filters.regionIDs,
        officeName: filters.officeName,
        orderBy: filters.orderBy,
        emptyRegion: filters.emptyRegion,
      },
    });

    try {
      const {
        dsoId,
        filters: { limit, page, regionIDs, groupIDs, officeName },
      } = getState().dsos.orgStructure.officeList;

      const headers = await getAuthHeaders();

      const response = await tempMee.get(`/office-service/admin/offices`, {
        params: {
          dsoIDs: dsoId,
          limit,
          page,
          regionIDs: regionIDs || undefined,
          groupIDs: groupIDs || undefined,
          officeName: officeName || undefined,
          orderBy: filters.orderBy || undefined,
          emptyRegion: filters.emptyRegion || undefined,
        },
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.FETCH_DSO_OFFICE_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.FETCH_DSO_OFFICE_LIST_FAILURE, payload: e });
    }
  };

export const addOfficesToRegion =
  ({ regionId, officesIds }) =>
  async (dispatch) => {
    dispatch({ type: DSOS_ACTIONS.ADD_OFFICES_TO_REGION_REQUEST });

    try {
      const headers = await getAuthHeaders();
      await tempMee.put(
        `/office-service/admin/regions/${regionId}/offices`,
        { OfficeIDs: officesIds },
        { headers },
      );

      dispatch({
        type: DSOS_ACTIONS.ADD_OFFICES_TO_REGION_SUCCESS,
        payload: { regionId, officesIds },
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `The ${officesIds.length > 1 ? 'offices were' : 'office was'} added to the region successfully.`,
        },
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.ADD_OFFICES_TO_REGION_FAILURE, payload: e });
    }
  };

/* Org Structure */
export const clearOrgStructure = () => (dispatch) => {
  clearQueryCache(['dsoGroupList', 'dsoRegionList', 'dsoOfficeList']);
  dispatch({ type: DSOS_ACTIONS.CLEAR_ORG_STRUCTURE });
};

export const selectDsoGroup = (groupId) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.SELECT_DSO_GROUP_ID, payload: groupId });
};

export const getGroupRegionsAndOffices =
  ({ dsoId, groupId }) =>
  async (dispatch, getState) => {
    clearQueryCache([`dsoRegionList`, `dsoOfficeList`]);
    dispatch({ type: DSOS_ACTIONS.GET_GROUP_REGIONS_AND_OFFICES_REQUEST });

    try {
      await dispatch(fetchDsoRegionList(dsoId, { page: 1, limit: 100, groupID: groupId }));

      const { data: regionList } = getState().dsos.orgStructure.regionList;

      await dispatch(
        fetchDsoOffices(dsoId, {
          regionIDs: regionList.map((region) => region.id).join(','),
          groupIDs: groupId,
          page: 1,
          limit: 15,
        }),
      );

      dispatch({
        type: DSOS_ACTIONS.GET_GROUP_REGIONS_AND_OFFICES_SUCCESS,
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.GET_GROUP_REGIONS_AND_OFFICES_FAILURE, payload: e });
    }
  };

export const selectDsoRegion = (regionId) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.SELECT_DSO_REGION_ID, payload: regionId });
};

export const selectMultipleRegions = (regionIds) => async (dispatch) => {
  clearQueryCache('dsoOfficeList');
  dispatch({ type: DSOS_ACTIONS.SELECT_MULTIPLE_REGIONS, payload: regionIds });
};

export const addRegionToGroup =
  ({ groupId, region }) =>
  async (dispatch) => {
    dispatch({ type: DSOS_ACTIONS.ADD_REGION_TO_GROUP_REQUEST });

    try {
      const headers = await getAuthHeaders();
      await tempMee.post(
        `/office-service/admin/groups/${groupId}/regions/${region.id}`,
        {},
        { headers },
      );

      dispatch({
        type: DSOS_ACTIONS.ADD_REGION_TO_GROUP_SUCCESS,
        payload: { region, groupId },
      });

      const { dsoId, ...filters } = getQueryData('dsoGroupList');
      dispatch(fetchDsoGroupList(dsoId, { ...filters, page: 1, filter: 15 }, true));

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `The region was added to the group successfully.`,
        },
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.ADD_REGION_TO_GROUP_FAILURE, payload: e });
    }
  };

export const assignOfficesToRegion =
  ({ regionId, offices }) =>
  async (dispatch) => {
    dispatch({ type: DSOS_ACTIONS.ASSIGN_OFFICES_TO_REGION_REQUEST });

    try {
      const headers = await getAuthHeaders();
      await tempMee.put(
        `/office-service/admin/regions/${regionId}/offices`,
        { officeIDs: offices.map((office) => office.id) },
        { headers },
      );

      dispatch({
        type: DSOS_ACTIONS.ASSIGN_OFFICES_TO_REGION_SUCCESS,
        payload: { regionId, offices },
      });

      const { dsoId, ...filters } = getQueryData('dsoRegionList');
      dispatch(fetchDsoRegionList(dsoId, filters, true));

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: `The ${offices.length > 1 ? 'offices were' : 'office was'} added to the region successfully.`,
        },
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.ASSIGN_OFFICES_TO_REGION_FAILURE, payload: e });
    }
  };

export const fetchDsoJobs =
  (dsoId, filters = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: DSOS_ACTIONS.GET_JOBS_REQUEST,
      payload: {
        dsoId,
        limit: filters.limit || 15,
        page: filters.page || 1,
      },
    });

    try {
      const { page, limit } = getState().dsos.dsoJobs.filters;
      const headers = await getAuthHeaders();

      const response = await tempMee.get(`/admin/dsos/${dsoId}/jobs`, {
        params: {
          limit: filters.limit || limit,
          page: filters.page || page,
        },
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.GET_JOBS_SUCCESS,
        payload: {
          data: response.data,
        },
      });
    } catch (err) {
      dispatch({ type: DSOS_ACTIONS.GET_JOBS_FAILURE, payload: err });
    }
  };

export const toggleBulkOfficeSelection = () => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.TOGGLE_BULK_OFFICE_SELECTION });
};

export const selectDsoOffice = (office) => async (dispatch) => {
  dispatch({ type: DSOS_ACTIONS.SELECT_DSO_OFFICE, payload: office });
};

export const unlinkOfficeFromRegion =
  ({ regionId, officeId }) =>
  async (dispatch) => {
    dispatch({ type: DSOS_ACTIONS.UNLINK_OFFICE_FROM_REGION_REQUEST });

    try {
      const headers = await getAuthHeaders();
      await tempMee.delete(
        `/office-service/admin/regions/${regionId}/offices?officeID=${officeId}`,
        {
          headers,
        },
      );

      dispatch({
        type: DSOS_ACTIONS.UNLINK_OFFICE_FROM_REGION_SUCCESS,
        payload: { regionId, officeId },
      });

      const { dsoId, ...filters } = getQueryData('dsoRegionList');
      dispatch(fetchDsoRegionList(dsoId, filters, true));

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: 'The office was unlinked from region successfully.',
        },
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.UNLINK_OFFICE_FROM_REGION_FAILURE, payload: e });
    }
  };

export const unlinkRegionFromGroup =
  ({ regionId, groupId }) =>
  async (dispatch) => {
    dispatch({ type: DSOS_ACTIONS.UNLINK_REGION_FROM_GROUP_REQUEST });

    try {
      const headers = await getAuthHeaders();
      await tempMee.delete(`/office-service/admin/groups/${groupId}/regions/${regionId}`, {
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.UNLINK_REGION_FROM_GROUP_SUCCESS,
        payload: { regionId, groupId },
      });

      const { dsoId, ...filters } = getQueryData('dsoGroupList');
      dispatch(fetchDsoGroupList(dsoId, { ...filters, page: 1, filter: 15 }, true));

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: 'The region was unlinked from group successfully.',
        },
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.UNLINK_REGION_FROM_GROUP_FAILURE, payload: e });
    }
  };

export const removeGroupFromDSO =
  ({ dsoId, groupId }) =>
  async (dispatch) => {
    dispatch({ type: DSOS_ACTIONS.REMOVE_GROUP_FROM_DSO_REQUEST });

    try {
      const headers = await getAuthHeaders();
      await tempMee.delete(`/office-service/admin/dsos/${dsoId}/groups/${groupId}`, {
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.REMOVE_GROUP_FROM_DSO_SUCCESS,
        payload: { dsoId, groupId },
      });

      const { dsoId: cacheDsoId, ...filters } = getQueryData('dsoGroupList');
      dispatch(fetchDsoGroupList(cacheDsoId, { ...filters, page: 1, filter: 15 }, true));

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: 'The group was removed from DSO successfully.',
        },
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.REMOVE_GROUP_FROM_DSO_FAILURE, payload: e });
    }
  };

export const removeOfficeFromDSO =
  ({ dsoId, officeId }) =>
  async (dispatch) => {
    dispatch({ type: DSOS_ACTIONS.REMOVE_OFFICE_FROM_DSO_REQUEST });

    try {
      const headers = await getAuthHeaders();
      await tempMee.delete(`/office-service/admin/dsos/${dsoId}/offices?officeID=${officeId}`, {
        headers,
      });

      dispatch({
        type: DSOS_ACTIONS.REMOVE_OFFICE_FROM_DSO_SUCCESS,
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: 'The office was removed from DSO successfully.',
        },
      });
    } catch (e) {
      dispatch({ type: DSOS_ACTIONS.REMOVE_OFFICE_FROM_DSO_FAILURE, payload: e });
    }
  };
