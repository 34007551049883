import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchRateLimitHistories } from '../../../../../../../../../actions/staffActions';
import { createLoadingSelector } from '../../../../../../../../../apis/selectors';
import { useMounted } from '../../../../../../../../../hooks/useMounted';

const loading = createLoadingSelector(['FETCH_RATE_LIMIT_HISTORY']);

export const useRateLimitHistory = () => {
  const dispatch = useDispatch();
  const { isMounted } = useMounted();

  const { id: staffId } = useParams();

  const isLoading = useSelector(loading);
  const { data, filters, total } = useSelector((state) => state.staff.rateLimits);

  const handlePageChanged = useCallback(
    (page) => {
      dispatch(fetchRateLimitHistories(staffId, { page }));
    },
    [dispatch, staffId],
  );

  useEffect(() => {
    if (!isMounted || !staffId) return;

    dispatch(fetchRateLimitHistories(staffId, { page: 1 }));
  }, [dispatch, isMounted, staffId]);

  return {
    data,
    filters,
    isInitialLoading: !data.length && isLoading,
    isLoading,
    total,
    totalPages: Math.ceil(total / filters.limit),
    handlePageChanged,
  };
};
