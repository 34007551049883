import dateFormat from 'dateformat';
import {
  GIFT_CARD_REDEEM_POINT_STATUS,
  GIFT_CARD_REDEEM_POINT_STATUS_TEXT,
} from '../../../enums/ProperksStatus';
import { Colors } from '../../../themes/colors';
import { formatNumber } from '../../../utils/NumberUtils';

const style = {
  tableText: {
    fontWeight: '400',
    fontSize: '13px',
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    textAlign: 'center',
  },
};

const getColorStatus = (status) => {
  switch (status) {
    case GIFT_CARD_REDEEM_POINT_STATUS.SENT:
      return Colors.success_300;
    case GIFT_CARD_REDEEM_POINT_STATUS.PENDING:
      return Colors.warning_700;
    case GIFT_CARD_REDEEM_POINT_STATUS.FAILED:
      return Colors.error_500;

    default:
      return Colors.black;
  }
};

const formatAmount = (amount) =>
  `$${formatNumber(
    Math.abs(Number(String(amount).replace('$', '').replace(' Gift Card Redeemed', ''))),
  )}`;

export const properksRedemptionColumn = () => [
  {
    selector: (row) => row.createdAt,
    name: 'Date',
    format: (row) =>
      row.createdAt ? dateFormat(new Date(row.createdAt), 'mm/d/yyyy', { utc: true }) : '-',
    style: style.tableText,
  },
  {
    selector: (row) => row.description,
    name: 'Gift Card Amount',
    format: (row) => (row.description ? formatAmount(row.description) : '-'),
    style: style.tableText,
  },
  {
    selector: (row) => row.status,
    name: 'Status',
    cell: (row) => (
      <p style={{ ...style.tableText, color: getColorStatus(row.status), fontWeight: '700' }}>
        {GIFT_CARD_REDEEM_POINT_STATUS_TEXT?.[row.status] ?? '-'}
      </p>
    ),
  },
];
