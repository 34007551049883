import { useDispatch, useSelector } from 'react-redux';
import { selectMultipleRegions } from '../../../../../../../../../../actions/dsosActions';
import { Colors } from '../../../../../../../../../../themes/colors';

export const SelectAll = () => {
  const dispatch = useDispatch();
  const {
    selectedRegionsIds,
    regionList: { data },
  } = useSelector((state) => state.dsos.orgStructure);
  const isAllSelected = selectedRegionsIds.length === data.length;
  const { selectedGroupId } = useSelector((state) => state.dsos.orgStructure);

  const handleButtonClick = () => {
    if (isAllSelected) {
      dispatch(selectMultipleRegions([]));
      return;
    }
    dispatch(selectMultipleRegions(data.map((region) => region.id)));
  };

  if (!data.length || !selectedGroupId) {
    return null;
  }

  return (
    <button type="button" style={styles.button} onClick={handleButtonClick}>
      <b style={styles.linkText}>{isAllSelected ? 'Unselect all' : 'Select All'}</b>
    </button>
  );
};

const styles = {
  button: {
    all: 'unset',
    cursor: 'pointer',
  },
  linkText: {
    fontSize: 16,
    color: Colors.secondary_500,
    textDecoration: 'underline',
  },
};
