import { Spinner } from 'react-bootstrap';
import { TabViewContainer } from '../../../../commonComponents/TabViewContainer';
import { StaffList } from './StaffList';

export const OfficeStaffMemberList = ({ data = [], isInitialLoading, isLoading, onScroll }) => (
  <TabViewContainer
    title="Office Staff"
    className="col-6"
    variant="card"
    style={{
      marginInline: 0,
      minWidth: 'auto',
      paddingInline: 24,
      maxHeight: 600,
      overflow: 'hidden',
      paddingBottom: 24,
    }}
  >
    {isInitialLoading ? (
      <Spinner />
    ) : (
      <StaffList data={data} isLoading={isLoading} onScroll={onScroll} />
    )}
  </TabViewContainer>
);
