import history from '../../../../../history';
import { ChevronRightIcon } from '../../../../../images/icons';
import { Colors } from '../../../../../themes/colors';
import { ListItem } from '../../../../commonComponents/ListItem';
import { Spinner } from '../../../../commonComponents/Spinner';

export const StaffList = ({ data = [], isLoading, onScroll }) => {
  if (data.length === 0 && !isLoading) {
    return (
      <p
        style={{
          color: Colors.neutral_500,
          fontSize: 14,
          fontWeight: 600,
          marginBlock: 20,
          textAlign: 'center',
        }}
      >
        There are no staff members available.
      </p>
    );
  }

  return (
    <>
      <ListItem
        text="Staff Member Name"
        textStyle={{ flex: 1, fontWeight: 700 }}
        TrailingComponent={() => (
          <span
            style={{
              color: Colors.neutral_600,
              display: 'block',
              fontSize: 16,
              fontWeight: 700,
              textAlign: 'center',
              width: '15%',
            }}
          >
            View
          </span>
        )}
      />
      <div
        style={{
          flex: 1,
          flexShrink: 1,
          overflowY: 'auto',
        }}
        onScroll={onScroll}
      >
        {data.map(({ firstName, lastName, id }) => (
          <ListItem
            key={id}
            text={`${firstName} ${lastName}`}
            textStyle={{
              flex: 1,
              fontSize: 14,
            }}
            TrailingComponent={() => (
              <button
                type="button"
                style={{
                  all: 'unset',
                  width: '15%',
                  height: 24,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => history.push(`/staff/${id}`)}
              >
                <ChevronRightIcon />
              </button>
            )}
          />
        ))}
        {isLoading && (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              paddingBlock: 16,
            }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 24,
                width: 24,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
