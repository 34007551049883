export const FormControl = ({
  inputStyle,
  label,
  labelStyle,
  onChange,
  placeholder,
  style,
  value,
}) => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      gap: 12,
      marginBottom: 40,
      marginTop: 20,
      maxWidth: 400,
      width: '100%',
      ...style,
    }}
  >
    <b
      style={{
        fontSize: 16,
        textWrap: 'nowrap',
        ...labelStyle,
      }}
    >
      {label}
    </b>
    <input
      type="text"
      className="input-field"
      style={inputStyle}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  </div>
);
