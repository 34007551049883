import { useDispatch, useSelector } from 'react-redux';
import {
  clearOrgStructure,
  fetchDsoGroupList,
  fetchDsoOffices,
  fetchDsoRegionList,
} from '../../../../../../../../../actions/dsosActions';
import { OrderBy } from '../../../../../../../../../enums/Sorting';

export const useRegionsSubHeader = (dsoId) => {
  const dispatch = useDispatch();
  const {
    total,
    filters: { orderBy },
  } = useSelector((state) => state.dsos.orgStructure.regionList);

  const onOrderBy = () => {
    dispatch(clearOrgStructure());
    Promise.all([
      dispatch(fetchDsoGroupList(dsoId, { page: 1, limit: 15 })),
      dispatch(
        fetchDsoRegionList(dsoId, {
          page: 1,
          limit: 100,
          orderBy: orderBy === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC,
        }),
      ),
      dispatch(fetchDsoOffices(dsoId, { page: 1, limit: 15 })),
    ]);
  };

  return {
    orderBy,
    onOrderBy,
    total,
  };
};
