export const SearchOffice = ({ value, onChange }) => (
  <div
    style={{
      width: 320,
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      marginInline: 'auto',
    }}
  >
    <b
      style={{
        fontSize: 14,
        textWrap: 'nowrap',
      }}
    >
      Search:
    </b>
    <input
      className="form-control"
      type="text"
      placeholder="Type Office Name"
      value={value}
      onChange={onChange}
    />
  </div>
);
