import * as React from 'react';
import { Colors } from '../../themes/colors';

export const DotIcon = ({ size = 16, color = Colors.primary_500 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={8} cy={8.00005} r={8} fill={color} />
  </svg>
);
