import { PAYMENT_METHODS_ACTIONS } from '../actions/actionTypes';

const initialState = {
  allPaymentMethods: [],
  allPaymentMethodsX: [],
  allPaymentMethodsForReferral: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
  case PAYMENT_METHODS_ACTIONS.GET_ALL_PAYMENT_SUCCESS:

  case PAYMENT_METHODS_ACTIONS.HYG_GET_ALL_PAYMENT_SUCCESS:
    return {
      ...state,
      allPaymentMethods: action.payload.payment_methods ?? action.payload,
      allPaymentMethodsForReferral: action.payload.payment_methods_for_referral,
    };

  case PAYMENT_METHODS_ACTIONS.HYG_GET_ALL_PAYMENT_X_SUCCESS:
    return {
      ...state,
      allPaymentMethodsX: action.payload.payment_methods ?? action.payload,
    };

  case PAYMENT_METHODS_ACTIONS.SELECT_HYG_PAYMENT_SUCCESS:
  case PAYMENT_METHODS_ACTIONS.SELECT_DNT_PAYMENT_SUCCESS:
    const indexSelected = state.allPaymentMethods.findIndex(
      (paymnet) => paymnet.id === action.payload,
    );
    if (indexSelected === -1) {
      return state;
    }

    return {
      ...state,
      allPaymentMethods: state.allPaymentMethods.map((el, index) => ({
        ...el,
        is_selected: index === indexSelected,
      })),
    };
    case PAYMENT_METHODS_ACTIONS.SELECT_HYG_PAYMENT_X_SUCCESS:
      const indexXSelected = state.allPaymentMethodsX.findIndex(
        (paymnet) => paymnet.id === action.payload,
      );
      if (indexXSelected === -1) {
        return state;
      }
  
      return {
        ...state,
        allPaymentMethodsX: state.allPaymentMethodsX.map((el, index) => ({
          ...el,
          is_selected: index === indexXSelected,
        })),
      };
  case PAYMENT_METHODS_ACTIONS.SELECT_HYG_MARKETING_PAYMENT_SUCCESS:
    const index = state.allPaymentMethodsForReferral.findIndex(
      (payment) => payment.id === action.payload,
    );
    if (index === -1) {
      return state;
    }

    return {
      ...state,
      allPaymentMethodsForReferral: state.allPaymentMethodsForReferral.map((el, i) => ({
        ...el,
        is_selected: i === index,
      })),
    };

  default:
    return state;
  }
};
