import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { EditPencilIcon } from '../../../../../../../images/icons';
import CustomPortal from '../../../../../../commonComponents/CustomPortal';
import { MenuItem } from '../../../../../../commonComponents/DotsMenu';
import { FormControl } from '../../../FormControl';

export const EditNameMenuItem = ({ dsoId, item, label, editAction }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MenuItem
        Icon={() => <EditPencilIcon />}
        text="Edit Name"
        onClick={() => setShowModal(true)}
      />

      {showModal && (
        <EditNameModal
          dsoId={dsoId}
          item={item}
          label={label}
          editAction={editAction}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

const EditNameModal = ({ dsoId, item, label, editAction, onClose }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(item.name);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(editAction(dsoId, item.id, name));
    onClose();
  };

  return (
    <CustomPortal
      title={`Edit ${label} Name`}
      textStyle={{ textAlign: 'center' }}
      cancelText="Close"
      submitText={`Edit ${label}`}
      submitPressed={handleSubmit}
      cancelPressed={() => {
        onClose();
        setName(item.name);
      }}
      rightButtonDisabled={!name || name === item.name}
    >
      <FormControl label={`${label} Name:`} value={name} onChange={handleNameChange} />
    </CustomPortal>
  );
};
