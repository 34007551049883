import React from 'react';

export const CheckCircleIcon = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" fill="#4FC0CF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8598 9.44038C17.1137 9.69422 17.1137 10.1058 16.8598 10.3596L11.6598 15.5596C11.406 15.8135 10.9944 15.8135 10.7406 15.5596L8.14058 12.9596C7.88674 12.7058 7.88674 12.2942 8.14058 12.0404C8.39442 11.7865 8.80597 11.7865 9.05981 12.0404L11.2002 14.1808L15.9406 9.44038C16.1944 9.18654 16.606 9.18654 16.8598 9.44038Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
