import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStaffDSOGroups,
  getStaffDSORegions,
} from '../../../../../../../../../actions/staffActions';
import { createLoadingSelector } from '../../../../../../../../../apis/selectors';

const loadingSelector = createLoadingSelector(['FETCH_DSO_GROUPS', 'FETCH_DSO_REGIONS']);

export const useAccessPermission = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => loadingSelector(state));
  const staff = useSelector((state) => state.staff.staffDetails);

  const { data: groups } = useSelector((state) => state.staff.dso.groups);
  const { data: regions } = useSelector((state) => state.staff.dso.regions);

  useEffect(() => {
    if (!isLoading && staff.dsoID) {
      Promise.all([
        dispatch(getStaffDSOGroups({ dsoId: staff.dsoID, filters: { limit: 50 } })),
        dispatch(getStaffDSORegions({ dsoId: staff.dsoID, filters: { limit: 50 } })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, staff.dsoID]);

  return {
    hasDSOAssigned: !!staff?.dsoID,
    hasGroups: groups.length > 0,
    hasRegions: regions.length > 0,
  };
};
