import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBulkOfficeSelection } from '../../../../../../../../../../actions/dsosActions';
import { Colors } from '../../../../../../../../../../themes/colors';
import { LinkOfficesToRegionPopup } from '../LinkOfficesToRegionMenuItem';

export const BulkSelect = ({ dsoId }) => {
  const dispatch = useDispatch();
  const { selectedOffices, isBulkOfficeSelectionActive } = useSelector(
    (state) => state.dsos.orgStructure,
  );
  const [showModal, setShowModal] = useState(false);

  const linkOfficesText = useMemo(
    () => `Link Offices (${selectedOffices.length})`,
    [selectedOffices.length],
  );

  return (
    <>
      {!isBulkOfficeSelectionActive ? (
        <button
          type="button"
          style={styles.button}
          onClick={() => dispatch(toggleBulkOfficeSelection())}
        >
          <b style={styles.linkText}>Bulk Select</b>
        </button>
      ) : (
        <div style={styles.activeSelectionContainer}>
          <button
            type="button"
            style={{
              ...styles.button,
              cursor: selectedOffices.length > 0 ? 'pointer' : 'not-allowed',
            }}
            onClick={() => selectedOffices.length > 0 && setShowModal(true)}
          >
            <b style={styles.linkText}>{linkOfficesText}</b>
          </button>
          <button
            type="button"
            style={styles.button}
            onClick={() => dispatch(toggleBulkOfficeSelection())}
          >
            <b style={styles.linkText}>Cancel</b>
          </button>
        </div>
      )}

      {showModal && (
        <LinkOfficesToRegionPopup
          dsoId={dsoId}
          offices={selectedOffices}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

const styles = {
  button: {
    all: 'unset',
    cursor: 'pointer',
  },
  linkText: {
    fontSize: 16,
    color: Colors.secondary_500,
    textDecoration: 'underline',
  },
  activeSelectionContainer: {
    display: 'flex',
    gap: 16,
  },
};
