import { editDsoGroupName } from '../../../../../../../../../actions/dsosActions';
import CheckCircle from '../../../../../../../../commonComponents/CheckCircle';
import { Pressable } from '../../../../../../../../Pressable';
import { DefaultItem } from '../../DefaultItem';
import { EditNameMenuItem } from '../../EditNameMenuItem';
import { useGroupItem } from '../hooks';
import { LinkRegionToGroupMenuItem } from './LinkRegionToGroupMenuItem';
import { RemoveGroupMenuItem } from './RemoveGroupMenuItem';

export const GroupItem = ({ dsoId, item }) => {
  const { onRadioClick, isSelected, isLoading } = useGroupItem(dsoId, item);

  return (
    <DefaultItem
      text={`${item.name} (${item.numOfRegions})`}
      LeadingComponent={() => (
        <Pressable onClick={onRadioClick} disabled={isLoading}>
          <CheckCircle checked={isSelected} />
        </Pressable>
      )}
      MenuOptions={() => (
        <>
          <EditNameMenuItem dsoId={dsoId} item={item} label="Group" editAction={editDsoGroupName} />
          <LinkRegionToGroupMenuItem dsoId={dsoId} item={item} />
          <RemoveGroupMenuItem item={item} />
        </>
      )}
    />
  );
};
