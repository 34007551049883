import '../../../../../themes/AvailabilityCalendar.scss';
import React from 'react';
import { FireIcon } from '../../../../../images/icons/FireIcon';
import { DotIcon } from '../../../../../images/icons/DotIcon';

export const DayIcon = ({ isOnCall, isAvailability, isToday }) => {
  if (isOnCall) {
    return (
      <div className="bg-icon on-call">
        <FireIcon />
      </div>
    );
  }

  if (isAvailability) {
    return (
      <div className="bg-icon">
        <DotIcon size={34} color="#6ACE8B" />
      </div>
    );
  }

  if (isToday) {
    return (
      <div className="bg-icon">
        <DotIcon size={34} />
      </div>
    );
  }

  return null;
};
