import { editDsoRegionName } from '../../../../../../../../../actions/dsosActions';
import { Colors } from '../../../../../../../../../themes/colors';
import { Checkbox } from '../../../../../../../../commonComponents/Checkbox';
import { DefaultItem } from '../../DefaultItem';
import { EditNameMenuItem } from '../../EditNameMenuItem';
import { useRegionItem } from '../hooks';
import { LinkOfficeToRegionMenuItem } from './LinkOfficeToRegionMenuItem';
import { ManageLinkedGroups } from './ManageLinkedGroups';
import { RemoveRegionMenuItem } from './RemoveRegionMenuItem/RemoveRegionMenuItem';

export const RegionItem = ({ dsoId, item }) => {
  const { onCheckboxClick, isSelected, isLoading } = useRegionItem(dsoId, item);

  return (
    <DefaultItem
      text={`${item.name} (${item.numOfOffices})`}
      LeadingComponent={() => (
        <Checkbox
          checked={isSelected}
          disabled={isLoading}
          onChanged={onCheckboxClick}
          checkboxStyle={{
            width: 18,
            height: 18,
            borderRadius: 4,
            border: `2px solid ${Colors.secondary_500}`,
            backgroundColor: isSelected ? Colors.secondary_500 : Colors.white,
            marginRight: 0,
          }}
        />
      )}
      MenuOptions={() => (
        <>
          <EditNameMenuItem
            dsoId={dsoId}
            item={item}
            label="Region"
            editAction={editDsoRegionName}
          />
          <LinkOfficeToRegionMenuItem dsoId={dsoId} item={item} />
          <ManageLinkedGroups item={item} />
          <RemoveRegionMenuItem dsoId={dsoId} item={item} />
        </>
      )}
    />
  );
};
