import { useEffect, useState } from 'react';
import debounceHook from '../../../../../commonComponents/debounceHook';
import { InputSearch } from '../../../../../commonComponents/InputSearch';
import { DsoFilter } from './DsoFilter';

export const TableHeader = ({ filters = {}, handleFilterChanged = () => {} }) => {
  const [searchStaffEmail, setSearchStaffEmail] = useState(filters.staffEmail);
  const [searchStaffName, setSearchStaffName] = useState(filters.staffName);
  const [searchStaffPhone, setSearchStaffPhone] = useState(filters.staffPhone);

  const debouncedSearchStaffEmail = debounceHook(searchStaffEmail, 1000);
  const debouncedSearchStaffName = debounceHook(searchStaffName, 1000);
  const debouncedSearchStaffPhone = debounceHook(searchStaffPhone, 1000);

  useEffect(() => {
    if (filters?.staffEmail === debouncedSearchStaffEmail) return;
    handleFilterChanged('staffEmail', searchStaffEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchStaffEmail]);

  useEffect(() => {
    if (filters?.staffName === debouncedSearchStaffName) return;
    handleFilterChanged('staffName', searchStaffName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchStaffName]);

  useEffect(() => {
    if (filters?.staffPhone === debouncedSearchStaffPhone) return;
    handleFilterChanged('staffPhone', searchStaffPhone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchStaffPhone]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
          gap: 20,
          alignItems: 'end',
          marginTop: 40,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 12,
          }}
        >
          <strong>Search:</strong>

          <InputSearch
            placeholder="Staff Member Name"
            value={searchStaffName}
            onChange={(e) => setSearchStaffName(e.target.value)}
          />

          <InputSearch
            placeholder="Staff Member Email"
            value={searchStaffEmail}
            onChange={(e) => setSearchStaffEmail(e.target.value)}
          />

          <InputSearch
            placeholder="Staff Member Phone Number"
            value={searchStaffPhone}
            onChange={(e) => setSearchStaffPhone(e.target.value)}
          />
        </div>

        <DsoFilter filters={filters} handleFilterChanged={handleFilterChanged} label="DSO Name:" />
      </div>
    </div>
  );
};
