/* eslint-disable react/forbid-prop-types */

import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { classNames } from '../../../utils/ClassNameUtils';
import { TabContext } from './Context';

import './styles.scss';

export const Tabs = ({ LeadingComponent, TrailingComponent, children, defaultValue, tabs }) => {
  const [activeTab, setActiveTab] = useState();

  useEffect(() => {
    setActiveTab(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const contextValue = useMemo(
    () => ({
      activeTab,
    }),
    [activeTab],
  );

  return (
    <TabContext.Provider value={contextValue}>
      <div className="tabs-v2">
        <nav>
          {LeadingComponent && <LeadingComponent />}
          <ul>
            {tabs.map(({ title, value }) => {
              const isActive = value === activeTab;

              return (
                <li
                  className={classNames(isActive ? 'active' : '')}
                  key={value}
                  role="presentation"
                  onClick={() => setActiveTab(value)}
                >
                  {title}
                </li>
              );
            })}
          </ul>
          {TrailingComponent && <TrailingComponent />}
        </nav>
        {children}
      </div>
    </TabContext.Provider>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValue: PropTypes.any.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
};
