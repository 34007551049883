import FormData from 'form-data';
import tempMee, { callAuthServiceApi } from '../apis/tempMee';
import {
  getShouldUseAuthService,
  getShouldUseMetricsService,
  getShouldUsingCredentialServiceEndpointForLicense,
} from '../growthbook';
import history from '../history';
import { updateUser as updateUserService } from '../services/users';
import {
  ADD_CHILD_OFFICE_FAILURE,
  ADD_CHILD_OFFICE_REQUEST,
  ADD_CHILD_OFFICE_SUCCESS,
  ADD_FAVORITE_USER_FAILURE,
  ADD_FAVORITE_USER_REQUEST,
  ADD_FAVORITE_USER_SUCCESS,
  ADD_LICENSE_FAILURE,
  ADD_LICENSE_REQUEST,
  ADD_LICENSE_SUCCESS,
  ADD_NEW_ADDRESS_FAILURE,
  ADD_NEW_ADDRESS_REQUEST,
  ADD_NEW_ADDRESS_SUCCESS,
  ADD_USER_NOTE_FAILURE,
  ADD_USER_NOTE_REQUEST,
  ADD_USER_NOTE_SUCCESS,
  APPROVE_USER_BY_SMS,
  BLOCK_HYG_FAILURE,
  BLOCK_HYG_REQUEST,
  BLOCK_HYG_SUCCESS,
  CREATE_EMPTY_USER_FAILURE,
  CREATE_EMPTY_USER_REQUEST,
  CREATE_EMPTY_USER_SUCCESS,
  CREATE_STRIPE_ACCOUNT_FAILURE,
  CREATE_STRIPE_ACCOUNT_REQUEST,
  CREATE_STRIPE_ACCOUNT_SUCCESS,
  DELETE_CHILD_OFFICE_FAILURE,
  DELETE_CHILD_OFFICE_REQUEST,
  DELETE_CHILD_OFFICE_SUCCESS,
  DELETE_FAVORITE_USER_FAILURE,
  DELETE_FAVORITE_USER_REQUEST,
  DELETE_FAVORITE_USER_SUCCESS,
  DELETE_LICENSE_FAILURE,
  DELETE_LICENSE_REQUEST,
  DELETE_LICENSE_SUCCESS,
  DELETE_USER_ADDRESS_FAILURE,
  DELETE_USER_ADDRESS_REQUEST,
  DELETE_USER_ADDRESS_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_W9_FAILURE,
  DELETE_W9_REQUEST,
  DELETE_W9_SUCCESS,
  EDIT_CANCELLATION_PENALTY_FAILURE,
  EDIT_CANCELLATION_PENALTY_REQUEST,
  EDIT_CANCELLATION_PENALTY_SUCCESS,
  FETCH_CHILD_OFFICES_FAILURE,
  FETCH_CHILD_OFFICES_REQUEST,
  FETCH_CHILD_OFFICES_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  FETCH_CURRENT_USER_REQUEST,
  FETCH_CURRENT_USER_STRIPE_ID,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_DENTIST_FAILURE,
  FETCH_DENTIST_REQUEST,
  FETCH_DENTIST_SUCCESS,
  FETCH_DSO_FAILURE,
  FETCH_DSO_REQUEST,
  FETCH_DSO_SUCCESS,
  FETCH_HYGIENIST_FAILURE,
  FETCH_HYGIENIST_REQUEST,
  FETCH_HYGIENIST_SUCCESS,
  FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_FAILURE,
  FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_REQUEST,
  FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_SUCCESS,
  FETCH_PARTNERSHIP_ORGANIZATIONS_FAILURE,
  FETCH_PARTNERSHIP_ORGANIZATIONS_REQUEST,
  FETCH_PARTNERSHIP_ORGANIZATIONS_SUCCESS,
  FETCH_UNFINISHED_USERS_FAILURE,
  FETCH_UNFINISHED_USERS_REQUEST,
  FETCH_UNFINISHED_USERS_SUCCESS,
  FETCH_USER,
  FETCH_USER_FAVORITES_LIST_FAILURE,
  FETCH_USER_FAVORITES_LIST_REQUEST,
  FETCH_USER_FAVORITES_LIST_SUCCESS,
  FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_FAILURE,
  FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_REQUEST,
  FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_SUCCESS,
  FETCH_USER_JOBS_FAILURE,
  FETCH_USER_JOBS_REQUEST,
  FETCH_USER_JOBS_SUCCESS,
  FETCH_USER_NOTES_FAILURE,
  FETCH_USER_NOTES_REQUEST,
  FETCH_USER_NOTES_SUCCESS,
  FETCH_USER_NOTIFICATION_SETTINGS_FAILURE,
  FETCH_USER_NOTIFICATION_SETTINGS_HYG_FAILURE,
  FETCH_USER_NOTIFICATION_SETTINGS_HYG_REQUEST,
  FETCH_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS,
  FETCH_USER_NOTIFICATION_SETTINGS_REQUEST,
  FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS,
  FETCH_USER_OFFERS_FAILURE,
  FETCH_USER_OFFERS_REQUEST,
  FETCH_USER_OFFERS_SUCCESS,
  FETCH_USER_WORKER_CLASSIFICATION_FAILURE,
  FETCH_USER_WORKER_CLASSIFICATION_REQUEST,
  FETCH_USER_WORKER_CLASSIFICATION_SUCCESS,
  GET_ADMIN_RAM_FAILURE,
  GET_ADMIN_RAM_REQUEST,
  GET_ADMIN_RAM_SUCCESS,
  GET_ADMIN_USER_FAILURE,
  GET_ADMIN_USER_REQUEST,
  GET_ADMIN_USER_SUCCESS,
  GET_ALL_METADATA_FAILURE,
  GET_ALL_METADATA_REQUEST,
  GET_ALL_METADATA_SUCCESS,
  GET_CANCELLATION_POLICIES_FAILURE,
  GET_CANCELLATION_POLICIES_REQUEST,
  GET_CANCELLATION_POLICIES_SUCCESS,
  GET_DSO_NAMES_FAILURE,
  GET_DSO_NAMES_REQUEST,
  GET_DSO_NAMES_SUCCESS,
  GET_MANAGERS_LIST_SUCCESS,
  GET_REFERRAL_TYPES_FAILURE,
  GET_REFERRAL_TYPES_REQUEST,
  GET_REFERRAL_TYPES_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  HIDE_EXISTING_LICENSE_POPUP,
  IMPORT_TO_TRINET_FAILURE,
  IMPORT_TO_TRINET_REQUEST,
  IMPORT_TO_TRINET_SUCCESS,
  LICENSE_ACTIONS,
  LOADING_NOTIFICATION_SETTINGS_UPDATE,
  MAP_TYPES,
  PATCH_HYGIENIST_NOTIFICATION,
  REPORT_ACTIONS,
  RESET_USER_PASSWORD_FAILURE,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  RESUME_UPLOAD_FAILURE,
  RESUME_UPLOAD_REQUEST,
  RESUME_UPLOAD_SUCCESS,
  SAVE_USER_WORKER_CLASSIFICATION_FAILURE,
  SAVE_USER_WORKER_CLASSIFICATION_REQUEST,
  SAVE_USER_WORKER_CLASSIFICATION_SUCCESS,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_OFFICE_RELEASE_FORM_FAILURE,
  SEND_OFFICE_RELEASE_FORM_REQUEST,
  SEND_OFFICE_RELEASE_FORM_SUCCESS,
  SEND_PUSH_NOTIFICATION_FAILURE,
  SEND_PUSH_NOTIFICATION_REQUEST,
  SEND_PUSH_NOTIFICATION_SUCCESS,
  SEND_USER_MFA_FAILURE,
  SEND_USER_MFA_REQUEST,
  SEND_USER_MFA_SUCCESS,
  SEND_W9_FAILURE,
  SEND_W9_REQUEST,
  SEND_W9_SUCCESS,
  SHOW_MESSAGE,
  TERMINATE_USER_FAILURE,
  TERMINATE_USER_REQUEST,
  TERMINATE_USER_SUCCESS,
  UNBLOCK_HYG_FAILURE,
  UNBLOCK_HYG_REQUEST,
  UNBLOCK_HYG_SUCCESS,
  UPDATE_ADMIN_DSO_MANAGER_REQUEST,
  UPDATE_ADMIN_DSO_NAME_REQUEST,
  UPDATE_ADMIN_RAM_REQUEST,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_FAILURE,
  UPDATE_CURRENT_USER_PAYMENT_FAILURE,
  UPDATE_CURRENT_USER_PAYMENT_REQUEST,
  UPDATE_CURRENT_USER_PAYMENT_SUCCESS,
  UPDATE_CURRENT_USER_REQUEST,
  UPDATE_CURRENT_USER_SUCCESS,
  UPDATE_I9_FAILURE,
  UPDATE_I9_REQUEST,
  UPDATE_I9_SUCCESS,
  UPDATE_NATIONAL_PROVIDER_IDENTIFIER_FAILURE,
  UPDATE_NATIONAL_PROVIDER_IDENTIFIER_REQUEST,
  UPDATE_NATIONAL_PROVIDER_IDENTIFIER_SUCCESS,
  UPDATE_RAM_VERIFIED_STATUS_FAILURE,
  UPDATE_RAM_VERIFIED_STATUS_REQUEST,
  UPDATE_RAM_VERIFIED_STATUS_SUCCESS,
  UPDATE_USER_AVAILABILITY_FAILURE,
  UPDATE_USER_AVAILABILITY_REQUEST,
  UPDATE_USER_AVAILABILITY_SUCCESS,
  UPDATE_USER_AVATAR_FAILURE,
  UPDATE_USER_AVATAR_REQUEST,
  UPDATE_USER_AVATAR_SUCCESS,
  UPDATE_USER_LICENSE_FAILURE,
  UPDATE_USER_LICENSE_REQUEST,
  UPDATE_USER_LICENSE_SUCCESS,
  UPDATE_USER_NOTIFICATION_SETTINGS_FAILURE,
  UPDATE_USER_NOTIFICATION_SETTINGS_HYG_FAILURE,
  UPDATE_USER_NOTIFICATION_SETTINGS_HYG_REQUEST,
  UPDATE_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS,
  UPDATE_USER_NOTIFICATION_SETTINGS_REQUEST,
  UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_USER_REFERRAL_TYPE_FAILURE,
  UPDATE_USER_REFERRAL_TYPE_REQUEST,
  UPDATE_USER_REFERRAL_TYPE_SUCCESS,
  UPDATE_USER_STATE_FAILURE,
  UPDATE_USER_STATE_REQUEST,
  UPDATE_USER_STATE_SUCCESS,
  UPDATE_W9_FAILURE,
  UPDATE_W9_REQUEST,
  UPDATE_W9_SUCCESS,
  USER_ACTIONS,
} from './actionTypes';
import { getAuthHeaders } from './authActions';
import { sourceType } from '../constants';
import { fetchStaffById } from './staffActions';
import {
  getProfLicenseByIdV2,
  getProfLicensesV2,
  updateProfLicenseV2,
  deleteProfLicenseByIdV2,
  addProfLicenseV2,
  addProfLicenseDaV2,
} from '../services/credentials';
import {
  convertLicenseDetailV2toV1,
  convertLicensesV2toV1,
  getLicensesV2Payload,
} from '../utils/CredentialUtils';
import { UserProfession, UserSubProfession } from '../enums/UserProfession';
import { handleUploadLicenseFilesV2 } from './licensesAction';

export const patchHygienistNotifications =
  (notificationsSettings) => async (dispatch, getState) => {
    const userId = getState().users.currentUser._id;
    const url = `user/${userId}/notifications`;
    const response = await tempMee.patch(url, notificationsSettings);
    dispatch({ type: PATCH_HYGIENIST_NOTIFICATION, payload: response.data });
  };

export const fetchUsers =
  ({
    page,
    limit,
    searchText,
    phone,
    sortField,
    order,
    sortOrder,
    user_type,
    completeRegistration,
    status,
    ram,
    dsoName,
    dsoManager,
    workerClassification,
    workerClassificationRequest,
    state,
    isDSO,
    profession,
    subProfession,
    requiredCredentials,
    ramVerified,
    preApproved,
    dsoRamIds,
    registrationStatus,
  }) =>
  async (dispatch, getState) => {
    const newParams = {
      page,
      limit,
      searchText,
      phone,
      sortField,
      order: sortOrder || order,
      user_type,
      completeRegistration,
      status,
      ram,
      dsoName,
      dsoManager,
      dsoRamIds,
      workerClassification,
      workerClassificationRequest,
      state,
      isDSO,
      profession,
      requiredCredentials,
      ramVerified,
      preApproved,
      subProfession,
      registrationStatus,
    };

    let searchParams;
    let requestType;
    let successType;
    let failureType = '';
    if (!newParams?.phone) {
      delete newParams?.phone;
    }

    if (completeRegistration === false) {
      searchParams = getState().users.unfinishedSearchParams;
      successType = FETCH_UNFINISHED_USERS_SUCCESS;
      requestType = FETCH_UNFINISHED_USERS_REQUEST;
      failureType = FETCH_UNFINISHED_USERS_FAILURE;
    } else if (user_type === 'DNT' && isDSO) {
      searchParams = getState().users.dsoSearchParams;
      requestType = FETCH_DSO_REQUEST;
      successType = FETCH_DSO_SUCCESS;
      failureType = FETCH_DSO_FAILURE;
    } else if (user_type === 'DNT') {
      searchParams = getState().users.dentistSearchParams;
      requestType = FETCH_DENTIST_REQUEST;
      successType = FETCH_DENTIST_SUCCESS;
      failureType = FETCH_DENTIST_FAILURE;
    } else if (user_type === 'HYG') {
      searchParams = getState().users.hygienistSearchParams;
      successType = FETCH_HYGIENIST_SUCCESS;
      requestType = FETCH_HYGIENIST_REQUEST;
      failureType = FETCH_HYGIENIST_FAILURE;
    }

    if (JSON.stringify(searchParams) === JSON.stringify(newParams)) {
      return;
    }

    dispatch({
      type: requestType,
      payload: newParams,
    });

    try {
      const headers = await getAuthHeaders();
      const response = await tempMee.get('admin/users', {
        params: newParams,
        headers,
      });

      dispatch({ type: successType, payload: response.data });
    } catch (err) {
      dispatch({ type: failureType, payload: err });
    }
  };

export const fetchSpecificUser = (userId) => async (dispatch) => {
  // const response = await fetchUser(userId);
  const headers = await getAuthHeaders();

  const response = await tempMee.get(`admin/users/${userId}`, { headers });

  dispatch({ type: FETCH_USER, payload: response.data });
};

/**
 * @param {string} userId
 * @param {boolean} silentRequest - if true, no loading listener will be shown
 */
export const fetchCurrentUser =
  (userId, { silentRequest = false } = {}) =>
  async (dispatch) => {
    try {
      if (!silentRequest) {
        dispatch({ type: FETCH_CURRENT_USER_REQUEST });
      }

      const headers = await getAuthHeaders();
      const response = await tempMee.get(`admin/users/${userId}`, { headers });

      dispatch({
        type: FETCH_CURRENT_USER_SUCCESS,
        payload: response.data,
      });

      const currentUser = response.data.user;

      if (currentUser?.user_type === 'HYG') {
        dispatch(fetchCurrentUserLicensesV2(userId));
      }

      if (currentUser?.is_profile_complete) {
        if (currentUser?.user_type === 'HYG') {
          const [resStripeId, resStripeXId] = await Promise.all([
            tempMee.get(`/payment/admin/account/${userId}`, {
              headers: {
                ...headers,
                'x-source-type': sourceType.gotutech,
              },
            }),
            tempMee.get(`/payment/admin/account/${userId}`, {
              headers: {
                ...headers,
                'x-source-type': sourceType.gotux,
              },
            }),
          ]);

          dispatch({
            type: FETCH_CURRENT_USER_STRIPE_ID,
            payload: {
              stripeTech: resStripeId.data.id,
              stripeX: resStripeXId.data.id,
            },
          });
        } else {
          const resStripeId = await tempMee.get(`/payment/admin/account/${userId}`, {
            headers: {
              ...headers,
            },
          });

          dispatch({
            type: FETCH_CURRENT_USER_STRIPE_ID,
            payload: {
              stripeTech: resStripeId.data.id,
            },
          });
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_CURRENT_USER_FAILURE, payload: error });
    }
  };

export const fetchUserNotes = (userId) => async (dispatch) => {
  dispatch({ type: FETCH_USER_NOTES_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.get(`admin/users/${userId}/notes`, { headers });
    dispatch({
      type: FETCH_USER_NOTES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: FETCH_USER_NOTES_FAILURE, payload: error });
  }
};

export const addUserNote = (userId, content) => async (dispatch) => {
  dispatch({ type: ADD_USER_NOTE_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.post(`admin/users/${userId}/notes`, { content }, { headers });
    dispatch({
      type: ADD_USER_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: ADD_USER_NOTE_FAILURE, payload: error });
  }
};

export const fetchUsersAround =
  ({ radius, state, status }) =>
  async (dispatch, getState) => {
    dispatch({ type: MAP_TYPES.FETCH_USERS_AROUND_REQUEST });
    try {
      const currUserId = getState().users.currentUser.id;
      const headers = await getAuthHeaders();

      const response = await tempMee.get(`admin/users/${currUserId}/users-around`, {
        headers,
        params: {
          radius,
          state,
          status,
        },
      });

      dispatch({
        type: MAP_TYPES.FETCH_USERS_AROUND_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: MAP_TYPES.FETCH_USERS_AROUND_FAILURE, payload: error });
    }
  };

export const fetchChildOffices = (userId) => async (dispatch) => {
  dispatch({ type: FETCH_CHILD_OFFICES_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.get(`admin/${userId}/descend`, { headers });
    dispatch({
      type: FETCH_CHILD_OFFICES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: FETCH_CHILD_OFFICES_FAILURE, payload: error });
  }
};

export const addChildOffice = (parentId, officeId) => async (dispatch) => {
  dispatch({ type: ADD_CHILD_OFFICE_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(`admin/${parentId}/descend`, { officeId }, { headers });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Office has been added',
      },
    });
    dispatch({ type: ADD_CHILD_OFFICE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: ADD_CHILD_OFFICE_FAILURE, payload: error });
  }
};

export const deleteChildOffice = (parentId, officeId) => async (dispatch) => {
  dispatch({ type: DELETE_CHILD_OFFICE_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.delete(`admin/${parentId}/descend`, {
      headers,
      data: { officeId },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Child office has been deleted from child list',
      },
    });
    dispatch({ type: DELETE_CHILD_OFFICE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: DELETE_CHILD_OFFICE_FAILURE, payload: error });
  }
};

export const blockHygienist = (dntId, hygId) => async (dispatch) => {
  dispatch({ type: BLOCK_HYG_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `admin/users/${dntId}/addBlocked`,
      { user: hygId },
      { headers },
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Hygienist has been blocked',
      },
    });
    dispatch({ type: BLOCK_HYG_SUCCESS, payload: response.data });
    return true;
  } catch (error) {
    dispatch({ type: BLOCK_HYG_FAILURE, payload: error });
  }
};

export const fetchUserReviews = (state) => async (dispatch, getState) => {
  try {
    const currUserId = getState().users.currentUser.id;

    const newSearchParams = {
      page: state.page,
      limit: state.limit || 15,
      currUserId,
    };

    const { reviewsPagination } = getState().users.reviews;
    if (
      JSON.stringify(reviewsPagination) === JSON.stringify(newSearchParams) ||
      (state.page !== 1 && state.page <= reviewsPagination.page)
    ) {
      return;
    }

    const headers = await getAuthHeaders();
    dispatch({ type: USER_ACTIONS.FETCH_USER_REVIEWS_REQUEST, payload: newSearchParams });

    const response = await tempMee.get(`admin/users/${currUserId}/reviews`, {
      headers,
      params: newSearchParams,
    });
    dispatch({
      type: USER_ACTIONS.FETCH_USER_REVIEWS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: USER_ACTIONS.FETCH_USER_REVIEWS_FAILURE, payload: error });
  }
};

export const unblockHygienist = (dntId, hygId) => async (dispatch) => {
  dispatch({ type: UNBLOCK_HYG_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `admin/users/${dntId}/removeBlocked`,
      { user: hygId },
      { headers },
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Hygienist has been unblocked',
      },
    });
    dispatch({ type: UNBLOCK_HYG_SUCCESS, payload: response.data });
    return true;
  } catch (error) {
    dispatch({ type: UNBLOCK_HYG_FAILURE, payload: error });
  }
};

const updateSpecialtiesAndProcedures = async ({ currentUser, education, insurances, headers }) => {
  const response = await tempMee.patch(
    `admin/users/${currentUser?.id}/specialtiesAndProcedures`,
    {
      education: {
        specialtiesAndProcedures: education?.specialtiesAndProcedures || {},
        ...education,
      },
      insurances: insurances || currentUser?.office_info?.insurances || [],
    },
    { headers },
  );

  return response;
};

export const updateCurrentUser = (body) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_CURRENT_USER_REQUEST });

    const { currentUser } = getState().users;
    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();

    let response;

    const { office_info, productivity, ...bodyRest } = body;
    const { education, insurances, ...officeInfoRest } = office_info || {};

    const officeInfo = Object.keys(officeInfoRest).length ? { office_info: officeInfoRest } : {};

    if (Object.keys(bodyRest).length || Object.keys(officeInfoRest).length) {
      response = await tempMee.patch(
        `admin/users/${currUserId}`,
        {
          ...bodyRest,
          ...officeInfo,
        },
        { headers },
      );
    }

    if (productivity) {
      await tempMee.patch(
        `/admin/users/${currUserId}/office-productivity`,
        {
          productivity,
        },
        { headers },
      );
    }

    if (education || insurances) {
      response = await updateSpecialtiesAndProcedures({
        currentUser,
        education,
        insurances,
        headers,
      });
    }

    dispatch({ type: UPDATE_CURRENT_USER_SUCCESS, payload: response.data });
    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'User was updated successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: UPDATE_CURRENT_USER_FAILURE, payload: err });
  }
};

export const updateCurrentUserPayment = (body) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_CURRENT_USER_PAYMENT_REQUEST });
    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(`admin/users/${currUserId}/payment-information`, body, {
      headers,
    });
    dispatch({ type: UPDATE_CURRENT_USER_PAYMENT_SUCCESS, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'User was updated successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: UPDATE_CURRENT_USER_PAYMENT_FAILURE, payload: err });
  }
};

export const getUsers = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USERS_REQUEST });
    const headers = await getAuthHeaders();
    let data = [];
    if (params.searchText !== '') {
      const response = await tempMee.get('admin/users', {
        headers,
        params,
      });
      data = response.data;
    } else {
      data = [];
    }
    dispatch({ type: GET_USERS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: GET_USERS_FAILURE, payload: err });
  }
};

export const clearUserList = () => ({ type: 'CLEAR_USERS_SUCCESS' });

export const updateUserAvatar = (image) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_USER_AVATAR_REQUEST });
    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    const form = new FormData();
    form.append('image', image);

    const response = await tempMee.patch(`admin/users/${currUserId}/profile`, form, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch({ type: UPDATE_USER_AVATAR_SUCCESS, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'User was updated successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: UPDATE_USER_AVATAR_FAILURE, payload: err });
  }
};

export const setCurrentUser = (user) => ({
  type: UPDATE_CURRENT_USER,
  payload: user.ResponsePacket,
});

export const updateUserAvailability = (availability) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_USER_AVAILABILITY_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();

    const response = await tempMee.patch(
      `admin/users/${currUserId}/availability`,
      { availability },
      {
        headers,
      },
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'User availability was updated successfully.',
      },
    });
    dispatch({ type: UPDATE_USER_AVAILABILITY_SUCCESS, payload: response.data });
    return true;
  } catch (error) {
    dispatch({ type: UPDATE_USER_AVAILABILITY_FAILURE, payload: error });
  }
};

export const updateAccountStanding = (body) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_CURRENT_USER_REQUEST });

    const accountStanding = body?.account_standing;
    const { currentUser } = getState().users;
    const currUserId = currentUser.id;
    const headers = await getAuthHeaders();

    let updatedUser;
    if (getShouldUseAuthService()) {
      await callAuthServiceApi(
        'PATCH',
        `admin/users/${currUserId}`,
        { accountStanding },
        undefined,
        headers,
      );

      updatedUser = { ...currentUser, account_standing: accountStanding };
    } else {
      const { data } = await tempMee.patch(`admin/users/${currUserId}`, body, {
        headers,
      });
      updatedUser = data;
    }

    dispatch({ type: UPDATE_CURRENT_USER_SUCCESS, payload: updatedUser });
    dispatch({ type: UPDATE_CURRENT_USER, payload: updatedUser });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        // eslint-disable-next-line max-len
        message: `This profile was updated successfully. Account standing has been updated to ${accountStanding}`,
      },
    });
  } catch (err) {
    dispatch({ type: UPDATE_CURRENT_USER_FAILURE, payload: err });
  }
};

export const getReferralTypes = () => async (dispatch) => {
  try {
    dispatch({ type: GET_REFERRAL_TYPES_REQUEST });

    const headers = await getAuthHeaders();

    const response = await tempMee.get('admin/referralTypes', {
      headers,
    });

    dispatch({ type: GET_REFERRAL_TYPES_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_REFERRAL_TYPES_FAILURE, payload: err });
  }
};

export const updateUserReferralType = (body) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_USER_REFERRAL_TYPE_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    await tempMee.patch(`admin/users/${currUserId}/referrals`, body, {
      headers,
    });

    dispatch({ type: UPDATE_USER_REFERRAL_TYPE_SUCCESS, payload: body.defaultReferralType });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: `Referral type has been updated to ${body.defaultReferralType}`,
      },
    });
  } catch (err) {
    dispatch({ type: UPDATE_USER_REFERRAL_TYPE_FAILURE, payload: err });
  }
};

export const updateRamVerifiedStatus = (body) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_RAM_VERIFIED_STATUS_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    await tempMee.patch(`admin/users/${currUserId}/status`, body, {
      headers,
    });
    dispatch({ type: UPDATE_RAM_VERIFIED_STATUS_SUCCESS, payload: body });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: `This profile was updated successfully. RAM Verified Status has been updated to ${body?.ramVerified}`,
      },
    });
  } catch (err) {
    dispatch({ type: UPDATE_RAM_VERIFIED_STATUS_FAILURE, payload: err });
  }
};

export const loadingNotificationSettingsUpdate = (stateId) => ({
  type: LOADING_NOTIFICATION_SETTINGS_UPDATE,
});

export const sendPushNotification = (text) => async (dispatch, getState) => {
  try {
    dispatch({ type: SEND_PUSH_NOTIFICATION_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();

    await tempMee.post(
      `/admin/users/${currUserId}/send-notification`,
      { text },
      {
        headers,
      },
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Push notification sent successfully.',
      },
    });
    dispatch({ type: SEND_PUSH_NOTIFICATION_SUCCESS });
  } catch (error) {
    dispatch({ type: SEND_PUSH_NOTIFICATION_FAILURE, payload: error });
  }
};

export const sendUserMFA = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SEND_USER_MFA_REQUEST });

    const userId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    await tempMee.post(
      `admin/users/${userId}/mfa-request`,
      {},
      {
        headers,
      },
    );

    dispatch({ type: SEND_USER_MFA_SUCCESS });
  } catch (error) {
    dispatch({ type: SEND_USER_MFA_FAILURE, payload: error });
  }
};

export const resetUserPassword =
  ({ code, password }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: RESET_USER_PASSWORD_REQUEST });

      const userId = getState().users.currentUser.id;
      const headers = await getAuthHeaders();
      await tempMee.post(
        `/admin/users/${userId}/reset-admin-password`,
        { code, password },
        {
          headers,
        },
      );

      dispatch({ type: RESET_USER_PASSWORD_SUCCESS });
    } catch (error) {
      dispatch({ type: RESET_USER_PASSWORD_FAILURE, payload: error });
    }
  };

export const updateUserPicture = (state_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_USER_STATE_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const form = new FormData();
    form.append('image', state_id);
    const headers = await getAuthHeaders();

    const response = await tempMee.patch(`admin/users/${currUserId}/licanse`, form, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'State I.D. was updated successfully.',
      },
    });
    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });
    dispatch({ type: UPDATE_USER_STATE_SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_USER_STATE_FAILURE, payload: error });
  }
};

export const importToTrinet = (userId) => async (dispatch) => {
  try {
    dispatch({ type: IMPORT_TO_TRINET_REQUEST });

    const headers = await getAuthHeaders();

    await tempMee.post(
      `/admin/users/${userId}/i9/onboard`,
      {},
      {
        headers,
      },
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Import runned successfully.',
      },
    });
    dispatch({ type: IMPORT_TO_TRINET_SUCCESS });
  } catch (error) {
    dispatch({ type: IMPORT_TO_TRINET_FAILURE, payload: error });
  }
};

export const terminateUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: TERMINATE_USER_REQUEST });

    const headers = await getAuthHeaders();

    await tempMee.delete(`/admin/users/${userId}/i9/onboard`, {
      headers,
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Termination ran successfully.',
      },
    });
    dispatch({ type: TERMINATE_USER_SUCCESS });
  } catch (error) {
    dispatch({ type: TERMINATE_USER_FAILURE, payload: error });
  }
};

export const updateW9Form = (docW9) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_W9_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const form = new FormData();
    form.append('document', docW9);
    const headers = await getAuthHeaders();

    const response = await tempMee.patch(`admin/users/${currUserId}/w9`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers,
      },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'W9 was updated successfully.',
      },
    });
    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });
    dispatch({ type: UPDATE_W9_SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_W9_FAILURE, payload: error });
  }
};

export const sendOfficeReleaseForm = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SEND_OFFICE_RELEASE_FORM_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();

    await tempMee.post(
      `/admin/users/${currUserId}/office-release-form`,
      {},
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...headers,
        },
      },
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Release form was sent successfully.',
      },
    });

    dispatch(fetchCurrentUser(currUserId));

    dispatch({ type: SEND_OFFICE_RELEASE_FORM_SUCCESS });
  } catch (error) {
    dispatch({ type: SEND_OFFICE_RELEASE_FORM_FAILURE, payload: error });
  }
};

export const sendW9 = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SEND_W9_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();

    await tempMee.patch(
      `/admin/users/${currUserId}/sendW9`,
      {},
      {
        headers,
      },
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'W9 has been sent successfully.',
      },
    });
    dispatch({ type: SEND_W9_SUCCESS });
  } catch (error) {
    dispatch({ type: SEND_W9_FAILURE, payload: error });
  }
};

export const deleteW9Form = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_W9_REQUEST });

    const headers = await getAuthHeaders();
    const currUserId = getState().users.currentUser.id;

    const response = await tempMee.delete(`admin/users/${currUserId}/w9`, {
      headers,
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'W9 was deleted successfully.',
      },
    });

    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });
    dispatch({ type: DELETE_W9_SUCCESS });
  } catch (error) {
    dispatch({ type: DELETE_W9_FAILURE });
  }
};

export const updateResume = (resume) => async (dispatch, getState) => {
  try {
    dispatch({ type: RESUME_UPLOAD_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const form = new FormData();
    form.append('document', resume);
    const headers = await getAuthHeaders();

    const response = await tempMee.patch(`admin/users/${currUserId}/resume`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers,
      },
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Resume was updated successfully.',
      },
    });

    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });
    dispatch({ type: RESUME_UPLOAD_SUCCESS });
  } catch (error) {
    dispatch({ type: RESUME_UPLOAD_FAILURE, payload: error });
  }
};

export const addAddress = (address, distance) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_NEW_ADDRESS_REQUEST });
    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `admin/users/${currUserId}/address`,
      { place_id: address.place_id, distance },
      { headers },
    );
    dispatch({ type: ADD_NEW_ADDRESS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: ADD_NEW_ADDRESS_FAILURE, payload: error });
  }
};

export const deleteAddress = (addressId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_USER_ADDRESS_REQUEST });
    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    await tempMee.delete(`admin/users/${currUserId}/address/${addressId}`, { headers });
    dispatch({ type: DELETE_USER_ADDRESS_SUCCESS, payload: addressId });
  } catch (error) {
    dispatch({ type: DELETE_USER_ADDRESS_FAILURE, payload: error });
  }
};

export const getUsersReport = (reportType) => async (dispatch, getState) => {
  try {
    dispatch({ type: REPORT_ACTIONS.FETCH_USERS_REPORT_REQUEST });
    let params = {};
    if (reportType === 'unfinished') {
      params = getState().users.unfinishedSearchParams;
    } else if (reportType === 'dnt') {
      params = getState().users.dentistSearchParams;
    } else if (reportType === 'dso') {
      params = getState().users.dsoSearchParams;
    } else if (reportType === 'hyg') {
      params = getState().users.hygienistSearchParams;
    }
    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/users/report', { headers, params });
    dispatch({
      type: REPORT_ACTIONS.FETCH_USERS_REPORT_SUCCESS,
      payload: { report: response.data, reportType },
    });
  } catch (error) {
    dispatch({ type: REPORT_ACTIONS.FETCH_USERS_REPORT_FAILURE, payload: error });
  }
};

export const approveUserBysSMS = (message) => async (dispatch, getState) => {
  // TODO: fix request
  const currUserId = getState().users.currentUser.id;
  const headers = await getAuthHeaders();
  const response = await tempMee.post(`admin/users/${currUserId}/sms`, { message }, { headers });
  dispatch({ type: APPROVE_USER_BY_SMS, payload: response.data });
  dispatch({
    type: SHOW_MESSAGE,
    payload: { message: 'Message has been sent' },
  });
};

export const deleteCurrentUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    const response = await tempMee.delete(`admin/users/${currUserId}/quarantine`, { headers });
    dispatch({ type: DELETE_USER_SUCCESS, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'This profile was deleted successfully.' },
    });
    history.push('/');
  } catch (error) {
    dispatch({ type: DELETE_USER_FAILURE, payload: error });
  }
};

export const getUsersJobs = (state) => async (dispatch, getState) => {
  const searchParams = {
    page: state.page,
    limit: 15,
    sortOrder: state.sortOrder,
    sortField: state.sortField,
    profession: state.profession,
    jobStatus: state.jobStatus,
    specialty: state.specialty,
  };

  dispatch({ type: FETCH_USER_JOBS_REQUEST });
  try {
    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    const response = await tempMee.get(`admin/users/${currUserId}/jobs`, {
      headers,
      params: searchParams,
    });
    dispatch({ type: FETCH_USER_JOBS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: FETCH_USER_JOBS_FAILURE, payload: err });
  }
};

export const getUserOffers = (state) => async (dispatch, getState) => {
  try {
    const currUserId = state.userId || getState().users.currentUser.id;

    const sortFieldConvertion = {
      job_datetime: 'date',
      created_at: 'newest',
      rate: 'rate'
    }

    const newSearchParams = {
      page: state.page,
      limit: state.limit || 15,
      sort: sortFieldConvertion[state.sortField] ?? state.sortField,
      order: state.sortOrder,
      profession: (state.licenseTypes ?? []).join(','),
    };

    const { searchParamsOffers } = getState().users;

    if (JSON.stringify(searchParamsOffers) === JSON.stringify(newSearchParams)) {
      return;
    }

    dispatch({
      type: FETCH_USER_OFFERS_REQUEST,
      payload: newSearchParams,
    });

    const headers = await getAuthHeaders();
    const response = await tempMee.get(`/offer-discovery/admin/users/${currUserId}/offers`, {
      headers,
      params: newSearchParams,
    });

    dispatch({ type: FETCH_USER_OFFERS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: FETCH_USER_OFFERS_FAILURE, payload: err });
  }
};

export const sendEmail = (subject, body) => async (dispatch, getState) => {
  dispatch({ type: SEND_EMAIL_REQUEST });
  try {
    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    await tempMee.post(`admin/users/${currUserId}/sendEmail`, { subject, body }, { headers });
    dispatch({ type: SEND_EMAIL_SUCCESS });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Message has been sent.' },
    });
  } catch (err) {
    dispatch({ type: SEND_EMAIL_FAILURE, payload: err });
  }
};

/**
 * @param {string} licId
 * @param data
 * @param {function | undefined} onCreateOrUpdateCredential
 */
export const updateUserLicense =
  (licId, data, license, onCreateOrUpdateCredential) => async (dispatch, getState) => {
    if (getShouldUsingCredentialServiceEndpointForLicense()) {
      return dispatch(updateUserLicenseV2(licId, data, license, onCreateOrUpdateCredential))
    }

    dispatch({ type: UPDATE_USER_LICENSE_REQUEST });

    try {
      const currUserId = getState().users.currentUser.id;

      const headers = await getAuthHeaders();
      const response = await tempMee.patch(`admin/users/${currUserId}/licenses/${licId}`, data, {
        headers,
      });

      if (onCreateOrUpdateCredential) {
        await onCreateOrUpdateCredential();

        // getting user data once more to get the latest, after create/update license
        // delay is needed since `fetchCurrentUser` might return old value
        setTimeout(() => {
          dispatch(fetchCurrentUser(currUserId, { silentRequest: true }));
        }, 5000);
      } else {
        // update store to latest when no credential is needed to be created/updated
        dispatch({ type: UPDATE_USER_LICENSE_SUCCESS, payload: response.data });
      }

      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: 'License has been updated.' },
      });
    } catch (err) {
      dispatch({ type: UPDATE_USER_LICENSE_FAILURE, payload: err });
    }
  };

export const updateNationalProviderIdentifier =
  (nationalProviderIdentifier) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_NATIONAL_PROVIDER_IDENTIFIER_REQUEST });
    try {
      const currUserId = getState().users.currentUser.id;
      const headers = await getAuthHeaders();

      await tempMee.patch(
        `admin/users/${currUserId}/nationalProviderIdentifier`,
        { nationalProviderIdentifier },
        { headers },
      );

      dispatch({
        type: UPDATE_NATIONAL_PROVIDER_IDENTIFIER_SUCCESS,
        payload: nationalProviderIdentifier,
      });

      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: 'NPI Number has been updated.' },
      });
    } catch (err) {
      dispatch({ type: UPDATE_NATIONAL_PROVIDER_IDENTIFIER_FAILURE, payload: err });
    }
  };

export const hideExistingLicensePopup = () => ({ type: HIDE_EXISTING_LICENSE_POPUP });

export const deleteLicense = (licId) => async (dispatch, getState) => {
  dispatch({ type: DELETE_LICENSE_REQUEST });
  try {
    const currUserId = getState().users.currentUser.id;

    if (getShouldUsingCredentialServiceEndpointForLicense()) {
      await deleteProfLicenseByIdV2(currUserId, licId);
    } else {
      const headers = await getAuthHeaders();
      await tempMee.delete(`admin/users/${currUserId}/licenses/${licId}`, {
        headers,
      });
    }
    dispatch({ type: DELETE_LICENSE_SUCCESS, payload: licId });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'License has been deleted.' },
    });
  } catch (err) {
    dispatch({ type: DELETE_LICENSE_FAILURE, payload: err });
  }
};

export const addLicense = (data) => async (dispatch, getState) => {
  dispatch({ type: ADD_LICENSE_REQUEST });
  try {
    const currUserId = getState().users.currentUser.id;

    const headers = await getAuthHeaders();
    const response = await tempMee.post(`admin/users/${currUserId}/licenses`, data, {
      headers,
    });
    dispatch({ type: ADD_LICENSE_SUCCESS, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'License has been added.' },
    });
  } catch (err) {
    dispatch({ type: ADD_LICENSE_FAILURE, payload: err });
  }
};

export const editCancellationPenalty = (penalty, cancellationId) => async (dispatch, getState) => {
  dispatch({ type: EDIT_CANCELLATION_PENALTY_REQUEST });
  try {
    const currUserId = getState().users.currentUser.id;

    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `admin/user/${currUserId}/cancellation/${cancellationId}`,
      { penalty },
      {
        headers,
      },
    );

    dispatch({ type: EDIT_CANCELLATION_PENALTY_SUCCESS, payload: response.data });
    const restrictionDate = response?.data?.restricted_until;
    if ((restrictionDate && new Date(restrictionDate)) > new Date()) {
      dispatch({ type: USER_ACTIONS.UPDATE_USER_STATUS, payload: 'restricted' });
    } else {
      dispatch({ type: USER_ACTIONS.UPDATE_USER_STATUS, payload: 'verified' });
    }

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Cancellation penalty(or info) has been updated.' },
    });

    return response.data;
  } catch (err) {
    dispatch({ type: EDIT_CANCELLATION_PENALTY_FAILURE, payload: err });
  }
};

export const getAdminUser = () => async (dispatch) => {
  dispatch({ type: GET_ADMIN_USER_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.get('auth/me', {
      headers,
    });

    dispatch({ type: GET_ADMIN_USER_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_ADMIN_USER_FAILURE, payload: err });
  }
};

export const getAdminRamUser = () => async (dispatch) => {
  dispatch({ type: GET_ADMIN_RAM_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.get('admin/rams-dsomanagers', {
      headers,
    });

    dispatch({ type: GET_MANAGERS_LIST_SUCCESS, payload: response.data });
    dispatch({ type: GET_ADMIN_RAM_SUCCESS });
  } catch (err) {
    dispatch({ type: GET_ADMIN_RAM_FAILURE, payload: err });
  }
};

export const patchAdminRamUser = (ramId) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_ADMIN_RAM_REQUEST });
  try {
    const currUserId = getState().users.currentUser.id;

    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `admin/users/${currUserId}/ram/`,
      { ram: ramId },
      {
        headers,
      },
    );
    dispatch({ type: UPDATE_CURRENT_USER_SUCCESS, payload: response.data });
    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'User was updated successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: UPDATE_CURRENT_USER_FAILURE, payload: err });
  }
};

export const getDsoNames = () => async (dispatch) => {
  try {
    dispatch({ type: GET_DSO_NAMES_REQUEST });

    const headers = await getAuthHeaders();

    const response = await tempMee.get('admin/dso-names', {
      headers,
    });

    dispatch({ type: GET_DSO_NAMES_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_DSO_NAMES_FAILURE, payload: err });
  }
};

export const patchDsoManager = (dsoManager) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_ADMIN_DSO_MANAGER_REQUEST });
  try {
    const currUserId = getState().users.currentUser.id;

    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `admin/users/${currUserId}/dsoManager`,
      { dsoManager },
      {
        headers,
      },
    );
    dispatch({ type: UPDATE_CURRENT_USER_SUCCESS, payload: response.data });
    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'User was updated successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: UPDATE_CURRENT_USER_FAILURE, payload: err });
  }
};

export const patchDsoName = (dsoName) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_ADMIN_DSO_NAME_REQUEST });
  try {
    const currUserId = getState().users.currentUser.id;

    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `admin/users/${currUserId}/dsoName`,
      { dsoName },
      {
        headers,
      },
    );
    dispatch({ type: UPDATE_CURRENT_USER_SUCCESS, payload: response.data });
    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'User was updated successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: UPDATE_CURRENT_USER_FAILURE, payload: err });
  }
};

export const updateDsoRamManager = (dsoRamId) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_ADMIN_DSO_NAME_REQUEST });
  try {
    const currUserId = getState().users.currentUser.id;

    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `admin/users/${currUserId}/dso-ram-manager`,
      { dsoRamId },
      {
        headers,
      },
    );

    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'User was updated successfully.',
      },
    });
    dispatch({ type: UPDATE_CURRENT_USER_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: UPDATE_CURRENT_USER_FAILURE, payload: err });
  }
};

export const getCancellationPolicies = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CANCELLATION_POLICIES_REQUEST });

    const headers = await getAuthHeaders();

    const response = await tempMee.get('admin/cancellation-policy', {
      headers,
    });

    dispatch({ type: GET_CANCELLATION_POLICIES_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_CANCELLATION_POLICIES_FAILURE, payload: err });
  }
};

export const fetchPartnershipOrganizations = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_PARTNERSHIP_ORGANIZATIONS_REQUEST });

    const headers = await getAuthHeaders(dispatch, getState);

    const response = await tempMee.get('partnership-code', {
      headers,
    });

    dispatch({ type: FETCH_PARTNERSHIP_ORGANIZATIONS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: FETCH_PARTNERSHIP_ORGANIZATIONS_FAILURE, payload: err });
  }
};

export const createStripeAccount = () => async (dispatch, getState) => {
  dispatch({ type: CREATE_STRIPE_ACCOUNT_REQUEST });
  try {
    const user = getState().users.currentUser;

    const headers = await getAuthHeaders();
    let response;
    await tempMee.patch(
      `/payment/admin/account/${user?.id}`,
      {
        user: {
          user_type: user?.user_type,
          first_name: user?.first_name,
          last_name: user?.last_name,
          full_name: user?.full_name,
          email: user?.email_id,
          formatted_address: user?.address?.[0]?.formatted_address,
          city: user?.address?.[0]?.city,
          state: user?.address?.[0]?.state,
          country: user?.address?.[0]?.country,
          postal_code: user?.address?.[0]?.postal_code,
          phone_number: user?.contact_number,
        },
      },
      {
        headers,
      },
    );
    dispatch(fetchCurrentUser(user?.id));

    dispatch({ type: CREATE_STRIPE_ACCOUNT_SUCCESS, payload: response?.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Stripe account created' },
    });
  } catch (err) {
    dispatch({ type: CREATE_STRIPE_ACCOUNT_FAILURE, payload: err });
  }
};

export const getUserReceipts = (state) => async (dispatch, getState) => {
  try {
    const currUserId = getState().users.currentUser.id;

    const newSearchParams = {
      page: state.page,
      limit: state.limit || 25,
      currUserId,
    };

    const { receiptsPagination } = getState().users.officeReceipts;
    if (
      JSON.stringify(receiptsPagination) === JSON.stringify(newSearchParams) ||
      (state.page !== 1 && state.page <= receiptsPagination.page)
    ) {
      return;
    }

    dispatch({ type: USER_ACTIONS.GET_OFFICE_RECEIPTS_REQUEST, payload: newSearchParams });

    const headers = await getAuthHeaders();

    const response = await tempMee.get(`admin/users/${currUserId}/receipts`, {
      headers,
      params: newSearchParams,
    });
    dispatch({ type: USER_ACTIONS.GET_OFFICE_RECEIPTS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: USER_ACTIONS.GET_OFFICE_RECEIPTS_FAILURE, payload: err });
  }
};

export const getUserInvoices = (state) => async (dispatch, getState) => {
  try {
    const currUserId = getState().users.currentUser.id;

    const newParams = {
      page: state.page,
      limit: state.limit || 25,
      sort: state.sort || 'date',
      order: state.order || 'desc',
    };

    const { invoicesPagination } = getState().users.officeInvoices;
    if (
      JSON.stringify(invoicesPagination) === JSON.stringify(newParams) ||
      (state.page !== 1 && state.page <= invoicesPagination.page)
    ) {
      return;
    }

    dispatch({ type: USER_ACTIONS.GET_OFFICE_INVOICES_REQUEST, payload: newParams });

    const headers = await getAuthHeaders();

    const response = await tempMee.get(`admin/${currUserId}/invoices`, {
      headers,
      params: newParams,
    });
    dispatch({ type: USER_ACTIONS.GET_OFFICE_INVOICES_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: USER_ACTIONS.GET_OFFIC_INVOICES_FAILURE, payload: err });
  }
};

export const createEmptyUser = (user_type) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_EMPTY_USER_REQUEST });
    const headers = await getAuthHeaders();
    const response = await tempMee.post('admin/users', { user_type }, { headers });
    dispatch({
      type: CREATE_EMPTY_USER_SUCCESS,
      payload: response.data,
    });
    if (user_type === 'HYG') {
      history.push(`/user/hygienist/${response.data.new_id}`);
    } else {
      history.push(`/user/dentist/${response.data.new_id}`);
    }
  } catch (error) {
    dispatch({ type: CREATE_EMPTY_USER_FAILURE, payload: error });
  }
};

export const getBlockedUsers = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_ACTIONS.GET_OFFICES_BLOCKED_REQUEST });

    const headers = await getAuthHeaders();

    const response = await tempMee.get(`admin/users/${userId}/blocked`, {
      headers,
    });
    dispatch({ type: USER_ACTIONS.GET_OFFICES_BLOCKED_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: USER_ACTIONS.GET_OFFICES_BLOCKED_FAILURE, payload: err });
  }
};
export const updateUserStripeEmail =
  ({ email, userId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CURRENT_USER_REQUEST });
      const headers = await getAuthHeaders();
      const response = await tempMee.put(
        `admin/users/${userId}/billing-email`,
        { email },
        {
          headers,
        },
      );
      dispatch({ type: UPDATE_CURRENT_USER_SUCCESS, payload: response.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: 'Email Stripe Has Been Updated',
        },
      });
    } catch (err) {
      dispatch({ type: UPDATE_CURRENT_USER_FAILURE, payload: err });
    }
  };

export const fetchNotificationSettings = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_REQUEST });

    const { currentUser } = getState().users || {};

    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.get(
      `notification-sender/admin/user/${userId ?? currentUser?.id}/notification-settings?userType=DNT`,
      {
        headers,
      },
    );

    dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_FAILURE, payload: err });
  }
};

export const updateUserNotificationSettings =
  (notificationSettings, userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_USER_NOTIFICATION_SETTINGS_REQUEST });

      const { currentUser } = getState().users || {};

      const headers = await getAuthHeaders(dispatch, getState);
      const response = await tempMee.patch(
        `notification-sender/admin/user/${userId ?? currentUser?.id}/notification-settings?userType=DNT`,
        notificationSettings,
        {
          headers,
        },
      );

      dispatch({ type: UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS, payload: response.data });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: 'User notification preferences have been updated!',
        },
      });
    } catch (err) {
      dispatch({ type: UPDATE_USER_NOTIFICATION_SETTINGS_FAILURE, payload: err });
    }
  };

export const fetchUserFavoritesList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_USER_FAVORITES_LIST_REQUEST });

    const userId = getState().users.currentUser.id;

    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.get(`admin/users/${userId}/favorite-professionals`, {
      headers,
    });

    dispatch({ type: FETCH_USER_FAVORITES_LIST_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: FETCH_USER_FAVORITES_LIST_FAILURE, payload: err });
  }
};

export const fetchUserHighlyRatedProfessionalsList = (page) => async (dispatch, getState) => {
  try {
    const userId = getState().users.currentUser.id;

    dispatch({
      type: FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_REQUEST,
      payload: { page },
    });

    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.get(`admin/users/${userId}/highly-rated`, {
      headers,
      params: {
        limit: 15,
        page,
      },
    });

    dispatch({ type: FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_FAILURE, payload: err });
  }
};

export const fetchOfficesWithFavoritedProfessional =
  ({ userId, page = 1, limit = 10 }) =>
  async (dispatch, getState) => {
    try {
      const { officesWithFavoritedProfessional } = getState().users;
      const newParams = {
        ...officesWithFavoritedProfessional,
        page,
        limit,
        userId,
      };

      if (
        JSON.stringify(officesWithFavoritedProfessional) === JSON.stringify(newParams) ||
        (page !== 1 && page <= officesWithFavoritedProfessional.page)
      ) {
        return;
      }

      dispatch({
        type: FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_REQUEST,
        payload: newParams,
      });

      const headers = await getAuthHeaders(dispatch, getState);
      const response = await tempMee.get(`admin/offices`, {
        headers,
        params: {
          page,
          limit,
          favoriteProfessional: userId,
        },
      });

      dispatch({
        type: FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      dispatch({ type: FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_FAILURE, payload: err });
    }
  };

export const fetchUserWorkerClassification = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_USER_WORKER_CLASSIFICATION_REQUEST });

    const userId = getState().users.currentUser.id;

    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.get(`/admin/users/${userId}/worker-classification`, {
      headers,
    });

    dispatch({ type: FETCH_USER_WORKER_CLASSIFICATION_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: FETCH_USER_WORKER_CLASSIFICATION_FAILURE, payload: err });
  }
};

export const saveUserWorkerClassification = (workerType) => async (dispatch, getState) => {
  try {
    dispatch({ type: SAVE_USER_WORKER_CLASSIFICATION_REQUEST });

    const userId = getState().users.currentUser.id;

    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.patch(
      `/admin/users/${userId}/worker-classification`,
      { type: workerType },
      {
        headers,
      },
    );

    dispatch(fetchCurrentUser(userId));

    dispatch({ type: SAVE_USER_WORKER_CLASSIFICATION_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: SAVE_USER_WORKER_CLASSIFICATION_FAILURE, payload: err });
  }
};

export const addFavoriteUser = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_FAVORITE_USER_REQUEST });

    const officeId = getState().users.currentUser.id;

    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.patch(
      `admin/users/${officeId}/favorite-professionals`,
      { userIds: [userId] },
      {
        headers,
      },
    );

    dispatch({ type: ADD_FAVORITE_USER_SUCCESS, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Favorite user has been added.' },
    });
  } catch (err) {
    dispatch({ type: ADD_FAVORITE_USER_FAILURE, payload: err });
  }
};

export const deleteFavoriteUser = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_FAVORITE_USER_REQUEST });

    const officeId = getState().users.currentUser.id;

    const headers = await getAuthHeaders(dispatch, getState);
    await tempMee.delete(`admin/users/${officeId}/favorite-professionals/${userId}`, {
      headers,
    });

    dispatch({ type: DELETE_FAVORITE_USER_SUCCESS, payload: userId });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Favorite user has been removed.' },
    });
  } catch (err) {
    dispatch({ type: DELETE_FAVORITE_USER_FAILURE, payload: err });
  }
};

export const fetchNotificationSettingsHYG = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_HYG_REQUEST });

    const userId = getState().users.currentUser.id;

    const headers = await getAuthHeaders(dispatch, getState);
    const [response, responseNotification] = await Promise.all([
      tempMee.get(`notification-sender/admin/user/${userId}/job-notification-settings`, {
        headers,
      }),
      tempMee.get(`notification-sender/admin/user/${userId}/notification-settings?userType=HYG`, {
        headers,
      }),
    ]);

    dispatch({
      type: FETCH_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS,
      payload: {
        job: response.data?.settings,
        notification: responseNotification?.data,
      },
    });
  } catch (err) {
    dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_HYG_FAILURE, payload: err });
  }
};

export const updateUserNotificationSettingsHYG =
  (jobSettings, notificationSettings) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_USER_NOTIFICATION_SETTINGS_HYG_REQUEST });

      const userId = getState().users.currentUser.id;

      const headers = await getAuthHeaders(dispatch, getState);
      await Promise.all([
        tempMee.put(
          `notification-sender/admin/user/${userId}/job-notification-settings`,
          jobSettings,
          {
            headers,
          },
        ),
        tempMee.patch(
          `notification-sender/admin/user/${userId}/notification-settings?userType=HYG`,
          notificationSettings,
          {
            headers,
          },
        ),
      ]);

      dispatch({
        type: UPDATE_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS,
        payload: notificationSettings,
      });
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          message: 'User notification preferences have been updated!',
        },
      });
    } catch (err) {
      dispatch({ type: UPDATE_USER_NOTIFICATION_SETTINGS_HYG_FAILURE, payload: err });
    }
  };

export const getAllMetadata = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_METADATA_REQUEST });

    const headers = await getAuthHeaders();
    const response = await tempMee.get('metadata', {
      headers,
    });

    dispatch({ type: GET_ALL_METADATA_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_ALL_METADATA_FAILURE, payload: err });
  }
};

export const updateMedMalStatus = (body) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_ACTIONS.UPDATE_MEDMAL_STATUS_REQUEST });

    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(`admin/users/${currUserId}`, body, {
      headers,
    });
    dispatch({ type: USER_ACTIONS.UPDATE_MEDMAL_STATUS_SUCCESS, payload: response.data });
    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: `This profile was updated successfully. MedMal Status has been updated to ${body.medMalSurveyStatus}`,
      },
    });
  } catch (err) {
    dispatch({ type: USER_ACTIONS.UPDATE_MEDMAL_STATUS_FAILURE, payload: err });
  }
};

export const updateI9Status = (body) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_I9_REQUEST });
    const currUserId = getState().users.currentUser.id;
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(`admin/users/${currUserId}/employee`, body, {
      headers,
    });
    dispatch({ type: UPDATE_I9_SUCCESS, payload: response.data });
    dispatch({ type: UPDATE_CURRENT_USER, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'I9 was updated successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: UPDATE_I9_FAILURE, payload: err });
  }
};

export const fetchProfessionalStats = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_ACTIONS.FETCH_PROFESSIONAL_STATS_REQUEST });

    const headers = await getAuthHeaders();
    const isMetricsServiceEnabled = getShouldUseMetricsService();

    let data = {};
    if (isMetricsServiceEnabled) {
      const response = await tempMee.get(
        `metrics-service/admin/professional-metrics?professionalIds=${userId}`,
        { headers },
      );
      const responseData = response?.data?.data?.[0] || {};

      data = {
        completed_jobs: responseData?.completedJobsCount || 0,
        no_show_count: responseData?.noShowCount || 0,
        total_cancellation_count: responseData?.totalCancellationCount || 0,
        late_cancellation_count: responseData?.lateCancelCount || 0,
        attendance_percentage: responseData?.attendanceScore || 0,
        average_rating: responseData?.averageRating,
      };
    } else {
      const response = await tempMee.get(`admin/users/${userId}/stats`, { headers });
      data = {
        completed_jobs: getState().users?.currentUser?.completedJobs || 0,
        no_show_count: response?.data?.no_show_count || 0,
        total_cancellation_count: response?.data?.total_cancellation_count || 0,
        late_cancellation_count: response?.data?.late_cancellation_count || 0,
        attendance_percentage: response?.data?.attendance_percentage || 0,
        average_rating: getState().users?.currentUser?.average_rating,
      };
    }

    dispatch({
      type: USER_ACTIONS.FETCH_PROFESSIONAL_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: USER_ACTIONS.FETCH_PROFESSIONAL_STATS_FAILURE, payload: error });
  }
};

export const fetchProfessionalStatsByDay = (userId, daysCount) => async (dispatch) => {
  try {
    dispatch({ type: USER_ACTIONS.FETCH_PROFESSIONAL_STATS_BY_DAY_REQUEST });

    const headers = await getAuthHeaders();
    const response = await tempMee.get(`admin/users/${userId}/stats`, {
      params: { daysCount },
      headers,
    });

    dispatch({
      type: USER_ACTIONS.FETCH_PROFESSIONAL_STATS_BY_DAY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: USER_ACTIONS.FETCH_PROFESSIONAL_STATS_BY_DAY_FAILURE, payload: error });
  }
};

export const updateUserInfo = (userId, staffId, payload) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_ACTIONS.UPDATE_USER_INFO_REQUEST });

    await updateUserService(userId, payload);

    await fetchStaffById(staffId)(dispatch, getState);

    dispatch({ type: USER_ACTIONS.UPDATE_USER_INFO_SUCCESS });
  } catch (err) {
    dispatch({ type: USER_ACTIONS.UPDATE_USER_INFO_FAILURE, payload: err });
  }
};

/** start of cred-service license v2 */

export const fetchCurrentUserLicensesV2 = (userId) => async (dispatch) => {
  if (!getShouldUsingCredentialServiceEndpointForLicense()) {
    return;
  }
  try {
    dispatch({
      type: LICENSE_ACTIONS.GET_LICENSES_REQUEST,
    });
    const response = await getProfLicensesV2(userId);

    dispatch({
      type: LICENSE_ACTIONS.GET_LICENSES_SUCCESS,
      payload: convertLicensesV2toV1(response?.data?.data || []),
    });
  } catch (error) {
    dispatch({
      type: LICENSE_ACTIONS.GET_LICENSES_FAILED,
      payload: error,
    });
  }
};

export const fetchCurrentUserLicensesDetailV2 = (licenseId) => async (dispatch, getState) => {
  if (!getShouldUsingCredentialServiceEndpointForLicense()) {
    return;
  }
  try {
    dispatch({
      type: LICENSE_ACTIONS.GET_LICENSE_DETAIL_REQUEST,
      payload: licenseId,
    });
    const { users } = getState();
    const response = await getProfLicenseByIdV2(users.currentUser?.id, licenseId);
    const newLicense = convertLicenseDetailV2toV1(response?.data);

    const licenses =
      users.currentUser?.licenses?.map?.((item) => {
        if (item.id === licenseId) {
          return newLicense;
        }

        return item;
      }) || [];

    dispatch({
      type: LICENSE_ACTIONS.GET_LICENSE_DETAIL_SUCCESS,
      payload: licenses,
    });
  } catch (error) {
    dispatch({
      type: LICENSE_ACTIONS.GET_LICENSE_DETAIL_FAILED,
      payload: error,
    });
  }
};

export const addUserLicenseV2 = (data, onSuccess) => async (dispatch, getState) => {
  const { profession, subProfession, credentials } = data;
  const professionFinal =
    profession === UserProfession.DA && subProfession === UserSubProfession.EFDA
      ? UserSubProfession.EFDA
      : profession;

  dispatch({ type: ADD_LICENSE_REQUEST });
  try {
    const { currentUser } = getState().users;

    const payload = getLicensesV2Payload(data, credentials, currentUser, subProfession);

    let newLicenseResponse;

    const file = payload?.data?.file;

    if (file) {
      const form = new FormData();

      form.append(file?.type, file?.uri);
      form.append(
        'command',
        JSON.stringify({
          state: payload?.data.state,
          stateLicense: payload?.data?.stateLicense,
        }),
      );
      newLicenseResponse = await addProfLicenseDaV2(currentUser?.id, professionFinal, form);
    } else {
      newLicenseResponse = await addProfLicenseV2(
        currentUser?.id,
        professionFinal,
        payload?.data,
      );
    }
    const newLicenseData = newLicenseResponse?.data;
    const licenseId = newLicenseData?.id;

    if (payload.files.length > 0 && licenseId) {
      // for upload files DA and FD
      await Promise.all(
        handleUploadLicenseFilesV2(currentUser?.id, licenseId, payload.files, subProfession)
      );
    }

    const newLicenses = convertLicensesV2toV1([newLicenseData]);

    dispatch({ type: ADD_LICENSE_SUCCESS, payload: newLicenses?.[0] });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'License has been added.' },
    });
    onSuccess?.();
  } catch (err) {
    const { error } = err?.response?.data || {};
    if (
      error === 'invalid state license' ||
      error === 'invalid state' ||
      error === 'missing required file type'
    ) {
      dispatch({
        type: ADD_LICENSE_FAILURE,
        payload: {
          ...err,
          response: {
            data: {
              error: 'Missing required fields',
            },
          },
        },
      });
    } else {
      dispatch({ type: ADD_LICENSE_FAILURE, payload: err });
    }
  }
};

/**
 * @param {string} licId
 * @param data
 * @param {function | undefined} onCreateOrUpdateCredential
 */
export const updateUserLicenseV2 =
  (licId, data, license, onCreateOrUpdateCredential) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_USER_LICENSE_REQUEST });

    try {
      const currUserId = getState().users.currentUser.id;

      await updateProfLicenseV2(currUserId, licId, data);

      dispatch({ type: UPDATE_USER_LICENSE_SUCCESS, payload: license });
      
      await onCreateOrUpdateCredential();
      await dispatch(fetchCurrentUserLicensesDetailV2(licId));
      dispatch({
        type: SHOW_MESSAGE,
        payload: { message: 'License has been updated.' },
      });
    } catch (err) {
      dispatch({ type: UPDATE_USER_LICENSE_FAILURE, payload: err });
    }
  };

/** end of cred-service license v2 */
