import { useParams } from 'react-router-dom';
import { Spinner } from '../../../../../commonComponents/Spinner';
import { GroupsCard, OfficesCard, RegionsCard, UnlinkedOfficeLabel } from './components';

export const OrgStructureTab = () => {
  const params = useParams();

  if (!params.id) {
    return <Spinner />;
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
        marginTop: 20,
        paddingBottom: 40,
      }}
    >
      <span
        style={{
          fontSize: 24,
          marginTop: 20,
          textAlign: 'center',
        }}
      >
        Org Structure
      </span>
      <UnlinkedOfficeLabel />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1.5fr',
          gridGap: 20,
          width: '100%',
        }}
      >
        <GroupsCard />
        <RegionsCard />
        <OfficesCard />
      </div>
    </div>
  );
};
