import { Colors } from '../../../../../../../themes/colors';

export const UnlinkedOfficeLabel = () => (
  <div
    style={{
      display: 'flex',
      alignSelf: 'flex-end',
      alignItems: 'center',
      gap: 8,
      marginTop: 8,
      marginBottom: 12,
      marginRight: 8,
      color: Colors.neutral_500,
    }}
  >
    <div
      style={{
        width: 16,
        height: 16,
        border: '1px solid #A34B15',
        borderRadius: 4,
        backgroundColor: '#E26E26',
      }}
    />
    <span
      style={{
        fontSize: 16,
        color: Colors.neutral_500,
      }}
    >
      Unlinked Office
    </span>
  </div>
);
