import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useJobsList } from './hooks/useJobsList';
import { TabViewContainer } from '../../../../../commonComponents/TabViewContainer';
import { userJobListColumns } from '../../../../../commonComponents/jobs-column';
import { Spinner } from '../../../../../commonComponents/Spinner';

export const JobsTab = () => {
  const { id: dsoId } = useParams();

  const { data, isFirstLoading, isLoading, handleScroll } = useJobsList(dsoId);

  const getJobUrl = (job) => `/job/${job.id}`;
  const getUserUrl = (userId, userType) => `/user/${userType.toLowerCase()}/${userId}`;

  const columns = useMemo(() =>
    userJobListColumns(
      {
        getUserUrl,
        getJobUrl,
      },
      [],
    ),
  );

  return (
    <TabViewContainer
      title="Jobs"
      variant="card"
      style={{
        alignItems: 'center',
        width: '90%',
        marginBottom: 0,
      }}
    >
      {isFirstLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 400,
          }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="info"
            style={{
              height: 40,
              width: 40,
            }}
          />
        </div>
      ) : (
        <div
          className="card"
          style={{
            width: '100%',
            height: 500,
            overflowY: 'scroll',
            overflowX: 'scroll',
            marginTop: 40,
          }}
          onScroll={handleScroll}
        >
          <table>
            <tr
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                backgroundColor: 'lightblue',
                position: 'sticky',
                top: '0',
              }}
            >
              {columns.map((column) => (
                <td key={column.name} className="card-text table-title-text">
                  {column.name}
                </td>
              ))}
            </tr>
            {data.map((item) => (
              <tr
                key={item.id}
                style={{
                  width: '100%',
                  borderBottom: '1px solid #efefef',
                  padding: '5px 10px',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {columns.map((column) => {
                  if (column.cell) {
                    return (
                      <td key={column.name} className="card-text">
                        {column.cell(item)}
                      </td>
                    );
                  }

                  if (column.format) {
                    return (
                      <td key={column.name} className="card-text">
                        {column.format(item)}
                      </td>
                    );
                  }

                  return (
                    <td key={column.name} className="card-text py-3">
                      {column.selector(item)}
                    </td>
                  );
                })}
              </tr>
            ))}
          </table>
          {isLoading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            </div>
          )}
          {!data.length && (
            <div
              style={{
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              There are no Jobs
            </div>
          )}
        </div>
      )}
    </TabViewContainer>
  );
};
