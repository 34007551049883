import React from 'react';
import { RectangleIcon } from '../../../../images/icons/RectangleIcon';

export const CustomHeader = () => (
  <div
    style={{
      display: 'flex',
      width: '90%',
      justifyContent: 'flex-end',
      fontSize: 16,
      gap: 23,
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
      <RectangleIcon />
      <span>Available</span>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
      <RectangleIcon border="#E2E4A7" background="#FDFFC9" />
      <span>On-Call</span>
    </div>
  </div>
);
