import { getAuthHeaders, getAuthServiceHeaders } from '../actions/authActions';
import tempMeeApi from '../apis/tempMee';

export const patchUser = async (userId, data = {}) => {
  const headers = await getAuthHeaders();

  return tempMeeApi.patch(`admin/users/${userId}`, data, { headers });
};

/**
 *
 * @param {string} id - The unique identifier of the user.
 * @param {Object} payload - The parameters object.
 * @param {string} payload.firstName - The first name of the user.
 * @param {string} payload.lastName - The last name of the user.
 * @param {string} payload.email - The email address of the user.
 * @param {string} payload.phone - The phone number of the user.
 *
 * @details https://bump.sh/gotu/hub/gotu-all-services/doc/auth-service/operation/operation-patch-admin-users-id
 *
 * @returns {Promise<void>} A promise that resolves to the updated user object.
 * @throws {Error} If the request fails.
 */
export const updateUser = async (id, payload = {}) => {
  const headers = await getAuthServiceHeaders();

  const response = await tempMeeApi.patch(`/auth-service/admin/users/${id}`, payload, {
    headers,
  });

  return response.data;
};
