import { OrderBy as OrderByEnum } from '../../../enums/Sorting';
import { Colors } from '../../../themes/colors';
import { OrderBy } from './OrderBy';

export const SubHeader = ({ total, children, onOrderBy, orderBy = OrderByEnum.ASC }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0px 32px 0 32px',
      gap: 12,
    }}
  >
    <span style={{ fontSize: 16, color: Colors.neutral_500 }}>Results ({total})</span>
    <div style={{ flex: 1 }}>{children}</div>
    <OrderBy orderBy={orderBy} onOrderBy={onOrderBy} />
  </div>
);
