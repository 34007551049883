import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import imageCompression from 'browser-image-compression';
import ImageZoom from 'react-medium-image-zoom';
import { uploadCredential, deleteCredentialFile } from '../../../../actions/licensesAction';
import { DeleteIcon, LeftIcon, PlusIcon, RightIcon } from '../../../../images/icons';

const Slider = ({ credential, license, setLicenses, credentialType }) => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState(credential?.files || []);
  const [thumbnail, setThumbnail] = useState(credential?.files?.[0] || {});
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    setFiles(credential.files);
  }, [credential.files]);

  const resetThumbnail = () => {
    if (credential?.files?.length > 0) {
      setCurrentIndex(0);
      setThumbnail(credential?.files[0]);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            position: 'relative',
            width: 336,
            height: 224,
            backgroundColor: '#D9D9D9E5',
            borderRadius: 5,
            overflow: 'hidden',
          }}
        >
          <ImageZoom
            image={{
              src: thumbnail?.s3_url,
              alt: 'license_image',
              className: 'img',
              style: {
                objectFit: 'contain',
                width: '100%',
                height: 'auto',
              },
            }}
            zoomImage={{
              src: thumbnail?.s3_url,
              alt: 'license_image',
              style: {
                height: 'auto',
                maxWidth: 500,
                width: 'auto',
              },
            }}
          />

          <span
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (currentIndex - 1 < files.length - 1 && currentIndex - 1 >= 0) {
                const nextIndex = currentIndex - 1;
                setCurrentIndex(nextIndex);
                setThumbnail(files[nextIndex]);
              } else {
                setCurrentIndex(files.length - 1);
                setThumbnail(files[files.length - 1]);
              }
            }}
            style={{
              position: 'absolute',
              top: '40%',
              left: -7,
              cursor: 'pointer',
            }}
          >
            <LeftIcon />
          </span>

          <span
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (currentIndex + 1 < files.length) {
                const nextIndex = currentIndex + 1;
                setCurrentIndex(nextIndex);
                setThumbnail(files[nextIndex]);
              } else {
                setCurrentIndex(0);
                setThumbnail(files[0]);
              }
            }}
            style={{
              position: 'absolute',
              top: '40%',
              right: -7,
              cursor: 'pointer',
            }}
          >
            <RightIcon />
          </span>

          <label
            style={{
              position: 'absolute',
              bottom: -11,
              left: '40%',
              cursor: 'pointer',
            }}
            htmlFor={`file_${credentialType}_${license.id}`}
          >
            <PlusIcon />
          </label>

          <input
            onChange={async (event) => {
              event.stopPropagation();
              event.preventDefault();

              let file = event.target.files[0];
              const options = {
                maxSizeMB: 1,
              };
              try {
                file = await imageCompression(file, options);
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
              }

              dispatch(uploadCredential(file, null, license.id, credential?.id)).then((res) => {
                if (!res || res?.error) return;
                setLicenses((prevState) => {
                  const licenseIndex = prevState.findIndex((data) => data.id === license.id);
                  const cIndex = prevState[licenseIndex].credentials.findIndex(
                    (c) => c && c.credential_type === credentialType,
                  );

                  prevState[licenseIndex].credentials[cIndex] = res;
                  return [...prevState];
                });
              });
            }}
            type="file"
            id={`file_${credentialType}_${license.id}`}
            style={{ display: 'none' }}
          />

          <span
            onClick={() => {
              if (thumbnail?._id) {
                dispatch(deleteCredentialFile(license.id, credential.id, thumbnail?.file_id)).then(
                  () => {
                    setLicenses((prevState) => {
                      const licenseIndex = prevState.findIndex((data) => data.id === license.id);
                      const credentialIndex = prevState[licenseIndex].credentials.findIndex(
                        (c) => c && c.credential_type === credentialType,
                      );

                      if (credentialIndex > -1) {
                        prevState[licenseIndex].credentials[credentialIndex].files = prevState[
                          licenseIndex
                        ].credentials[credentialIndex].files.filter(
                          (f) => f._id !== thumbnail?._id,
                        );
                      }
                      return [...prevState];
                    });
                    resetThumbnail();
                  },
                );
              }
            }}
            style={{
              position: 'absolute',
              bottom: -5,
              left: '54%',
              cursor: 'pointer',
            }}
          >
            <DeleteIcon />
          </span>
        </div>

        <div
          style={{
            marginTop: 10,
            height: 90,
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            margin: 20,
            width: 270,
          }}
        >
          {files.map((file, index) => (
            <div
              onClick={() => {
                setThumbnail(file);
                setCurrentIndex(index);
              }}
              style={{
                width: 92,
                height: 62,
                backgroundColor: '#D9D9D9E5',
                borderRadius: 5,
                position: 'relative',
                flex: '0 0 auto',
                width: 92,
                height: 62,
                maxWidth: '100%',
                marginRight: '10px',
                backgroundImage: `url(${file?.s3_url})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                border: `1px solid ${index === currentIndex ? 'grey' : 'white'}`,
              }}
            >
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (file?._id) {
                    dispatch(deleteCredentialFile(license.id, credential.id, file?.file_id)).then(
                      () => {
                        setLicenses((prevState) => {
                          const licenseIndex = prevState.findIndex(
                            (data) => data.id === license.id,
                          );
                          const credentialIndex = prevState[licenseIndex].credentials.findIndex(
                            (c) => c && c.credential_type === credentialType,
                          );

                          if (credentialIndex > -1) {
                            prevState[licenseIndex].credentials[credentialIndex].files = prevState[
                              licenseIndex
                            ].credentials[credentialIndex].files.filter((f) => f._id !== file?._id);
                          }
                          return [...prevState];
                        });
                        if (thumbnail._id === file._id) {
                          resetThumbnail();
                        }
                      },
                    );
                  }
                }}
                style={{
                  position: 'absolute',
                  bottom: -7,
                  left: '40%',
                  cursor: 'pointer',
                }}
              >
                <DeleteIcon />
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
