import { REFERRAL_ACTIONS } from '../actions/actionTypes';

const defaultState = {
  referrals: [],
  totalReferrals: 0,
  searchParams: {
    referralPage: 1,
    payFilter: '',
    searchText: '',
  },
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  switch (action.type) {
    case REFERRAL_ACTIONS.GET_REFERRAL_REQUEST:
      return {
        ...state,
        searchParams: action.payload,
        referrals: [],
      };
    case REFERRAL_ACTIONS.GET_REFERRAL_SUCCESS:
      return {
        ...state,
        referrals: action.payload.data,
        totalReferrals: action.payload.total,
      };
    case REFERRAL_ACTIONS.PAYOUT_REFERRAL_SUCCESS: {
      const newReferrals = state.referrals.map((r) => {
        if (r.id === action.payload) {
          // eslint-disable-next-line no-param-reassign
          r.status = 'completed';
        }
        return r;
      });
      return {
        ...state,
        referrals: newReferrals,
      };
    }
    case REFERRAL_ACTIONS.CANCEL_REFERRAL_SUCCESS: {
      const newReferrals = state.referrals.map((r) => {
        if (r.id === action.payload?.id) {
          return {
            ...r,
            ...action.payload,
          };
        }
        return r;
      });
      return {
        ...state,
        referrals: newReferrals,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
