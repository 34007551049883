import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { createLoadingSelector } from '../../../../../../../apis/selectors';
import { fetchDsoJobs } from '../../../../../../../actions/dsosActions';

const getJobsLoadingSelector = createLoadingSelector(['GET_DSO_JOBS']);

export const useJobsList = (dsoId) => {
  const dispatch = useDispatch();

  const { data, filters, isFinalPage } = useSelector((state) => state.dsos.dsoJobs);

  const isLoading = useSelector((state) => getJobsLoadingSelector(state));

  const handleScroll = useCallback(
    (event) => {
      const tableScrollHeight = event?.target?.scrollHeight - event?.target?.scrollTop - 20;
      const clientHeight = event?.target?.clientHeight;

      if (tableScrollHeight <= clientHeight && !isFinalPage && !isLoading) {
        dispatch(fetchDsoJobs(dsoId, { page: filters.page + 1 }));
      }
    },
    [dispatch, dsoId, filters.page, isFinalPage, isLoading],
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    dispatch(fetchDsoJobs(dsoId, { page: 1 }));
  }, []);

  return {
    data,
    isFirstLoading: isLoading && !data.length,
    isLoading,
    filters,
    handleScroll,
  };
};
