import { useState } from 'react';
import { LinkOfficeForm } from './components';

export const LinkOffice = () => {
  const [isOpenModal, setOpenModal] = useState(false);

  return (
    <>
      <button className="btn btn-success ml-auto" type="button" onClick={() => setOpenModal(true)}>
        Link Office
      </button>
      {isOpenModal && <LinkOfficeForm onClosePopup={() => setOpenModal(false)} />}
    </>
  );
};
