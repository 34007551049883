import { addRegionToGroup } from '../../../../../../../../../../actions/dsosActions';
import { LinkEntityMenuItem } from '../../../LinkEntityTo';
import { useLinkRegionToGroup } from './hooks';

export const LinkRegionToGroupMenuItem = ({ dsoId, item }) => (
  <LinkEntityMenuItem
    dsoId={dsoId}
    item={item}
    entityName="Region"
    linkTo="Group"
    fetchHook={useLinkRegionToGroup}
    action={({ items, entity }) => addRegionToGroup({ groupId: items[0].id, region: entity })}
  />
);
