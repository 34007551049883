import React from 'react';

import '../../themes/Checkbox.scss';

/**
 *
 * @param {string} htmlFor
 * @param {function} onChanged
 * @param {string} label
 * @param {boolean} checked
 * @param {boolean} disabled
 * @param {object} textStyle
 * @param {object} style
 *
 * @returns {React.JSX.Element}
 */
export function Checkbox({
  htmlFor,
  onChanged,
  label,
  checked,
  disabled = false,
  textStyle = {},
  style = {},
  checkboxStyle = {},
}) {
  return (
    <label htmlFor={htmlFor} className="checkbox" style={style}>
      <input
        id={htmlFor}
        type="checkbox"
        className="form-control"
        onClick={(e) => {
          e.preventDefault();

          onChanged();
        }}
        defaultChecked={checked}
        disabled={disabled}
        style={checkboxStyle}
      />

      {label && <span style={textStyle}>{label}</span>}
    </label>
  );
}
